import { processNamesConfig } from '../config/processNames';

import { isAionTheme } from './setupTheme';
import { themeName } from './Envutils';

import type { TBusinessProcess } from '../reducers';

export const getBusinessProcessName = (process: TBusinessProcess): 'ROP' | 'REN' | null => {
  if (process === 'ROP') return processNamesConfig.rop;
  if (process === 'REN') return processNamesConfig.ren;
  return null;
};

const mapFormProcess = (): string => {
  if (isAionTheme(themeName)) { return processNamesConfig.nbAion; }
  return processNamesConfig.nb;
};

export const getProcessName = (process?: TBusinessProcess): string => {
  if (!process) {
    return mapFormProcess();
  }
  return getBusinessProcessName(process) || mapFormProcess();
};
