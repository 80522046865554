export const SURNAME = new RegExp(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+(-[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*)?$/i);

export const NAME = new RegExp(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*$/i);

export const MILAGE = new RegExp(/^((?!(0))[0-9]{1,3})$/);

export const CAR_REGISTRATION_NUMBER = new RegExp(/^[a-zA-Z0-9]*$/);

export const PESEL = '00000000000';

export const NIP = '0000000000';

export const MAIL = new RegExp(/^(([a-zA-Z0-9_.+-@])+)$/);

export const BUILDING_FLAT_NUMBER = new RegExp(/^[A-Za-z0-9/]*$/);

export const EXCLUDE_POLISH_LETTERS = /^[^żźćńółęąśŻŹĆĄŚĘŁÓŃ\s]+$/i;
