/* eslint-disable jsx-a11y/control-has-associated-label */
import { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import moment from 'moment';
import styled from 'styled-components';
import 'moment/locale/pl';
import smoothscroll from 'smoothscroll-polyfill';
import 'react-app-polyfill/ie11';
import {
  Alert, Popover, sendError, PrivacyPolicyPopup, ProcessContext,
} from 'porowneo-ui';

import {
  MODAL_TYPES,
} from '../config/main';
import { phoneNumber } from '../helpers/phone';
import ModalsContainer from '../components/modal/ModalsWrapper';
import { goPageView, viewWelcomeAgainPopup } from '../helpers/tagManager/TagManagerUtils';
import { isDev } from '../helpers/Envutils';
import { openSocket, openSocketServices } from '../helpers/socketsCalculations';
import { closeAlert, setMarketingMaterials, showModal } from '../actions';
import InfoBar from '../components/InfoBar/InfoBar';
import { modalProcessConfig } from '../config/modalProcess';
import { checkIfDayIsBetweenWorkingHours, isWorkingSunday } from '../helpers/infoBar';
import { checkIfIsFullSale } from '../helpers/FormUtils';
import { invokeCallbackOnClick } from '../helpers/htmlUtils';
import { FOOTER_CONTACT_LINK_SELECTOR } from '../config/htmlElementsConstants';
import { covers, marketingConfig } from '../config/marketingMaterial';

import { AppRouter } from './AppRouter';

import type { TModalProcessType } from '../api/carProject';
import type { Dispatch } from 'redux';
import type { ITheme } from 'porowneo-ui';
import type { IMarketingMaterials, IRootState } from '../reducers';

// add smooth scroll for safari/ie!
smoothscroll.polyfill();

moment.locale('pl');

const AppStyled = styled.div`
  min-height: 100vh;

  .app-content {
    overflow: visible;
    min-height: 90vh;
  }

  .app-content__section-title {
    margin: 30px 0 35px 0;
  }

  .hr {
    height: 1px;
    border-top: thin solid #E1E5EB;
    display: inline-block;
    width: 100%;
  }

  .hr--smaller {
    max-width: 352px;
  }

  @media (max-width: 719px) {
    .hr--smaller {
      width: 100%;
    }
  }

  .hr--margin {
    margin-top: 38px;
  }

  .hr--only-mobile {
    display: none;
  }

  @media (max-width: 719px) {
    .hr--only-mobile {
      display: inline-block;
    }
  }

  .popover-container {
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
    margin-top: 100px;
    position: relative;
  }

  @media (max-width: 719px) {
    .popover-container {
      margin-top: 48px;
    }
  }
`;

interface IAppState {
  infoBarStatus: 'disabled' | 'pending' | 'visible' | 'shown'
}

type TAppProps = Pick<IRootState, 'visitedSteps' | 'fromStepFour' | 'activeFullSaleInsurer' | 'fullSaleStatus' | 'sentetLeadUpdate' | 'user' | 'alertConfig' | 'lockedWebsockets' | 'step' | 'modal'> &
  { paymentType: IRootState['payload']['stepTwoFs']['paymentType'] } &
  { theme: ITheme } &
  { dispatch: Dispatch} &
  Record<string, any>;

export class App extends Component<TAppProps, IAppState> {
  private socket;

  private socketServices;

  private interval;

  constructor(props) {
    super(props);
    this.state = {
      infoBarStatus: 'pending',
    };

    this.socket = undefined;
    this.socketServices = undefined;
  }

  override componentDidMount() {
    const {
      props: {
        isLoadLocalStateModal,
        subscribeToStore,
      },
      state: {
        infoBarStatus,
      },
      showLoadCalculationModal,
      addClickListenerOnContactInFooter,
    } = this;

    if (isLoadLocalStateModal) showLoadCalculationModal(subscribeToStore);

    sessionStorage.setItem('wasModalOnExitFired', `${sessionStorage.getItem('wasModalOnExitFired') === 'true'}`);

    goPageView();

    setTimeout(() => {
      const ROOT: any = document.querySelector('#root');
      ROOT.style.opacity = 1;
    }, 500);

    addClickListenerOnContactInFooter();

    if (isWorkingSunday() && infoBarStatus !== 'disabled') {
      this.checkTimeAndShowInfoBar();

      this.interval = setInterval(this.checkTimeAndShowInfoBar, 5000);
    } else {
      this.setState({ infoBarStatus: 'disabled' });
    }

    this.getCurrentMarketingMaterial();
  }

  override componentWillUnmount() {
    if (isWorkingSunday() && this.interval) {
      clearInterval(this.interval);
    }
  }

  addClickListenerOnContactInFooter = () => {
    invokeCallbackOnClick(() => this.showFooterContactModal(), FOOTER_CONTACT_LINK_SELECTOR);
  }

  openSockets = () => {
    if (!this.socket || !this.socketServices) {
      this.socket = openSocket(this.props);
      this.socketServices = openSocketServices();
    }
  }

  isInfoBar = () => {
    const { infoBarStatus } = this.state;
    return infoBarStatus === 'visible';
  }

  getCurrentMarketingMaterial = () => {
    const { props: { dispatch } } = this;
    const now = new Date();
    const currentMaterials = marketingConfig.filter(({ displayTime: { startDate, endDate } }) => {
      const fromDate = new Date(startDate);
      const toDate = new Date(endDate);
      toDate.setDate(toDate.getDate() + 1); // * including the day it is set to
      return now > fromDate && now < toDate;
    });
    const coverMaterials: IMarketingMaterials = {};
    covers.forEach((cover) => {
      const foundMaterial = currentMaterials.find(materialCover => !!materialCover.priceDifference[cover]);
      const foundPrice = foundMaterial ? foundMaterial.priceDifference[cover] : undefined;
      if (foundMaterial && !!foundPrice) {
        coverMaterials[cover] = { ...foundMaterial, priceDifference: foundPrice };
      }
    });
    dispatch(setMarketingMaterials(coverMaterials));
  };

  checkTimeAndShowInfoBar = () => {
    const { infoBarStatus } = this.state;
    try {
      const isWorking = isWorkingSunday();
      const isBetweenWorkingHours = checkIfDayIsBetweenWorkingHours();

      if (isWorking && isBetweenWorkingHours && infoBarStatus === 'pending') {
        this.showInfoBar();
      } else if (isWorking && !isBetweenWorkingHours && infoBarStatus === 'visible') {
        this.hideInfoBar();
        clearInterval(this.interval);
      }
    } catch (e) {
      sendError(e, false, {
        method: 'checkTimeAndShowInfoBar',
        component: 'Form',
      });
    }
  };

  hideInfoBar = () => {
    this.setState({ infoBarStatus: 'shown' });
  };

  showInfoBar = () => {
    this.setState({ infoBarStatus: 'visible' });
  };

  showLoadCalculationModal = (subscribeToStore) => {
    const { dispatch } = this.props;
    viewWelcomeAgainPopup();
    const loadCalculationModalProps = {
      typeOfModal: MODAL_TYPES.loadCalculationModal,
      modalSource: modalProcessConfig.loadForm,
      background: 'secondary',
      subscribeToStore,
    };

    dispatch(showModal(loadCalculationModalProps));
  };

  showFooterContactModal = () => {
    const { dispatch } = this.props;
    let footerModalProps: {
      typeOfModal: typeof MODAL_TYPES.needContact,
      modalSource: TModalProcessType,
      background: string,
    } = {
      typeOfModal: MODAL_TYPES.needContact,
      modalSource: modalProcessConfig.footerContact,
      background: 'secondary',
    };

    if (checkIfIsFullSale()) {
      footerModalProps = {
        ...footerModalProps,
        modalSource: 'stopka-full-kontakt',
      };
    }

    dispatch(showModal(footerModalProps));
  };

  override render() {
    const {
      props: {
        visitedSteps,
        fromStepFour,
        activeFullSaleInsurer,
        alertConfig,
        dispatch,
        step,
        lockedWebsockets,
        paymentType,
        user,
        theme,
        fullSaleStatus,
        modal,
      },
      openSockets,
      socket,
      socketServices,
    } = this;

    return (
      <ProcessContext.Provider value={user.businessProcess}>
        <Router>
          <AppStyled id="main-app-container">
            <div className="app-content" style={{ paddingBottom: this.isInfoBar() ? '80px' : 'inherit' }}>
              <AppRouter
                step={step}
                openSockets={openSockets}
                socket={socket}
                visitedSteps={visitedSteps}
                fromStepFour={fromStepFour}
                socketServices={socketServices}
                activeFullSaleInsurer={activeFullSaleInsurer}
                paymentType={paymentType}
                lockedWebsockets={lockedWebsockets}
                fullSaleStatus={fullSaleStatus}
                user={user}
              />
              {step >= 1 && step <= 4 && window?.innerWidth < 720 && (
                <div className="desktop-hide">
                  <Popover
                    title="Potrzebujesz pomocy przy wypełnieniu formularza?"
                    message="Zadzwoń do nas - nasi agenci pomogą Ci uzupełnić trudne pola."
                    phoneNumber={phoneNumber}
                    showOnInit
                  />
                </div>
              )}
            </div>
            <InfoBar hideInfoBar={this.hideInfoBar} isInfoBar={this.isInfoBar()} />
            {!isDev && <PrivacyPolicyPopup themeColor={theme?.colors?.primary} />}
          </AppStyled>
          {modal?.isModal && (
            <ModalsContainer />
          )}
          {alertConfig?.open && (
            <Alert
              {...alertConfig}
              closeAlert={() => dispatch(closeAlert())}
            />
          )}
        </Router>
      </ProcessContext.Provider>
    );
  }
}

const mapStateToProps = ({
  visitedSteps,
  fromStepFour,
  activeFullSaleInsurer,
  fullSaleStatus,
  sentetLeadUpdate,
  user,
  alertConfig,
  step,
  payload: {
    stepTwoFs: { paymentType },
  },
  modal,
  dispatch,
  lockedWebsockets,
}: IRootState & { dispatch: Dispatch }) => ({
  visitedSteps,
  fromStepFour,
  activeFullSaleInsurer,
  fullSaleStatus,
  sentetLeadUpdate,
  user,
  alertConfig,
  step,
  paymentType,
  lockedWebsockets,
  modal,
  dispatch,
});

export default connect(mapStateToProps)(App);
