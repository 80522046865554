/* eslint-disable react/prop-types */
import { Component } from 'react';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky';

import styled, { css } from 'styled-components';
import {
  Modal, Portal, OfferDetailsCompare, VARS, below,
} from 'porowneo-ui';
import { MobileTooltip, Button } from 'porowneo-design';

import * as ACTIONS from '../../actions';
import { editStepTwoFsParam } from '../../actions/Payload';
import { fullSaleModalAgreements } from '../../config/FullSaleConfig/fullSaleMain';
import Disclaimer from '../../views/Results/components/Disclaimer';
import * as GTMEvents from '../../helpers/tagManager/TagManagerEventNames';
import {
  closeCheckoutErrorPopup,
  closeExtraDataPopup,
  closeWelcomeAgainPopup,
  createConfigForFullSaleEdits,
  handleCallGtmFieldErrorStep,
  pushFieldEventToDataLayer,
} from '../../helpers/tagManager/TagManagerUtils';
import FullSaleConfirmModal from '../FullSaleComponents/Modals/FullSaleConfirmModal';
import { FullSaleFreeMonthModal } from '../FullSaleComponents/Modals/FreeMonthModal/FullSaleFreeMonthModal';
import {
  modalContainer, MODAL_TYPES, agreementsTelAndReg, isRadioZetTheme,
} from '../../config/main';
import { FreeMonthPrice } from '../FreeMonthPrice';
import { HTML_HEADER_ROOT_FOOTER, NO_SCROLL_HTML_CLASS_NAME } from '../../config/htmlElementsConstants';
import { ENABLED_PROMOTIONS } from '../../config/promotions.config';

import LeaveContactContainer from './LeaveContact';
// eslint-disable-next-line import/no-named-as-default
import NeedContact from './NeedContact';
import LockModal from './LockModal';
import InfoModal from './InfoModal';
import LeasingContactContainer from './LeasingContact';
import CompleteFieldsContainer from './CompleteFields';
import MobileFilterModal from './MobileFilterModal';
import LoadCalculationModal from './loadCalculationModal/LoadCalculationModal';
import OfferRemind from './OfferRemindModal';
import { LeavePhoneNumberModal } from './leavePhoneNumberModal/LeavePhoneNumberModal';
import { AionAccessDeniedModal } from './aionAccessDeniedModal/AionAccessDeniedModal';

import type { TFullSalePartnerName } from '../../types/Partners.type';
import type { IModalState, IRootState, IUser } from '../../reducers';
import type { Dispatch } from 'redux';

export const OfferDetailsCompareWrapper = styled.div`
  overflow-x: scroll;
`;
OfferDetailsCompareWrapper.displayName = 'OfferDetailsCompareWrapper';

export const mapGtm = {
  name: 'inputExitPopupFirstname',
  surname: 'inputExitPopupSurname',
  phoneLeaveContact: 'inputExitPopupPhone',
  MARK_TEL: 'selectExitPopupComms',
  MARK_MAIL: 'selectExitPopupMarketing',
  REG_PPL: 'selectExitPopupTerms',
};

const modalStyles = {
  [MODAL_TYPES.offerDetailsCompare]: {
    modal: css``,
    modalDialog: css`
      max-width: 1124px;
    `,
  },
  [MODAL_TYPES.freeMonth]: {
    modal: css`
      height: 100vh;
    `,
    modalDialog: css`
      max-width: 80vw;
    `,
  },
  [MODAL_TYPES.tooltip]: {
    modalDialog: css`
      ${below(VARS.breakpoints.md)} {
        margin: 0;
      }
    `,
  },
};

type TModalsWrapperProps = ReturnType<typeof mapStateToProps> & { modalProps?: IModalState['modalProps'] };

export class ModalsWrapper extends Component<TModalsWrapperProps, any> {
  constructor(props: TModalsWrapperProps) {
    super(props);
    this.state = { windowLocation: 0 };
  }

  override componentDidMount() {
    const { dispatch } = this.props;

    // In case someone tries to close modal with back/forward button
    window.onpopstate = () => {
      dispatch(ACTIONS.closeModal());
    };
    this.setWindowLocation();
    window.scrollTo(0, 0);
    if (!isRadioZetTheme) {
      document.querySelector(HTML_HEADER_ROOT_FOOTER)?.classList.add(NO_SCROLL_HTML_CLASS_NAME);
    }
  }

  override componentWillUnmount() {
    const { windowLocation } = this.state;
    const headerFromFooterWrapper = document.querySelector(HTML_HEADER_ROOT_FOOTER);
    if (headerFromFooterWrapper?.classList.contains(NO_SCROLL_HTML_CLASS_NAME)) {
      headerFromFooterWrapper.classList.remove(NO_SCROLL_HTML_CLASS_NAME);
    }

    window.scrollTo(0, windowLocation);
  }

  handleCallGtm = (key, value) => {
    if (mapGtm[key]) pushFieldEventToDataLayer(GTMEvents[mapGtm[key]].event, value);
  }

  handleSetAgreements = type => (key, value) => {
    const {
      dispatch,
      user,
      stepTwoFs,
      activeFullSaleInsurer,
    } = this.props;

    if (value && mapGtm[key] && type) {
      pushFieldEventToDataLayer(GTMEvents[mapGtm[key]].event, value);
    }

    if (activeFullSaleInsurer) {
      dispatch(editStepTwoFsParam({
        userAgreements: {
          ...stepTwoFs.userAgreements,
          [key]: value,
        },
      }));
    } else {
      dispatch(ACTIONS.setUser({
        ...user,
        userAgreements: {
          ...user.userAgreements,
          [key]: value,
        },
      }));
    }
  }

  setWindowLocation() {
    this.setState({ windowLocation: window.pageYOffset });
  }

  closeModalWithStatusLeadUpdate = () => {
    const { dispatch, statusLeadUpdate } = this.props;
    const leadStatus = (statusLeadUpdate === 'done' || statusLeadUpdate === 'sent') ? 'sent' : '';
    dispatch(ACTIONS.setStatusLeadUpdate(leadStatus));
    dispatch(ACTIONS.closeModal());
  }

  override render() {
    const {
      statusLeadUpdate,
      agreements,
      dispatch,
      user,
      activeFullSaleInsurer,
      carProjectVersionId,
      stepOne: {
        ac,
        nnw,
        protectionBegins,
      },
      stepTwoFs,
      stepOneFs,
      stepThree,
      modal: {
        modalProps: modalPropsFromState,
      },
      step,
      projectId,
      email,
      modalProps: passedModalProps,
    } = this.props;

    const modalProps = passedModalProps || modalPropsFromState;

    const { handleSetAgreements, handleCallGtm, closeModalWithStatusLeadUpdate } = this;

    const userForContact = {
      sessionId: user.sessionId,
      locator: user.locator,
      phoneLeaveContact: user.phoneNumber,
      surname: user?.surname || stepOneFs?.owner?.lastName || stepThree?.owner?.lastName,
      name: user?.name || stepOneFs?.owner?.firstName || stepThree?.owner?.firstName,
      businessProcess: user.businessProcess,
    };

    const { userAgreements } = user;

    if (modalProps.typeOfModal === 'offerDetailsCompare' && modalProps.isFullSale) {
      userForContact.phoneLeaveContact = stepOneFs.phoneFromFs;
      userForContact.name = stepOneFs?.insurer?.firstName || stepOneFs?.owner?.firstName || null;
      userForContact.surname = stepOneFs?.insurer?.lastName || stepOneFs?.owner?.lastName || null;
    }

    const closeModalGtm = (close: () => void = () => dispatch(ACTIONS.closeModal())) => {
      close();
      if (modalProps.typeOfModal === MODAL_TYPES.completeFields) {
        closeExtraDataPopup({ calculation_id: user.locator });
      }
      if (modalProps.typeOfModal === MODAL_TYPES.loadCalculationModal) {
        closeWelcomeAgainPopup();
      }
      if (modalProps.typeOfModal === MODAL_TYPES.needContact && modalProps.fullSaleError && user.locator) {
        closeCheckoutErrorPopup(createConfigForFullSaleEdits({
          insurerOffer: user.locator,
          dataForGtm: modalProps.dataForGtm,
        }));
        if (modalProps.callBackOnClose) {
          modalProps.callBackOnClose();
        }
      }
    };

    const agreementsToShow = () => {
      const agrs = [...agreementsTelAndReg];
      if (activeFullSaleInsurer) {
        return fullSaleModalAgreements;
      }
      if (step > 1) {
        const index = agrs.indexOf('REG_PPL');
        if (index > -1) agrs.splice(index, 1);
        return agrs;
      }
      return agrs;
    };

    const contactModalProps = {
      email,
      user: userForContact as IUser,
      closeModal: closeModalWithStatusLeadUpdate,
      neededAgreements: agreementsToShow(),
      modalContainer: 'modal-contact',
      userAgreements: activeFullSaleInsurer ? stepTwoFs.userAgreements : userAgreements,
      agreements,
      dispatch,
      statusLeadUpdate,
      handleCallGtm,
      coverType: { ac, nnw },
      activeFullSaleInsurer,
      carProjectId: projectId,
      // Revert FORMMOTO-3258
      step,
      // END
    };

    const generatePromoPricesToCompare = offers => offers.map(offer => (
      <FreeMonthPrice
        key={offer.calculation_id}
        total={offer.totalInstalmentsCalc ?? offer.total}
      />
    ));

    const renderModal = () => {
      switch (modalProps.typeOfModal) {
      case MODAL_TYPES.leaveContactOffer:
        return (
          <>
            <div
              className="wrapper wrapper--contact"
              role="document"
            >
              <LeaveContactContainer
                handleCallGtmFieldError={handleCallGtmFieldErrorStep('Leave Contact Popup')}
                {...contactModalProps}
                {...{ handleSetAgreements: handleSetAgreements(null) }}
                {...modalProps} // TODO pass { guid: XXXX, name: YYYY }
              />
            </div>
            {statusLeadUpdate === '' && <Disclaimer />}
          </>
        );
      case MODAL_TYPES.needContact:
        return (
          <>
            <div
              className="wrapper wrapper--contact"
              role="document"
            >
              <NeedContact
                handleCallGtmFieldError={handleCallGtmFieldErrorStep('Exit Popup')}
                protectionStart={protectionBegins}
                setStatusLeadUpdate={payload => dispatch(ACTIONS.setStatusLeadUpdate(payload))}
                {...contactModalProps}
                {...{ handleSetAgreements: handleSetAgreements(true) }}
                {...modalProps}
              />
            </div>
            {statusLeadUpdate === '' && <Disclaimer />}
          </>
        );
      case MODAL_TYPES.leasingContact:
        return (
          <>
            <div
              className="wrapper wrapper--contact"
              role="document"
            >
              <LeasingContactContainer
                {...contactModalProps}
                {...{ handleSetAgreements: handleSetAgreements(null) }}
                {...modalProps}
              />
            </div>
            {statusLeadUpdate === '' && <Disclaimer />}
          </>
        );
      case MODAL_TYPES.offerDetailsCompare:
        return (
          <OfferDetailsCompareWrapper
            role="document"
          >
            <StickyContainer>
              <OfferDetailsCompare
                {...modalProps}
                closeModal={() => dispatch(ACTIONS.closeModal())}
                isFreeMonth={ENABLED_PROMOTIONS.includes('freeMonth') && user.businessProcess !== 'REN'}
                promotionPrice={ENABLED_PROMOTIONS.includes('freeMonth') && generatePromoPricesToCompare(modalProps.insurerOffers)}
                showStars={false}
                buttonComponent={Button}
              />
            </StickyContainer>
          </OfferDetailsCompareWrapper>
        );
      case MODAL_TYPES.mobileFilterModal:
        return (
          <div
            role="document"
          >
            <MobileFilterModal
              closeModal={() => dispatch(ACTIONS.closeModal())}
              {...modalProps}
            />
          </div>
        );
      case MODAL_TYPES.completeFields:
        return (
          <div
            className="wrapper wrapper--contact"
            role="document"
          >
            <CompleteFieldsContainer
              {...modalProps}
            />
          </div>
        );
      case MODAL_TYPES.fullSaleConfirmModal:
        return (
          <div
            role="document"
          >
            <FullSaleConfirmModal
              partnerName={activeFullSaleInsurer as TFullSalePartnerName}
              {...modalProps}
            />
          </div>
        );
      case MODAL_TYPES.infoModal:
        return (
          <div
            role="document"
          >
            <InfoModal />
          </div>
        );
      case MODAL_TYPES.lockModal:
        return (
          <div
            role="document"
          >
            <LockModal
              {...modalProps}
            />
          </div>
        );
      case MODAL_TYPES.loadCalculationModal:
        return (
          <div
            role="document"
          >
            <LoadCalculationModal
              closeModal={() => {
                if (modalProps.subscribeToStore) {
                  modalProps.subscribeToStore();
                }
                closeModalGtm();
              }}
              {...modalProps}
            />
          </div>
        );
      case MODAL_TYPES.offerRemind:
        return (
          <div
            className="wrapper wrapper--contact"
            role="document"
          >
            <OfferRemind
              protectionStart={protectionBegins}
              {...contactModalProps}
              {...{ handleSetAgreements: handleSetAgreements(null) }}
              {...modalProps}
              carProjectId={projectId || 0}
              carProjectVersionId={carProjectVersionId || 0}
              closeModal={closeModalGtm}
              neededAgreements={['MARK_TEL', 'MARK_MAIL']}
            />
          </div>
        );
      // REVERT FORMMOTO-3264
      // delete src\components\modal\LeavePhoneNumberModal.tsx
      // delete src\components\leavePhoneNumberForm
      case MODAL_TYPES.leavePhoneNumber:
        return (
          <div
            className="wrapper wrapper--contact"
            role="document"
          >
            <LeavePhoneNumberModal
              savePhoneNumber={(store) => {
                dispatch(ACTIONS.closeModal());
                modalProps.savePhoneNumber(store);
              }}
            />
          </div>
        );
      // END
      case MODAL_TYPES.tooltip:
        return (
          <MobileTooltip {...modalProps} />
        );
      case MODAL_TYPES.freeMonth:
        return (
          <FullSaleFreeMonthModal />
        );
      case MODAL_TYPES.aionAccessDenied:
        return (
          <AionAccessDeniedModal />
        );
      default: return null;
      }
    };

    return (
      <Portal containerId={modalContainer}>
        <Modal
          id="contact"
          closeModal={() => closeModalGtm((modalProps.typeOfModal === 'needContact' && modalProps.customCloseModal) || undefined)}
          isNarrow
          background={(modalProps.typeOfModal && modalProps.background) || null}
          showClose={modalProps.showClose}
          stylesModal={modalStyles[modalProps.typeOfModal]}
          {...(modalProps.typeOfModal && modalProps.modalContainerProps)}
        >
          <>
            {renderModal()}
          </>
        </Modal>
      </Portal>
    );
  }
}

const mapStateToProps = ({
  email,
  agreements,
  dispatch,
  user,
  activeFullSaleInsurer,
  statusLeadUpdate,
  coverType,
  step,
  payload: {
    carProjectVersionId,
    stepOne,
    projectId,
    stepTwoFs,
    stepOneFs,
    stepThree,
  },
  modal,
}: IRootState & { dispatch: Dispatch }) => ({
  agreements,
  dispatch,
  user,
  activeFullSaleInsurer,
  statusLeadUpdate,
  stepOne,
  stepThree,
  stepTwoFs,
  stepOneFs,
  coverType,
  step,
  projectId,
  email,
  carProjectVersionId,
  modal,
});

export default connect(mapStateToProps)(ModalsWrapper);
