import {
  editStepFourInsuranceHistoryParam,
  editStepFourInsuranceHistoryParamByCover,
  editStepFourParam,
} from '../../../actions/Payload';
import { setHistoryPerCover } from '../../../reducers/Payload';
import * as ACTIONS from '../../../actions/Steps';

import type { THandleChangeStepFourUtil } from '../StepFour.types';
import type { TDamagesPaidRangeNumber, TYearsBuyingRangeNumber } from '../../../types/Insurer.type';

export const handleChangeStepFour: THandleChangeStepFourUtil = (allValues) => {
  const {
    dispatch,
    key,
    insurerType,
    insuranceCover,
    fieldCover,
    clearKey,
  } = allValues;

  const resetDamages = {
    firstDamage: null, secondDamage: null, thirdDamage: null, fourthDamage: null, fifthDamage: null,
  };

  switch (key) {
  case `${insurerType}InsuranceHistorySame`: {
    const { value } = allValues;
    return dispatch(editStepFourInsuranceHistoryParam({
      insuranceHistorySame: value === 'yes',
      acInsuranceHistory: value !== 'yes' ? setHistoryPerCover('ac') : null,
    }, insurerType));
  }
  case `${insurerType}FirstDamage${fieldCover}`:
  case `${insurerType}SecondDamage${fieldCover}`:
  case `${insurerType}ThirdDamage${fieldCover}`:
  case `${insurerType}FourthDamage${fieldCover}`:
  case `${insurerType}FifthDamage${fieldCover}`: {
    const { value } = allValues;
    return dispatch(editStepFourInsuranceHistoryParamByCover({ [clearKey]: parseInt(String(value), 10) }, insurerType, insuranceCover.toLowerCase() as 'oc' | 'ac'));
  }
  case `${insurerType}YearsBuying${fieldCover}`: {
    const { value } = allValues;
    const values = value === 0 ?
      {
        yearsBuying: 0 as TYearsBuyingRangeNumber,
        damagesPaid: 0 as TDamagesPaidRangeNumber,
        ...resetDamages,
      } :
      {
        yearsBuying: value as TYearsBuyingRangeNumber,
        ...resetDamages,
      };
    return dispatch(editStepFourInsuranceHistoryParamByCover(
      values,
      insurerType,
        insuranceCover.toLowerCase() as 'oc' | 'ac',
    ));
  }
  case `${insurerType}DamagesPaid${fieldCover}`: {
    const { value } = allValues;
    const values = value === 0 ?
      { damagesPaid: 0 as TDamagesPaidRangeNumber, ...resetDamages } :
      { damagesPaid: value as TDamagesPaidRangeNumber };
    return dispatch(editStepFourInsuranceHistoryParamByCover(
      values,
      insurerType,
        insuranceCover.toLowerCase() as 'oc' | 'ac',
    ));
  }
  case 'email': {
    const { value } = allValues;
    dispatch(editStepFourParam({ email: value }));
    return dispatch(ACTIONS.typeEmail(value));
  }
  default:
    return null;
  }
};
