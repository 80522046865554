import { getBusinessProcessName, getProcessName } from '../../helpers/processName';
import { getCookieByName } from '../../helpers/FormUtils';

import {
  yesNoToBool, createUserPayload, createInsurerPayload, parseInsuranceHistory, validateFSOne,
  isStepOneUpdate, isStepThreeUpdate, isStepTwoUpdate, isStepFourUpdate, isStepOneFSUpdate, isStepTwoFSUpdate, omitMovedFSFields,
} from './CarProjectUtils';

import type { IPayloadStepOne, IPayloadStepTwo, IPayloadStepThree } from '../../reducers/Payload';
import type { TLocator } from '../../types/Locator.type';
import type { IRootState, TBusinessProcess } from '../../reducers';
import type {
  IUpdateCarProjectFirstStep, IUpdateCarProjectSecondStep, IUpdateCarProjectThirdStep,
  IUpdateCarProjectFourthStep, IUpdateCarProjectFullSaleFirstStep, IUpdateCarProjectFullSaleSecondStep,
  TCarProjectUpdate, ICarProjectStepOneFSUpdate, ICarProjectStepFourUpdate, ICreateCarProject,
} from './CarProject.types';

/**
 * @throws {Error}
 */
export const mapCreate = ({ payload, user }: Pick<IRootState, 'user' | 'payload'>, projectId: number): ICreateCarProject => {
  const { eurotaxVersion, productionYear } = payload.stepOne.vehicle;
  if (eurotaxVersion === null) {
    throw new Error('EurotaxVersion cant be null');
  }
  if (productionYear === null) {
    throw new Error('ProductionYear cant be null');
  }
  const newApiCreateCarProjectPayload: ICreateCarProject = {
    eurotaxVersion,
    infoexVersion: payload.stepOne.vehicle.infoexVersion,
    carYear: productionYear,
    lpg: payload.stepOne.vehicle.lpg,
    locatorFull: user.locator,
    locatorShort: user.locator.slice(0, 6),
    sessionId: user.sessionId,
    ga: getCookieByName('_ga'),
    dataOrigin: payload.stepOne.dataOrigin,
    ac: payload.stepOne.ac,
    nnw: payload.stepOne.nnw,
    ktApiProjectId: projectId,
    protectionBegins: payload.stepOne.protectionBegins,
    phone: payload.stepOne.phone.replace(/\s/g, '') || null,
  };
  return newApiCreateCarProjectPayload;
};

export const mapStepOne = (payload: IPayloadStepOne, locator: TLocator, process: TBusinessProcess): IUpdateCarProjectFirstStep => ({
  locator,
  eurotaxVersion: payload.vehicle.eurotaxVersion,
  infoexVersion: payload.vehicle.infoexVersion,
  carYear: Number(payload.vehicle.productionYear),
  dataOrigin: 'www2',
  lpg: payload.vehicle.lpg,
  phone: payload.phone?.replace(/\s/g, '') || null,
  protectionBegins: payload.protectionBegins,
  ac: payload.ac,
  nnw: payload.nnw,
  process: getBusinessProcessName(process),
});

/**
 * @throws {Error}
 */
export const mapStepTwo = (payload: IPayloadStepTwo): IUpdateCarProjectSecondStep => {
  const {
    actualKm, mileageEstimator, whichOwner, firstCountry, night, damageDetail,
  } = payload;
  if (actualKm === null) {
    throw new Error('Cannot map null actualKm for CarProject');
  }
  if (!mileageEstimator) {
    throw new Error('Cannot map empty mileageEstimator for CarProject');
  }
  if (!whichOwner) {
    throw new Error('Cannot map empty whichOwner for CarProject');
  }
  if (!firstCountry) {
    throw new Error('Cannot map empty firstCountry for CarProject');
  }
  if (!night) {
    throw new Error('Cannot map empty night for CarProject');
  }
  return {
    dataOrigin: 'www2',
    carRegisteredOn: payload.carRegisteredOn,
    carUsage: payload.carUsage,
    actualKm,
    mileageEstimator,
    whichOwner,
    firstCountry,
    // TODO FORMMOTO-3555
    // @ts-ignore
    firstImmat: payload.firstImmat,
    ownerRegisteredDate: payload.ownerRegisteredDate,
    yearOwnerRegistered: payload.yearOwnerRegistered,
    plate: payload.plate || null,
    rightWheel: payload.rightWheel,
    night,
    abroad: payload.abroad,
    damage: payload.damage,
    damageDetail: !damageDetail || ['no', ''].includes(damageDetail) ? null : damageDetail,
    regInPl: firstCountry === 'pol',
    modification: null,
    modDetail: null,
    householdCars: null,
    lastInsurerOc: payload.lastInsurerOc,
    lastInsurerAc: payload.lastInsurerAc,
  };
};

/**
 * @throws {Error}
 */
export const mapStepThree = (payload: IPayloadStepThree): IUpdateCarProjectThirdStep => {
  const {
    driverUnder26, mainDriver, hasChildrenU26, ageOldestChild,
  } = payload;
  if (driverUnder26 === null) {
    throw new Error('Cannot map null driverUnder26 for CarProject');
  }
  if (!mainDriver) {
    throw new Error('Cannot map empty mainDriver for CarProject');
  }
  if (hasChildrenU26 === null) {
    throw new Error('Cannot map null hasChildrenU26 for CarProject');
  }

  return {
    dataOrigin: 'www2',
    hasChildren: !!payload.numberOfChilds && payload.numberOfChilds > 0,
    driverUnder26,
    mainDriver,
    coowners: payload.coowners,
    numberOfChilds: payload.numberOfChilds,
    hasChildrenU26,
    ageOldestChild,
    owner: createUserPayload(payload.owner),
    coOwner: payload.coOwner ? createUserPayload(payload.coOwner) : null,
    coCoOwner: payload.coCoOwner ? createUserPayload(payload.coCoOwner) : null,
    driver: payload.driver ? createUserPayload(payload.driver) : null,
    youngPerson: payload.youngPerson,
  };
};

/**
 * @throws {Error}
 */
export const mapStepFour = ({
  stepPayload, sendIdd, process, updatePhone,
}: ICarProjectStepFourUpdate): IUpdateCarProjectFourthStep => ({
  dataOrigin: 'www2',
  ownerInsuranceHistory: parseInsuranceHistory(stepPayload.ownerInsuranceHistory),
  coOwnerInsuranceHistory: stepPayload.coOwnerInsuranceHistory ? parseInsuranceHistory(stepPayload.coOwnerInsuranceHistory) : null,
  coCoOwnerInsuranceHistory: stepPayload.coCoOwnerInsuranceHistory ? parseInsuranceHistory(stepPayload.coCoOwnerInsuranceHistory) : null,
  driverInsuranceHistory: stepPayload.driverInsuranceHistory ? parseInsuranceHistory(stepPayload.driverInsuranceHistory) : null,
  sendIdd,
  email: stepPayload.email || null,
  phone: updatePhone?.replace(/\s/g, '') || null,
  process: getProcessName(process),
});

/**
 * @throws {Error}
 */
export const mapStepOneFS = ({
  stepPayload, sendIdd, process, addCompanyInfo, includeMovedFields,
}: ICarProjectStepOneFSUpdate): IUpdateCarProjectFullSaleFirstStep => {
  const [owner, insurerPerson] = validateFSOne(stepPayload);

  const mappedPayload: IUpdateCarProjectFullSaleFirstStep = {
    ...stepPayload,
    plate: stepPayload.carRegistrationNumber || null,
    vin: stepPayload.vinNumber || null,
    insurerUserId: insurerPerson !== 'insurer' ? stepPayload[insurerPerson]?.userId : null,
    dataOrigin: 'www2',
    sendIdd,
    process: getProcessName(process),
    owner: createInsurerPayload(stepPayload, owner),
    coOwner: stepPayload.coOwner ? createInsurerPayload(stepPayload, stepPayload.coOwner) : null,
    coCoOwner: stepPayload.coCoOwner ? createInsurerPayload(stepPayload, stepPayload.coCoOwner) : null,
    driver: stepPayload.driver ? createInsurerPayload(stepPayload, stepPayload.driver) : null,
    insurer: (insurerPerson === 'insurer' && stepPayload.insurer) ? createInsurerPayload(stepPayload, stepPayload.insurer) : null,
    carSecurity: stepPayload.carSecurity && stepPayload.carSecurity.length > 0 ?
      stepPayload.carSecurity.toString() :
      null,
    hasServiceHistory: stepPayload.hasServiceHistory ? yesNoToBool(stepPayload.hasServiceHistory) : null,
    wasModified: stepPayload.wasModified ? yesNoToBool(stepPayload.wasModified) : null,
    company: addCompanyInfo ? stepPayload.company : null,
    contact: {
      firstName: stepPayload[insurerPerson]?.firstName || null,
      lastName: stepPayload[insurerPerson]?.lastName || null,
      phone: stepPayload.phoneFromFs.replace(/\s/g, ''),
      email: stepPayload.emailFromFs || null,
    },
  };

  return includeMovedFields ? mappedPayload : omitMovedFSFields(mappedPayload);
};

/**
 * @throws {Error}
 */
export const mapCarProject = (update: TCarProjectUpdate): IUpdateCarProjectFirstStep |
IUpdateCarProjectSecondStep |
IUpdateCarProjectThirdStep |
IUpdateCarProjectFourthStep |
IUpdateCarProjectFullSaleFirstStep |
IUpdateCarProjectFullSaleSecondStep => {
  if (isStepOneUpdate(update)) {
    return mapStepOne(update.stepPayload, update.locator, update.process);
  }
  if (isStepTwoUpdate(update)) {
    return mapStepTwo(update.stepPayload);
  }
  if (isStepThreeUpdate(update)) {
    return mapStepThree(update.stepPayload);
  }
  if (isStepFourUpdate(update)) {
    return mapStepFour(update);
  }
  if (isStepOneFSUpdate(update)) {
    return mapStepOneFS(update);
  }
  if (isStepTwoFSUpdate(update)) {
    return update.stepPayload;
  }
  return update;
};
