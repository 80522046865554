import { SIDEBAR_CONFIG } from '../../../config/sidebar';

import type { TProcess } from '../Sidebar';
import type { TSteps } from '../../StepContainer/StepContainer';

interface ISideBarContent {
  step: TSteps
  process: TProcess
}

const stepHasSidebar = (step: TSteps): step is keyof typeof SIDEBAR_CONFIG => step in SIDEBAR_CONFIG;

const shouldShowSidebar = (process: TProcess, step: TSteps) => {
  if (process === 'REN' && [2, 4].includes(step)) {
    return false;
  }
  return true;
};

export const getSidebarConfig = ({ step, process }: ISideBarContent) => {
  if (step && stepHasSidebar(step) && shouldShowSidebar(process, step)) {
    const header = SIDEBAR_CONFIG[step]?.header ? { header: SIDEBAR_CONFIG[step].header } : {};

    return {
      ...header,
      content: SIDEBAR_CONFIG[step].content,
    };
  }
  return null;
};
