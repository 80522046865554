import { editStepOneParam } from '../../../actions/Payload';

import { setInfoAboutFiltersInGtm } from './setInfoAboutFiltersInGtm';

import type { ICarDictionaryData, IFilter } from '../components/baseCarDataSection/BaseCarDataSection.types';
import type { Dispatch } from 'redux';

export const handleFilteredBy = (
  dispatch: Dispatch,
  key: keyof IFilter,
  value: string | number,
  allData: ICarDictionaryData[],
): void => {
  const NESTED_KEY = 'filters';

  setInfoAboutFiltersInGtm(key, value, allData);

  if (value) {
    dispatch(editStepOneParam({ [key]: value }, NESTED_KEY));
  } else {
    dispatch(editStepOneParam({ }, NESTED_KEY, [key]));
  }
};
