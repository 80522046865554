import styled from 'styled-components';

import { STEP_ONE_FIELDS } from './config/StepOne.config';

export const FilterTooltipWrapper = styled.div`
  display: flex;
  flex-basis: 90%;
`;

export const FieldFilters = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 1;
  @media (max-width: 719px) {
    display: block;
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
`;

export const CoversWrapper = styled('div')`
  & #${STEP_ONE_FIELDS.coverType.id} {
    width: 100%;
  }
`;
