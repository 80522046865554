import type { IPayloadStepOneFs, IPayloadStepThree } from '../../../../../reducers/Payload';
import type { TAdditionArguments } from '../Insurer.types';
import type { TInsurer, TInsurerType, TYoungPerson } from '../../../../../types/Insurer.type';
import type { IRootState } from '../../../../../reducers';

type TFullSaleInsurerType = NonNullable<IPayloadStepOneFs[Exclude<TInsurerType, TYoungPerson>]>
type TStepThreeInsurerType = NonNullable<IPayloadStepThree[Exclude<TInsurerType, TInsurer>]>

export const selectInsurerPropsFromStore = <T extends TAdditionArguments<TInsurerType>>({
  sensitiveData,
  activeFullSaleInsurer,
  payload: {
    stepThree,
    stepOneFs,
  },
}: IRootState, {
    stepOrigin,
    type,
  }: T) => ({
    insurer: stepOrigin === 'fs' ? { ...stepOneFs[type] } as TFullSaleInsurerType : { ...stepThree[type] } as TStepThreeInsurerType,
    coowners: stepThree.coowners,
    mainDriver: stepThree.mainDriver,
    partnerName: activeFullSaleInsurer,
    sensitiveData,
  });
