import type { IUser, IMarketingMaterials, IModalState } from '../reducers';

export function createAction<K, T = string, N = string>(type: T) {
  return function (payload?: K, nestedKey?: N) {
    return ({ type, payload, nestedKey });
  };
}

export const createStrictAction = <K, T = string>(type: T) => (payload: K) => ({ type, payload });

export const RESET_STORE = 'RESET_STORE';
export const SET_STEP = 'SET_STEP';
export const SET_USER = 'SET_USER' as const;
export const EDIT_USER = 'EDIT_USER' as const;
export const CONNECT = 'CONNECT';
export const DISCONNECT = 'DISCONNECT';
export const RESULT = 'RESULT';
export const RESULT_ERROR = 'RESULT_ERROR';
export const COMPLETE = 'COMPLETE';
export const LOCKED = 'LOCKED';
export const SET_AGREEMENTS = 'SET_AGREEMENTS';
export const GOING_TO_STEP = 'GOING_TO_STEP';
export const VISITED_STEP = 'VISITED_STEP';
export const SET_STATUS_LEAD_UPDATE = 'SET_STATUS_LEAD_UPDATE';
export const SET_SENT_LEAD_UPDATE = 'SET_SENT_LEAD_UPDATE';
export const SET_SENT_OFFER_REMIND = 'SET_SENT_OFFER_REMIND';
export const RESET_SENT_OFFER_REMIND = 'RESET_SENT_OFFER_REMIND';
export const FROM_STEP_FOUR = 'FROM_STEP_FOUR';
export const ACTIVE_FULL_SALE_INSURER = 'ACTIVE_FULL_SALE_INSURER';
export const SET_OFFER_DETAIL = 'SET_OFFER_DETAIL';
export const RESET_OFFER_DETAIL = 'RESET_OFFER_DETAIL';
export const SET_PARTNER_AGREEMENTS = 'SET_PARTNER_AGREEMENTS';
export const SET_PARTNER_REQ_AGREEMENTS = 'SET_PARTNER_REQ_AGREEMENTS';
export const SET_FULL_SALE_STATUS = 'SET_FULL_SALE_STATUS';
export const SHOW_ALERT = 'SHOW_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const RESET_VISITED_STEP = 'RESET_VISITED_STEP';
export const RESET_FROM_STEP_FOUR = 'RESET_FROM_STEP_FOUR';
export const IS_CALCULATED = 'IS_CALCULATED';
export const SET_USED_EMAIL = 'SET_USED_EMAIL';
export const SET_DISABLE_FIELDS = 'SET_DISABLE_FIELDS';
export const SET_KEPT_FIELDS = 'SET_KEPT_FIELDS';
export const SET_IDD_LAST_EMAIL = 'SET_IDD_LAST_EMAIL';
export const SET_SALE_DATA = 'SET_SALE_DATA';
export const SET_MARKETING_MATERIALS = 'SET_MARKETING_MATERIALS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const UPDATE_MODAL = 'UPDATE_MODAL';

export const resetStore = createAction<boolean, typeof RESET_STORE>(RESET_STORE); // if pass true will keep Active Fs Insurer
export const connect = createAction(CONNECT);
export const disconnect = createAction(DISCONNECT);
export const result = createAction(RESULT);
export const resultWithError = createAction(RESULT_ERROR);
export const complete = createAction(COMPLETE);
export const locked = createAction(LOCKED);
export const pushStepToVisited = createAction(VISITED_STEP);
export const resetVisitedStep = createAction(RESET_VISITED_STEP);
export const setFromStepFour = createAction(FROM_STEP_FOUR);
export const resetFromStepFour = createAction(RESET_FROM_STEP_FOUR);
export const setCalculated = createAction(IS_CALCULATED);
export const setActiveFullSaleInsurer = createAction(ACTIVE_FULL_SALE_INSURER);
export const setFullSaleStatus = createAction(SET_FULL_SALE_STATUS);
export const showAlert = createAction(SHOW_ALERT);
export const closeAlert = createAction(CLOSE_ALERT);
export const setOfferDetail = createAction(SET_OFFER_DETAIL);
export const resetOfferDetail = createAction(RESET_OFFER_DETAIL);
export const setSentOfferRemind = createAction(SET_SENT_OFFER_REMIND);
export const resetSentOfferRemind = createAction(RESET_SENT_OFFER_REMIND);
export const setUsedEmail = createAction(SET_USED_EMAIL);
export const setStatusLeadUpdate = createAction(SET_STATUS_LEAD_UPDATE);
export const setSentLeadUpdate = createAction(SET_SENT_LEAD_UPDATE);
export const setStep = createAction(SET_STEP);
export const setUser = createAction<IUser, typeof SET_USER>(SET_USER);
export const setAgreements = createAction(SET_AGREEMENTS);
export const setPartnerAgreements = createAction(SET_PARTNER_AGREEMENTS);
export const setPartnerReqAgreements = createAction(SET_PARTNER_REQ_AGREEMENTS);
export const setDisableFields = createAction(SET_DISABLE_FIELDS);
export const setKeptFields = createAction(SET_KEPT_FIELDS);
export const setIddLastEmail = createAction(SET_IDD_LAST_EMAIL);
export const setSaleData = createAction(SET_SALE_DATA);
export const setMarketingMaterials = createAction<IMarketingMaterials, typeof SET_MARKETING_MATERIALS>(SET_MARKETING_MATERIALS);
export const closeModal = createAction<unknown, typeof CLOSE_MODAL>(CLOSE_MODAL);
export const showModal = createStrictAction<IModalState['modalProps'], typeof SHOW_MODAL>(SHOW_MODAL);
export const updateModal = createStrictAction<Partial<IModalState['modalProps']>, typeof UPDATE_MODAL>(UPDATE_MODAL);

export interface IDispatchEditUser {
  type: typeof EDIT_USER;
  payload: Partial<IUser> | IUser['userAgreements'];
  nestedKey?: 'userAgreements';
}
export function editUser(payload: Partial<IUser>): { type: typeof EDIT_USER, payload: Partial<IUser> };
export function editUser(payload: IUser['userAgreements'], nestedKey: 'userAgreements'): { type: typeof EDIT_USER, payload: IUser['userAgreements'], nestedKey: 'userAgreements' };
export function editUser(payload: Partial<IUser> | IUser['userAgreements'], nestedKey?: 'userAgreements'): {
  type: typeof EDIT_USER,
  payload: Partial<IUser> | IUser['userAgreements'],
  nestedKey?: 'userAgreements',
} {
  return ({
    type: EDIT_USER,
    payload,
    nestedKey,
  });
}
