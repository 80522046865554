import { VARS } from 'porowneo-ui';
import styled from 'styled-components';

export const WarningInfoBoxLinkStyled = styled('a')`
  color: ${VARS.rawSienna};
  font-weight: 700;

  &:hover {
    color: ${VARS.pickledBluewood};
  }
`;
WarningInfoBoxLinkStyled.displayName = 'WarningInfoBoxLink';
