import axios from 'axios';
import log from 'loglevel';
import { sendError } from 'porowneo-ui';

import {
  API_V2, URL_CAR_PROJECT, createUrlCarProjectStep, axiosConfigJsonStatuses200, axiosConfigWithSession, createGetQuote,
} from '../../config/url';
import SCHEMAS, { FS_SCHEMAS, v as validator } from '../jsonSchema';

import { mapCarProject, mapCreate } from './CarProjectMapping';
import { createSaveUpdateInStorage, createVersioningHandle } from './CarProjectUtils';

import type { AxiosResponse } from 'axios';
import type { IRootState } from '../../reducers';
import type {
  ICreateCarProject, ICreateCarProjectCSDReminder,
  IUpdateCarProjectSaveContact, TCarProjectQuote, TCarProjectUpdate,
} from './CarProject.types';

export type TModalProcessType = 'umow-rozmowe' | 'error-full-form' | 'stopka-kontakt' | 'stopka-full-kontakt' | 'load-form' | 'exit-full-form' | 'exit-form' | 'aviva-leasing' | 'partners-agreements';

const CONTENT_TYPE = 'application/merge-patch+json';

const apiAxiosInstance = axios.create({
  baseURL: `${API_V2}/api/`,
  timeout: 20000, // TEMPORARY FOR TESTS
});

export const createCarProject = async (store: Pick<IRootState, 'payload' | 'user'>, projectId: number): Promise<AxiosResponse> => {
  const { user: { sessionId, locator } } = store || {};
  const payload = mapCreate(store, projectId);
  log.debug('newApiCreateCarProjectPayload:', payload);

  const { valid, errors } = validator.validate(payload, SCHEMAS.create);
  log.debug('createCarProject', valid, errors);
  if (valid) {
    // Only if valid do request
    sessionStorage.setItem('step_1', JSON.stringify(payload));
    return apiAxiosInstance.post(URL_CAR_PROJECT, payload, {
      headers: {
        'session-id': sessionId,
        'full-locator': locator,
      },
    });
  }
  return Promise.reject(errors.map(i => i.message));
};

export const updateCarProject = async (updatePayload: TCarProjectUpdate): Promise<AxiosResponse | { message: string, cached: boolean }> => {
  try {
    const stepPayload = mapCarProject(updatePayload);
    const { step, isFullSale } = updatePayload;
    const { valid, errors } = validator.validate(stepPayload, (isFullSale ? FS_SCHEMAS[step] : SCHEMAS[step]));
    log.debug('updateCarProject', valid, errors);
    if (valid) {
      const {
        newProjectId, sessionId, locator, carProjectVersionId,
      } = updatePayload;
      const stepKey = `step_${isFullSale ? 'fs_' : ''}${step}`;
      const savedStepPayload = sessionStorage.getItem(stepKey);
      if (savedStepPayload !== JSON.stringify(stepPayload)) {
        return apiAxiosInstance.patch(
          createUrlCarProjectStep(newProjectId, step, isFullSale),
          stepPayload,
          {
            headers: {
              'Content-Type': CONTENT_TYPE,
              'session-id': sessionId,
              'full-locator': locator,
            },
          },
        ).then(createSaveUpdateInStorage(stepKey, stepPayload));
      }
      // if nothing changed -> we need to versioning data on step 4 or fs1 or fs2
      if ((step === 4 || isFullSale) && carProjectVersionId) {
        return apiAxiosInstance
          .patch(
            `${URL_CAR_PROJECT}/${newProjectId}/versioning`,
            { dataOrigin: 'www2' },
            {
              headers: {
                'Content-Type': CONTENT_TYPE,
                'session-id': sessionId,
                'full-locator': locator,
              },
            },
          )
          .then(createVersioningHandle(step));
      }
      return Promise.resolve({ message: 'Nothing changed', cached: true });
    }
    log.warn('updateCarProject: incorrect paylaod', errors);
    return Promise.reject(errors.map(i => i.message));
  } catch (error) {
    log.warn('updateCarProject: incorrect paylaod', error);
    return Promise.reject(error);
  }
};

export const updateCarProjectContact = async (
  params: IUpdateCarProjectSaveContact,
  projectId: number,
  sessionId: string,
  locator: string,
): Promise<void> => apiAxiosInstance
  .patch(
    `${URL_CAR_PROJECT}/${projectId}/save-contact`,
    params,
    {
      headers: {
        'Content-Type': CONTENT_TYPE,
        'session-id': sessionId,
        'full-locator': locator || '',
      },
    },
  )
  .then(res => log.debug('versiononing', res))
  .catch((err) => {
    log.debug('ERROR: versioning', err);
    sendError(err, false, {
      method: 'updateCarProjectContact',
    });
    throw err;
  });

export const csdReminders = async (
  csdPayload: ICreateCarProjectCSDReminder,
  sessionId: string,
  locator: string,
): Promise<AxiosResponse> => apiAxiosInstance
  .post<ICreateCarProject, AxiosResponse<unknown>>(
    'car-project-csd-reminders',
    csdPayload,
    axiosConfigJsonStatuses200<ICreateCarProject>(sessionId, locator),
  );

export const getPayload = async (locator: string, sessionId: string): Promise<AxiosResponse<TCarProjectQuote>> => (
  apiAxiosInstance.get<never, AxiosResponse<TCarProjectQuote>>(createGetQuote(locator), axiosConfigWithSession<never>(sessionId, locator))
);
