import moment from 'moment';

import { DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD } from '../../../../../config/formats';

export const formatBirthDate = (date: string) => {
  if (!date || !moment(date, DATE_FORMAT_YYYY_MM_DD).isValid()) {
    return '';
  }
  return moment(date, DATE_FORMAT_YYYY_MM_DD).format(DATE_FORMAT);
};
