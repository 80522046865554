import { useState, useEffect } from 'react';

import type { IPorowneoCalculating } from './PorowneoCalculating.type';

export const calculateProgress = (timeLeft:number, timeTotal:number): number => {
  if (timeLeft >= 0 && timeTotal > 0 && timeLeft <= timeTotal) return Math.round(100 - ((timeLeft / timeTotal) * 100));
  return 0;
};

export const useProgress = (timeToHide: number, forceComplete: boolean): [number] => {
  const [progress, setProgress] = useState<number>(0);
  const [timeLeft, setTimeLeft] = useState<number>(timeToHide);

  useEffect(() => {
    let interval: any;
    if (forceComplete) {
      setProgress(100);
    }

    if (timeToHide > 0) {
      interval = setInterval(() => {
        if (progress < 100) {
          setProgress((prev) => {
            if (prev === 100) return prev;
            return calculateProgress(timeLeft, timeToHide);
          });
        }
        setTimeLeft(prev => prev - 1);
      }, 1000);
      if (progress >= 100) {
        clearInterval(interval);
      }
    }
    return () => interval && clearInterval(interval);
  }, [progress, timeLeft]);

  return [progress];
};

export const componentShouldUpdate = (prevProps: IPorowneoCalculating, nextProps: IPorowneoCalculating):boolean => prevProps.show === nextProps.show;
