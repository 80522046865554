import { StickyContainer } from 'react-sticky';

import StepContainer from '../../../../components/StepContainer/StepContainer';
import PromoCodeSection from '../../../../components/PromoCodeSection';
import { themeName } from '../../../../helpers/Envutils';
import { isAionTheme } from '../../../../helpers/setupTheme';
import { IS_PROMO_CODES } from '../../../../config/main';

import type { IStepTwoWrapper } from './StepTwoWrapper.types';

const StepTwoWrapper = ({
  prevStep,
  nextStep,
  children,
  isProgressStepBar,
  fillStatusPercent,
}: IStepTwoWrapper): JSX.Element => (
  <StickyContainer>
    <StepContainer
      step={2}
      btnOneConfig={{ show: true, btnText: 'Wróć', func: prevStep }}
      btnTwoConfig={{ show: true, btnText: 'Dalej', func: nextStep }}
      isProgressStepBar={isProgressStepBar}
      title="Użytkowanie samochodu"
      fillStatusPercent={fillStatusPercent}
    >
      <div>
        {IS_PROMO_CODES && !isAionTheme(themeName) && (
          <div className="desktop-hide mb-3">
            <PromoCodeSection />
            <div className="hr" />
          </div>
        )}
        {children}
      </div>
    </StepContainer>
  </StickyContainer>
);

export default StepTwoWrapper;
