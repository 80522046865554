import styled from 'styled-components';

interface IProps {
  top: number;
  bottom: number;
}

const setPxIfHasValue = (value: number) => (value ? `${value}px` : 0);

export const Spacer = styled.span<IProps>`
  display: block;
  margin-top: ${({ top }) => setPxIfHasValue(top)};
  margin-bottom: ${({ bottom }) => setPxIfHasValue(bottom)};
`;
