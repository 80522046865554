import { useLayoutEffect, useState, useRef } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

export const useResizeObserver = <T extends HTMLElement>() => {
  const [domRect, setDomRect] = useState<null | DOMRect>(null);

  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const handleResize: ResizeObserverCallback = () => {
        setDomRect(ref?.current?.getBoundingClientRect() || null);
      };
      const createObserver = () => {
        const observer = new ResizeObserver(handleResize);
        if (ref.current !== null) {
          observer.observe(ref.current);
        }
      };
      createObserver();
    }
  }, [ref.current]);

  return { domRect, ref };
};
