import { Component } from 'react';

import { sendError } from 'porowneo-ui';
import { debug } from 'loglevel';

import { updateCarProjectContact } from '../../api/carProject';
import { leadUpdate } from '../../helpers/socketsCalculations';
import * as ACTIONS from '../../actions/index';
import ContactFormWithValidation from '../ContactForm';
import SentSection from '../SentSection';
import ThankYouSection from '../ThankYouSection';
import agreementsHoc, { type IWithAgreementsHOCProps } from '../hoc/agreementsHoc';

import { ModalTextContainer } from './Modal.styled';
import { setPhoneNumberFromModal } from './utils/dispatchPhoneNumber';

import type { IUser } from '../../reducers';

interface ILeasingContactProps extends IWithAgreementsHOCProps, Record<string, any> {
  user: IUser
}

interface ILeasingContactState {
  user: Pick<IUser, 'locator' | 'phoneLeaveContact' | 'sessionId' | 'surname' | 'name'>
}
export class LeasingContact extends Component<ILeasingContactProps, ILeasingContactState> {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      user: {
        locator: user.locator,
        phoneLeaveContact: user.phoneLeaveContact,
        sessionId: user.sessionId,
        surname: user.surname,
        name: user.name,
      },
    };
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [key]: value,
      },
    }));
  }

  settingLeadUpdate = () => {
    const { dispatch, statusLeadUpdate } = this.props;
    const leadStatus = (statusLeadUpdate === 'done' || statusLeadUpdate === 'sent') ? 'sent' : '';

    dispatch(ACTIONS.setStatusLeadUpdate(leadStatus));
  }

  settingLeadError = (error: unknown) => {
    const { dispatch } = this.props;
    dispatch(ACTIONS.setStatusLeadUpdate('failed'));
    debug('Lead update failed', error);
    sendError(error, false, {
      method: 'send/updateCarProjectContact',
      component: 'LeasingContact',
    });
  };

  send = () => {
    const {
      modalSource,
      sendAgreements,
      dispatch,
      coverType,
      // Revert FORMMOTO-3258
      step,
      // END
      carProjectId,
      user: { businessProcess, sessionId, locator },
    } = this.props;

    const { user } = this.state;

    sendAgreements(user);

    if (carProjectId) {
      updateCarProjectContact({
        firstName: user.name || '',
        lastName: user.surname || '',
        phone: user.phoneLeaveContact.replace(/\s/g, ''),
        process: modalSource,
      }, carProjectId, sessionId, locator).catch(this.settingLeadError);
    }

    // Revert FORMMOTO-3258
    setPhoneNumberFromModal(dispatch, { phone: user.phoneLeaveContact, step });
    // END

    leadUpdate({
      phone: user.phoneLeaveContact.replace(/\s/g, ''),
      firstname: user.name,
      lastname: user.surname,
      businessProcess,
      name: modalSource,
      sessionId: user.sessionId,
      coverType,
      locator: user.locator,
      referer: document.referrer ? document.referrer : 'window.location.href',
    }, dispatch);
  }

  override render() {
    const {
      agreements,
      closeModal,
      statusLeadUpdate,
      setDefaultLeadUpdate,
      handleSetAgreements,
      requiredAgreements,
      userAgreements,
      modalContainer,
      neededAgreements,
    } = this.props;

    const {
      send,
      handleChange,
    } = this;

    const { user } = this.state;

    const formSection = (
      <div className="col p-0">
        <h1 className="wrapper__header-title wrapper__header-title--contact">Zostaw kontakt dla oferty samochodu w leasingu</h1>
        <ModalTextContainer>
          <p className="wrapper__header-subtitle wrapper__header-subtitle--leasing">
            Aktualnie ofertę na samochód w leasingu może przedstawić jedynie towarzystwo Aviva.
          </p>
          <p className="wrapper__header-subtitle wrapper__header-subtitle--leasing">
            Uzupełnij formularz, aby doradca Aviva mógł oddzwonić niezobowiązująco i bezpłatnie.
          </p>
        </ModalTextContainer>
        <div className="modal-default">
          {statusLeadUpdate === 'failed' && (
            <h5 className="text-error">
              Przepraszamy, niestety nie udało zapisać się prośby o kontakt.
              <br />
              Możesz spróbować jeszcze raz.
            </h5>
          )}

          <ContactFormWithValidation
            user={user}
            userAgreements={userAgreements}
            agreements={agreements}
            stepOrigin={900}
            handleChange={handleChange}
            callBackIfValid={send}
            handleSetAgreements={handleSetAgreements}
            requiredAgreements={requiredAgreements}
            showTheseAgreements={neededAgreements}
            containerId={modalContainer}
          />
        </div>
      </div>
    );

    switch (statusLeadUpdate) {
    case 'done':
      return (<ThankYouSection closeModal={closeModal} />);
    case 'sent':
      return (<SentSection setDefaultLeadUpdate={setDefaultLeadUpdate} />);
    default:
      return formSection;
    }
  }
}

export default agreementsHoc(LeasingContact);
