import styled from 'styled-components';
import {
  above,
  VARS,
} from 'porowneo-ui';

interface IPromoPriceWrapper {
  hideOnDesktop?: boolean;
  isFullSale?: boolean;
}

const fullSaleCss = `
  display: flex;
  justify-content: space-between;
`;
export const PromoPriceWrapper = styled.div`
  width: 100%;
  text-align: center;

  ${above(VARS.breakpoints.lg)} {
    ${({ hideOnDesktop = false }: IPromoPriceWrapper) => (hideOnDesktop ? 'display: none' : '')};
    margin-top: ${({ isFullSale }) => (isFullSale ? '0' : '-15px')};
  };

  ${({ isFullSale }: IPromoPriceWrapper) => (isFullSale && fullSaleCss)};
`;

PromoPriceWrapper.displayName = 'PromoPriceWrapper';

export const PromoPrice = styled.span<{ isFullSale?: boolean }>`
  color: #e6427a;
  font-weight: ${({ isFullSale }) => (isFullSale ? '500' : 'bold')};
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline #e6427a;
  }

  ${({ isFullSale = false }: { isFullSale?: boolean }) => (isFullSale && 'font-size: 16px;')};
`;

PromoPrice.displayName = 'PromoPrice';
