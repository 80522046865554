import { Close as CloseIcon } from '../../assets/icon/Close';

interface ICloseButton {
  closeModal: () => void;
}

const CloseButton = ({ closeModal }: ICloseButton) => (
  <button
    id="closeButton"
    type="button"
    className="wrapper__close-button"
    data-dismiss="modal"
    aria-label="Close"
    onClick={closeModal}
  >
    <CloseIcon />
  </button>
);

export default CloseButton;
