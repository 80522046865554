import styled, { css } from 'styled-components';
import {
  above,
  VARS,
} from 'porowneo-ui';

export const MAIN_CONTENT_WIDTH = '832';
export const MAX_CONTENT_WIDTH = '1336';

export const appContainer = css`
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    margin-right: auto;
    margin-left: auto;

  ${above(VARS.breakpoints.xl)} {
    max-width: ${MAX_CONTENT_WIDTH}px;
    padding-right: 16px;
    padding-left: 16px;
  };
`;

export const AppContainer = styled.div`
  ${appContainer}
`;

export const MainSectionWrapper = styled.div`
  display: flex;
  position: relative;

  ${above(VARS.breakpoints.xl)} {
    flex-direction: row;
  };
`;

export const LeftSection = styled.div`
  display: none;
  ${above(VARS.breakpoints.xl)} {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    max-width: 109px;
    width: 100%;
  };
`;

export const MainSection = styled.div`
  width: 100%;
  ${above(VARS.breakpoints.xl)} {
    margin-left: auto;
    max-width: ${MAIN_CONTENT_WIDTH}px;
  };
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
`;

export const StepContentStyled = styled('div')<{bottomMargin: 'small' | 'large'}>`
  margin-bottom: ${({ bottomMargin }) => (bottomMargin === 'large' ? '32px' : '24px')};

  ${above(VARS.breakpoints.md)} {
    margin-bottom: ${({ bottomMargin }) => (bottomMargin === 'large' ? '48px' : '24px')};
  }

`;
StepContentStyled.displayName = 'StepContent';
