import { useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import {
  InputText,
  Legend,
  sendError,
  getDangerColor,
} from 'porowneo-ui';
import { Button } from 'porowneo-design';
import styled from 'styled-components';

import { EXCLUDE_POLISH_LETTERS } from '../config/regex';
import { editPayloadParam } from '../actions/Payload';
import promoCodesApi from '../api/promoCode';
import { useAppSelector } from '../hooks/useAppSelector';

const Error = styled.div`
  font-weight: 500;
  font-style: normal;
  color: ${props => getDangerColor(props)};
  margin: 15px 0;
`;

const PromoCodeSection = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const mgm = useAppSelector(state => state.payload.mgm);
  const locator = useAppSelector(state => state.payload.locator);
  const sessionId = useAppSelector(state => state.user.sessionId);

  const setPromoCode = (value: string) => {
    dispatch(editPayloadParam({
      mgm: {
        status: '',
        message: '',
        code: value?.toUpperCase() || '',
      },
    }));
  };

  const pinPromoCodeToLocator = async () => {
    if (mgm.code && mgm.code.length > 3) {
      setLoading(true);
      try {
        const { data } = await promoCodesApi.pinPromoCode(locator, mgm.code, sessionId);
        if (data) {
          dispatch(editPayloadParam({
            mgm: {
              ...mgm,
              status: data.success ? 'valid' : 'invalid',
              message: data.message,
            },
          }));
        }
      } catch (e) {
        sendError(e, false, {
          method: 'pinPromoCodeToLocator',
          component: 'PromoCodeSection',
        });
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(editPayloadParam({
        mgm: {
          ...mgm,
          message: 'Kod jest za krótki. Sprawdź poprawność kodu.',
        },
      }));
    }
  };

  const unpinPromoCodefromLocator = async () => {
    setLoading(true);
    try {
      const { data } = await promoCodesApi.unpinPromoCode(locator, mgm.code, sessionId);
      if (data) {
        dispatch(editPayloadParam({
          mgm: {
            ...mgm,
            status: '',
            message: '',
          },
        }));
      }
    } catch (e) {
      sendError(e, false, {
        method: 'unpinPromoCodefromLocator',
        component: 'PromoCodeSection',
      });
    } finally {
      setLoading(false);
    }
  };

  const msg = mgm.message.replace(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, (match: any) => `<span style="white-space: nowrap">${match}</span>`);

  return (
    <>
      {mgm.status !== 'valid' ? (
        <div>
          <Legend title="Wprowadź kod promocyjny" />
          <InputText
            id="code"
            name="code"
            maxLength={20}
            upperCase
            mask={EXCLUDE_POLISH_LETTERS}
            disabled={isLoading}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => setPromoCode(e.target.value)}
            value={mgm.code}
            fullWidth
            validationStatus={mgm.status}
            stylesInput={{
              input: ['background-color: white'],
            }}
          />
          <Error dangerouslySetInnerHTML={{ __html: msg }} />
          <Button
            id="confirmButton"
            handleClick={() => pinPromoCodeToLocator()}
            fullWidth
            variant="contained"
          >
            Zatwierdź
          </Button>
        </div>
      ) : (
        <div>
          <Legend title="Wprowadzono kod promocyjny" />
          <p dangerouslySetInnerHTML={{ __html: mgm.message }} />
          <Button
            id="deleteButton"
            variant="text"
            handleClick={unpinPromoCodefromLocator}
            disabled={isLoading}
          >
            Usuń
          </Button>
        </div>
      )}
    </>
  );
};

export default PromoCodeSection;
