import isEqual from 'lodash/isEqual';
import log from 'loglevel';
import { mapOfferDetailsData, priceFormatter } from 'porowneo-ui';

import { modifyCoverTypeValueForSelectButtons } from '../../../stepOne/utils/DataUtils';
import { pushFullSaleStepToDataLayer } from '../../../../helpers/tagManager/TagManagerUtils';
import { runReco } from '../SendReco';

import { checkIfNeedToFillUFG } from './UFG';

import type { TSetResultsInfoInGTM } from '../../Results.types';
import type { IRootState } from '../../../../reducers';
import type { IDaemonBasket } from '../../../../types/DaemonOutput.type';

const { mapDataAdditionalRisksHelpers: { mapAdditionalRisksForRow } } = mapOfferDetailsData;

interface IOfferDetailParams {
  total: IDaemonBasket['total']
  totalInstalmentsCalc: IDaemonBasket['total']
  coversInfo: {
    nnw: {
      nnwSum: IDaemonBasket['nnw_insurance_sum'],
      nnwPerson: IDaemonBasket['nnw_covered_persons'],
      nnwTerritorialRange: IDaemonBasket['nnw_territorial_range'],
    }
  },
  covers: IDaemonBasket['covers'],
  partnerName: IDaemonBasket['name']
  installmentsDetails: IDaemonBasket['installments_variants'],
}

export type TRecoTypes = 'cheapest' | 'eco' | 'optimum' | 'max';
export interface IRecommendedBasket extends IDaemonBasket {
  type: TRecoTypes,
}

export type TAdditionalRisks = ReturnType<typeof mapAdditionalRisksForRow>[];

// TODO: Unify UI and Moto Daemon Basket types
export const mapAdditionalRisks = (bestOffers: IRecommendedBasket[]): TAdditionalRisks => bestOffers.map(offer => mapAdditionalRisksForRow(offer as any));

export const isAdditionalOffers = (risks: TAdditionalRisks) => {
  if (risks && Array.isArray(risks)) {
    return risks.some(item => Array.isArray(item) && item.length !== 0);
  }
  return false;
};

export const setNnwPrice = (insurer: IDaemonBasket['name'], price: IDaemonBasket['nnw_insurance_sum']) => {
  if (insurer === 'aviva' && typeof price === 'object' && price?.driver) return priceFormatter(price.driver, false);
  if ((typeof price === 'string' || typeof price === 'number') && price) return priceFormatter(price, false);
  return '';
};

const getOfferByType = (offers: IDaemonBasket[], type: typeof offerTypes[number], isAcSelected: boolean) => {
  const isAcCover = offers.filter(res => res.covers.ac).length > 0;

  const filteringOfferType = (offer: IDaemonBasket) => {
    if (isAcSelected) {
      return (isAcCover ?
        offer.recommendation_group_ac_ac :
        offer.recommendation_group_ac_oc) === type;
    }
    return (isAcCover ?
      offer.recommendation_group_oc_ac :
      offer.recommendation_group_oc_oc) === type;
  };

  const filteredOffers = offers
    .filter(filteringOfferType)
    .sort((a, b) => a.recommendation_score - b.recommendation_score);

  log.debug('OFFER TYPE:', type, 'OFFERS FILTERED BY TYPE:', filteredOffers);
  return filteredOffers.length > 0 ? filteredOffers[0] : null;
};

export const isNotEmptyPrice = (total: IDaemonBasket['total']) => typeof (total) === 'number' && total > 0;
export const offerTypes = ['eco', 'optimum', 'max'] as const;
export const getBestOffers = (results: IDaemonBasket[], isAcSelected: boolean, cheapestOffer?: IDaemonBasket | IRecommendedBasket): IRecommendedBasket[] => {
  const activeOffers = results.filter(item => isNotEmptyPrice(item.total));
  const recommendedOffers: IRecommendedBasket[] = [];

  if (activeOffers && activeOffers.length > 0) {
    offerTypes.forEach((item) => {
      const offer = getOfferByType(activeOffers, item, isAcSelected);
      if (offer) recommendedOffers.push({ ...offer, type: item });
    });
  }
  const ecoOffer = recommendedOffers.filter(offer => offer.type === offerTypes[0]);
  const optimumOffer = recommendedOffers.filter(offer => offer.type === offerTypes[1]);
  const ecoPrice = ecoOffer?.[0]?.total;
  const optimumPrice = optimumOffer?.[0]?.total;

  if (ecoPrice && optimumPrice && ecoPrice > optimumPrice && cheapestOffer) {
    return recommendedOffers.map((reco) => {
      if (reco?.type === offerTypes[0]) {
        return { ...cheapestOffer, type: offerTypes[0] };
      }
      return reco;
    });
  }
  return recommendedOffers;
};

export type TCheapestOffer = null | IDaemonBasket & { type: 'cheapest' };

export const getCheapestOfferForSelectedCover = ({ payload, resultWebsocets }: Pick<IRootState, 'payload' | 'resultWebsocets'>): TCheapestOffer => {
  const { ac, nnw } = payload.stepOne;

  const coverType = modifyCoverTypeValueForSelectButtons(ac, nnw);

  const isOcSelected = coverType.includes('oc');
  const isAcSelected = coverType.includes('ac');

  let cheapestOfferForSelectedCover: IDaemonBasket | undefined;

  const sortDescending = (a: IDaemonBasket, b: IDaemonBasket) => a?.total - b?.total;

  const resultsWithPrice = resultWebsocets.filter(({ total }) => typeof total === 'number' && total > 0);
  const resultsWithOc = resultsWithPrice.filter(({ covers }) => covers?.oc);
  const resultsWithOcAc = resultsWithOc.filter(({ covers }) => covers?.ac);

  if (isOcSelected && resultsWithOc.length && ((isAcSelected && !resultsWithOcAc.length) || !isAcSelected)) {
    resultsWithOc.sort(sortDescending);
    [cheapestOfferForSelectedCover] = resultsWithOc;
  } else if (isOcSelected && isAcSelected && resultsWithOcAc.length) {
    resultsWithOcAc.sort(sortDescending);
    [cheapestOfferForSelectedCover] = resultsWithOcAc;
  }

  return cheapestOfferForSelectedCover ? { ...cheapestOfferForSelectedCover, type: 'cheapest' } : null;
};

export const setOfferDetailsAfterReCalc = ({
  state, showInfoModal, setResultsInfoInGTM, goToFullSale, updateOfferDetails,
}: {
  state: Pick<IRootState, 'payload' | 'resultWebsocets' | 'activeFullSaleInsurer' | 'offerDetails' | 'user'>,
  showInfoModal: () => void;
  setResultsInfoInGTM: TSetResultsInfoInGTM,
  /* eslint-disable camelcase */
  goToFullSale: ({ basket_id, total }: Pick<IDaemonBasket, 'basket_id' | 'total'>) => void,
  /* eslint-enable camelcase */
  updateOfferDetails: (params: IOfferDetailParams) => void,
}) => {
  const {
    payload, resultWebsocets, activeFullSaleInsurer, offerDetails,
  } = state;
  if (activeFullSaleInsurer && !checkIfNeedToFillUFG(payload.stepThree)) { // dell all to else
    // after recalculate search our row
    const activeResult = resultWebsocets.filter(({ covers, name }) => (
      name === activeFullSaleInsurer && isEqual(covers, offerDetails.covers)
    ));
    const singleResult = activeResult[0];
    // if find set new offer details
    if (activeResult.length === 1 && singleResult) {
      /* eslint-disable camelcase */
      const {
        name,
        total,
        covers,
        nnw_insurance_sum,
        nnw_covered_persons,
        nnw_territorial_range,
        installments_variants,
        status,
        basket_id,
      } = singleResult;
      /* eslint-enable camelcase */

      if (status === 'error') {
        showInfoModal();
      } else {
        const params: IOfferDetailParams = {
          total,
          totalInstalmentsCalc: total,
          coversInfo: {
            nnw: {
              nnwSum: nnw_insurance_sum,
              nnwPerson: nnw_covered_persons,
              nnwTerritorialRange: nnw_territorial_range,
            },
          },
          covers,
          partnerName: name,
          installmentsDetails: installments_variants,
        };

        updateOfferDetails(params);
        pushFullSaleStepToDataLayer(name, total);
        goToFullSale({ basket_id, total });
      }
    }
    if (activeResult.length === 0) {
      showInfoModal();
    }
  } else {
    runReco(state);
    setResultsInfoInGTM();
  }
};
