import { getUrlVars } from 'porowneo-ui';

import { isDev, isStage } from '../helpers/Envutils';
import { createDummyMarketingMaterial } from '../helpers/marketingMaterial';

import type { TPartnerName } from '../types/Partners.type';

export const covers = ['ac', 'noAc'] as const;

export type TCoverage = typeof covers[number];

type TDateString = string // * RRRR-MM-DD

export interface IMarketingMaterial {
  insurer: TPartnerName;
  rankedPosition: number;
  priceDifference: {
    [key in TCoverage]?: number;
  }
  displayTime: {
    startDate: TDateString,
    endDate: TDateString,
  }
}

export const marketingConfig: IMarketingMaterial[] = [
  {
    insurer: 'generali',
    rankedPosition: 10,
    priceDifference: {
      noAc: 150,
      ac: 600,
    },
    displayTime: {
      startDate: '2022-02-02',
      endDate: '2022-03-02',
    },
  },
  ...createDummyMarketingMaterial({ urlVars: getUrlVars(), isDev, isStage }),
];
