import PropTypes from 'prop-types';
import moment from 'moment';

import { generateInsurerFieldsConfig } from '../../../config/InsurerCommonFields';
import { DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD } from '../../../config/formats';
import { InputField } from '../../fields/inputField/InputField';

import type { IPayloadStepThree } from '../../../reducers/Payload';
import type { IHandleSetValidation } from 'porowneo-ui';
import type { IInsurer, TInsurerType } from '../../../types/Insurer.type';

interface IUfgInsurerSection<T extends TInsurerType> {
  title: '' | 'Dane właściciela' | 'Dane współwłaściciela' | 'Dane pierwszego współwłaściciela' | 'Dane drugiego współwłaściciela' | 'Dane głównego kierowcy';
  birthdate: IInsurer<T>['birthdate'];
  firstName: IInsurer<T>['firstName'];
  lastName: IInsurer<T>['lastName'];
  maidenName: IInsurer<T>['maidenName'];
  pesel: IInsurer<T>['pesel'];
  sex: IInsurer<T>['sex'];
  handleChange: (key: string, value: unknown, type: T) => void;
  handleSetValidation: Pick<IHandleSetValidation, 'handleSetValidation' | 'callBackIfFieldInValid'>;
  sensitiveData: string[];
  type: T;
  coowners: IPayloadStepThree['coowners'];
  orderPrefix: number;
  setSensitiveDataInactive: (id: string) => void;
}

const UfgInsurerSection = <T extends TInsurerType> ({
  title,
  birthdate,
  firstName,
  lastName,
  pesel,
  sex,
  handleChange,
  maidenName,
  handleSetValidation,
  sensitiveData,
  type,
  coowners,
  orderPrefix,
  setSensitiveDataInactive,
}: IUfgInsurerSection<T>) => {
  const FIELDS_CONF = generateInsurerFieldsConfig(type, coowners, 'ufg', sex, '');
  const innerHandleChange = (key, value) => handleChange(key.replace(`${type}-`, ''), value, type);
  const birthdateFormatForValidation = moment(birthdate, DATE_FORMAT_YYYY_MM_DD).format(DATE_FORMAT);

  return (
    <>
      <p className="wrapper__section-title">{title}</p>

      <InputField
        order={orderPrefix + 1}
        title={FIELDS_CONF.birthdate.title}
        disabled
        value={birthdate}
        handleSetValidation={handleSetValidation}
      />

      <InputField
        {...FIELDS_CONF.firstName}
        handleChange={innerHandleChange}
        value={firstName}
        order={orderPrefix + 2}
        handleSetValidation={handleSetValidation}
        sensitiveData={sensitiveData}
        setSensitiveDataInactive={setSensitiveDataInactive}
        additionalValidationParams={sensitiveData}
      />

      <InputField
        {...FIELDS_CONF.lastName}
        handleChange={innerHandleChange}
        value={lastName}
        order={orderPrefix + 3}
        handleSetValidation={handleSetValidation}
        sensitiveData={sensitiveData}
        setSensitiveDataInactive={setSensitiveDataInactive}
        additionalValidationParams={sensitiveData}
      />

      {sex !== 'male' && (
        <InputField
          {...FIELDS_CONF.maidenName}
          handleChange={innerHandleChange}
          order={orderPrefix + 4}
          value={maidenName}
          handleSetValidation={handleSetValidation}
          sensitiveData={sensitiveData}
          setSensitiveDataInactive={setSensitiveDataInactive}
          additionalValidationParams={sensitiveData}
        />
      )}

      <InputField
        {...FIELDS_CONF.pesel}
        handleChange={innerHandleChange}
        value={pesel}
        birthdate={birthdateFormatForValidation}
        gender={sex}
        order={orderPrefix + 5}
        handleSetValidation={handleSetValidation}
        listener="onAccept"
        sensitiveData={sensitiveData}
        setSensitiveDataInactive={setSensitiveDataInactive}
        additionalValidationParams={sensitiveData}
      />
    </>
  );
};

UfgInsurerSection.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  pesel: PropTypes.string.isRequired,
  birthdate: PropTypes.string.isRequired,
  sex: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  setSensitiveDataInactive: PropTypes.func.isRequired,
  handleSetValidation: PropTypes.func.isRequired,
  sensitiveData: PropTypes.arrayOf(PropTypes.string).isRequired,
  coowners: PropTypes.number.isRequired,
  orderPrefix: PropTypes.number.isRequired,
};

export default UfgInsurerSection;
