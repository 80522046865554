/* eslint camelcase: [0] */
import log from 'loglevel';
import axios from 'axios';
import { filterObjectPropertiesBy, sendError } from 'porowneo-ui';

import {
  wsCalculation, wsServices, wsLock, createUrlProfileCreate,
  createUrlUserUpdate, createUrlLeadUpdate, axiosConfigWithSession, createUrlSaveData,
} from '../config/url';
import { OFFER_LIST_INSURERS } from '../config/Partners.config';
import * as ACTIONS from '../actions';
import { modalProcessConfig } from '../config/modalProcess';
import { resetVisitedStep } from '../actions';
import { resetFullSalePayload, resetPayload } from '../actions/Payload';

import { mapStateToDataSendByWS } from './mappingStateSendByWS';
import { exitDataLayer } from './tagManager/TagManagerUtils';
import mappingTransientData from './mappingSendToTransientData';
import { getProcessName } from './processName';
import { getFullUrlFromSessionStorage } from './UrlHelpers';
import {
  createIsPayloadUpdatedConfig,
} from './payloadEquality/createIsPayloadUpdatedConfig';
import { resetLocalStorageVisitedSteps } from './StoreUtils';

import type { IRootState } from '../reducers';

export const openSocket = (props, onResultCb?: any, onConnectCb?: any, onCompleteCb?: any, onDisconnectCb?: any, onResultWithErrorCb?: any) => {
  log.debug('openSocket');
  const socketCalc = wsCalculation();
  const { dispatch } = props;

  socketCalc.on('connect', () => {
    dispatch(ACTIONS.connect('connected'));
    dispatch(ACTIONS.complete(''));
    dispatch(ACTIONS.disconnect(''));
    if (onConnectCb) onConnectCb();
  });

  socketCalc.on('disconnect', () => {
    dispatch(ACTIONS.disconnect('disconnected'));
    if (onDisconnectCb) onDisconnectCb();
  });

  const resultCb = (data) => {
    const { partners } = data;
    log.info(
      `result ${partners.name}:${partners.total}:${JSON.stringify(partners.covers)}`,
      data,
    );
    if (onResultCb) onResultCb(data);
    if (partners.name && OFFER_LIST_INSURERS.includes(partners.name)) {
      dispatch(ACTIONS.result(partners));
    }
  };

  const resultCbError = (data) => {
    const { partners } = data;
    const { name, errorMessages, action } = partners;
    if (partners && action === 'calculate') {
      log.debug('error_action ', name, errorMessages);
      if (onResultWithErrorCb) onResultWithErrorCb(data);
      if (name && OFFER_LIST_INSURERS.includes(name)) {
        dispatch(ACTIONS.resultWithError(partners));
      }
    }
    log.debug('error_action ', data);
  };

  socketCalc.on('calculate', resultCb);
  socketCalc.on('calculate_customized', resultCb);

  socketCalc.on('complete', (msg) => {
    log.debug('complete', msg);
    dispatch(ACTIONS.complete('completed'));
    if (onCompleteCb) onCompleteCb();
  });

  socketCalc.on('error_action', resultCbError);

  return socketCalc;
};

export const openSocketServices = () => {
  log.debug('openServiceSockets');
  const socketCalc = wsServices();

  socketCalc.on('connect', () => {
    log.debug('SOCKET SERVICES ---- service socket connected');
  });

  socketCalc.on('disconnect', () => {
    log.debug('SOCKET SERVICES ---- service socket disconnected');
  });

  socketCalc.on('complete', (msg) => {
    log.debug('SOCKET SERVICES ---- complete', msg);
  });

  socketCalc.on('error_action', () => {
    log.debug('SOCKET SERVICES ---- error_action');
  });

  return socketCalc;
};

export const openLockSocket = ({ dispatch, user }) => {
  log.debug('openLockSocket');
  const socketLock = wsLock();

  const setLockProjectLocator = (fullLocator) => {
    socketLock.emit('project', { fullLocator });
  };

  setLockProjectLocator(user.locator);

  socketLock.on('lock-update', (data) => {
    dispatch(resetVisitedStep());
    dispatch(resetPayload());
    dispatch(resetFullSalePayload());
    resetLocalStorageVisitedSteps();
    dispatch(ACTIONS.locked({ locked: true, data }));
  });
  return socketLock;
};

export const emitAction = isSocketForNewDemon => (isSocketForNewDemon ? 'partnerapi' : 'get_calculation');

export const getCalculationFromAppState = (
  props: Pick<IRootState, 'offerDetails' | 'activeFullSaleInsurer' | 'user' | 'payload' | 'partnerAgreements'>,
  socketCalc,
  modification = {},
  partnerAction,
) => {
  const data = {
    ...mapStateToDataSendByWS(props),
    ...modification,
  };

  const isPayloadUpdated = createIsPayloadUpdatedConfig(props);

  const dataWithIsPayloadConfig = { ...data, ...isPayloadUpdated };

  const dataSendBySockets = partnerAction ?
    { data: dataWithIsPayloadConfig, action: partnerAction, partner: props?.activeFullSaleInsurer } :
    dataWithIsPayloadConfig;

  socketCalc.emit(
    emitAction(partnerAction),
    dataSendBySockets,
  );
};

const createUserUpdatePayload = (state: { user: Partial<IRootState['user']>, payload: { stepOne: Partial<IRootState['payload']['stepOne']> } }, leadUpdate) => {
  const {
    user: {
      phoneLeaveContact,
      phoneNumber,
      name,
      surname,
      businessProcess,
      locator,
    },
    payload: {
      stepOne,
    },
  } = state;

  const phoneLeaveContactNoSpaces = phoneLeaveContact?.replace(/\s/g, '');
  const phoneNumberNoSpaces = phoneNumber?.replace(/\s/g, '');
  let phone;
  let phoneHuman;
  if (leadUpdate && phoneLeaveContactNoSpaces && phoneLeaveContactNoSpaces.length > 0) {
    phone = `+48${phoneLeaveContactNoSpaces}`;
    phoneHuman = phoneLeaveContact;
  } else {
    phone = `+48${phoneNumberNoSpaces}`;
    phoneHuman = phoneNumber;
  }

  return ({
    ac: stepOne?.ac || false,
    phone,
    phoneHuman,
    contact_firstname: name,
    contact_lastname: surname,
    process: getProcessName(businessProcess),
    source: 'porowneo-form',
    data_origin: 'www2',
    referer: getFullUrlFromSessionStorage(),
    protection_start: stepOne?.protectionBegins || '',
    locator_full: locator,
  });
};

export const saveStepDataInRedis = (state) => {
  const {
    user: { locator, sessionId },
  } = state;

  if (locator !== '') {
    axios
      .post(
        createUrlSaveData(locator),
        {
          ...mappingTransientData(state),
        },
        axiosConfigWithSession(sessionId),
      )
      .then(({ data }) => {
        log.debug(data);
      })
      .catch((err) => {
        sendError(err, false, {
          method: 'saveStepDataInRedis',
          url: createUrlSaveData(locator),
        });
      });
  } else {
    sendError(new Error('The locator was lost'), false, {
      method: 'saveStepDataInRedis',
      url: createUrlSaveData(locator),
    });
  }
};

export const firstStepPayload = {
  // dane ktore musza miec inne wartosci niz w fullPayload
  young_person_age: 20, // wysylamy 'wybierz'
  owner_years_buying_oc: 1, // wysyalamy ''
  owner_damages_paid_from_policies: null, // ''
  owner_first_damage: null, // ''
  owner_second_damage: null, // ''
  owner_third_damage: null, // ''
  owner_fourth_damage: null, // ''
  owner_fifth_damage: null, // ''
  owner_years_buying_ac: null, // ''
  owner_damages_paid_from_policies_ac: null, // ''
  owner_first_damage_ac: null, // ''
  owner_second_damage_ac: null, // ''
  owner_third_damage_ac: null, // ''
  owner_fourth_damage_ac: null, // ''
  owner_fifth_damage_ac: null, // ''
  driver_years_buying_oc: null, // ''
  co_owner_years_buying_oc: null, // ''
  co_co_owner_years_buying_oc: null, // ''
  driver_damages_paid_from_policies: null, // ''
  co_owner_damages_paid_from_policies: null, // ''
  co_co_owner_damages_paid_from_policies: null, // ''
  driver_first_damage: null, // ''
  co_owner_first_damage: null, // ''
  co_co_owner_first_damage: null, // ''
  driver_second_damage: null, // ''
  co_owner_second_damage: null, // ''
  co_co_owner_second_damage: null, // ''
  driver_third_damage: null, // ''
  co_owner_third_damage: null, // ''
  co_co_owner_third_damage: null, // ''
  driver_fourth_damage: null, // ''
  co_owner_fourth_damage: null, // ''
  co_co_owner_fourth_damage: null, // ''
  driver_fifth_damage: null, // ''
  co_owner_fifth_damage: null, // ''
  co_co_owner_fifth_damage: null, // ''
  driver_years_buying_ac: null, // ''
  co_owner_years_buying_ac: null, // ''
  co_co_owner_years_buying_ac: null, // ''
  driver_damages_paid_from_policies_ac: null, // ''
  co_owner_damages_paid_from_policies_ac: null, // ''
  co_co_owner_damages_paid_from_policies_ac: null, // ''
  driver_first_damage_ac: null, // ''
  co_owner_first_damage_ac: null, // ''
  co_co_owner_first_damage_ac: null, // ''
  driver_second_damage_ac: null, // ''
  co_owner_second_damage_ac: null, // ''
  co_co_owner_second_damage_ac: null, // ''
  driver_third_damage_ac: null, // ''
  co_owner_third_damage_ac: null, // ''
  co_co_owner_third_damage_ac: null, // ''
  driver_fourth_damage_ac: null, // ''
  co_owner_fourth_damage_ac: null, // ''
  co_co_owner_fourth_damage_ac: null, // ''
  driver_fifth_damage_ac: null, // ''
  co_owner_fifth_damage_ac: null, // ''
  co_co_owner_fifth_damage_ac: null, // ''
  alarm: null,
  commune: null,
  district: null,
  immobiliser: null,
  last_oc_insr: 'BRAK',
  oc_bonus: null,
  oc_cl_year: 0,
  oc_without_cl: 0,
  owner: null,
  proposals: null,
  speedbox: null,
  id: null,
  purchase: null,
  source: 'porowneo-form',
  first_immat: null,
  job: 'zatrudniony',
};

const createProfilePayload = (state: Pick<IRootState, 'user' | 'payload' | 'offerDetails'> & Partial<Pick<IRootState, 'activeFullSaleInsurer' | 'partnerAgreements' | 'email' | 'step'>>, additionalParams) => {
  const {
    user,
    step,
    email,
    payload: {
      stepTwo,
    },
  } = state;

  return ({
    ...firstStepPayload,
    ...mapStateToDataSendByWS(state),
    last_insurer: stepTwo?.lastInsurerOc || null,
    data_origin: 'www2',
    email: email || '',
    locator_full: user.locator,
    process: getProcessName(user.businessProcess),
    step: step && step < 5 ? (step?.toString() || '') : 'calculation',
    ...additionalParams,
  });
};

export const profileCreate = (
  state: Pick<IRootState, 'user' | 'payload' | 'offerDetails'> & Partial<Pick<IRootState, 'activeFullSaleInsurer' | 'partnerAgreements' | 'email' | 'step'>>,
  additionalParams,
  isLeadUpdate = true,
) => new Promise((resolve, reject) => {
  const {
    user: { sessionId },
  } = state;
  const profileCreatePayload = createProfilePayload(state, additionalParams);

  const profileCreatePayloadFilteredFromEmptyStrings = filterObjectPropertiesBy(profileCreatePayload, '');

  const userUpdatePayload = createUserUpdatePayload(state, false);

  log.debug('profile:create payload --->', profileCreatePayloadFilteredFromEmptyStrings);

  axios
    .post(
      createUrlProfileCreate(),
      profileCreatePayloadFilteredFromEmptyStrings,
      axiosConfigWithSession(sessionId),
    )
    .then(({ data: response }) => {
      log.debug('createUrlProfileCreate response:', response);
      if (response && typeof response === 'object' && 'id' in response) {
        const { id: projectId } = response;
        if (isLeadUpdate) {
          log.debug('isLeadUpdate userUpdate:', userUpdatePayload);
          axios
            .post(createUrlUserUpdate(), {
              ...userUpdatePayload,
            }, axiosConfigWithSession(sessionId))
            .then((resp) => {
              resolve({ projectId });
              log.debug('isLeadUpdate && UserUpdate response:', resp);
            })
            .catch((err) => {
              resolve({ projectId });
              log.debug('Catch userUpdate err:', err);
              sendError(err, false, {
                method: 'profileCreate -> userUpdate',
                url: createUrlUserUpdate(),
              });
            });
        } else {
          resolve({ projectId });
        }
      } else {
        reject(new Error(`Profile was not created (response.id undefined). Response: ${response}`));
        log.error('Profile was not created (response.id undefined):', response);
        sendError(response, false, {
          method: 'profileCreate',
          url: createUrlProfileCreate(),
        });
      }
    })
    .catch((err) => {
      reject(new Error(`Profile was not created. Error message: ${err?.message}`));
      log.error('Profile was not created.', err);
      sendError(err, false, {
        method: 'profileCreate',
        url: createUrlProfileCreate(),
      });
    });
});

export const leadUpdate = (options, dispatch) => {
  const {
    name = '',
    lastname = '',
    firstname = '',
    guid = '',
    sessionId = '',
    calculation_id = null,
    locator,
    phone,
    email,
    coverType,
    businessProcess,
  } = options;

  const userUpdatePayload = createUserUpdatePayload({
    user: {
      name: firstname,
      surname: lastname,
      phoneNumber: phone,
      phoneLeaveContact: phone,
    },
    payload: {
      stepOne: {
        ac: coverType,
        protectionBegins: '',
      },
    },
  }, true);
  let resultsData;
  if (options) {
    resultsData = {
      ...userUpdatePayload,
      from_fend: true,
      checkbox: 1,
      offers: [],
      email: email || '',
      lastname,
      firstname,
      guid,
      calculation_id,
      is_optin: 1, // todo need to do something with this ?
      msg: 'created',
      partner: name,
      policy: 0, // should be default ??
      process: getProcessName(businessProcess),
    };

    axios
      .post(createUrlLeadUpdate(), {
        ...resultsData,
        locator_full: locator,
      }, axiosConfigWithSession(sessionId))
      .then(({ data: response }) => {
        log.debug('app_profile_user_lead', response);
        if (response && typeof response === 'object' && response.status && response.status === 'done') {
          dispatch(ACTIONS.setStatusLeadUpdate(response.status)); // TODO the same? ⬇︎
          dispatch(ACTIONS.setSentLeadUpdate(response.status)); // TODO the same?   ⬆︎
        } else {
          dispatch(ACTIONS.setStatusLeadUpdate('failed'));
          log.debug('leadUpdate – Response.status != "done", response:', response);
        }
      }).catch((err) => {
        sendError(err, false, {
          method: 'leadUpdate',
          component: 'FullSaleInsuranceDetails',
          url: createUrlLeadUpdate(),
        });
        dispatch(ACTIONS.setStatusLeadUpdate('failed'));
        log.debug('LeadUpdate catch err:', err);
      });
  }
};

export const userUpdate = (data, dispatch) => {
  log.debug('userUpdate', data);
  axios
    .post(createUrlUserUpdate(), {
      ...data,
      from_fend: true,
      process: getProcessName(data?.businessProcess),
      referer: getFullUrlFromSessionStorage(),
    }, axiosConfigWithSession(data.sessionId))
    .then(({ data: response }) => {
      if (response && typeof response === 'object' && response.msg && response.msg === 'done') {
        if ([modalProcessConfig.fullFormExit, modalProcessConfig.formExit].includes(data.source)) exitDataLayer.pushExitPopupLead();
        dispatch(ACTIONS.setStatusLeadUpdate(response.msg));
        dispatch(ACTIONS.setSentLeadUpdate(response.msg));
      } else {
        dispatch(ACTIONS.setStatusLeadUpdate('failed'));
      }
    })
    .catch((error) => {
      log.debug('user lead update failed', error);
      dispatch(ACTIONS.setStatusLeadUpdate('failed'));
      sendError(error, false, {
        method: 'socketsCalculations/userUpdate',
      });
    });
};
