import { RECALCULATION_PAYLOAD, createCheckPayload } from './checkChangedDataFromFs';
import { checkIfPayloadWasSaved, checkIfSavedPayloadEqualNewOne, savePayload } from './equalityPayloadHelpers';

import type { IRootState } from '../../reducers';

export const getShortLocator = (locator: unknown) => (typeof locator === 'string' ?
  locator.slice(0, 6) :
  '');

export const createIsPayloadUpdatedConfig = (props: Pick<IRootState, 'payload'>) => {
  const checkPayload = createCheckPayload(props);

  if (checkIfPayloadWasSaved(RECALCULATION_PAYLOAD)) {
    return ({ isPayloadUpdated: !checkIfSavedPayloadEqualNewOne(RECALCULATION_PAYLOAD, checkPayload) });
  }

  savePayload(RECALCULATION_PAYLOAD, checkPayload);

  return ({});
};
