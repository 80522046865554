import PropTypes from 'prop-types';
import log from 'loglevel';
import { isZipCode, Fetch, FullLoader } from 'porowneo-ui';

import { InputField } from '../../../../components/fields/inputField/InputField';
import FetchedField from '../../../../components/fields/ContainedFetch';
import { DropDownField, DropDownFieldWithoutHOC } from '../../../../components/fields/DropDownField';
import { ADDRESS_PROPTYPES } from '../../../../config/proptypes';
import { createUrlSearchForZipcode, createUrlGetStreet } from '../../../../config/url';
import { generateInsurerAddressFieldsConfig } from '../../../../config/InsurerCommonFields';
import {
  convertArrayToAvailabeValuesNew,
  convertCitiesStreetsToFormat, findCityByName,
  getCitiesStringArray,
} from '../../../../helpers/FormUtils';
import { setStepThreeGtm } from '../../../../helpers/tagManager/TagManagerUtils';
import { setViewValue, isDisableField } from '../../../../helpers/payloadUtils';
import { updateInsurerStore } from '../../../../helpers/StoreUtils';
import { FetchError } from '../../../../components/fetchError/FetchError';

import { keysToResetInsurerAddress } from './utils/keysToResetInsurerAddress';

import type { TDropDownFieldInnerProps } from '../../../../components/fields/DropDownField';
import type { IInsurerAddress, TInsurersAddressType } from './Insurer.types';
import type { IInsurerFull, TInsurerType } from '../../../../types/Insurer.type';

const InsurerAddress = <T extends Exclude<TInsurerType, 'youngPerson'>>({
  address,
  typeOfAddress,
  coowners,
  typeOfInsurer,
  dispatch,
  handleSetValidation,
  stepOrigin,
  addressRegisteredIdentical,
  orderPrefix = 0,
  setInitializedStep,
  partnerName,
  disableFields,
// eslint-disable-next-line sonarjs/cognitive-complexity
}: IInsurerAddress<T>) => {
  const isFullAddress = (addressObject: typeof address): addressObject is IInsurerFull<T>['address'] | IInsurerFull<T>['addressRegistered'] => (
    !!addressObject &&
    ('street' in addressObject || 'building' in addressObject || 'flat' in addressObject)
  );

  const FIELDS_CONF = generateInsurerAddressFieldsConfig(typeOfAddress, coowners, typeOfInsurer, stepOrigin, partnerName);

  const handleCallGtm = (key: string, value: string, _allData, autoSelect) => {
    if (key === 'city') {
      if (autoSelect) setStepThreeGtm('zipCode', value, typeOfInsurer, typeOfAddress); // event zipCode but value city
      if (value && !autoSelect) setStepThreeGtm(key, value, typeOfInsurer, typeOfAddress);
    }
  };
  const handleChange = (
    keyWithPrefix: TInsurersAddressType[keyof TInsurersAddressType]['id'],
    value: string,
    allData = {},
    autoSelect = false,
    additionalData = {},
  ) => {
    const registeredSuffix = '-registered';
    const key = keyWithPrefix.replace(`${typeOfInsurer}-`, '').replace(registeredSuffix, '');

    handleCallGtm(key, value, allData, autoSelect);

    log.debug('InsurerAddress', key, value, typeOfAddress, typeOfInsurer);

    if (setInitializedStep && stepOrigin === 3) setInitializedStep(keyWithPrefix, autoSelect);

    if (value !== address?.[key]) {
      const formatedValue = key === 'city' || key === 'street' ? convertCitiesStreetsToFormat(value) : value;
      const districtData = (additionalData as any)?.district ? { district: (additionalData as any).district } : { district: address?.district };
      const addressData = {
        ...address, ...districtData, ...keysToResetInsurerAddress(key, stepOrigin), [key]: formatedValue,
      };

      // if addressRegisteredIdentical update only address
      if (addressRegisteredIdentical) {
        dispatch(updateInsurerStore(stepOrigin, { address: addressData, type: typeOfInsurer }));
      } else {
        dispatch(updateInsurerStore(stepOrigin, { [typeOfAddress]: addressData, type: typeOfInsurer }));
      }
    }
  };

  return (
    address ? (
      <>
        {FIELDS_CONF.zipCode.isShow && (
          <>
            <InputField
              {...FIELDS_CONF.zipCode}
              disabled={FIELDS_CONF.zipCode?.disabled || isDisableField(disableFields, FIELDS_CONF.zipCode)}
              order={orderPrefix + 1}
              handleChange={handleChange}
              value={address.zipCode}
              dispatch={dispatch}
              listener="both"
              accessInputLength={6}
              handleSetValidation={handleSetValidation}
            />
            {isZipCode(address.zipCode) && <div className="hr" />}
          </>
        )}

        {FIELDS_CONF.city.isShow && (
          <>
            {isZipCode(address.zipCode) && (
              <>
                {(stepOrigin === 'fs' && typeOfInsurer !== 'insurer' && address.city && address.city !== '' && (partnerName !== 'link4' || typeOfAddress !== 'addressRegistered')) ? (
                  <>
                    <InputField
                      {...FIELDS_CONF.city}
                      disabled={FIELDS_CONF.city?.disabled || isDisableField(disableFields, FIELDS_CONF.city)}
                      value={setViewValue(address.city)}
                      order={orderPrefix + 2}
                      handleSetValidation={handleSetValidation}
                    />
                    <div className="hr" />
                  </>
                ) : (
                  <>
                    <FetchedField
                      {...FIELDS_CONF.city}
                      url={createUrlSearchForZipcode(address.zipCode)}
                      order={orderPrefix + 2}
                      value={address.city}
                      handleSetValidation={handleSetValidation}
                      component={({ data, ...additionalProps }: { data: any; } & Omit<TDropDownFieldInnerProps, 'availableValues' | 'onChange'>) => {
                        if (data && data.count > 0) {
                          const { cities: citiesData } = data;
                          const cities = getCitiesStringArray(citiesData).map(city => ({ value: city, label: city }));

                          if (!address.district && address.city?.toLowerCase()) {
                            dispatch(updateInsurerStore(stepOrigin, { [typeOfAddress]: { ...address, district: findCityByName(citiesData, address.city?.toLowerCase())?.district || '' }, type: typeOfInsurer }));
                          }

                          return (
                            <>
                              {cities && typeof cities === 'object' && (
                                <DropDownFieldWithoutHOC
                                  {...additionalProps}
                                  customOption
                                  onChange={(keyWithPrefix: string, value: any) => {
                                    handleChange(`${typeOfInsurer}-city`, value, cities, cities.length === 1, findCityByName(citiesData, value));
                                  }}
                                  availableValues={cities}
                                />
                              )}
                            </>
                          );
                        }
                        return <></>;
                      }}
                      errorComponent={() => (
                        <FetchError
                          id={FIELDS_CONF.city.id}
                          handleSetValidation={handleSetValidation}
                        />
                      )}
                    />
                    {stepOrigin === 'fs' && <div className="hr" />}
                  </>

                )}
              </>
            )}
          </>
        )}

        {FIELDS_CONF.street.isShow && address.zipCode && address.city && isFullAddress(address) && (
          <Fetch
            url={createUrlGetStreet(address.zipCode)}
            loader={FullLoader}
            component={({ data }) => {
              if (data) {
                const valueExist = data?.streets?.map(str => str.toLowerCase()).includes(address.street?.toLowerCase());
                const value = data.count === 0 || !valueExist ? setViewValue(address.street) : setViewValue(address.street).toLowerCase();
                return (
                  <>
                    <DropDownField
                      {...FIELDS_CONF.street}
                      disabled={FIELDS_CONF.street?.disabled || isDisableField(disableFields, FIELDS_CONF.street)}
                      onChange={(k: `${typeof typeOfInsurer}-street`, newValue) => {
                      // This fix for case when we return back to this form and already have the value.
                      // Keep in mind: after set new street value we reset building & flat
                        if (newValue !== value) {
                          handleChange(k, newValue);
                        }
                      }}
                      value={value}
                      handleSetValidation={handleSetValidation}
                      availableValues={convertArrayToAvailabeValuesNew(data?.streets)}
                      autoSelectIfOne
                      customOption
                      searchable
                      order={orderPrefix + 3}
                    />
                    <div className="hr" />
                  </>
                );
              }
              return <></>;
            }}
            errorComponent={() => (
              <FetchError
                id={FIELDS_CONF.street.id}
                handleSetValidation={handleSetValidation}
              />
            )}
          />
        )}

        {FIELDS_CONF.street.isShow && address.zipCode && isFullAddress(address) && (
          <>
            {FIELDS_CONF.building.isShow && (
              <>
                <InputField
                  {...FIELDS_CONF.building}
                  disabled={FIELDS_CONF.building?.disabled || isDisableField(disableFields, FIELDS_CONF.building)}
                  handleChange={handleChange}
                  value={address.building}
                  handleSetValidation={handleSetValidation}
                  order={orderPrefix + 4}
                />
                <div className="hr" />
              </>
            )}

            {FIELDS_CONF.flat.isShow && (
              <>
                <InputField
                  {...FIELDS_CONF.flat}
                  disabled={FIELDS_CONF.flat?.disabled || isDisableField(disableFields, FIELDS_CONF.flat)}
                  handleChange={handleChange}
                  value={address.flat}
                  handleSetValidation={handleSetValidation}
                  order={orderPrefix + 5}
                />
              </>
            )}
          </>
        )}
      </>
    ) : null
  );
};

InsurerAddress.defaultProps = {
  addressRegisteredIdentical: true,
  orderPrefix: 0,
  setInitializedStep: () => {},
  partnerName: '',
};

InsurerAddress.propTypes = {
  orderPrefix: PropTypes.number,
  address: ADDRESS_PROPTYPES.isRequired,
  dispatch: PropTypes.func.isRequired,
  addressRegisteredIdentical: PropTypes.bool,
  handleSetValidation: PropTypes.shape({
    handleSetValidation: PropTypes.func.isRequired,
    callBackIfFieldInValid: PropTypes.func.isRequired,
    callBackSetValidationFieldStatus: PropTypes.func.isRequired,
  }).isRequired,
  coowners: PropTypes.number.isRequired,
  typeOfAddress: PropTypes.oneOf(['address', 'addressRegistered']).isRequired,
  typeOfInsurer: PropTypes.oneOf(['owner', 'coOwner', 'coCoOwner', 'driver', 'insurer', 'youngPerson']).isRequired,
  stepOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setInitializedStep: PropTypes.func,
  partnerName: PropTypes.string,
  disableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InsurerAddress;
