import styled from 'styled-components';

export const InfoBarWrapper = styled.div<{ isInfoBar: boolean }>`
  position: fixed;
  z-index: 10001;
  bottom: ${({ isInfoBar }) => (isInfoBar ? '0' : '-500px ') /* ! Has to be put lower due to mobile firefox navigation bar bug */};
  text-align: center;
  width: 100%;
  justify-content: center;
  background-color: #34bd6f;
  height: 50px;
  transition: all 2.9s ease-out;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    height: 121px;
  }
`;
export const InfoBarRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  
`;
export const InfoBarContent = styled.div`
  color: white;
  font-weight: 500;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
`;
export const InfoBarPhone = styled.a`
  color: #fff;
  background: rgb(199, 47, 121);
  padding: 5px 1.5em;
  border-radius: 5px;
  margin-left: 10px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
export const InfoBarHideButton = styled.button`
  @keyframes MoveUpDown {
    0%, 100% {
      bottom: 0;
    }
    25% {
      bottom: -5px;
    }
      75% {
      bottom: 2px;
    }
  }

  position: absolute;
  right: 0;
  font-size: 2em;
  font-weight: 600;
  color: #353535;
  text-decoration: none;
  border: none;
  background: none;
  bottom: 0;
  cursor: pointer;
  outline: 0;
  
  @media (max-width: 768px) {
    right: auto;
    left: 0;
    bottom: 18px;

  }

  &:hover {
    animation: MoveUpDown 1.5s ease-in infinite;
  }
`;
