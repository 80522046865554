const InfoBarHideIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    overflow="hidden"
  >
    <g transform="rotate(90 20 20)">
      <defs>
        <linearGradient
          gradientTransform="translate(-4 -4)"
          y2={0}
          x2={0}
          y1={1}
          x1={0}
          id="infoBarLinearGradient"
        >
          <stop stopColor="#fff" offset="0%" />
          <stop stopColor="#fff" offset="100%" />
        </linearGradient>
      </defs>
      <path
        transform="translate(4 4)"
        d="M16 1.466C7.973 1.466 1.466 7.973 1.466 16c0 8.027 6.507 14.534 14.534 14.534 8.027 0 14.534-6.507 14.534-14.534 0-8.027-6.507-14.534-14.534-14.534zm-2.335 24.259l-3.536-3.539 6.187-6.187-6.187-6.187 3.536-3.536 9.724 9.723-9.724 9.726z"
        fill="url(#infoBarLinearGradient)"
      />
    </g>
  </svg>
);

export default InfoBarHideIcon;
