import { pushLockModalEventToDataLayer } from '../../helpers/tagManager/TagManagerUtils';

import type { ILockedData } from './LockModalContainer';

interface ILockModal {
  lockedData?: ILockedData;
}

const LockModal = (props: ILockModal) => {
  pushLockModalEventToDataLayer();
  const { lockedData } = props;
  const { shortLocator = '', sfUserName = '' } = lockedData || {};
  return (
    <div className="wrapper wrapper--contact">
      <div className="row">
        <div className="col wrapper__header-title--text-centered ">
          <h2 className="h2--line-height">
            Dziękujemy!
            <br />
            {`${sfUserName} pomoże Ci z Twoją kalkulacją`}
          </h2>
          <p>Numer Twojej kalkulacji:</p>
        </div>
      </div>
      <div className="row wrapper__body-content--lock">
        <div className="col ">
          <h1>{shortLocator}</h1>
        </div>
      </div>
    </div>
  );
};

export default LockModal;
