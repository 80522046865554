import axios from 'axios';
import { sendError } from 'porowneo-ui';
import log from 'loglevel';

import { createUrlFreeMonthEmail, axiosConfigWithSession } from '../../../config/url';
import { saveUsedEmailInStore } from '../../../helpers/FormUtils';

import type { setUsedEmail } from '../../../actions';
import type { Dispatch } from 'redux';
import type { AxiosResponse } from 'axios';
import type { IContactData } from '../../../types/ContactData.type';
import type { IRootState, IUser } from '../../../reducers';
import type { IPromotionMailData } from './promotion.types';

export const sendPromotionMail = (config: IPromotionMailData, user: Pick<IUser, 'sessionId' | 'locator'>) => axios.post<IPromotionMailData, AxiosResponse<never>>(createUrlFreeMonthEmail(), config, axiosConfigWithSession(user.sessionId, user.locator));

export const createOnPromotionMailFulfilled = ({ dispatch, data, resolve }: {
  dispatch: Dispatch<ReturnType<typeof setUsedEmail>>,
  data: { email: IContactData['email'], usedEmail: IRootState['usedEmail'] },
  resolve: () => void;
}) => (res: AxiosResponse<never>) => {
  const { email, usedEmail } = data;
  saveUsedEmailInStore(dispatch, email, usedEmail, 'promotions');
  log.debug('sendFreeMonthEmail response: ', res);
  resolve();
};

export const createOnPromotionMailReject = (reject: (reason: unknown) => void) => (err: unknown) => {
  log.debug('sendFreeMonthEmail error', err);
  sendError(err, false, { method: 'sendFreeMonthEmail', url: createUrlFreeMonthEmail() });
  reject(err);
};
