import styled from 'styled-components';
import { getLinkTextFieldColor } from 'porowneo-ui';

import type { ITheme } from 'porowneo-ui';

export const Wrapper = styled.div`
  width: 312px;
  margin-left: auto;
`;

export const SubWrapper = styled.div`
  margin-bottom: 32px;
`;

interface IImportantContent { theme: ITheme }

export const ImportantContent = styled.span<IImportantContent>`
  font-weight: 700;
  color: ${props => getLinkTextFieldColor(props)};
`;
