import { SELECT_OPTION_LABEL, DATE_FORMAT } from '../../../config/formats';
import { nowDate, GLOBAL_FIELDS } from '../../../config/fields';

import type { TBaseCarDataSectionFields } from '../components/baseCarDataSection/BaseCarDataSection.types';

export const STEP_ONE_FIELDS = {
  protectionBegins: {
    id: 'protectionBegins',
    defaultValue: '',
    valueOnOpen: nowDate,
    title: 'Data startu ubezpieczenia',
    tooltip: {
      message: <p>Sprawdź dokładnie datę startu. Za przerwę w OC możesz zapłacić nawet 6000 zł kary.</p>,
    },
    format: DATE_FORMAT,
    isInput: true,
    validations: {
      before: 365,
      start: 0,
      protectionBegins: true,
    },
  },
  coverType: {
    id: 'coverType',
    defaultValue: 'oc',
    title: 'Zakres ubezpieczenia',
    tooltip: {
      message: (
        <>
          <p>Wybierz zakres ubezpieczenia jaki Cię interesuje. Zawsze możesz wrócić i zmienić swój wybór:</p>
          <p>OC - obowiązkowe Ubezpieczenie Odpowiedzialności Cywilnej.</p>
          <p>AC - tzw. Autocasco zapewnia odszkodowanie lub naprawę pojazdu, gdy zostanie uszkodzony, zniszczony lub skradziony.</p>
          <p>
            NNW - Ubezpieczenie od Następstw Nieszczęśliwych Wypadków zapewnia odszkodowanie, gdy kierowca lub pasażerowie odniosą obrażenia podczas wypadku.
          </p>
        </>
      ),
    },
    format: SELECT_OPTION_LABEL,
    validations: {
      required: true,
    },
    availableValues: [
      {
        value: 'oc',
        label: 'OC',
      },
      {
        value: 'ac',
        label: 'AC',
      },
      {
        value: 'nnw',
        label: 'NNW',
      },
    ],
  },
  phone: {
    ...GLOBAL_FIELDS.phoneNumber,
    title: 'Twój numer telefonu',
    subTitle: 'Łatwo i szybko wrócisz do kalkulacji zapisując ją pod swoim numerem telefonu.',
    id: 'phone',
    tooltip: {
      message: (
        <>
          <p>Chronimy Twoje dane i wykorzystujemy je tylko w jednym celu, żebyś otrzymał jak najlepsze oferty ubezpieczeń.</p>
          <p>Podając numer telefonu łatwo i szybko wrócisz do Twojej kalkulacji.</p>
          <p>Pamiętaj, że Twoje dane są u nas bezpieczne. Cenimy Twoją prywatność.</p>
        </>
      ),
    },
    placeholder: '999 999 999',
    validations: {
      phone: true,
      phoneHLR: true,
      phoneForbidden: true,
    },
  },
} as const;

export type TStepOneFieldsKeys = keyof typeof STEP_ONE_FIELDS;

export const HIDDEN_CAR_BASE_FIELDS: TBaseCarDataSectionFields[] = [];

export interface IAgreementsStepOne {
  neededAgreements: ['REG_PPL', 'MARK_TEL'],
  required: ['REG_PPL'],
  order: {
    REG_PPL: 2,
    MARK_TEL: 1,
  },
}

export const AGREEMENTS_STEP_ONE: IAgreementsStepOne = {
  neededAgreements: ['REG_PPL', 'MARK_TEL'],
  required: ['REG_PPL'],
  order: {
    REG_PPL: 2,
    MARK_TEL: 1,
  },
};

export type TAgreementsStepOneKeys = IAgreementsStepOne['neededAgreements'][number]
