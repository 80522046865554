const PZU_MOCK = {
  name: 'pzu',
  total: 'none',
  oc: 'none',
  ac: 'none',
  nnw: 'none',
  url: 'none',
  guid: '111',
  offer_type: [],
  marquee: false,
  popular: true,
  promo: {},
  image: null,
  form_heading_ws: '',
  form_heading_ads: '',
  form_popover_heading: '',
  form_popover_content: '',
  covers: {
    oc: true,
    ac: true,
    nnw: true,
    as: true,
    tires: false,
    glass: false,
  },
  confirm_mer: {},
  details: {},
  topics: {},
  assistance: {},
  online_weight: 1100,
  online_height: 900,
  is_price_hidden: false,
  is_approx: false,
  action: 'calculate',
  calculation_id: 'pzu',
  basket_id: 'pzu',
};

export default PZU_MOCK;
