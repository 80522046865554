import { GiftLeft } from '../../assets/icon/GiftLeft';
import { GiftRight } from '../../assets/icon/GiftRight';
import { getFreeMonthDuration } from '../../helpers/promotions';
import {
  AppContainer, MainSection, MainSectionWrapper,
} from '../../styled/gridHelpers';

import {
  FormattedWords,
  GiftLeftTop,
  GiftRightBottom,
  GiftRightTop,
  Paragraph,
  Title,
  Wrapper,
} from './FreeMonthInfoBox.styled';

const checkIfFreeMonthInfoBoxShouldBeRendered = (step: number) => step === 1;

export const FreeMonthInfoBox = ({ step }: { step: number }) => (checkIfFreeMonthInfoBoxShouldBeRendered(step) ? (
  <AppContainer>
    <div className="row">
      <div className="col-xl-9 col-sm-12">
        <MainSectionWrapper>
          <MainSection>
            <Wrapper>
              <Title>Teraz promocja!</Title>
              <Paragraph>
                Kup polisę, a składka za
                {' '}
                <FormattedWords>{getFreeMonthDuration()}</FormattedWords>
                {' '}
                ubezpieczenia wróci na Twoje konto!
              </Paragraph>
              <GiftRightBottom>
                <GiftRight />
              </GiftRightBottom>
              <GiftRightTop>
                <GiftLeft />
              </GiftRightTop>
              <GiftLeftTop>
                <GiftLeft />
              </GiftLeftTop>
            </Wrapper>
          </MainSection>
        </MainSectionWrapper>
      </div>
    </div>
  </AppContainer>
) : null);
