import type { ComponentProps } from 'react';

import { Fetch, ValidationFieldHoc, FullLoader } from 'porowneo-ui';
import { FieldContainer } from 'porowneo-design';

import { withTooltipData } from './TooltipDataProvider';

export interface IValidationFieldHocInnerProps {
  validationStatus: 'init' | 'valid' | 'invalid' | 'validating';
  triggerValidationManually: () => void;
  validations: Record<string, unknown>;
}

const ContainedFetch = (props: Omit<ComponentProps<typeof Fetch>, 'loader'> & IValidationFieldHocInnerProps): JSX.Element => {
  const {
    url,
    retry,
    retryTimeout,
    config,
    component,
    errorComponent,
    catchError,
    cacheIt,
    modifyFetchedData,
    API,
    ...additionalProps
  } = props;

  return (
    <Fetch
      {...additionalProps}
      url={url}
      retry={retry}
      retryTimeout={retryTimeout}
      config={config}
      loader={FullLoader}
      component={component}
      errorComponent={errorComponent}
      catchError={catchError}
      cacheIt={cacheIt}
      modifyFetchedData={modifyFetchedData}
      API={API}
    />
  );
};

export default withTooltipData(ValidationFieldHoc(ContainedFetch, FieldContainer as any));
