import { Suspense, lazy, useEffect } from 'react';
import {
  Routes, Route, Navigate, useNavigate, useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getUrlVars, scrollToMyRef } from 'porowneo-ui';
import { animateScroll as scroll } from 'react-scroll/modules';

import { FULL_SALE_INSURERS } from '../config/Partners.config';
import {
  ROUTING_RESULTS, ROUTING_REDIRECT, ROUTING_FULL_SALE_PERSONAL_DATA, ROUTING_FULL_SALE_VEHICLE_DATA,
  ROUTING_FULL_SALE_PAYMENT, ROUTING_BASE, ROUTING_AV_PROCESS, ROUTING_FULL_SALE_DOCS,
} from '../config/Router.config';
import {
  checkUrlParams, checkIfIsFullSale, setPaymentPayload, checkIfIsResultPage,
} from '../helpers/FormUtils';
import ErrorBoundary from '../components/hoc/ErrorBoundary';
import { deleteStore } from '../helpers/StoreUtils';
import {
  addClassToBodyBasedOnStepType,
  addShortLocatorToHeader, addVersionToHeader,
  clickListenerOnGoBackButton,
  setPhoneNumber,
} from '../helpers/htmlUtils';
import { clickEditData } from '../helpers/tagManager/TagManagerUtils';
import * as ACTIONS from '../actions';
import { resetErrorsPerInsurer } from '../actions/ResultsErrors';
import { resetFullSalePayload } from '../actions/Payload';
import { setFullSaleStatus, setPartnerAgreements, setPartnerReqAgreements } from '../actions';
import { createNavLink } from '../helpers/routerUtils/NavUtils';
import { phoneNumber } from '../helpers/phone';

import Form from './Form';

import type { IUser, IRootState } from '../reducers';
import type { IPayloadStepTwoFs } from '../reducers/Payload';

const FullSaleStepOne = lazy<any>(() => import('../views/fullSaleStepOne/FullSaleStepOne'));
const FullSaleStepTwo = lazy<any>(() => import('../views/fullSaleStepTwo/FullSaleStepTwo'));
const FullSaleStepThree = lazy<any>(() => import('../views/fullSaleStepThree/FullSaleStepThree'));
const Results = lazy<any>(() => import('../views/Results/Results'));
const FullSaleAgreementDocuments = lazy<any>(() => import('../views/fullSaleAgreementDocuments/FullSaleAgreementDocuments'));
const Redirect = lazy<any>(() => import('../views/redirect/Redirect'));
const PageNotFound = lazy<any>(() => import('../views/pageNotFound/PageNotFound'));

interface AppRouterProps {
  openSockets: () => void,
  socket: any,
  visitedSteps: IRootState['visitedSteps'],
  fromStepFour: IRootState['fromStepFour'],
  socketServices: any,
  activeFullSaleInsurer: IRootState['activeFullSaleInsurer'],
  paymentType: IPayloadStepTwoFs['paymentType'],
  lockedWebsockets: IRootState['lockedWebsockets'],
  fullSaleStatus: IRootState['fullSaleStatus'],
  step: IRootState['step'],
  user: Pick<IUser, 'locator' | 'sessionId' | 'businessProcess' | 'shortLocator'>
}

export const AppRouter = ({
  openSockets,
  socket,
  visitedSteps,
  fromStepFour,
  socketServices,
  activeFullSaleInsurer,
  paymentType,
  lockedWebsockets,
  fullSaleStatus,
  step,
  user,
}: AppRouterProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = getUrlVars();

  useEffect(() => {
    // If someone come form step two after buy. Delete Store from memory and force reload app
    if (![ROUTING_FULL_SALE_VEHICLE_DATA, ROUTING_FULL_SALE_PAYMENT].includes(window.location.pathname) && fullSaleStatus === 'success') {
      deleteStore(dispatch);
      window.location.reload();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const IS_FULL_SALE = checkIfIsFullSale();
    const IS_RESULTS = checkIfIsResultPage();
    const preventDefaultGtm = () => {
      // GTM
      clickEditData();
      sessionStorage.setItem('GTMStep', '1');
      scrollToMyRef(scroll);
      dispatch(ACTIONS.result([]));
      dispatch(ACTIONS.resultWithError([]));
      dispatch(resetErrorsPerInsurer());
      dispatch(resetFullSalePayload());
      dispatch(ACTIONS.setStep(1));
      dispatch(ACTIONS.resetVisitedStep());
      dispatch(ACTIONS.resetFromStepFour());
      dispatch(ACTIONS.setActiveFullSaleInsurer(''));
      dispatch(setFullSaleStatus(''));
      dispatch(setPartnerAgreements({}));
      dispatch(setPartnerReqAgreements([]));
      navigate(createNavLink(ROUTING_BASE, user));
    };

    addClassToBodyBasedOnStepType(step, IS_FULL_SALE, IS_RESULTS);
    addShortLocatorToHeader(user.shortLocator);
    setPhoneNumber(phoneNumber);
    addVersionToHeader();
    clickListenerOnGoBackButton(() => preventDefaultGtm());
  }, [location, step, user.shortLocator]);

  return (
    <Suspense fallback={<div>...</div>}>
      <Routes>
        <Route path={ROUTING_BASE}>
          <Route
            index
            element={(
              <ErrorBoundary>
                <Form
                  openSockets={openSockets}
                  navigate={navigate}
                />
              </ErrorBoundary>
            )}
          />

          {ROUTING_AV_PROCESS.map(path => (
            <Route
              key={path}
              path={path}
              element={
                (!!params.l && !!params.s) ? (
                  <ErrorBoundary>
                    <Form
                      openSockets={openSockets}
                      navigate={navigate}
                    />
                  </ErrorBoundary>
                ) : (<Navigate to={{ pathname: ROUTING_BASE }} replace />)
              }
            />
          ))}

          <Route
            path={ROUTING_REDIRECT}
            element={(
              <ErrorBoundary>
                <Redirect openSockets={openSockets} socket={socket} />
              </ErrorBoundary>
            )}
          />

          <Route
            path={ROUTING_RESULTS}
            element={
              (visitedSteps.length === 4 && fromStepFour) ? (
                <ErrorBoundary>
                  <Results
                    socket={socket}
                    socketServices={socketServices}
                    navigate={navigate}
                  />
                </ErrorBoundary>
              ) : (<Navigate to={{ pathname: ROUTING_BASE }} replace />)
            }
          />
          <Route
            path={ROUTING_FULL_SALE_PERSONAL_DATA}
            element={
              (activeFullSaleInsurer && FULL_SALE_INSURERS.includes(activeFullSaleInsurer)) ? (
                <ErrorBoundary>
                  <FullSaleStepOne
                    socket={socket}
                    socketServices={socketServices}
                    openSockets={openSockets}
                  />
                </ErrorBoundary>
              ) : (<Navigate to={{ pathname: ROUTING_BASE }} replace />)
            }
          />
          <Route
            path={ROUTING_FULL_SALE_DOCS}
            element={(
              <ErrorBoundary>
                <FullSaleAgreementDocuments />
              </ErrorBoundary>
            )}
          />
          <Route
            path={ROUTING_FULL_SALE_VEHICLE_DATA}
            element={
              (activeFullSaleInsurer && FULL_SALE_INSURERS.includes(activeFullSaleInsurer)) ? (
                <ErrorBoundary>
                  <FullSaleStepTwo
                    socket={socket}
                    socketServices={socketServices}
                    openSockets={openSockets}
                    navigate={navigate}
                  />
                </ErrorBoundary>
              ) : (<Navigate to={{ pathname: ROUTING_BASE }} replace />)
            }
          />
          <Route
            path={ROUTING_FULL_SALE_PAYMENT}
            element={checkUrlParams(getUrlVars()) ? (
              <ErrorBoundary>
                <FullSaleStepThree
                  paymentPayload={setPaymentPayload(getUrlVars())}
                  paymentType={paymentType}
                  socket={socket}
                  openSockets={openSockets}
                  dispatch={dispatch}
                  lockedWebsockets={lockedWebsockets}
                />
              </ErrorBoundary>
            ) : (<Navigate to={{ pathname: ROUTING_BASE }} replace />)}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<Navigate to={{ pathname: ROUTING_BASE }} replace />} />
      </Routes>
    </Suspense>
  );
};
