import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { InfoBox } from 'porowneo-design';
import { ValidationHoc } from 'porowneo-ui';
import moment from 'moment';

import { CustomDatePickerField } from '../../components/fields/DatePickerField';
import FetchedField from '../../components/fields/ContainedFetch';
import { InputField } from '../../components/fields/inputField/InputField';
import { DropDownField, DropDownFieldWithoutHOC } from '../../components/fields/DropDownField';
import { isDisableField } from '../../helpers/payloadUtils';
import { handleCallGtmFieldErrorStep } from '../../helpers/tagManager/TagManagerUtils';
import { FetchError } from '../../components/fetchError/FetchError';
import { axiosConfigWithSession, createUrlInsurersToShow } from '../../config/url';
import { setInsurersToShowValues, prodDate, maxFirstDateRegistered } from '../../helpers/FormUtils';
import { DATE_FORMAT } from '../../config/formats';
import { useAppSelector } from '../../hooks/useAppSelector';
import ButtonCheckboxField from '../../components/fields/ButtonCheckboxField';

import { STEP_TWO_FIELDS } from './StepTwo.config';
import StepTwoWrapper from './components/stepTwoWrapper/StepTwoWrapper';
import selectPropsFromStore, { mapStoreState } from './utils/SelectPropsFromStore';
import createHandleChange from './utils/handleChange';

import type { TDropDownFieldInnerProps } from '../../components/fields/DropDownField';
import type {
  TSelectedProps, TInsurerValues, IStepTwo, ICarWheelSideAvValues, IWhichOwnerAvValues,
} from './StepTwo.types';

const StepTwo = ValidationHoc(({
  fillStatusPercent,
  triggerValidation,
  prevStep,
  handleSetValidation: handleSetValidationFromHoc,
  callBackSetValidationFieldStatus,
  handleSetFilledFieldStatus,
}: IStepTwo): JSX.Element => {
  const {
    visitedSteps,
    isCalculated,
    carRegisteredOn,
    carUsage,
    disableFields,
    carMileage,
    mileageEstimator,
    whichOwner,
    firstCountry,
    productionYear,
    lastInsurerName,
    sessionId,
    locator,
    firstImmat,
    firstDateRegistered,
    damaged,
    plate,
    night,
    abroad,
    dateRegisteredActualOwner,
    carWheelSide,
  } = mapStoreState(useAppSelector<TSelectedProps>(selectPropsFromStore));
  const dispatch = useDispatch();

  const [hasStartedFilling, setHasStartedFilling] = useState<boolean>(false);

  const isProgressStepBar = hasStartedFilling || visitedSteps.includes(2) || isCalculated;

  const tryingToGoToNextStep = async () => {
    await triggerValidation();
  };

  const handleSetValidation = {
    handleSetValidation: handleSetValidationFromHoc,
    callBackIfFieldInvalid: handleCallGtmFieldErrorStep(2),
    handleSetFilledFieldStatus,
    callBackSetValidationFieldStatus,
  };

  const handleChange = useCallback(createHandleChange(
    dispatch,
    () => setHasStartedFilling(true),
    hasStartedFilling,
    { whichOwner, firstImmat, plate },
  ), [dispatch, whichOwner, firstImmat, plate, setHasStartedFilling, hasStartedFilling]);

  return (
    <StepTwoWrapper
      nextStep={tryingToGoToNextStep}
      prevStep={prevStep}
      isProgressStepBar={isProgressStepBar}
      fillStatusPercent={fillStatusPercent}
    >
      <DropDownField
        {...STEP_TWO_FIELDS.carRegisteredOn}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.carRegisteredOn)}
        fullWidth
        order={1}
        onChange={(key: typeof STEP_TWO_FIELDS.carRegisteredOn.id, value: typeof STEP_TWO_FIELDS.carRegisteredOn.availableValues[number]['value']) => (
          handleChange({ key, value })
        )}
        value={carRegisteredOn}
        handleSetValidation={handleSetValidation}
      />

      {carRegisteredOn === 'leasingCompany' && (
        <InfoBox
          {...STEP_TWO_FIELDS.infoBoxCarRegisteredOnLeasingCompany}
        />
      )}
      {carRegisteredOn === 'company' && (
        <InfoBox {...STEP_TWO_FIELDS.infoBoxCarRegisteredOnCompany} />
      )}

      <div className="hr" />

      <DropDownField
        {...STEP_TWO_FIELDS.carUse}
        fullWidth
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.carUse)}
        order={2}
        onChange={(key: typeof STEP_TWO_FIELDS.carUse.id, value: typeof STEP_TWO_FIELDS.carUse.availableValues[number]['value']) => handleChange({ key, value })}
        value={carUsage}
        narrower90
        handleSetValidation={handleSetValidation}
      />

      {carUsage === 'other' && <InfoBox {...STEP_TWO_FIELDS.infoBoxCarUse} />}

      <div className="hr" />

      <InputField
        {...STEP_TWO_FIELDS.carMileage}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.carMileage)}
        order={3}
        handleChange={(key: typeof STEP_TWO_FIELDS.carMileage.id, value: string) => handleChange({ key, value })}
        value={carMileage}
        handleSetValidation={handleSetValidation}
      />

      <div className="hr" />

      <DropDownField
        {...STEP_TWO_FIELDS.planToDriveThisYear}
        searchable={!isMobile}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.planToDriveThisYear)}
        order={4}
        onChange={(key: typeof STEP_TWO_FIELDS.planToDriveThisYear.id, value: (typeof STEP_TWO_FIELDS.planToDriveThisYear.availableValues)[number]['value']) => handleChange({ key, value })}
        handleSetValidation={handleSetValidation}
        value={mileageEstimator}
      />

      <div className="hr" />

      <ButtonCheckboxField
        {...STEP_TWO_FIELDS.whichOwnerInOrder}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.whichOwnerInOrder)}
        order={5}
        onChange={(key: typeof STEP_TWO_FIELDS.whichOwnerInOrder.id, value: IWhichOwnerAvValues['value']) => (
          handleChange({ key, value })
        )}
        value={whichOwner}
        multiSelect={false}
        handleSetValidation={handleSetValidation}
        isWider
      />

      <div className="hr" />

      <DropDownField
        {...STEP_TWO_FIELDS.firstCountry}
        fullWidth
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.firstCountry)}
        order={6}
        onChange={(key: typeof STEP_TWO_FIELDS.firstCountry.id, value: typeof STEP_TWO_FIELDS.firstCountry.availableValues[number]['value']) => (
          handleChange({ key, value })
        )}
        value={firstCountry}
        handleSetValidation={handleSetValidation}
        isWider
      />

      <div className="hr" />

      <CustomDatePickerField
        {...STEP_TWO_FIELDS.firstDateRegistered}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.firstDateRegistered)}
        order={7}
        onChange={(key: typeof STEP_TWO_FIELDS.firstDateRegistered.id, value: null | Date) => handleChange({ key, value })}
        value={firstDateRegistered}
        valueOnOpen={prodDate(productionYear)}
        customMaxDate={productionYear ?
          maxFirstDateRegistered(productionYear, STEP_TWO_FIELDS.firstDateRegistered.maxRegistrationDelay) :
          STEP_TWO_FIELDS.firstDateRegistered.maxRegistrationDelay}
        handleSetValidation={handleSetValidation}
        productionYear={productionYear}
      />

      <div className="hr" />

      {whichOwner === 'next' ? (
        <>
          <CustomDatePickerField
            {...STEP_TWO_FIELDS.dateRegisteredActualOwner}
            disabled={isDisableField(disableFields, STEP_TWO_FIELDS.dateRegisteredActualOwner)}
            order={8}
            onChange={(key: typeof STEP_TWO_FIELDS.dateRegisteredActualOwner.id, value: null | Date) => handleChange({ key, value })}
            value={dateRegisteredActualOwner}
            valueOnOpen={moment().format(DATE_FORMAT)}
            handleSetValidation={handleSetValidation}
            firstDateRegistered={firstDateRegistered}
          />
          <div className="hr" />
        </>
      ) : null}

      <InputField
        {...STEP_TWO_FIELDS.carRegistrationNumber}
        order={9}
        handleChange={(key: typeof STEP_TWO_FIELDS.carRegistrationNumber.id, value: null | string) => handleChange({ key, value })}
        value={plate}
        handleSetValidation={handleSetValidation}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.carRegistrationNumber)}
        ignoreFillStatus
      />

      <div className="hr" />

      <ButtonCheckboxField
        {...STEP_TWO_FIELDS.carWheelSide}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.carWheelSide)}
        order={11}
        multiSelect={false}
        handleSetValidation={handleSetValidation}
        onChange={(key: typeof STEP_TWO_FIELDS.carWheelSide.id, value: ICarWheelSideAvValues['value']) => (
          handleChange({ key, value })
        )}
        value={carWheelSide}
      />

      <div className="hr" />

      <DropDownField
        {...STEP_TWO_FIELDS.parkingPlace}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.parkingPlace)}
        fullWidth
        order={12}
        handleSetValidation={handleSetValidation}
        onChange={(key: typeof STEP_TWO_FIELDS.parkingPlace.id, value: typeof STEP_TWO_FIELDS.parkingPlace.availableValues[number]['value']) => (
          handleChange({ key, value })
        )}
        value={night}
      />

      <div className="hr" />

      <DropDownField
        {...STEP_TWO_FIELDS.timeUsedAbroad}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.timeUsedAbroad)}
        fullWidth
        order={13}
        onChange={(key: typeof STEP_TWO_FIELDS.timeUsedAbroad.id, value: typeof STEP_TWO_FIELDS.timeUsedAbroad.availableValues[number]['value']) => (
          handleChange({ key, value })
        )}
        handleSetValidation={handleSetValidation}
        value={abroad}
        isWider
      />

      <div className="hr" />

      <DropDownField
        {...STEP_TWO_FIELDS.damaged}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.damaged)}
        fullWidth
        order={14}
        handleSetValidation={handleSetValidation}
        onChange={(key: typeof STEP_TWO_FIELDS.damaged.id, value: typeof STEP_TWO_FIELDS.damaged.availableValues[number]['value']) => handleChange({ key, value })}
        value={damaged}
      />

      <div className="hr" />

      <FetchedField
        {...STEP_TWO_FIELDS.lastInsurer}
        searchable={!isMobile}
        disabled={isDisableField(disableFields, STEP_TWO_FIELDS.lastInsurer)}
        order={15}
        url={createUrlInsurersToShow()}
        modifyFetchedData={(data: unknown) => setInsurersToShowValues(data as Record<string, string>)}
        cacheIt
        config={axiosConfigWithSession(sessionId, locator)}
        handleSetValidation={handleSetValidation}
        value={lastInsurerName}
        component={({ data, ...additionalProps }: { data: TInsurerValues } & Omit<TDropDownFieldInnerProps, 'availableValues'>) => {
          if (data.length > 0) {
            return (
              <>
                <DropDownFieldWithoutHOC
                  {...additionalProps}
                  availableValues={data}
                  onChange={(_key: string, value: TInsurerValues[number]['value'] | number | null) => {
                    const checkedValue = value as string;
                    return handleChange({ key: 'lastInsurer', value: checkedValue }, data);
                  }}
                />
              </>
            );
          }
          return <></>;
        }}
        errorComponent={() => (
          <FetchError
            id={STEP_TWO_FIELDS.lastInsurer.id}
            handleSetValidation={handleSetValidation.handleSetValidation}
          />
        )}
      />
      <div className="hr" />
    </StepTwoWrapper>
  );
});

export default StepTwo;
