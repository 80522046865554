/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import {
  ValidationHoc as validationHoc,
  AgreementsContainerV2 as AgreementsContainer,
} from 'porowneo-ui';
import { Button } from 'porowneo-design';

import { agreementsOrder } from '../config/main';
import FIELDS from '../config/fields';
import * as regex from '../config/regex';
import { AGREEMENT_PROP_TYPES, CONTACT_USER_PROP_TYPES } from '../config/proptypes';

import { CheckboxField } from './fields/CheckboxField';
import { InputField } from './fields/inputField/InputField';

import type { TAgreement } from 'porowneo-ui';
import type { IUser } from '../reducers';

export type TUserType = Pick<IUser, 'locator' | 'phoneLeaveContact' | 'sessionId' | 'name' | 'surname' | 'businessProcess'>;
export interface IContactFormWithValidation {
  user: TUserType;
  agreements: TAgreement<'api'>[];
  userAgreements: any;
  stepOrigin: string | number;
  handleSetAgreements: () => void;
  requiredAgreements: string[];
  showTheseAgreements: string[];
  handleCallGtmFieldError?: (id: string) => void;
  handleChange: (id: string, value: string | number) => void;
  handleCallGtm?: (key: string, value: string | number) => void;
}

export const ContactForm = ({
  user,
  userAgreements,
  stepOrigin,
  agreements,
  handleChange,
  handleCallGtm,
  triggerValidation,
  showTheseAgreements,
  handleSetAgreements,
  requiredAgreements,
  status,
  handleSetValidation: handleSetValidationFromHoc,
  handleCallGtmFieldError,
}) => {
  const handleSetValidation = {
    handleSetValidation: handleSetValidationFromHoc,
    callBackIfFieldInValid: handleCallGtmFieldError,
  };
  return (
    <>
      <InputField
        {...FIELDS.name}
        handleChange={handleChange}
        value={user.name}
        mask={regex.NAME}
        stepOrigin={stepOrigin}
        callBackIfValid={handleCallGtm}
        order={1}
        handleSetValidation={handleSetValidation}
      />

      <InputField
        {...FIELDS.surname}
        handleChange={handleChange}
        value={user.surname}
        mask={regex.SURNAME}
        stepOrigin={stepOrigin}
        callBackIfValid={handleCallGtm}
        order={2}
        handleSetValidation={handleSetValidation}

      />

      <InputField
        {...FIELDS.phoneLeaveContact}
        handleChange={handleChange}
        value={user.phoneLeaveContact}
        stepOrigin={stepOrigin}
        callBackIfValid={handleCallGtm}
        order={3}
        handleSetValidation={handleSetValidation}
      />

      <AgreementsContainer
        id="needContactAgreements"
        agreements={agreements}
        neededAgreements={showTheseAgreements}
        handleChange={handleSetAgreements}
        requiredAgreements={requiredAgreements}
        userAgreements={userAgreements}
        stepOrigin={stepOrigin}
        startOrder={4}
        handleSetValidation={handleSetValidation.handleSetValidation}
        agreementsOrder={agreementsOrder}
        CheckboxComponent={CheckboxField as any} // TODO FORMMOTO-3709 Fix after moving agreementsContainer to porowneo-design
      />

      <Button
        id="sendButton"
        handleClick={triggerValidation}
        fullWidth
        variant="contained"
      >
        {status === 'validating' ? 'Wysyłanie...' : 'Wyślij'}
      </Button>
    </>
  );
};

ContactForm.defaultProps = {
  handleCallGtm: () => {},
  triggerValidation: () => {},
  handleSetAgreements: () => {},
};

ContactForm.defaultProps = {
  showTheseAgreements: [],
  requiredAgreements: [],
  user: {
    name: '',
    surname: '',
    phoneLeaveContact: '',
  },
};

ContactForm.propTypes = {
  user: CONTACT_USER_PROP_TYPES,
  userAgreements: PropTypes.objectOf(PropTypes.any).isRequired,
  stepOrigin: PropTypes.number.isRequired,
  agreements: PropTypes.arrayOf(AGREEMENT_PROP_TYPES).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCallGtm: PropTypes.func,
  triggerValidation: PropTypes.func,
  showTheseAgreements: PropTypes.arrayOf(PropTypes.any),
  handleSetAgreements: PropTypes.func,
  requiredAgreements: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string.isRequired,
  handleSetValidation: PropTypes.func.isRequired,
  handleCallGtmFieldError: PropTypes.func.isRequired,
};

export default validationHoc<any>(ContactForm);
