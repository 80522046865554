/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-useless-escape,camelcase,no-underscore-dangle,import/prefer-default-export */
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { createStore } from 'redux';
import log from 'loglevel';
import rg4js from 'raygun4js';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import { version } from '../package.json';

import './config/polyfills';

import { globalCss } from './styled/globalStyles';
import reducer from './reducers';
import AppContainer from './containers/App';
import {
  saveState,
  loadState,
  settingAppVersion,
  shouldShowLocalStateModal,
} from './helpers/StoreUtils';
import { getTheme } from './helpers/setupTheme';
import { saveFullUrlInSessionStorage } from './helpers/UrlHelpers';

const theme = getTheme() || {};

const GlobalStyles = createGlobalStyle`
  ${globalCss}
`;

require('dotenv').config();

if (process.env.REACT_APP_ENV === 'prod') {
  rg4js('apiKey', 'iMeuYah2aOjS1jB2wI7NtQ');
  log.disableAll();
} else {
  rg4js('apiKey', 'pKZhbAWG3QOInpsxT0XbLw');
  log.enableAll();
  log.setLevel('debug');
}

rg4js('enableCrashReporting', true);

rg4js('options', {
  excludedHostnames: ['\.local', 'localhost'],
});

const appVersion = 'localStorage' in window ? settingAppVersion(localStorage, version) : version;

rg4js('setVersion', appVersion);

saveFullUrlInSessionStorage();
const persistedState = loadState('');
const localStorageState = loadState('local');

const store = createStore(reducer, persistedState, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

const isLocalStateModal = shouldShowLocalStateModal(
  localStorageState,
  persistedState,
  localStorage,
);

const subscribeToStore = () => {
  store.subscribe(() => {
    saveState(store.getState());
  });
};

if (!isLocalStateModal) subscribeToStore();

const rootElement = document.getElementById('root');

const WrappedApp = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Provider store={store}>
      <AppContainer
        theme={theme}
        isLoadLocalStateModal={isLocalStateModal}
        subscribeToStore={subscribeToStore}
      />
    </Provider>
  </ThemeProvider>
);

render(
  <WrappedApp />,
  rootElement,
);
