/* eslint-disable camelcase */
import moment from 'moment';
import { mapOfferDetailsData } from 'porowneo-ui';

import { OFFER_LIST_INSURERS } from '../../config/Partners.config';

const {
  mapDataOcHelpers,
  mapDataAcHelpers,
  mapDataAssHelpers,
  mapDataNnwHelpers,
  mapDataAdditionalRisksHelpers,
} = mapOfferDetailsData;

const isVehicleYoung = year => (parseInt(moment().format('YYYY'), 10) - parseInt(year, 10)) <= 15;
const territorialScope = 'Zakres terytorialny';
const insurance_sum = 'Suma ubezpieczenia';

export const configForOffersDetails = (offertDetails: any, additionalParams: any = {}) => {
  const {
    // ac
    ac_insurance_sum,
    ac_ins_contribution,
    ac_ins_contribution_value,
    ac_ins_damage_settlement,
    ac_ins_depreciation,
    ac_ins_depreciation_value,
    as_ins_incident_repair,
    ac_ins_damage_rep_on_parts,
    ac_ins_damage_rep_dealership,
    ac_territorial_range,
    ac_ins_variant,
    ac_min_damage_value,
    ac_ins_estimate_type,
    mini_ac,
    ac_when_work,
    ac_mini_risk,
    // as
    as_ins_variant,
    as_insurance_sum,
    as_ins_tow_distance,
    as_ins_rep_vehicle_days,
    as_ins_when_is_due,
    as_territorial_range,
    as_min_distance_house,
    // nnw
    nnw_insurance_sum,
    nnw_covered_persons,
    nnw_territorial_range,
    // other
    name,
    covers,
    basket_id,
  } = offertDetails;

  const oc = {
    oc_cover_range: {
      title: 'Sumy gwarancyjne',
      subtitle: 'Suma określa maksymalną wysokość odszkodowania lub świadczenia',
      value: ['5 210 000 EUR w przypadku szkody osobowej', '1 050 000 EUR w przypadku szkody w mieniu'],
      order: 2,
    },
    oc_territorial_scope: {
      title: territorialScope,
      subtitle: '',
      value: ['Polska i Europa z wyjątkiem krajów, w których wymagana jest Zielona Karta'],
      order: 3,
    },
    oc_green_card: {
      title: 'Zielona Karta',
      subtitle: '',
      value: mapDataOcHelpers.mapOcGreenCard(name),
      order: 4,
    },
    oc_bls: {
      title: 'Bezpośrednia likwidacja szkody (BLS)',
      subtitle: '',
      value: mapDataOcHelpers?.mapOcBls?.[name] || ['Nie'],
      order: 5,
    },
  };

  const ac = {
    ac_type: {
      title: 'Rodzaj',
      subtitle: '',
      value: mapDataAcHelpers.mapAcType(name, ac_ins_variant, mini_ac, basket_id, false),
      order: 2,
    },
    ac_damage_to_be_insured: {
      title: 'Szkoda podlegająca ubezpieczeniu',
      subtitle: '',
      value: mapDataAcHelpers.mapAcDamageToBeInsured(name, ac_ins_estimate_type, ac_ins_variant),
      order: 3,
    },
    ac_when_ac_work: {
      title: 'Kiedy działa?',
      subtitle: '',
      value: mapDataAcHelpers.mapWhenAceWork(name, ac_when_work, ac_ins_variant, ac_mini_risk, basket_id),
      order: 4,
    },
    ac_insurance_sum: {
      title: insurance_sum,
      subtitle: '',
      value: mapDataAcHelpers.mapAcInsuranceSum(name, ac_insurance_sum),
      order: 5,
    },
    ac_territorial_scope: {
      title: territorialScope,
      subtitle: '',
      value: mapDataAcHelpers.mapTerritorialScope(name, ac_territorial_range, ac_ins_variant),
      order: 6,
    },
    ac_ins_contribution: {
      title: 'Udział własny',
      subtitle: 'W przypadku szkody wartość udziału własnego zostanie odjęta od wysokości odszkodowania',
      value: mapDataAcHelpers.mapOwnContribution(name, ac_ins_contribution, ac_ins_contribution_value),
      order: 7,
    },
    ac_minimum_damage_value: {
      title: 'Minimalna wartość szkody',
      subtitle: 'W przypadku szkód o niższej wartości ubezpieczyciel nie pokryje kosztów likwidacji szkody',
      value: mapDataAcHelpers.mapMinValueDamage(name, ac_min_damage_value),
      order: 8,
    },
    ac_ins_damage_settlement: {
      title: 'Sposób likwidacji szkody',
      subtitle: '',
      value: mapDataAcHelpers.mapDamageSettlements(name, ac_ins_damage_settlement),
      order: 9,
    },
    ac_repair_on_parts: {
      title: 'Naprawa na częściach',
      subtitle: '',
      value: mapDataAcHelpers.mapRepairOnParts(name, ac_ins_damage_rep_on_parts),
      order: 10,
    },
    ac_fix_in_aso: {
      title: 'Naprawa w ASO',
      subtitle: 'ASO - autoryzowany warsztat',
      value: mapDataAcHelpers.mapFixInAso(name, ac_ins_damage_rep_dealership),
      order: 11,
    },
    ac_ins_depreciation_value: {
      title: 'Amortyzacja części',
      subtitle: 'Utrata wartości części związana z upływem czasu',
      value: mapDataAcHelpers.mapDepreciation(name, ac_ins_depreciation, ac_ins_depreciation_value),
      order: 12,
    },
  };

  const as = {
    as_when_aid_is_available: {
      title: 'Kiedy przysługuje pomoc Assistance?',
      subtitle: '',
      value: mapDataAssHelpers.mapWhenAidIsAvailable(name, as_ins_variant, as_ins_when_is_due),
      order: 2,
    },
    as_territorial_scope: {
      title: territorialScope,
      subtitle: '',
      value: mapDataAssHelpers.mapAsTerritorialcope(name, as_territorial_range),
      order: 3,
    },
    as_insurance_sum: {
      title: insurance_sum,
      subtitle: '',
      value: mapDataAssHelpers.mapAsInsuranceSum(name, as_insurance_sum, undefined),
      order: 4,
    },
    as_how_far_from_home: {
      title: 'Minimalna odległość od domu uprawniająca do uzyskania pomocy Assistance',
      subtitle: '',
      value: mapDataAssHelpers.mapAsHowFarFromHome(name, as_min_distance_house),
      order: 5,
    },
    as_ins_tow_distance: {
      title: 'Holowanie pojazdu',
      subtitle: '',
      value: mapDataAssHelpers.mapTowingCar(name, as_ins_tow_distance, as_ins_variant),
      order: 6,
    },
    as_onsite_repair: {
      title: 'Naprawa na miejscu zdarzenia',
      subtitle: '',
      value: mapDataAssHelpers.mapAsInsIncidentRepair(name, as_ins_incident_repair),
      order: 7,
    },
    as_substitute_car: {
      title: 'Samochód zastępczy',
      subtitle: '',
      value: mapDataAssHelpers.mapSubstituteCar(name, as_ins_rep_vehicle_days, as_ins_variant),
      order: 8,
    },
    as_additional_help: {
      title: 'Dodatkowa pomoc/usługi',
      subtitle: '',
      value: mapDataAssHelpers.mapAdditionalHelp(name, as_ins_variant),
      order: 9,
    },
  };

  const nnw = {
    nnw_insurance_sum: {
      title: insurance_sum,
      subtitle: '',
      value: mapDataNnwHelpers.mapNnwPrice(name, nnw_insurance_sum),
      order: 2,
    },
    nnw_covered_persons: {
      title: 'Kogo obejmuje ubezpieczenie NNW',
      subtitle: '',
      value: mapDataNnwHelpers.mapNnwCoveredPerson(name, nnw_covered_persons),
      order: 3,
    },
    nnw_territorial_scope: {
      title: territorialScope,
      subtitle: '',
      value: mapDataNnwHelpers.mapNnwTerritorialRange(name, nnw_territorial_range),
      order: 4,
    },
  };

  const additionalRisks = mapDataAdditionalRisksHelpers.mapAdditionalRisks(offertDetails);

  const setTitle = (cover) => {
    switch (cover) {
    case 'ac':
      return 'AC zapewnia uzyskanie odszkodowania bądź wykonanie naprawy samochodu na koszt Ubezpieczyciela';
    case 'oc':
      return '';
    case 'as':
      return 'Assistance pozwala uzyskać pomoc w nieprzewidzianych sytuacjach na drodze';
    case 'nnw':
      return 'NNW pozwala otrzymać świadczenie w przypadku uszczerbku na zdrowiu lub śmierci w wypadku samochodowym';
    default:
      return '';
    }
  };

  const setOfferExist = (cover: 'ac' | 'as' | 'nnw') => ({
    offerExist: {
      subtitle: setTitle(cover),
      value: covers[cover] ? ['Tak'] : ['Brak'],
      order: 1,
    },
  });

  const additionalCondition = (partnerName) => {
    const partnerNameLowerCase = partnerName.toLowerCase();
    if (partnerNameLowerCase === 'benefia') {
      if (additionalParams?.year) {
        return isVehicleYoung(additionalParams.year);
      }
      return true;
    }
    return OFFER_LIST_INSURERS.includes(partnerName);
  };

  return ({
    oc,
    ac: covers.ac && additionalCondition(name) ? { ...setOfferExist('ac'), ...ac } : setOfferExist('ac'),
    as: covers.as && additionalCondition(name) ? { ...setOfferExist('as'), ...as } : setOfferExist('as'),
    nnw: covers.nnw ? { ...setOfferExist('nnw'), ...nnw } : setOfferExist('nnw'),
    additionalRisks,
    covers,
  });
};
