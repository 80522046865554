export const FOOTER_CONTACT_LINK_SELECTOR = '.footer-contact-link';
export const HEADER_RESULTS_GO_BACK_LINK = '.header-go-back-link';

export const LOCATOR_PLACEHOLDER = '.header__locator-info .locator';
export const VERSION_PLACEHOLDER = '.header__version';
export const PHONE_PLACEHOLDER = '.footer__phone-info-content';
export const PHONE_CONTENT_PLACEHOLDER = '.content__phone-number';
export const HTML_HEADER_ROOT_FOOTER = '.header-form-footer';

// used for showing modal so that the background is not scrollable
export const NO_SCROLL_HTML_CLASS_NAME = 'noScroll';
