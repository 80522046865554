export const NAME = {
  min: 3,
  max: 25,
};
export const SURNAME = {
  min: 2,
  max: 35,
};
export const CAR_REGISTRATION_NUMBER = 8;
export const NIP = 10;
export const MILAGE = 3;
export const STREET_NAME = { min: 2, max: 80 };
export const BUILDING_NUMBER = { min: 1, max: 8 };
export const FLAT_NUMBER = { min: 1, max: 8 };
