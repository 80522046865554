import { checkIfSavedPayloadEqualNewOne } from './equalityPayloadHelpers';

import type { IRootState } from '../../reducers';

export const RECALCULATION_PAYLOAD = 'recalculationPayload';

export interface IInsurerCheck {
  firstName: string,
  lastName: string,
  pesel: string,
}

export const createCheckPayload = (props: Pick<IRootState, 'payload'>): {
  owner: IInsurerCheck,
  coOwner: IInsurerCheck,
  coCoOwner: IInsurerCheck,
  driver: IInsurerCheck,
  plate: string,
} => {
  const {
    payload,
  } = props || {};

  const {
    stepThree,
    stepTwo,
  } = payload || {};

  const additionalField = {
    plate: stepTwo?.plate || '',
  };

  const baseOwnerData = {
    firstName: '',
    lastName: '',
    pesel: '',
  };

  const owners = {
    owner: { ...baseOwnerData },
    coOwner: { ...baseOwnerData },
    coCoOwner: { ...baseOwnerData },
    driver: { ...baseOwnerData },
  };

  Object.keys(owners).forEach((insurer) => {
    owners[insurer] = {
      firstName: stepThree?.[insurer]?.firstName || '',
      lastName: stepThree?.[insurer]?.lastName || '',
      pesel: stepThree?.[insurer]?.pesel || '',
    };
  });

  return {
    ...additionalField,
    ...owners,
  };
};

export const checkIfRecalculateAndSavePayloadToCompare = (props: Pick<IRootState, 'payload'>) => {
  const recalculationResults = createCheckPayload(props);

  return !checkIfSavedPayloadEqualNewOne(RECALCULATION_PAYLOAD, recalculationResults);
};
