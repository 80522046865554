import type { ReactNode } from 'react';

interface IStepRow {
  left?: ReactNode
  right?: ReactNode
  isFS?: boolean
}

const StepRow = ({ left, right, isFS = false }: IStepRow) => (
  <div className="row">
    <div className={`col-xl-9 ${isFS ? 'col-xl-8 col-12' : 'col-sm-12'}`}>
      {left}
    </div>
    <div className={`col-xl-3 ${isFS ? 'col-12 col-xl-4' : 'mobile-hide'}`}>
      {right}
    </div>
  </div>
);

export default StepRow;
