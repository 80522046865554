import type { TSteps } from '../components/StepContainer/StepContainer';

type TContent = JSX.Element[];

interface IStepConfig {
  header?: string;
  content: TContent;
}

type TSidebarConfig = Record<Exclude<TSteps, 5>, IStepConfig>;

export const SIDEBAR_CONFIG: TSidebarConfig = {
  1: {
    content: [
      <>
        Przygotuj dowód rejestracyjny - dzięki temu szybko znajdziesz potrzebne informacje
      </>,
    ],
  },
  2: {
    content: [
      <>
        Dane dotyczące użytkowania samochodu są potrzebne do oszacowania ryzyka wystąpienia np. szkody lub kradzieży pojazdu.
      </>,
    ],
  },
  3: {
    header: 'Twoje dane są u nas bezpieczne.',
    content: [
      <>
        Pytamy o nie, aby uwzględnić wszystkie Twoje zniżki i uzyskać najlepszą ofertę ubezpieczenia.
      </>,
    ],
  },
  4: {
    content: [
      <>
        Historia ubezpieczenia i szkodowości jest niezbędna do poprawnego wyliczenia składek.
      </>,
    ],
  },
};
