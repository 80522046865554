import { Validator } from 'jsonschema';

const INSURER_SCHEMA = '/InsurerSchema';
const INSURER_SCHEMA_WITH_ID = '/InsurerSchemaWithId';
const ADDRESS_SCHEMA = '/AddressSchema';
const INSURANCE_HISTORY_SCHEMA = '/InsuranceHistorySchema';
const COVER_INSURANCE_HISTORY_SCHEMA = '/CoverInsuranceHistorySchema';

const schemaCreate = {
  id: '/CreateCarProject',
  type: 'object',
  properties: {
    locatorFull: { type: 'string' },
    locatorShort: { type: 'string' },
    sessionId: { type: 'string' },
    eurotaxVersion: { type: 'number' },
    infoexVersion: { type: ['number', 'null'] },
    carYear: { type: 'number' },
    dataOrigin: { type: 'string' },
    lpg: { type: 'boolean' },
    phone: { type: ['string', 'null'] },
    protectionBegins: { type: ['string', 'null'] },
    ac: { type: 'boolean' },
    nnw: { type: 'boolean' },
    ga: { type: ['string', 'null'] },
    ktApiProjectId: { type: ['number', 'null'] },
    source: { type: ['string', 'null'] },
  },
  required: [
    'locatorFull',
    'locatorShort',
    'sessionId',
    'eurotaxVersion',
    'carYear',
    'dataOrigin',
    'lpg',
    'ac',
    'nnw',
  ],
};

const schemaStepOne = {
  ...schemaCreate,
  properties: {
    ...schemaCreate.properties,
    locator: { type: ['string'] },
  },
  id: '/UpdateCarProjectStep_1',
  required: [
    'locator',
  ],
};

const schemaStepTwo = {
  id: '/UpdateCarProjectStep_2',
  type: 'object',
  properties: {
    carRegisteredOn: { type: 'string' },
    carUsage: { type: 'string' },
    actualKm: { type: 'number' },
    mileageEstimator: { type: 'string' },
    whichOwner: { type: 'string' },
    firstCountry: { type: 'string' },
    firstImmat: { type: 'string' }, // → date format YYYY-MM-DD
    yearOwnerRegistered: { type: 'number' },
    ownerRegisteredDate: { type: ['string', 'null'] },
    plate: { type: ['string', 'null'] },
    rightWheel: { type: 'boolean' },
    night: { type: 'string' },
    abroad: { type: 'string' },
    damage: { type: 'boolean' },
    damageDetail: { type: ['string', 'null'] },
    regInPl: { type: ['boolean', 'null'] },
    modification: { type: ['boolean', 'null'] },
    modDetail: { type: ['string', 'null'] },
  },
  required: [
    'carRegisteredOn',
    'carUsage',
    'actualKm',
    'mileageEstimator',
    'whichOwner',
    'firstCountry',
    'firstImmat',
    'yearOwnerRegistered',
    'plate',
    'rightWheel',
    'night',
    'abroad',
    'damage',
    'damageDetail',
  ],
};

const addressSchema = {
  id: ADDRESS_SCHEMA,
  type: ['object', 'null'],
  properties: {
    type: { type: 'string' },
    zipCode: { type: 'string' },
    city: { type: 'string' },
    district: { type: 'string' },
    street: { type: ['string', 'null'] },
    building: { type: ['string', 'null'] },
    flat: { type: ['string', 'null'] },
  },
};

const insurerSchema = {
  id: INSURER_SCHEMA,
  type: ['object', 'null'],
  properties: {
    type: { type: 'string' },
    firstName: { type: ['string', 'null'] },
    lastName: { type: ['string', 'null'] },
    birthdate: { type: 'string' },
    pesel: { type: ['string', 'null'] },
    licenseYear: { type: ['string', 'null'] },
    licenseMonth: { type: ['string', 'null'] },
    hasDrivingLicense: { type: 'boolean' },
    maritalStatus: { type: ['string', 'null'] },
    addressRegisteredIdentical: { type: ['boolean', 'null'] },
    address: { $ref: ADDRESS_SCHEMA },
    addressRegistered: { $ref: ADDRESS_SCHEMA },
    phone: { type: ['string', 'null'] },
    email: { type: ['string', 'null'] },
  },
};

const insurerSchemaWithId = {
  ...insurerSchema,
  id: INSURER_SCHEMA_WITH_ID,
  properties: {
    ...insurerSchema.properties,
    userId: { type: 'number' },
  },
  required: ['userId'],
};

const schemaStepThree = {
  id: '/updateCarProjectStep3',
  type: 'object',
  properties: {
    hasChildren: { type: ['boolean', 'null'] },
    driverUnder26: { type: 'boolean' },
    coowners: { type: 'number' },
    mainDriver: { type: 'string' },
    numberOfChilds: { type: ['number', 'null'] },
    hasChildrenU26: { type: 'boolean' },
    ageOldestChild: { type: ['number', 'null'] },
    owner: { $ref: INSURER_SCHEMA },
    coOwner: { $ref: INSURER_SCHEMA },
    coCoOwner: { $ref: INSURER_SCHEMA },
    driver: { $ref: INSURER_SCHEMA },
    youngPerson: { $ref: INSURER_SCHEMA },
  },
  required: [
    'owner',
    'mainDriver',
  ],
};

const coverInsuranceHistorySchema = {
  id: COVER_INSURANCE_HISTORY_SCHEMA,
  type: ['object', 'null'],
  properties: {
    type: { type: 'string' },
    yearsBuying: { type: ['number'] },
    damagesPaid: { type: ['number'] },
    firstDamage: { type: ['number', 'null'] },
    secondDamage: { type: ['number', 'null'] },
    thirdDamage: { type: ['number', 'null'] },
    fourthDamage: { type: ['number', 'null'] },
    fifthDamage: { type: ['number', 'null'] },
  },
};
const insuranceHistorySchema = {
  id: INSURANCE_HISTORY_SCHEMA,
  type: ['object', 'null'],
  properties: {
    carUser: { type: 'number' },
    insuranceHistorySame: { type: 'boolean' },
    ocInsuranceHistory: { $ref: COVER_INSURANCE_HISTORY_SCHEMA },
    acInsuranceHistory: { $ref: COVER_INSURANCE_HISTORY_SCHEMA },
  },
};

const schemaStepFour = {
  id: '/updateCarProjectStep4',
  type: 'object',
  properties: {
    ownerInsuranceHistory: { $ref: INSURANCE_HISTORY_SCHEMA },
    coOwnerInsuranceHistory: { $ref: INSURANCE_HISTORY_SCHEMA },
    coCoOwnerInsuranceHistory: { $ref: INSURANCE_HISTORY_SCHEMA },
    driverInsuranceHistory: { $ref: INSURANCE_HISTORY_SCHEMA },
  },
  required: [],
};

export const v = new Validator();

v.addSchema(insurerSchema, INSURER_SCHEMA);
v.addSchema(insurerSchemaWithId, INSURER_SCHEMA_WITH_ID);
v.addSchema(addressSchema, ADDRESS_SCHEMA);
v.addSchema(insuranceHistorySchema, INSURANCE_HISTORY_SCHEMA);
v.addSchema(coverInsuranceHistorySchema, COVER_INSURANCE_HISTORY_SCHEMA);

const schemaStepOneFs = {
  id: '/updateCarProjectStep1_FS',
  type: 'object',
  properties: {
    owner: { $ref: INSURER_SCHEMA_WITH_ID },
    coOwner: { $ref: INSURER_SCHEMA_WITH_ID },
    coCoOwner: { $ref: INSURER_SCHEMA_WITH_ID },
    driver: { $ref: INSURER_SCHEMA_WITH_ID },
    youngPerson: { $ref: INSURER_SCHEMA_WITH_ID },
    inurer: { $ref: INSURER_SCHEMA },
    insurerUserId: { type: ['number', 'null'] },
  },
  required: ['owner'],
};

const schemaStepTwoFs = {
  id: '/updateCarProjectStep2_FS',
  type: 'object',
  properties: {},
  required: [],
};

// TO DO describe ANY
export const FS_SCHEMAS: {
  [key: number]: any,
} = {
  1: schemaStepOneFs,
  2: schemaStepTwoFs,
};

const SCHEMAS: {
  create: any,
  [key: number]: any,
} = {
  create: schemaCreate,
  1: schemaStepOne,
  2: schemaStepTwo,
  3: schemaStepThree,
  4: schemaStepFour,
};

export default SCHEMAS;
