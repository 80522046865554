import { Fragment, useState } from 'react';
import { StickyContainer } from 'react-sticky';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { ValidationHoc } from 'porowneo-ui';
import { InfoBox } from 'porowneo-design';
import isEqual from 'lodash/isEqual';

import StepContainer from '../../components/StepContainer/StepContainer';
import { DropDownField } from '../../components/fields/DropDownField';
import FIELDS from '../../config/fields';
import {
  OWNER,
  CO_CO_OWNER,
  CO_OWNER,
  DRIVER,
  YOUNG_PERSON,
} from '../../config/InsurerPersons.literal';
import { setCoOwnersSectionTitle } from '../../helpers/FormUtils';
import { handleCallGtmFieldErrorStep } from '../../helpers/tagManager/TagManagerUtils';
import { StepSectionTitle } from '../../steps/styled/Steps.styled';
import { setViewValue, isDisableField } from '../../helpers/payloadUtils';
import { useAppSelector } from '../../hooks/useAppSelector';
import ButtonCheckboxField from '../../components/fields/ButtonCheckboxField';

import InsurerWithStore from './components/Insurer/Insurer';
import { selectStepThreePropsFromStore } from './utils/selectStepThreePropsFromStore';
import { handleCallGtmStepThree } from './utils/handleCallGtmStepThree';
import { handleChangeStepThree } from './utils/handleChangeStepThree';
import { renderAvailableValuesMainDriver } from './utils/renderAvailableValuesMainDriver';

import type {
  TSetInitializedStep, TSelectedStepThreePropsFromStore, THandleChangeStepThree, IStepTrheeProps,
} from './StepThree.types';
import type { IHandleSetValidationSteps } from '../../types/Steps.type';
import type { IValidationProps } from 'porowneo-ui/build/ValidationHoc/ValidationHoc';

const callBackIfFieldInValid = handleCallGtmFieldErrorStep(3);

export const StepThree = ValidationHoc((props: IStepTrheeProps) => {
  const {
    fillStatusPercent,
    triggerValidation,
    handleSetValidation: handleSetValidationFromHoc,
    callBackSetValidationFieldStatus,
    handleSetFilledFieldStatus,
    validationFieldMethods,
    prevStep,
  } = props as IValidationProps & { prevStep:() => void };
  const dispatch = useDispatch();
  const {
    visitedSteps,
    payload,
    isCalculated,
    disableFields,
  } = useAppSelector<TSelectedStepThreePropsFromStore>(selectStepThreePropsFromStore, isEqual);
  const {
    coowners,
    mainDriver,
    driverUnder26,
    numberOfChilds,
    hasChildrenU26,
    ageOldestChild,
  } = payload.stepThree;

  const [isStartFilling, setIsStartFilling] = useState(false);

  const setInitializedStep: TSetInitializedStep = (key, autoSelect) => {
    const exclusionsFillStatusKeys = ['owner-firstName', 'owner-lastName', 'owner-pesel'];
    if (!isStartFilling && !exclusionsFillStatusKeys.includes(key) && !autoSelect) setIsStartFilling(true);
  };

  const handleChange: THandleChangeStepThree = (allValues) => {
    const {
      key,
      value,
      autoSelect,
    } = allValues;
    handleCallGtmStepThree(key, value);

    setInitializedStep(key, autoSelect);

    handleChangeStepThree({
      dispatch,
      ...allValues,
      mainDriver,
      coowners,
      ageOldestChild,
      numberOfChilds,
      hasChildrenU26,
    });
  };

  const handleSetValidation: IHandleSetValidationSteps = {
    handleSetValidation: handleSetValidationFromHoc,
    callBackIfFieldInValid,
    callBackSetValidationFieldStatus,
    handleSetFilledFieldStatus,
  };

  const availableValuesMainDriver = renderAvailableValuesMainDriver(coowners, FIELDS.mainDriver.availableValues);

  const isProgressStepBar = isStartFilling || visitedSteps.includes(3) || isCalculated;

  return (
    <StickyContainer>
      <StepContainer
        step={3}
        btnOneConfig={{ show: true, btnText: 'Wróć', func: prevStep }}
        btnTwoConfig={{ show: true, btnText: 'Dalej', func: triggerValidation }}
        isProgressStepBar={isProgressStepBar}
        fillStatusPercent={fillStatusPercent}
        title="Dane właściciela"
      >
        <div>
          <InsurerWithStore
            type={OWNER}
            showInfoBoxNameSurnamePesel
            handleSetValidation={handleSetValidation}
            validationFieldMethods={validationFieldMethods}
            stepOrigin={3}
            setInitializedStep={setInitializedStep}
            disableFields={disableFields}
          />

          <StepSectionTitle>Dodatkowi użytkownicy pojazdu</StepSectionTitle>
          <div className="hr" />

          <ButtonCheckboxField
            {...FIELDS.driverUnder26}
            disabled={isDisableField(disableFields, FIELDS.driverUnder26)}
            order={21}
            multiSelect={false}
            onChange={(
              key: typeof FIELDS.driverUnder26.id,
              value: typeof FIELDS.driverUnder26.availableValues[number]['value'],
              allData, autoSelect?: boolean,
            ) => handleChange({ key, value, autoSelect })}
            value={setViewValue(driverUnder26)}
            handleSetValidation={handleSetValidation}
          />

          <div className="hr" />

          {driverUnder26 && (
            <InsurerWithStore
              type={YOUNG_PERSON}
              handleSetValidation={handleSetValidation}
              orderPrefix={30}
              stepOrigin={3}
              setInitializedStep={setInitializedStep}
              disableFields={disableFields}
            />
          )}

          <DropDownField
            {...FIELDS.coowners}
            disabled={isDisableField(disableFields, FIELDS.coowners)}
            order={60}
            onChange={(
              key: typeof FIELDS.coowners.id,
              value: typeof FIELDS.coowners.availableValues[number]['value'],
              allData,
              autoSelect?: boolean,
            ) => handleChange({ key, value, autoSelect })}
            handleSetValidation={handleSetValidation}
            value={coowners}
          />

          <div className="hr" />

          <ButtonCheckboxField
            {...FIELDS.mainDriver}
            disabled={isDisableField(disableFields, FIELDS.mainDriver)}
            order={65}
            multiSelect={false}
            onChange={(
              key: typeof FIELDS.mainDriver.id,
              value: typeof FIELDS.mainDriver.availableValues[number]['value'],
              allData,
              autoSelect?: boolean,
            ) => handleChange({ key, value, autoSelect })}
            value={mainDriver}
            handleSetValidation={handleSetValidation}
            availableValues={availableValuesMainDriver}
          />

          {mainDriver === 'driver' && (
            <InfoBox
              id="mainDriver-infoBox"
              content="Dane Głównego Kierowcy, podane podczas wypełniania formularza nie są uwzględniane przez LINK4 w kalkulacji składki za polisę. Składka za ubezpieczenie w LINK4 obliczona zostanie na podstawie danych Właściciela pojazdu."
              fullWidth
              type="info"
            />
          )}

          <div className="hr" />

          {mainDriver !== 'owner' && mainDriver !== '' && (
            <>
              <DropDownField
                {...FIELDS.numberOfChilds}
                disabled={isDisableField(disableFields, FIELDS.numberOfChilds)}
                order={60}
                onChange={(
                  key: typeof FIELDS.numberOfChilds.id,
                  value: typeof FIELDS.numberOfChilds.availableValues[number]['value'],
                  allData,
                  autoSelect?: boolean,
                ) => handleChange({ key, value, autoSelect })}
                value={numberOfChilds}
                handleSetValidation={handleSetValidation}
              />
              <div className="hr" />
            </>
          )}

          {mainDriver && (
            <>
              <ButtonCheckboxField
                {...FIELDS.hasChildrenU26}
                disabled={isDisableField(disableFields, FIELDS.hasChildrenU26)}
                order={75}
                multiSelect={false}
                title={mainDriver === 'owner' ? FIELDS.hasChildrenU26.titleMainDriverOwner : FIELDS.hasChildrenU26.titleMainDriveOther}
                mainDriver={mainDriver}
                onChange={(
                  key: typeof FIELDS.hasChildrenU26.id,
                  value: typeof FIELDS.hasChildrenU26.availableValues[number]['value'],
                  allData,
                  autoSelect?: boolean,
                ) => handleChange({ key, value, autoSelect })}
                value={setViewValue(hasChildrenU26)}
                handleSetValidation={handleSetValidation}
                isWider
              />
              <div className="hr" />
            </>
          )}

          {(mainDriver === 'owner' && hasChildrenU26) && (
            <>
              <DropDownField
                {...FIELDS.numberOfChilds}
                disabled={isDisableField(disableFields, FIELDS.numberOfChilds)}
                order={80}
                onChange={(
                  key: typeof FIELDS.numberOfChilds.id,
                  value: typeof FIELDS.numberOfChilds.availableValues[number]['value'],
                  allData,
                  autoSelect?: boolean,
                ) => handleChange({ key, value, autoSelect })}
                value={numberOfChilds}
                handleSetValidation={handleSetValidation}
              />
              <div className="hr" />
            </>
          )}

          {hasChildrenU26 && (
            <>
              <DropDownField
                {...FIELDS.ageOldestChild}
                order={85}
                title={mainDriver === 'owner' ? FIELDS.ageOldestChild.titleMainDriverOwner : FIELDS.ageOldestChild.titleMainDriveOther}
                onChange={(
                  key: typeof FIELDS.ageOldestChild.id,
                  value: typeof FIELDS.ageOldestChild.availableValues[number]['value'],
                  allData,
                  autoSelect?: boolean,
                ) => handleChange({ key, value, autoSelect })}
                validations={{
                  required: mainDriver === 'owner' ? FIELDS.ageOldestChild.validationMsgMainDriverOwner : FIELDS.ageOldestChild.validationMsgMainDriverOther,
                }}
                value={setViewValue(ageOldestChild)}
                handleSetValidation={handleSetValidation}
                searchable={!isMobile}
                disabled={isDisableField(disableFields, FIELDS.ageOldestChild)}
              />
              <div className="hr" />
            </>
          )}

          {coowners > 0 && (
            <Fragment key="coOwnerWrapper">
              <StepSectionTitle>{setCoOwnersSectionTitle(coowners)}</StepSectionTitle>
              <div className="hr" />
              <InsurerWithStore
                type={CO_OWNER}
                showInfoBoxNameSurnamePesel
                handleSetValidation={handleSetValidation}
                validationFieldMethods={validationFieldMethods}
                orderPrefix={85}
                stepOrigin={3}
                disableFields={disableFields}
                setInitializedStep={setInitializedStep}
              />
            </Fragment>
          )}

          {coowners === 2 && (
            <Fragment key="coCoOwnerWrapper">
              <StepSectionTitle>Dane drugiego współwłaściciela</StepSectionTitle>
              <div className="hr" />
              <InsurerWithStore
                type={CO_CO_OWNER}
                showInfoBoxNameSurnamePesel
                handleSetValidation={handleSetValidation}
                validationFieldMethods={validationFieldMethods}
                orderPrefix={110}
                stepOrigin={3}
                disableFields={disableFields}
                setInitializedStep={setInitializedStep}
              />
            </Fragment>
          )}

          {mainDriver === 'driver' && (
            <Fragment key="driverWrapper">
              <StepSectionTitle>Dane głównego kierowcy</StepSectionTitle>
              <div className="hr" />
              <InsurerWithStore
                type={DRIVER}
                showInfoBoxNameSurnamePesel
                handleSetValidation={handleSetValidation}
                validationFieldMethods={validationFieldMethods}
                orderPrefix={140}
                stepOrigin={3}
                disableFields={disableFields}
                setInitializedStep={setInitializedStep}
              />
            </Fragment>
          )}
        </div>
      </StepContainer>
    </StickyContainer>
  );
});

export default StepThree;
