export function Close() {
  return (
    <svg
      viewBox="0 0 16 16"
    >
      <path
        transform="translate(.417 .417)"
        fill="#000"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        d="M15.1666667 1.5275L13.6391667 0 7.58333333 6.05583333 1.5275 0 0 1.5275 6.05583333 7.58333333 0 13.6391667 1.5275 15.1666667 7.58333333 9.11083333 13.6391667 15.1666667 15.1666667 13.6391667 9.11083333 7.58333333z"
      />
    </svg>
  );
}
