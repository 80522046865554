import { phoneNumber } from '../../../helpers/phone';
import { SELECT_OPTION_LABEL } from '../../../config/formats';
import { productionYearAvValues } from '../utils/DataUtils';
import { WarningInfoBoxLink } from '../components/warningInfoBoxLink/WarningInfoBoxLink';

export const PRODUCTION_YEAR = 'Rok produkcji';

const filterHpTooltip = {
  message: (
    <>
      <p>Informację o mocy silnika znajdziesz w dowodzie rejestracyjnym w polu P.2</p>
      <p>
        Nie martw się, jeśli zaznaczone wartości będą się
        nieznacznie różniły od tych w dowodzie. Towarzystwa Ubezpieczeniowe stosują zaokrąglenia
      </p>
    </>),
  imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny10v2.jpg`,
};

const filterCapacityTooltip = {
  message: <p>Informację o pojemności silnika znajdziesz w dowodzie rejestracyjnym w polu P.1</p>,
  imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny12v2.jpg`,
};

const filterDoorsTooltip = {
  message: <p>Informację o liczbie drzwi znajdziesz w dowodzie rejestracyjnym w polu S.1</p>,
  imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny9v2.jpg`,
};

export const CAR_FIELDS_BASE = {
  productionYear: {
    id: 'productionYear',
    defaultValue: '',
    title: PRODUCTION_YEAR,
    tooltip: {
      message: <p>Informację o roku produkcji znajdziesz na ostatniej stronie dowodu rejestracyjnego.</p>,
      imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny2v2.jpg`,
    },
    format: SELECT_OPTION_LABEL,
    autoSelectIfOne: true,
    searchable: true,
    sort: 'desc',
    validations: {
      year: true,
    },
    availableValues: productionYearAvValues(),
  },
  brand: {
    id: 'brand',
    defaultValue: '',
    title: 'Marka',
    tooltip: {
      message: <p>Informację o marce pojazdu znajdziesz na pierwszej stronie dowodu rejestracyjnego w polu D.1.</p>,
      imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny1v2.jpg`,
    },
    format: SELECT_OPTION_LABEL,
    autoSelectIfOne: true,
    validations: {
      required: true,
    },
    searchable: true,
  },
  model: {
    id: 'model',
    title: 'Model',
    tooltip: {
      message: <p>Informację o modelu samochodu znajdziesz na pierwszej stronie dowodu rejestracyjnego w polu D.3.</p>,
      imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny3v2.jpg`,
    },
    format: SELECT_OPTION_LABEL,
    autoSelectIfOne: true,
    validations: {
      required: true,
    },
    defaultValue: '',
    searchable: true,
  },
  fuel: {
    id: 'fuel',
    title: 'Rodzaj paliwa',
    defaultValue: '',
    tooltip: {
      message: (
        <>
          <p>Informację o rodzaju paliwa znajdziesz w dowodzie rejestracyjnym w polu P.3:</p>
          <p>
            P – to Benzyna,
            <br />
            D – to Diesel,
            <br />
            LPG – to gaz,
            <br />
            EE – to pojazd napędzany elektrycznie,
            <br />
            CNG - to gaz ziemny.
          </p>
        </>
      ),
      imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny4v2.jpg`,
    },
    validations: {
      required: true,
    },
  },
  lpg: {
    id: 'lpg',
    title: 'Pojazd posiada instalację gazową',
    defaultValue: false,
    hiddenTitle: true,
  },
  transmission: {
    id: 'transmission',
    title: 'Skrzynia biegów',
    validations: {
      required: true,
    },
    autoSelectIfOne: true,
  },
  eurotaxVersion: {
    id: 'eurotaxVersion',
    title: 'Wersja',
    titleHelper: '',
    typeOfSearch: 'match', // startsWith
    autoSelectIfOne: true,
    searchable: true,
    tooltip: {
      message: (
        <>
          <p>Jeśli masz wątpliwości, informację o wersji wyposażenia znajdziesz:</p>
          <p>
            - na naklejce na drzwiach samochodu lub na klapie bagażnika,
            <br />
            - w poprzedniej polisie,
            <br />
            - w dokumentach zakupu samochodu.
          </p>
        </>
      ),
    },
    validations: {
      required: true,
    },
    filters: [
      {
        propName: 'doors',
        label: 'Liczba drzwi',
        title: 'Ilość drzwi',
        required: false,
        tooltip: filterDoorsTooltip,
      },
      {
        propName: 'hp',
        label: 'Moc (kW / KM)',
        title: 'Moc',
        required: false,
        tooltip: filterHpTooltip,
      },
      {
        propName: 'capacity',
        label: 'Pojemność cm3',
        title: 'Pojemność silnika',
        required: false,
        tooltip: filterCapacityTooltip,
      },
    ],
  } as const,
  infoexVersion: {
    id: 'infoexVersion',
    title: 'Wersja wg Info-Ekspert',
    subTitle: 'Niektóre towarzystwa ubezpieczeniowe korzystają z innej bazy danych - wskaż, żeby otrzymać więcej ofert.',
    tooltip: {
      message: (
        <>
          <p>Niektóre Towarzystwa Ubezpieczeniowe korzystają z  bazy danych Info-Ekspert, która może nieznacznie się różnić.</p>
          <p>Wskaż wersję pojazdu lub sprawdź poprawność danych, żeby otrzymać więcej korzystnych ofert m. in. od Link4, Benefia i Uniqa.</p>
        </>
      ),
    },
    searchable: true,
    typeOfSearch: 'match', // startsWith
    autoSelectIfOne: { value: 0 },
    fromBegining: false,
    filterConfig: { hp: { range: 4 } },
    availableValues: [
      { value: 0, label: 'Nie widzę swojego pojazdu' },
    ],
    validations: {
      requiredWithTextMsg: true,
    },
  },
  infoBoxInfoEkspert: {
    id: 'infoBoxInfoEkspert',
    content: (
      <>
        Możesz przejść dalej, ale nie zobaczysz ofert dla Benefia, Euroins, Link4, Trasti,
        TUZ i Uniqa, ponieważ korzystają one ze słownika Info-Ekspert. Możemy pomóc w wyborze pojazdu, wystarczy zadzwonić:
        {' '}
        <WarningInfoBoxLink id="infoExCallLink" href={`tel: ${phoneNumber}`}>{phoneNumber}</WarningInfoBoxLink>
      </>
    ),
    type: 'warning',
    fullWidth: true,
  },
} as const;

export type TCarFieldsBaseKeys = keyof typeof CAR_FIELDS_BASE;

export type TFiltersConfig = typeof CAR_FIELDS_BASE['eurotaxVersion']['filters'];
