import { above, VARS, below } from 'porowneo-ui';
import styled from 'styled-components';

import car from './img/car.png';
import coins from './img/coins.png';

const pinkAccent = '#FF3E70';

export const FreeMonthContainer = styled.div`
  width: 80vw;
  min-height: 80vh;
  color: #000;

  ${above(VARS.breakpoints.lg)} {
    background-color: #F8F6F8;
    background-image: url(${car}), url(${coins});
    background-repeat: no-repeat;
    background-position: left center, right bottom;
    background-size: auto, auto 90%;
  };
`;

export const FreeMonthHeaderWrapper = styled.div`
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FreeMonthHeader = styled.div`
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  padding: 30px;
`;

export const FreeMonthDescription = styled.div`
  width: 50%;
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
`;

export const FreeMonthBodyWrapper = styled.div`
  min-height: 40vh;
  display: flex;
  justify-content: center;
  padding-top: 40px;

  ${above(VARS.breakpoints.lg)} {
    justify-content: flex-end;
  };
`;

export const FreeMonthContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FreeMonthList = styled.ol`
  width: 80%;
  display: block;
  counter-reset: li;
  list-style: none;

  ${below(VARS.breakpoints.lg)} {
    margin-left: 2em;
  };
`;

export const FreeMonthLi = styled.li`
  counter-increment: li;
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 23px;

  &:before {
    text-align: left;
    content: counter(li);
    font-size: 36px;
    font-weight: bold;
    color: ${pinkAccent};
    display: inline-block;
    position: relative;
    top: 13px;
    width: 1.25em;
    margin-left: -1.25em;
    margin-top: -20px;
  }
`;

export const FreeMonthReg = styled.p`
  font-size: 14px;
  text-align: center;

  ${above(VARS.breakpoints.lg)} {
    justify-content: flex-end;
    text-align: left;
  };

  a {
    color: ${pinkAccent};
    text-decoration: none;
  }
`;

export const FreeMonthButtonWrapper = styled.div`
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const FreeMonthButton = styled.div`
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${pinkAccent};
  border-radius: 25px;
  cursor: pointer;

  font-size: 18px;
  font-weight: 600;
  color: ${pinkAccent};
`;
