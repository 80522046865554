/* eslint-disable max-len */
export const defaultMsg = <p>Informacja ta jest uwzględniana przy obliczaniu wysokości składki.</p>;
export const hasDrivingLicense = (
  <>
    <p>Zaznacz opcje &quot;Brak prawa jazdy&quot; w przypadku kiedy uczestnik nie posiada uprawnień.</p>
    <p>Brak prawa jazdy oznacza wysłanie zapytania ofertowego tylko do towarzystw: Generali, mtu24.pl, Proama, Uniqa, You Can Drive.</p>
  </>
);
export const newDateObtainingLicense = (
  <>
    <p>Informację o dacie uzyskania prawa jazdy znajdziesz w kolumnie 10 na odwrocie prawa jazdy.</p>
    {hasDrivingLicense}
  </>
);
export const dateObtainingLicense = (
  <>
    <p>Informacja o dacie uzyskania prawa jazdy znajduje się w dokumencie prawa jazdy w polu 10. Podaj datę wydania dla wartości &quot;B&quot; z kolumny 9.</p>
    {hasDrivingLicense}
  </>
);
export const giveName = (
  <>
    <p>Podanie imienia, nazwiska i numeru PESEL umożliwi nam sprawdzenie, jakie zniżki posiadasz, dzięki czemu będziemy mogli zaproponować Ci jeszcze lepszą ofertę.</p>
    <p>Dodatkowo PESEL pozwoli na weryfikację w bazie szkodowej Ubezpieczeniowego Funduszu Gwarancyjnego. Informacje te są wymagane przez niektóre towarzystwa ubezpieczeniowe (m.in. Proama, Generali).</p>
  </>
);
export const pesel = (
  <>
    <p>Podanie numeru PESEL umożliwi nam sprawdzenie, jakie zniżki posiadasz, dzięki czemu będziemy mogli zaproponować Ci jeszcze lepszą ofertę.</p>
    <p>Dodatkowo PESEL pozwoli na weryfikację w bazie szkodowej Ubezpieczeniowego Funduszu Gwarancyjnego. Informacje te są wymagane przez niektóre towarzystwa ubezpieczeniowe (m.in. Proama, Generali).</p>
  </>
);
export const newDefaultMessage = <p>Informacja ta pozwala na uwzględnienie Twoich zniżek w przedstawianych ofertach ubezpieczeń.</p>;
export const otherUserDefaultMessage = <p>Dzięki podaniu tej informacji możesz uzyskać dodatkową zniżkę.</p>;
