import moment from 'moment';

import { setStepTwoGtm } from '../../../../helpers/tagManager/TagManagerUtils';
import { DATE_FORMAT } from '../../../../config/formats';
import { STEP_TWO_FIELDS } from '../../StepTwo.config';
import { getLabelByValueFromAvailableValues } from '../../../../helpers/FormUtils';

import type { IStepTwoValues } from '../../StepTwo.types';
import type { IPayloadStepTwo } from '../../../../reducers/Payload';

export const handleGTMDateChange = <K extends 'firstDateRegistered' | 'dateRegisteredActualOwner'>(key: K, value: IStepTwoValues[K]): void => {
  setStepTwoGtm(key, moment(value).format(DATE_FORMAT));
};

export const handleGTMNoAutoselectChange = <K extends keyof IStepTwoValues>(key: K, value: IStepTwoValues[K], autoSelect: boolean): void => {
  if (!autoSelect) {
    if (key === 'planToDriveThisYear') {
      setStepTwoGtm(key, getLabelByValueFromAvailableValues(STEP_TWO_FIELDS.planToDriveThisYear.availableValues, value));
    } else {
      setStepTwoGtm(key, value);
    }
  }
};

export const handleGTMCarMileageChange = (value: IStepTwoValues['carMileage']): void => {
  setStepTwoGtm('carMileage', parseInt(value, 10));
};

export const handleGTMCarRegistrationNumberChange = (value: IStepTwoValues['carRegistrationNumber'], plate: IPayloadStepTwo['plate']): void => {
  if (value && value !== plate && value.length > 3 && value.length < 9) {
    setStepTwoGtm('carRegistrationNumber', value);
  }
};
