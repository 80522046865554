import { memo } from 'react';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import styled, { css } from 'styled-components';
import {
  above,
  VARS,
} from 'porowneo-ui';
import { Button } from 'porowneo-design';

import { MainFormStyle } from '../StepContainer/StepContainer.styled';
import {
  BTN_TWO_ID, ButtonStyled, WrapperStyled,
} from '../../steps/StepsNavigateButton';
import { StepProgressBar } from '../StepProgressBar';

import type { IStepProgressBar } from '../StepProgressBar';

const ScrolledRow = styled.div`
  ${MainFormStyle}
  border-radius: 0 0 10px 10px;
  position: fixed;
  top: 0;
  z-index: 2999;
`;

const ScrolledRowColumn = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const scrolledPadding = css`
  padding-right: 32px;
  padding-left: 32px;

  ${above(VARS.breakpoints.xl)} {
    padding-right: 48px;
    padding-left: 48px;
  }
`;

const ScrolledRowWrapper = styled.div`
  ${scrolledPadding}

  padding-bottom: 24px;

  ${above(VARS.breakpoints.xl)} {
    padding-bottom: 33px;
  };
`;

const ScrolledProgressBarContainer = styled('div')`
  width: 100%;

  padding-top: 20px;

  padding-right: 32px;
  padding-left: 32px;

  ${above(VARS.breakpoints.xl)} {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

interface IScrollRowProps {
  rowButtonProps: IScrolledButton;
  progressBarProps: IStepProgressBar;
  isRowDisplayed: boolean;
  positionData: null | DOMRect;
}
interface IScrolledButton {
  triggerValidation: () => void;
}

const areEqual = (prevProps: IScrollRowProps, nextProps: IScrollRowProps): boolean => (
  isEqual(prevProps.rowButtonProps, nextProps.rowButtonProps) && isEqual(prevProps.progressBarProps, nextProps.progressBarProps) &&
  prevProps.isRowDisplayed === nextProps.isRowDisplayed &&
  prevProps.positionData?.width === nextProps.positionData?.width && prevProps.positionData?.left === nextProps.positionData?.left
);

export const ScrollRow = memo(({
  rowButtonProps: { triggerValidation }, isRowDisplayed, positionData, progressBarProps,
}: IScrollRowProps) => (isRowDisplayed ? (
  <ScrolledRow style={{ width: `${positionData?.width || 0}px`, left: `${positionData?.left || 0}px` }}>
    <div className="row">
      <ScrolledProgressBarContainer>
        <StepProgressBar {...progressBarProps} />
      </ScrolledProgressBarContainer>
      <ScrolledRowColumn>
        <ScrolledRowWrapper>
          <WrapperStyled>
            <ButtonStyled
              isShow
              btnId={BTN_TWO_ID}
            >
              <Button
                id={BTN_TWO_ID}
                color="primary"
                size="large"
                variant="contained"
                handleClick={debounce(() => triggerValidation(), 400, { leading: false, trailing: true })}
              >
                Dalej
              </Button>
            </ButtonStyled>
          </WrapperStyled>
        </ScrolledRowWrapper>
      </ScrolledRowColumn>
    </div>
  </ScrolledRow>
) : null), areEqual);
