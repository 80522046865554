import handleGTM from '../handleGTM';

import * as HandleChange from './HandleChange';

import type { Dispatch } from 'redux';
import type { IPayloadStepTwo } from '../../../../reducers/Payload';
import type { IStepTwoValues, TInsurerValues } from '../../StepTwo.types';

type TStepTwoArgs = {[K in keyof IStepTwoValues]: {
  key: K,
  value: IStepTwoValues[K],
}}[keyof IStepTwoValues];

const createHandleChange = (
  dispatch: Dispatch,
  startFilling: () => void,
  isFilling: boolean,
  { whichOwner, firstImmat, plate }: Pick<IPayloadStepTwo, 'whichOwner' | 'firstImmat' | 'plate'>,
) => (args: TStepTwoArgs, allData: TInsurerValues = [], autoSelect = false): void => {
  const { key } = args;
  if (args.value !== null && args.value !== '') {
    handleGTM(key, args.value, autoSelect, plate);
  }
  if (!autoSelect && !isFilling) {
    startFilling();
  }
  switch (key) {
  case 'firstDateRegistered': return HandleChange.handleFirstDateRegisteredChange(dispatch, args.value, whichOwner);
  case 'dateRegisteredActualOwner': return HandleChange.handleDateRegisteredActualOwnerChange(dispatch, args.value);
  case 'carUse': return HandleChange.handleCarUseChange(dispatch, args.value);
  case 'carRegisteredOn': return HandleChange.handleCarRegisteredOnChange(dispatch, args.value);
  case 'planToDriveThisYear': return HandleChange.handlePlanToDriveThisYearChange(dispatch, args.value);
  case 'whichOwnerInOrder': return HandleChange.handleWhichOwnerInOrderChange(dispatch, args.value, firstImmat);
  case 'firstCountry': return HandleChange.handleFirstCountryChange(dispatch, args.value);
  case 'carWheelSide': return HandleChange.handleCarWheelSideChange(dispatch, args.value);
  case 'parkingPlace': return HandleChange.handleParkingPlaceChange(dispatch, args.value);
  case 'lastInsurer': return HandleChange.handleLastInsurerChange(dispatch, args.value, allData);
  case 'timeUsedAbroad': return HandleChange.handleTimeUsedAbroadChange(dispatch, args.value);
  case 'damaged': return HandleChange.handleDamagedChange(dispatch, args.value);
  case 'carMileage': return HandleChange.handleCarMileageChange(dispatch, args.value);
  case 'carRegistrationNumber': return HandleChange.handleCarRegistrationNumberChange(dispatch, args.value);
  default: return key;
  }
};

export default createHandleChange;
