import moment from 'moment';

import * as fieldsLength from './FieldsParams/fieldsLength';
import * as fieldsTooltipMessages from './FieldsParams/fieldsTooltipMessages';
import { DATE_FORMAT, ZIP_CODE_FORMAT } from './formats';
import { ZIP_CODE } from './masks';
import * as fieldsAutoComplete from './FieldsParams/fieldsAutoComplete';
import { before18Years, after110Years } from './fields';
import * as regex from './regex';
import { DRIVER, YOUNG_PERSON } from './InsurerPersons.literal';

import type { IPayloadStepThree } from '../reducers/Payload';
import type { IRootState } from '../reducers';
import type { IInsurer, TInsurerType } from '../types/Insurer.type';
import type { TStepOrigin } from '../views/stepThree/components/Insurer/Insurer.types';

export const birthDateOnOpen = moment('01-01-1979', DATE_FORMAT).format(DATE_FORMAT);
export const youngPersonDateOnOpen = moment().subtract(18, 'years').format(DATE_FORMAT);

const getPeselSpecificFields = (stepOrigin: TStepOrigin, type: TInsurerType) => {
  const isFsNotInsurer = (stepOrigin === 'fs' && type !== 'insurer') || stepOrigin === 'ufg';
  return ({
    relativeFields: isFsNotInsurer ? { relativeFields: ['gender', 'birthdate'] } : {},
    relativeAuto: isFsNotInsurer ? { relativeAuto: [] } : { relativeAuto: ['sex', 'birthdate'] },
    peselValidation: isFsNotInsurer ? { peselUFG: true } : { pesel: true },
  });
};

export const getInsurerTitle = (coowners: IPayloadStepThree['coowners']) => ({
  owner: 'właściciela',
  coOwner: coowners === 2 ? 'pierwszego współwłaściciela' : 'współwłaściciela',
  coCoOwner: 'drugiego współwłaściciela',
  driver: 'głównego kierowcy',
  youngPerson: 'najmłodszego kierowcy',
  insurer: 'ubezpieczającego',
});

export const generateInsurerFieldsConfig = (
  type: TInsurerType,
  coowners: IPayloadStepThree['coowners'],
  stepOrigin: TStepOrigin,
  sex: IInsurer<TInsurerType>['sex'],
  mainDriver?: TInsurerType | '',
  showMaidenName = true,
) => {
  const title = getInsurerTitle(coowners)[type];
  const peselData = getPeselSpecificFields(stepOrigin, type);
  const isStepOriginFsOrUfg = stepOrigin === 'fs' || stepOrigin === 'ufg';

  return {
    firstName: {
      id: `${type}-firstName`,
      title: 'Imię',
      tooltip: { message: fieldsTooltipMessages.giveName },
      mask: regex.NAME,
      maxLength: fieldsLength.NAME.max,
      name: 'fname',
      autoComplete: fieldsAutoComplete.giveName,
      required: isStepOriginFsOrUfg,
      ignoreFillStatus: true,
      isSensitiveData: true,
      validations: {
        isSensitiveData: stepOrigin === 'fs',
        required: isStepOriginFsOrUfg,
        minName: fieldsLength.NAME.min,
        max: fieldsLength.NAME.max,
        name: true,
      },
      isShow: type !== 'youngPerson',
      disabled: false,
    },
    lastName: {
      id: `${type}-lastName`,
      title: 'Nazwisko',
      tooltip: { message: fieldsTooltipMessages.giveName },
      mask: regex.SURNAME,
      maxLength: fieldsLength.SURNAME.max,
      name: 'lname',
      autoComplete: fieldsAutoComplete.familyName,
      required: isStepOriginFsOrUfg,
      ignoreFillStatus: true,
      isSensitiveData: true,
      validations: {
        isSensitiveData: stepOrigin === 'fs',
        required: isStepOriginFsOrUfg,
        minSurname: fieldsLength.SURNAME.min,
        max: fieldsLength.SURNAME.max,
        surname: true,
      },
      isShow: type !== 'youngPerson',
      disabled: false,
    },
    maidenName: {
      id: `${type}-maidenName`,
      title: 'Nazwisko panieńskie',
      mask: regex.SURNAME,
      maxLength: fieldsLength.SURNAME.max,
      name: 'mname',
      isInput: true,
      required: false,
      ignoreFillStatus: true,
      isSensitiveData: true,
      validations: {
        isSensitiveData: stepOrigin === 'fs',
        minSurname: fieldsLength.SURNAME.min,
        max: fieldsLength.SURNAME.max,
        surname: true,
      },
      isShow: showMaidenName && type !== 'youngPerson' && (stepOrigin !== 'fs' || (stepOrigin === 'fs' && sex === 'female')),
      disabled: stepOrigin === 'fs' && type !== 'insurer',
    },
    pesel: {
      ...peselData.relativeFields,
      ...peselData.relativeAuto,
      id: `${type}-pesel`,
      title: 'PESEL',
      tooltip: { message: fieldsTooltipMessages.pesel },
      mask: regex.PESEL,
      required: isStepOriginFsOrUfg,
      isSensitiveData: true,
      ignoreFillStatus: true,
      validations: {
        ...peselData.peselValidation,
        isSensitiveData: stepOrigin === 'fs',
        required: isStepOriginFsOrUfg,
      },
      isShow: type !== 'youngPerson',
      disabled: false,
      type: 'numeric',
    },
    sex: {
      id: `${type}-sex`,
      title: 'Płeć',
      relativeFields: ['pesel'],
      validations: {
        requiredGender: true,
        gender: true,
      },
      autofilled: true,
      availableValues: [
        { value: 'male', label: 'Mężczyzna' },
        { value: 'female', label: 'Kobieta' },
      ],
      isShow: type !== 'youngPerson' && stepOrigin !== 'fs',
      disabled: stepOrigin === 'fs',
    },
    birthdate: {
      id: `${type}-birthdate`,
      defaultValue: DATE_FORMAT,
      valueOnOpen: type === 'youngPerson' ? youngPersonDateOnOpen : birthDateOnOpen,
      title: 'Data urodzenia',
      tooltip: {
        message: type === 'youngPerson' ? fieldsTooltipMessages.defaultMsg : fieldsTooltipMessages.newDefaultMessage,
      },
      format: DATE_FORMAT,
      showYearDropdown: true,
      autofilled: true,
      relativeFields: type !== 'youngPerson' ? ['pesel'] : [],
      validations: {
        before: before18Years,
        start: after110Years,
        birthDate: true,
      },
      isShow: type !== 'insurer',
      disabled: stepOrigin === 'fs',
    },
    licenseYear: {
      id: `${type}-licenseYear`,
      defaultValue: '',
      title: 'Rok uzyskania prawa jazdy kat.\u00a0B',
      tooltip: {
        message: type === 'youngPerson' ? fieldsTooltipMessages.dateObtainingLicense : fieldsTooltipMessages.newDateObtainingLicense,
        imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/PrawoJazdy5.jpg`,
      },
      format: 'YYYY',
      searchable: true,
      sort: 'asc',
      isShow: stepOrigin !== 'fs',
    },
    hasDrivingLicense: {
      id: `${type}-hasDrivingLicense`,
      defaultValue: true,
      title: '',
      checkboxTitle: 'Brak prawa jazdy',
      ignoreFillStatus: true,
      required: false,
      isShow: stepOrigin !== 'fs' && type !== YOUNG_PERSON && type !== DRIVER,
    },
    licenseMonth: {
      id: `${type}-licenseMonth`,
      defaultValue: '',
      title: 'Miesiąc uzyskania prawa jazdy kat. B',
      tooltip: {
        message: type === 'youngPerson' ? fieldsTooltipMessages.dateObtainingLicense : fieldsTooltipMessages.newDateObtainingLicense,
        imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/PrawoJazdy5.jpg`,
      },
      searchable: true,
      isShow: stepOrigin !== 'fs',
    },
    maritalStatus: {
      id: `${type}-maritalStatus`,
      title: 'Stan cywilny',
      tooltip: { message: fieldsTooltipMessages.newDefaultMessage },
      validations: {
        required: `Wybierz stan cywilny ${title}`,
      },
      availableValues: [
        { value: 'married', label: 'Mężatka / Żonaty' },
        { value: 'single', label: 'Panna / Kawaler' },
        { value: 'divorced', label: 'Rozwiedziona / Rozwiedziony' },
        { value: 'widower', label: 'Wdowa / Wdowiec' },
      ],
      isShow: type !== 'youngPerson' && stepOrigin !== 'fs' && (type === 'owner' || mainDriver === type),
      disabled: false,
    },
    addressRegisteredIdentical: {
      id: `${type}-addressRegisteredIdentical`,
      defaultValue: true,
      title: 'Adres zameldowania taki sam jak zamieszkania',
      hiddenTitle: true,
      ignoreFillStatus: true,
      isShow: stepOrigin !== 'fs' && type !== 'youngPerson',
      disabled: false,
    },
  } as const;
};

export const generateInsurerAddressFieldsConfig = (
  typeOfAddress: 'address' | 'addressRegistered',
  coowners: IPayloadStepThree['coowners'],
  typeOfInsurer: TInsurerType,
  stepOrigin: TStepOrigin,
  partnerName?: IRootState['activeFullSaleInsurer'],
) => {
  const title = getInsurerTitle(coowners)[typeOfInsurer];
  const registeredSuffix = typeOfAddress === 'address' ? '' : '-registered';
  const addressTitle = typeOfAddress === 'address' ? 'zamieszkania' : 'zameldowania';
  const unlockRegisterAddr = partnerName && ['link4'].includes(partnerName) && typeOfAddress === 'addressRegistered';

  return {
    zipCode: {
      id: `${typeOfInsurer}-zipCode${registeredSuffix}`,
      title: `Kod pocztowy miejsca ${addressTitle}`,
      tooltip: { message: fieldsTooltipMessages.defaultMsg },
      placeholder: ZIP_CODE_FORMAT,
      mask: ZIP_CODE,
      name: 'zip',
      autoComplete: 'postal-code',
      lazy: true,
      validations: { zipCode: true },
      isShow: typeOfInsurer !== 'youngPerson',
      disabled: stepOrigin === 'fs' && !['insurer', 'youngPerson'].includes(typeOfInsurer) && !unlockRegisterAddr,
      type: 'numeric',
    },
    city: {
      id: `${typeOfInsurer}-city${registeredSuffix}`,
      title: `Miejscowość ${addressTitle}`,
      searchable: true,
      validations: { zipCodeCities: true },
      isShow: typeOfInsurer !== 'youngPerson',
      disabled: stepOrigin === 'fs' && !['insurer', 'youngPerson'].includes(typeOfInsurer) && !unlockRegisterAddr,
    },
    street: {
      id: `${typeOfInsurer}-street${registeredSuffix}`,
      title: `Ulica ${addressTitle} ${title}`,
      maxLength: fieldsLength.STREET_NAME.max,
      validations: {
        requiredSecondary: true,
        min: fieldsLength.STREET_NAME.min,
        max: fieldsLength.STREET_NAME.max,
      },
      isShow: stepOrigin === 'fs' && typeOfInsurer !== 'youngPerson',
      disabled: false,
    },
    building: {
      id: `${typeOfInsurer}-building${registeredSuffix}`,
      title: `Numer budynku ${addressTitle} ${title}`,
      mask: regex.BUILDING_FLAT_NUMBER,
      maxLength: fieldsLength.BUILDING_NUMBER.max,
      validations: {
        requiredSecondary: true,
        min: fieldsLength.BUILDING_NUMBER.min,
        max: fieldsLength.BUILDING_NUMBER.max,
      },
      isShow: stepOrigin === 'fs' && typeOfInsurer !== 'youngPerson',
      disabled: false,
    },
    flat: {
      id: `${typeOfInsurer}-flat${registeredSuffix}`,
      title: `Numer lokalu ${addressTitle} ${title}`,
      mask: regex.BUILDING_FLAT_NUMBER,
      maxLength: fieldsLength.FLAT_NUMBER.max,
      validations: {
        requiredSecondary: false,
        min: fieldsLength.FLAT_NUMBER.min,
        max: fieldsLength.FLAT_NUMBER.max,
      },
      isShow: stepOrigin === 'fs' && typeOfInsurer !== 'youngPerson',
      disabled: false,
    },
  } as const;
};
