/* eslint-disable camelcase */
import io from 'socket.io-client';

import { ROUTING_BASE } from './Router.config';

import type { AxiosRequestConfig } from 'axios';
import type { IVehicle } from '../types/Car.type';

export const API_V2 = process?.env?.REACT_APP_V2_API_URL ?? 'http://localhost:8080';
export const API_URL = process?.env?.REACT_APP_API_URL ?? 'http://localhost:8888';
export const RT_URL = process?.env?.REACT_APP_RT_URL ?? 'localhost:9999';
export const API_DOCS_URL = process?.env?.REACT_APP_DOC_API_URL ?? 'http://localhost:3100';
export const WWW_PAGE = process?.env?.REACT_APP_HOME_PAGE_URL ?? 'http://localhost:3010';

// WS
export const wsCalculation = () => io(`${RT_URL}/calculation`, { forceNew: true, transports: ['websocket'] });
export const wsServices = () => io(`${RT_URL}/partnerapihandler`, { forceNew: true, transports: ['websocket'] });
export const wsLock = () => io(`${RT_URL}/lock`, { forceNew: true, transports: ['websocket'] });

// API v2
export const createUrlCarSessionApiPlatform = () => `${API_V2}/api/locators/create`;
export const createUrlInsurersToShow = () => `${API_V2}/api/insurers_to_show`;
export const URL_CAR_PROJECT = 'car-projects';
export const createUrlCarProjectStep = (id, step, isFullSale) => {
  if (isFullSale) {
    return `/car-projects/${id}/update-full-sale-step-${step}`;
  }
  return `car-projects/${id}/update-step-${step}`;
};
export const createGetQuote = locator => `${URL_CAR_PROJECT}/quote/${locator}`;
export const createUrlSaveReco = () => `${API_V2}/api/calculation-rekos`;

type TCreateUrlGetCarDataParam =
{
  type: 'brand',
  productionYear: IVehicle['productionYear'],
}|
{
  type: 'model',
  brand: IVehicle['brand'],
} |
{
  type: 'modelGroup',
  brand: IVehicle['brand'],
  productionYear: IVehicle['productionYear'],
} |
{
  type: 'fuel',
  brand: IVehicle['brand'],
  model: IVehicle['model'],
  productionYear: IVehicle['productionYear'],
} |
{
  type: 'transmission',
  brand: IVehicle['brand'],
  model: IVehicle['model'],
  productionYear: IVehicle['productionYear'],
  fuel: IVehicle['fuel'],
} |
{
  type: 'eurotaxVersion',
  model: IVehicle['model'],
  productionYear: IVehicle['productionYear'],
  fuel: IVehicle['fuel'],
  transmission: IVehicle['transmission'],
} |
{
  type: 'infoexVersion',
  productionYear: IVehicle['productionYear'],
  eurotaxVersion: IVehicle['eurotaxVersion'],
}

export const createUrlGetCarData = (param: TCreateUrlGetCarDataParam): string => {
  const baseUrl = `${API_V2}/api/`;
  switch (param.type) {
  case 'brand': {
    const {
      productionYear,
    } = param;
    return `${baseUrl}brands?year=${productionYear}`;
  }
  case 'model': {
    const {
      brand,
    } = param;
    return `${baseUrl}brands/${brand}/models`;
  }
  case 'modelGroup': {
    const {
      brand,
      productionYear,
    } = param;
    return `${baseUrl}brands/${brand}/models_groups?year=${productionYear}`;
  }
  case 'fuel': {
    const {
      brand,
      productionYear,
      model,
    } = param;
    return `${baseUrl}versions/list_of_fuels?from=0&brand=${brand}&modelGroup=${encodeURIComponent(model)}&year=${productionYear}`;
  }
  case 'transmission': {
    const {
      brand,
      productionYear,
      model,
      fuel,
    } = param;
    return `${baseUrl}versions/list_of_gears?from=0&brand=${brand}` +
      `&modelGroup=${encodeURIComponent(model)}&year=${productionYear}&fuel=${encodeURIComponent(fuel)}`;
  }
  case 'eurotaxVersion': {
    const {
      productionYear,
      model,
      fuel,
      transmission,
    } = param;
    return `${baseUrl}versions/search?from=0&modelGroup=${encodeURIComponent(model)}&year=${productionYear}` +
      `&fuel=${encodeURIComponent(fuel)}&gear=${transmission}`;
  }
  case 'infoexVersion': {
    const {
      productionYear,
      eurotaxVersion,
    } = param;
    return `${baseUrl}i_versions/matching_versions?from=0&version=${eurotaxVersion}&year=${productionYear}`;
  }
  default: {
    const x: never = param;
    return x;
  }
  }
};
export const createUrlSearchForZipcode = zipcode => `${API_V2}/api/geo/cities/by/zip/${zipcode}`;
export const createUrlGetStreet = zipcode => `${API_V2}/api/geo/street/${zipcode}`;

// Agreements
export const createGetAllAgreements = () => `${API_V2}/api/consents/active`;
export const createUrlAgreementsSaveInDB = () => `${API_V2}/api/client-consents`;

// API v1
export const createUrlValidataEmail = () => `${API_URL}/api/email/validation`;
export const createUrlPhoneValidation = () => `${API_URL}/api/phone/validation`;
export const createUrlProfileCreate = () => `${API_URL}/car/app_profile`;
export const createUrlUserUpdate = () => `${API_URL}/car/app_profile_user`;
export const createUrlLeadUpdate = () => `${API_URL}/car/app_profile_user_lead`;
export const createUrlSaveData = locator => `${API_URL}/account/transient-data/car:entries/${locator}?cmd=update&op=full-locator`;
export const createShortUrl = (name, guid) => `${API_URL}/car/api/action/ergohestia/${name}/saveStateWeb/${guid}`;
export const createShortUrlErgohestia = () => `${API_URL}/car/api/action/ergohestia/saveStateWeb`;
export const createShortUrlGenerali = ({ redis_key_generali }) => `${API_URL}/car/api/action/geturl/generali/${redis_key_generali}`;
export const createShortUrlProama = ({ redis_key_proama }) => `${API_URL}/car/api/action/geturl/proama/${redis_key_proama}`;
export const createUrlCarSession = () => `${API_URL}/car/session`;
export const createAvivaLink = ({ proposalNumber, brand, birthdate }) => `${API_URL}/car/api/action/aviva/online/${proposalNumber}/${brand}/${birthdate}`;
export const createUrlDelayLead = locatorFull => `${API_URL}/car/state/${locatorFull}`;

// Save info about pressed buy online button
export const createUrlSaveSaleInfo = () => `${API_URL}/online-click/save-params`;
// Get all agreements
export const createUrlFreeTowingEmail = () => `${API_URL}/free-towing/sendRegistrationEmail`;
export const createUrlFreeMonthEmail = () => `${API_V2}/api/mail/send`;
export const createUrlBonEmail = () => `${API_URL}/free-towing/sendRegistrationEmail`;

// PartnerAgreements
export const createUrlGetPartner = (partnerName: string): string => `${API_URL}/car/api/partner/${partnerName}`;
export const createUrlGetPartnerAgreements = (partnerId, source) => `${API_URL}/cli/agreements/partner/${partnerId}/${source}`;
export const createUrlPutPartnerAgreements = partnerId => `${API_URL}/cli/client/partner/${partnerId}`;
// Full Sale
export const createUrlSendOffertDocs = id => `${API_URL}/mail/send/partner/${id}`;
export const createUrlPaymentReturnUrl = () => `${window.location.origin}${ROUTING_BASE}/danedopolisy/platnosc`;
export const createUrlDocsPayments = () => `${API_URL}/car/docs/payment`;
export const createSavePaymentPayloadRedis = () => `${API_URL}/car/docs/payment/data`;
export const createGetPaymentPayloadRedis = locator => `${API_URL}/car/docs/payment/data/${locator}`;
export const createUrlGetDocs = () => `${API_V2}/api/documents`;

// DOCS API
export const createDocsApiSearchLabel = (insurer, tags) => `${API_DOCS_URL}/docs/calc-moto/${insurer}/${tags}`;
export const createDocsApiDownloadLink = id => `${API_DOCS_URL}/docs/download/${id}`;

export const axiosConfigJson = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
};

export const axiosConfigWithSession = <T>(sessionId: string, locator = ''): AxiosRequestConfig<T> => {
  const locatorData: { 'full-locator': string } | Record<string, never> = locator ? { 'full-locator': locator } : {};

  return ({
    ...axiosConfigJson,
    headers: {
      ...axiosConfigJson.headers,
      ...locatorData,
      'session-id': sessionId,
    },
  });
};

export const axiosConfigJsonStatuses200 = <T>(sessionId: string, locator?: string): AxiosRequestConfig<T> => ({
  ...axiosConfigWithSession(sessionId, locator),
  validateStatus: status => status >= 200 && status < 300, // default
});
