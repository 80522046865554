import { Button } from 'porowneo-design';

import { submitWelcomeAgainPopup } from '../../../helpers/tagManager/TagManagerUtils';
import { getPropertyFromLocalStorageById, handleLoadLocalState } from '../../../helpers/StoreUtils';
import { createUrlGetCarData } from '../../../config/url';

import { ButtonWrapperStyled } from './LoadCalculationModal.styled';

interface ILoadCalculationModal {
  subscribeToStore(): void;
  closeModal: () => void;
}

const LoadCalculationModal = ({ closeModal, subscribeToStore }: ILoadCalculationModal) => {
  const state = localStorage.getItem('state') ? JSON.parse(localStorage?.getItem('state') ?? '') : undefined;

  const {
    payload: {
      stepOne: {
        vehicle: {
          productionYear,
          model,
          fuel,
          transmission,
          eurotaxVersion,
        },
      },
    },
  } = state;

  const brandName = getPropertyFromLocalStorageById(
    createUrlGetCarData({
      type: 'eurotaxVersion', productionYear, model, fuel, transmission,
    }),
    eurotaxVersion,
    'brandName',
  );

  return (
    <div className="wrapper wrapper--contact">
      <div className="row">
        <div className="col wrapper__header-title--text-centered ">
          <h2 className="h2--line-height">
            Witamy ponownie!
          </h2>
          <p>
            {
              typeof brandName === 'string' && !!brandName && !!model && !!productionYear ?
                `Wczytać ostatnią kalkulację dla ${brandName.toUpperCase()} ${model.toUpperCase()} z ${productionYear} roku?` :
                'Wczytać ostatnią kalkulację?'
            }
          </p>
          <br />
          <ButtonWrapperStyled>
            <Button
              id="loadCalculation__yes"
              color="secondary"
              handleClick={() => {
                submitWelcomeAgainPopup();
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleLoadLocalState();
              }}
              style={{
                marginRight: '32px',
              }}
            >
              Tak
            </Button>
            <Button
              id="loadCalculation__no"
              color="secondary"
              handleClick={() => {
                closeModal();
                subscribeToStore();
              }}
            >
              Nie
            </Button>
          </ButtonWrapperStyled>
        </div>
      </div>
    </div>
  );
};

export default LoadCalculationModal;
