import styled from 'styled-components';

const DisclaimerStyled = styled.p`
  color: #B2B2B2;
  font-size: 12px;
  padding: 0 40px 20px 40px;

  a {
    color: #B2B2B2;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Disclaimer = () => (
  <DisclaimerStyled>
    Administratorem Pani/a danych osobowych jest Porowneo.pl Sp. z o.o. z siedzibą w Warszawie przy ul. Inflanckiej 4B,
    00-189 Warszawa, nr KRS: 0000439594. Administrator wyznaczył Inspektora Ochrony Danych, z którym mogą się
    Państwo skontaktować pisemnie (na adres siedziby Administratora) lub elektronicznie tj. za pośrednictwem adresu
    poczty elektronicznej: ado@porowneo.pl. Jednocześnie informujemy, że przysługuje Państwu prawo do żądania
    dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania jak również prawo do
    wniesienia sprzeciwu wobec przetwarzania, a także przenoszenia danych. Ponadto mają Państwo prawo wnieść
    skargę do Prezesa Urzędu Ochrony Danych Osobowych w związku z przetwarzaniem przez Administratora Państwa
    danych osobowych. Szczegółowe informacje na temat podstawy prawnej przetwarzania Państwa danych osobowych,
    podmiotach którym te dane mogą być przekazywane, okresie czasu przez który dane osobowe będą
    przechowywane jak również inne wymagane prawem informacje dostępne są w dokumencie
    {' '}
    <a href="https://porowneo.pl/regulamin?rules=polityka_prywatnosci" target="_blank" rel="noopener noreferrer">
      „POLITYKA PRYWATNOŚCI”
    </a>
    .
  </DisclaimerStyled>
);
DisclaimerStyled.displayName = 'DisclaimerStyled';
export default Disclaimer;
