// DELETE IT AFTER CREAT MECHANISM TO INFLU AGREEMENTS
export const promoCodesErrorCore = 'Dla podanego kodu promocyjnego konieczne jest podanie email i zaakceptowanie regulaminu by wziąć udział w promocji ';
export const promoCodesErrors = {
  influ: [
    promoCodesErrorCore, <b>#porównujęzyskuję</b>, '.',
  ],
  discount: [
    promoCodesErrorCore, <b>Rabat od Porówneo</b>, '.',
  ],
};
// end DELETE
