export const firstBrake = {
  lastYear: 'W ostatnim roku',
  moreThanTwoYears: 'Ponad rok temu',
  moreThanThreeYears: 'Ponad trzy lata temu',
  moreThanFourYears: 'Ponad cztery lata temu',
  moreThanFiveYears: 'Ponad pięć lat temu',
  moreThanSixYears: 'Ponad szcześć lat temu',
  moreThanSevenYears: 'Ponad siedem lat temu',
  moreThanEightYears: 'Ponad osiem lat temu',
  moreThanNineYears: 'Ponad dziewięć lat temu',
  moreThanTenYears: 'Ponad dziesięć lat',
};
export const maritalStatus = {
  married: 'Mężatka / Żonaty',
  single: 'Panna / Kawaler',
  divorced: 'Rozwiedziona / Rozwiedziony',
  widower: 'Wdowa / Wdowiec',
};
