/* eslint-disable camelcase */
import { Component } from 'react';

import log from 'loglevel';
import { sendError } from 'porowneo-ui';

import { updateCarProjectContact } from '../../api/carProject';
import { setStatusLeadUpdate } from '../../actions/index';
import { pushLeadModalEventToDataLayer } from '../../helpers/tagManager/TagManagerUtils';
import { leadUpdate } from '../../helpers/socketsCalculations';
import ContactFormWithValidation from '../ContactForm';
import agreementsHoc, { type IWithAgreementsHOCProps } from '../hoc/agreementsHoc';
import SentSection from '../SentSection';
import ThankYouSection from '../ThankYouSection';

import ContactInfoBox from './ContactInfoBox';
import { setPhoneNumberFromModal } from './utils/dispatchPhoneNumber';

import type { IAgreementApi } from 'porowneo-ui/build/AgreementsContainer/AgreementsContainer.types';
import type { ILeaveContactOffer } from './Modal.type';
import type { modalProcessConfig } from '../../config/modalProcess';
import type { TSteps } from '../StepContainer/StepContainer';
import type { IDaemonBasket } from '../../types/DaemonOutput.type';
import type { Dispatch } from 'redux';
import type { IUser, TLeadStatus } from '../../reducers';

interface ILeaveContactProps extends Partial<IDaemonBasket>, IWithAgreementsHOCProps {
  user: IUser;
  dispatch: Dispatch;
  coverType: {
    ac: boolean;
    nnw: boolean;
  }
  carProjectId: null | number;
  handleCallGtmFieldError: (id: string) => void;
  step: TSteps;
  createConfigForGTM?: ILeaveContactOffer['createConfigForGtm'];
  modalSource: typeof modalProcessConfig['leaveContact'];
  handleSetAgreements: (key: any, value: any) => void;
  agreements: IAgreementApi[];
  statusLeadUpdate: TLeadStatus;
  userAgreements: IUser['userAgreements'];
  neededAgreements: string[];
  closeModal: () => void;
}

interface ILeaveContactState {
  user: Pick<IUser, 'locator' | 'shortLocator' | 'phoneLeaveContact' | 'sessionId' | 'name' | 'surname'>
}

export class LeaveContact extends Component<ILeaveContactProps, ILeaveContactState> {
  constructor(props: ILeaveContactProps) {
    super(props);
    const { user } = props;
    this.state = {
      user: {
        locator: user.locator,
        shortLocator: user.shortLocator,
        phoneLeaveContact: user.phoneLeaveContact,
        sessionId: user.sessionId,
        surname: user.surname,
        name: user.name,
      },
    };
  }

  override componentDidMount() {
    const { user } = this.props;
    if (!user.phoneLeaveContact) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          phoneLeaveContact: user.phoneNumber,
        },
      }));
    }
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [key]: value,
      },
    }));
  }

  handleCallGtm = () => {}

  settingLeadError = (error: unknown) => {
    const { dispatch } = this.props;
    dispatch(setStatusLeadUpdate('failed'));
    log.debug('Lead update failed', error);
    sendError(error, false, {
      method: 'send/updateCarProjectContact',
      component: 'LeaveContact',
    });
  };

  send = () => {
    const {
      name,
      guid,
      coverType,
      dispatch,
      sendAgreements,
      createConfigForGTM,
      calculation_id,
      covers,
      carProjectId,
      modalSource,
      // Revert FORMMOTO-3258
      step,
      // END
      user: { businessProcess },
    } = this.props;

    const { user } = this.state;

    const acFromCovers = covers?.ac ? ['ac'] : [];

    sendAgreements(user);

    if (typeof createConfigForGTM === 'function') pushLeadModalEventToDataLayer(createConfigForGTM(null, null));

    log.debug('send leadUpdate user', user);

    if (carProjectId) {
      updateCarProjectContact({
        firstName: user.name || '',
        lastName: user.surname || '',
        phone: user.phoneLeaveContact.replace(/\s/g, ''),
        process: modalSource,
      }, carProjectId, user?.sessionId, user?.locator).catch(this.settingLeadError);
    }

    // Revert FORMMOTO-3258
    setPhoneNumberFromModal(dispatch, { phone: user.phoneLeaveContact, step });
    // END

    leadUpdate({
      lastname: user.surname,
      firstname: user.name,
      sessionId: user.sessionId,
      businessProcess,
      guid,
      calculation_id,
      locator: user.locator,
      phone: user.phoneLeaveContact,
      policy: 0,
      name,
      coverType: covers ? acFromCovers : coverType,
    }, dispatch);
  }

  override render() {
    const {
      name,
      agreements,
      statusLeadUpdate,
      handleSetAgreements,
      requiredAgreements,
      setDefaultLeadUpdate,
      userAgreements,
      neededAgreements,
      handleCallGtmFieldError,
      closeModal,
    } = this.props;

    const { user } = this.state;

    const {
      send,
      handleChange,
      handleCallGtm,
    } = this;

    const formSection = (
      <div className="col p-0">
        <h1 className="wrapper__header-title wrapper__header-title--contact">Zostaw kontakt</h1>
        <p className="wrapper__header-subtitle wrapper__header-subtitle--contact">
          Potrzebujesz pomocy lub masz pytania? Zadzwoń lub zostaw kontakt.
          <br />
          Numer kalkulacji:
          <span className="wrapper--bold">{` ${user.shortLocator}`}</span>
        </p>
        <div className="modal-default">
          {statusLeadUpdate === 'failed' && (
            <h5 className="text-error">
              Przepraszamy, niestety nie udało zapisać się prośby o kontakt.
              <br />
              Możesz spróbować jeszcze raz.
            </h5>
          )}

          <ContactInfoBox name={name || ''} />

          <ContactFormWithValidation
            user={user}
            userAgreements={userAgreements}
            agreements={agreements}
            stepOrigin={900}
            handleChange={handleChange}
            callBackIfValid={send}
            handleSetAgreements={handleSetAgreements}
            requiredAgreements={requiredAgreements}
            showTheseAgreements={neededAgreements}
            handleCallGtm={handleCallGtm}
            handleCallGtmFieldError={handleCallGtmFieldError}
          />
        </div>
      </div>
    );

    switch (statusLeadUpdate) {
    case 'done':
      return (<ThankYouSection closeModal={closeModal} />);
    case 'sent':
      return (<SentSection setDefaultLeadUpdate={setDefaultLeadUpdate} />);
    default:
      return formSection;
    }
  }
}

export default agreementsHoc(LeaveContact);
