import { ERGO_HESTIA_VINDICATED_PAYMENT } from '../config/Partners.config';

import type { TFullSalePartnerName } from '../types/Partners.type';

export const saveFullUrlInSessionStorage = (): void => {
  const fullUrl = window.location.href;
  sessionStorage.setItem('fullUrl', fullUrl);
};

export const getFullUrlFromSessionStorage = (): string => (sessionStorage.getItem('fullUrl') || '');

export const generatePaymentLink = (paymentLink: string, partnerName: TFullSalePartnerName, isVindicated: boolean) => {
  switch (partnerName) {
  case 'mtu':
  case 'ycd':
    return isVindicated ? ERGO_HESTIA_VINDICATED_PAYMENT : paymentLink;
  default:
    return paymentLink;
  }
};
