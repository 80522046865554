/* eslint-disable indent */
import { ButtonCheckbox, FieldContainer } from 'porowneo-design';
import { ValidationFieldHoc } from 'porowneo-ui';

import { withTooltipData } from './TooltipDataProvider';

import type { TButtonCheckboxProps } from 'porowneo-design';

export type TButtonCheckboxComponent<T extends string | number | null, TMulti extends boolean> = Omit<TButtonCheckboxProps<T, TMulti>, 'handleChange'> & {
  onChange: TButtonCheckboxProps<T>['handleChange'];
}

export const ButtonCheckboxFieldWithoutHOC = <T extends string | number | null, TMulti extends boolean>({
  id,
  onChange,
  ariaExpanded,
  ariaLabel,
  ariaPressed,
  availableValues,
  value,
  multiSelect = true,
  disabled = false,
  size = 'medium',
}: TButtonCheckboxComponent<T, TMulti>) => (
  <ButtonCheckbox
    id={id}
    handleChange={onChange}
    ariaExpanded={ariaExpanded}
    ariaLabel={ariaLabel}
    ariaPressed={ariaPressed}
    availableValues={availableValues}
    value={value}
    multiSelect={multiSelect}
    disabled={disabled}
    size={size}
  />
);

export default withTooltipData(ValidationFieldHoc(ButtonCheckboxFieldWithoutHOC, FieldContainer as any));
