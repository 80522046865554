import { checkErrorCode } from '../../helpers/FormUtils';

import type { IDaemonBasket } from '../../types/DaemonOutput.type';

interface IFullSaleErrorHeaderProps {
  warningMessages?: IDaemonBasket['warningMessages'],
}

export const FullSaleModalErrorHeader = ({
  warningMessages = [],
}: IFullSaleErrorHeaderProps) => {
  const isWarningMessage = warningMessages && warningMessages.length > 0;
  const isPaymentError = warningMessages.some(({ warningCode }) => checkErrorCode(warningCode, ['20102']));
  if (isWarningMessage && isPaymentError) {
    return (
      <>
        <p>
          Twój wniosek został wystawiony, jednak nie udało się wygenerować płatności.
        </p>
        <p>
          Jeśli zamkniesz to okno nastąpi ponowna próba wygenerowania płatności.
          <br />
          Jeśli problem będzie się powtarzał prosimy o kontakt z naszą infolinią.
        </p>
      </>
    );
  }

  return (<p>Niestety podczas sprzedaży polisy wystąpiły błędy. Polisa nie została zawarta. Prosimy o kontakt z infolinią lub pozostawienie kontaktu.</p>);
};
