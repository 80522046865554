import { aionTheme, porowneoTheme } from 'porowneo-ui';

import { themeName } from './Envutils';

import type { ITheme } from 'porowneo-ui';

export const AION_THEME = 'aionTheme';
export const POROWNEO_THEME = 'porowneoTheme';

const allowedThemes = {
  [AION_THEME]: aionTheme,
  [POROWNEO_THEME]: porowneoTheme,
} as const;

export const isAllowedThemesKeys = (key: unknown): boolean => (
  typeof key === 'string' && Object.keys(allowedThemes).includes(key)
);

export const getTheme = (customThemeName?: typeof AION_THEME | typeof POROWNEO_THEME): ITheme => {
  if (customThemeName && isAllowedThemesKeys(customThemeName)) {
    return allowedThemes[customThemeName];
  }
  if (themeName && isAllowedThemesKeys(themeName)) {
    return allowedThemes[themeName];
  }

  return allowedThemes.porowneoTheme;
};

export const setUpperCaseForTheme = ({ theme }: { theme: ITheme}) => (theme?.id === AION_THEME ? 'uppercase' : 'inherit');

export const isAionTheme = (theme: NodeJS.ProcessEnv['REACT_APP_THEME']): boolean => theme === AION_THEME;

export const isAion = isAionTheme(themeName);
