import { LinkButton } from './AionAccessDeniedModal.syled';

export const AionAccessDeniedModal = () => (
  <div className="wrapper wrapper--contact">
    <div className="row">
      <div className="col wrapper__header-title--text-centered ">
        <p>
          Przepraszamy, ale aby skorzystać z formularza należy przejść z aplikacji Aion Bank.
        </p>
        <br />
        <LinkButton
          id="leaveButton"
          href="https://aion.eu/pl-pl"
          rel="nofollow"
        >
          Przejdź do Aion Bank
        </LinkButton>
      </div>
    </div>
  </div>
);
