import InfoBarHideIcon from './InfoBarHideIcon';
import {
  InfoBarContent, InfoBarHideButton, InfoBarPhone, InfoBarRow, InfoBarWrapper,
} from './InfoBar.styled';

interface IInfoBar {
  isInfoBar: boolean;
  hideInfoBar: () => void;
}

const InfoBar = ({ hideInfoBar, isInfoBar }: IInfoBar) => (
  <InfoBarWrapper isInfoBar={isInfoBar}>
    <InfoBarRow>
      <InfoBarContent>
        <span>Dla naszych klientów pracujemy w tę niedzielę!</span>
        <InfoBarPhone href="tel:+48519902560">+48 519 902 560</InfoBarPhone>
      </InfoBarContent>
      <InfoBarHideButton onClick={hideInfoBar} type="button" aria-label="Zamknij" className="info-bar__close"><InfoBarHideIcon /></InfoBarHideButton>
    </InfoBarRow>
  </InfoBarWrapper>
);

export default InfoBar;
