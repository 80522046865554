import type { IDaemonBasket } from '../types/DaemonOutput.type';

export const SET_ERRORS_PER_INSURER = 'SET_ERRORS_PER_INSURER';
export const RESET_ERRORS_PER_INSURER = 'RESET_ERRORS_PER_INSURER';

export const setErrorsPerInsurer = (payload: IDaemonBasket) => ({
  type: SET_ERRORS_PER_INSURER,
  payload,
});

export const resetErrorsPerInsurer = () => ({
  type: RESET_ERRORS_PER_INSURER,
});
