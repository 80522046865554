import debounce from 'lodash/debounce';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  above, below, VARS,
} from 'porowneo-ui';
import { Button, InfoBox } from 'porowneo-design';

import { isAionTheme, setUpperCaseForTheme } from '../helpers/setupTheme';

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${above(VARS.breakpoints.md)} {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`;

const BTN_ONE_ID = 'btnOne';
export const BTN_TWO_ID = 'btnTwo';

const stepsNavigationOrder = (theme, btnId, isMobile) => {
  const isButtonOne = btnId === BTN_ONE_ID;
  const isAionThemOnMobile = isAionTheme(theme) && isMobile;

  if (isAionThemOnMobile) {
    return isButtonOne ? 3 : 1;
  }
  return isButtonOne ? 1 : 3;
};

export const ButtonStyled = styled('div')<{isShow?: boolean, btnId?: string}>`
  & > button {
    text-transform: ${setUpperCaseForTheme};
    visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
    width: 240px;

    ${above(VARS.breakpoints.md)} {
      width: ${({ btnId }) => (btnId === BTN_ONE_ID ? '160px' : '270px')};
    }

    ${below(VARS.breakpoints.md)} {
      margin-top: ${({ btnId }) => (btnId === BTN_ONE_ID ? '12px' : '0')};
    }
  }
`;
ButtonStyled.displayName = 'ButtonStyled';

const CSDBox = styled.div`
  margin-bottom: 32px;

  ${above(VARS.breakpoints.md)} {
    margin-bottom: 48px;
  }
`;
CSDBox.displayName = 'CSDBox';

const CSDInfoBox = ({ csdInfoBar }) => (
  <CSDBox>
    <InfoBox
      id="csdInfoBox"
      type="warning"
      fullWidth
      content={(
        <>
          <span>{csdInfoBar}</span>
          <br />
          <span>
            Na ekranie ofert będzie dostępna opcja przypomnienia Tobie o powrocie do kalkulacji we właściwym momencie.
          </span>
        </>
      )}
    />
  </CSDBox>
);

const ButtonWrapper = styled.div<{isShow?: boolean, btnId?: string}>`
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  order: ${({ btnId, theme }) => stepsNavigationOrder(theme?.id, btnId, true)};

  ${above(VARS.breakpoints.md)} {
    order: ${({ btnId, theme }) => stepsNavigationOrder(theme?.id, btnId, false)};
  }

  ${below(VARS.breakpoints.lg)} {
    display: ${({ isShow }) => (isShow && 'inline-block')};
  }
  ${above(VARS.breakpoints.md)} {
    margin-left: 32px;
  }
`;

const StepsNavigateButton = ({
  btnOneConfig,
  btnTwoConfig,
  csdInfoBar,
}) => (
  <>
    {!!csdInfoBar && (
      <CSDInfoBox csdInfoBar={csdInfoBar} />
    )}

    <WrapperStyled>
      <ButtonStyled
        btnId={BTN_ONE_ID}
        isShow={btnOneConfig.show}
      >
        <Button
          id={BTN_ONE_ID}
          color="secondary"
          variant="contained"
          size="large"
          handleClick={debounce(() => btnOneConfig.func(), 400, { leading: false, trailing: true })}
        >
          {btnOneConfig.btnText}
        </Button>
      </ButtonStyled>

      <ButtonWrapper>
        <ButtonStyled
          btnId={BTN_TWO_ID}
          isShow={btnTwoConfig.show}
        >
          <Button
            id={BTN_TWO_ID}
            color="primary"
            variant="contained"
            size="large"
            ref={btnTwoConfig.refElement || null}
            handleClick={debounce(() => btnTwoConfig.func(), 400, { leading: false, trailing: true })}
          >
            {btnTwoConfig.btnText}
          </Button>
        </ButtonStyled>
      </ButtonWrapper>
    </WrapperStyled>
  </>
);

StepsNavigateButton.defaultProps = {
  btnOneConfig: {
    func: () => {},
    show: false,
    btnText: '',
  },
  btnTwoConfig: {
    func: () => {},
    show: false,
    btnText: '',
    refElement: PropTypes.func,
  },
  csdInfoBar: '',
};

StepsNavigateButton.propTypes = {
  btnOneConfig: PropTypes.shape({
    func: PropTypes.func,
    show: PropTypes.bool,
    btnText: PropTypes.string,
  }),
  btnTwoConfig: PropTypes.shape({
    func: PropTypes.func,
    show: PropTypes.bool,
    btnText: PropTypes.string,
    refElement: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  }),
  csdInfoBar: PropTypes.string,
};

export default StepsNavigateButton;
