import moment from 'moment';

import FIELDS from '../config/fields';
import { STEP_ONE_FIELDS } from '../views/stepOne/config/StepOne.config';
import { DATE_FORMAT } from '../config/formats';
import {
  SELECT_COVER_TYPE,
  SELECT_START_DATE,
  NAME,
  SURNAME,
  TYPE_LEAVE_PHONE_NUMBER,
  TYPE_EMAIL,
} from '../actions/Steps';
import {
  CONNECT,
  DISCONNECT,
  RESULT,
  COMPLETE,
  LOCKED,
  RESULT_ERROR,
} from '../actions';

import type { TCovers } from '../types/Covers.type';
import type { TLocator, TShortLocator } from '../types/Locator.type';
import type { IDaemonBasket } from '../types/DaemonOutput.type';

export interface ILockedWebsockets {
  locked: boolean;
  data?: {
    code: 'LOCKED';
    shortLocator: TShortLocator;
    fullLocator?: TLocator;
    sfUserName: string;
    sfGender?: 'male' | 'female';
    ts?: number;
  }
}
export type TConnectedWebsockets = '' | 'connected';
export type TDisconnectWebsockets = '' | 'disconnected';
export type TCompleteWebsockets = '' | 'completed';
export type TEmailWebsockets = string;
export type TNameWebsockets = string;
export type TSurnameWebsockets = string;

export type TCreateReducerConst = typeof CONNECT | typeof DISCONNECT | typeof COMPLETE | typeof LOCKED | typeof TYPE_EMAIL | typeof NAME | typeof SURNAME;
export type TCreateReducerPayload<T> = T extends typeof CONNECT
  ? TConnectedWebsockets
  : T extends typeof DISCONNECT
  ? TDisconnectWebsockets
  : T extends typeof COMPLETE
  ? TCompleteWebsockets
  : T extends typeof LOCKED
  ? ILockedWebsockets
  : T extends typeof TYPE_EMAIL
  ? TEmailWebsockets
  : T extends typeof NAME
  ? TNameWebsockets
  : T extends typeof SURNAME
  ? TSurnameWebsockets
  : unknown;

export interface ICreateReducerAction<T extends TCreateReducerConst> {
  type: TCreateReducerConst,
  payload: TCreateReducerPayload<T>
}

const createReducer = <T extends TCreateReducerConst>(CONST: TCreateReducerConst, defaultState: '' | TCreateReducerPayload<T> = '') => (
  state = defaultState,
  action: ICreateReducerAction<T>,
): TCreateReducerPayload<T> => {
  if (action.type === CONST) {
    return action.payload;
  }
  return state as TCreateReducerPayload<never>;
};

export const connectWebsocets = createReducer<typeof CONNECT>(CONNECT);
export const disconnectWebsocets = createReducer<typeof DISCONNECT>(DISCONNECT);
export const completeWebsocets = createReducer<typeof COMPLETE>(COMPLETE);
export const lockedWebsockets = createReducer<typeof LOCKED>(LOCKED);

export const resultWebsocets = (state: IDaemonBasket[] = [], action: {
  type: typeof RESULT,
  payload: IDaemonBasket | [],
}): IDaemonBasket[] => {
  if (action.type === RESULT) {
    if (Array.isArray(action.payload) && action.payload.length === 0) {
      return [];
    }
    if (!Array.isArray(action.payload)) {
      return [...state, action.payload];
    }
  }
  return state;
};
export const resultErrorsWebsocets = (state: IDaemonBasket[] = [], action: {
  type: typeof RESULT_ERROR,
  payload: IDaemonBasket | [],
}): IDaemonBasket[] => {
  if (action.type === RESULT_ERROR) {
    if (Array.isArray(action.payload) && action.payload.length === 0) {
      return [];
    }
    if (!Array.isArray(action.payload)) {
      return [...state, action.payload];
    }
  }
  return state;
};

interface ISelectedCoverTypeAction {
  type: typeof SELECT_COVER_TYPE;
  coverType: TCovers;
}

export const selectedCoverType = (state: (TCovers)[] = [STEP_ONE_FIELDS.coverType.defaultValue], action: ISelectedCoverTypeAction) => {
  if (action.type === SELECT_COVER_TYPE) {
    if (action.coverType) {
      if (state.includes(action.coverType)) {
        const updatedState = state.filter(item => item !== action.coverType);
        return [...updatedState];
      }
      return [...state, action.coverType];
    }
    return state;
  }
  return state;
};
export const selectedStartDate = (state = '', action) => {
  if (action.type === SELECT_START_DATE) {
    return action === '' ? moment().format(DATE_FORMAT) : action.startDate;
  }
  return state;
};
export const typePhoneLeaveContact = (state = '', action) => {
  if (action.type === TYPE_LEAVE_PHONE_NUMBER) {
    return action.phoneLeaveContact;
  }
  return state;
};

// step 4
export const typeEmail = createReducer<typeof TYPE_EMAIL>(TYPE_EMAIL, FIELDS?.email?.defaultValue);

// leave contact
export const typeName = createReducer<typeof NAME>(NAME, FIELDS?.name?.defaultValue);
export const typeSurname = createReducer<typeof SURNAME>(SURNAME, FIELDS?.surname?.defaultValue);
