import type { IRootState } from '../../../reducers';

export const selectPropsFromStore = ({
  isCalculated,
  disableFields,
  payload: {
    stepOne: {
      phone,
      protectionBegins,
      ac,
      nnw,
      filters,
      vehicle: {
        productionYear,
        brand,
        model,
        fuel,
        lpg,
        transmission,
        eurotaxVersion,
        infoexVersion,
      },
    },
  },
  user: {
    locator,
    sessionId,
    userAgreements,
  },
  agreements,
  visitedSteps,
}: IRootState) => ({
  locator,
  sessionId,
  isCalculated,
  disableFields,
  ac,
  nnw,
  productionYear,
  brand,
  model,
  fuel,
  lpg,
  transmission,
  eurotaxVersion,
  infoexVersion,
  filters,
  protectionBegins,
  phone,
  agreements,
  userAgreements,
  visitedSteps,
});
