import omit from 'lodash/omit';
import log from 'loglevel';

import type { TDamagesPaid } from './CarProjectFields.types';
import type { AxiosResponse } from 'axios';
import type { IPayloadStepOneFs, TYesNo } from '../../reducers/Payload';
import type { IAddress } from '../../types/Address.type';
import type {
  IInsurer, IInsurerFull, IInsurerHistoryPayload, IInsurerHistoryPerCover, TInsurerBasePerson,
} from '../../types/Insurer.type';
import type {
  IAddressIdenticalCarUser, IAddressDifferentCarUser, TInsuredCarUser, TCarUserPayload,
  IFullSaleUpdateUser, TCarUser, ICarUserInsuranceData, ICarUserInsurancePayload, TCarProjectUpdate,
  ICarProjectStepOneUpdate, ICarProjectStepTwoUpdate, ICarProjectStepThreeUpdate, ICarProjectStepFourUpdate,
  ICarProjectStepOneFSUpdate, ICarProjectStepTwoFSUpdate, IUpdateCarProjectFullSaleFirstStep,
} from './CarProject.types';

export const yesNoToBool = (val: TYesNo): boolean => val === 'yes';

export const createAddressSamePayload = (addressPayload: IAddress<'addressAndRegistered'>): IAddressIdenticalCarUser => ({
  addressRegisteredIdentical: true,
  address: addressPayload,
  addressRegistered: null,
});

export const createAddressDifferentPayload = (addressPayload: IAddress<'address'>, addressRegisteredPayload: IAddress<'registered'>): IAddressDifferentCarUser => ({
  addressRegisteredIdentical: false,
  address: addressPayload,
  addressRegistered: addressRegisteredPayload,
});

export const addressConsideredIdentical = (addressPayload: IAddress<'address' | 'addressAndRegistered'>): addressPayload is IAddress<'addressAndRegistered'> => (
  addressPayload.type === 'addressAndRegistered'
);

export const addressNotIdentical = (addressPayload: IAddress<'address' | 'addressAndRegistered'>): addressPayload is IAddress<'address'> => (
  addressPayload.type === 'address'
);

/**
 * @throws {Error}
 */
export const createAddressPart = <T extends TInsuredCarUser>({ address, addressRegistered, addressRegisteredIdentical }: Pick<IInsurer<T>, 'address' | 'addressRegistered' | 'addressRegisteredIdentical'>): IAddressIdenticalCarUser | IAddressDifferentCarUser => {
  if (addressRegisteredIdentical && addressConsideredIdentical(address)) {
    return createAddressSamePayload(address);
  }
  if (!addressRegisteredIdentical && addressNotIdentical(address) && !!addressRegistered) {
    return createAddressDifferentPayload(address, addressRegistered);
  }
  throw new Error('Invalid address data; cannot map for CarProject');
};

/**
 * @throws {Error}
 */
export const createUserPayload = <T extends TInsuredCarUser>(userPayload: IInsurer<T>): TCarUserPayload<T> => {
  const { sex } = userPayload;
  if (!sex) {
    throw new Error('Cannot map empty sex for CarProject');
  }

  return {
    type: userPayload.type,
    sex,
    birthdate: userPayload.birthdate,
    licenseDate: userPayload.licenseDate,
    hasDrivingLicense: userPayload.hasDrivingLicense,
    firstName: userPayload.firstName || null,
    lastName: userPayload.lastName || null,
    maidenName: userPayload.maidenName || null,
    pesel: userPayload.pesel || null,
    maritalStatus: userPayload.maritalStatus,
    ...createAddressPart(userPayload),
  };
};

/**
 * @throws {Error}
 */
export const createInsurerPayload = <T extends Exclude<TCarUser, 'youngPerson' | 'contact'>>(
  payload: IPayloadStepOneFs,
  userPayload: IInsurerFull<T>,
): IFullSaleUpdateUser<T> => {
  const { sex } = userPayload;
  if (!sex) {
    throw new Error('Sex is empty on FS, cannot update CarProject');
  }
  return {
    type: userPayload.type,
    userId: userPayload.userId,
    birthdate: userPayload.birthdate,
    sex,
    firstName: userPayload.firstName || null,
    lastName: userPayload.lastName || null,
    maidenName: userPayload.maidenName || null,
    pesel: userPayload.pesel || null,
    addressRegisteredIdentical: userPayload.addressRegisteredIdentical,
    address: userPayload.address,
    addressRegistered: userPayload.addressRegistered || null,
    phone: userPayload.type === payload.insurerPerson ? payload.phoneFromFs.replace(/\s/g, '') : null,
    email: userPayload.type === payload.insurerPerson ? payload.emailFromFs : null,
  };
};

/**
 * @throws {Error}
 */
export const parseHistoryByCover = <T extends 'oc' | 'ac'>(coverPayload: IInsurerHistoryPerCover<T>): ICarUserInsuranceData<T> => {
  const { yearsBuying, damagesPaid } = coverPayload;
  if (yearsBuying === null || yearsBuying === '') {
    throw new Error('Invalid history yearsBuying data, cannot map CarProject');
  }
  if (damagesPaid === null) {
    throw new Error('Invalid history damagesPaid data, cannot map CarProject');
  }
  return {
    ...coverPayload,
    damagesPaid: typeof damagesPaid === 'string' ? // TODO remove this after fixing field type FORMMOTO-3480
      (parseInt(damagesPaid || '', 10) as TDamagesPaid) :
      damagesPaid,
    yearsBuying: parseInt(yearsBuying, 10),
  };
};

/**
 * @throws {Error}
 */
export const parseInsuranceHistory = <T extends TInsuredCarUser>(historyPayload: IInsurerHistoryPayload<T>): ICarUserInsurancePayload<T> => {
  const { acInsuranceHistory } = historyPayload;
  if (!historyPayload.insuranceHistorySame && !acInsuranceHistory) {
    throw new Error('InsuranceHistorySame is false, but no AC history passed; Cannot map to CarProject!');
  }
  return {
    type: historyPayload.type,
    carUser: historyPayload.carUser || null,
    insuranceHistorySame: historyPayload.insuranceHistorySame,
    ocInsuranceHistory: parseHistoryByCover(historyPayload.ocInsuranceHistory),
    acInsuranceHistory: (!historyPayload.insuranceHistorySame && acInsuranceHistory) ? parseHistoryByCover(acInsuranceHistory) : null,
  };
};

/**
 * @throws {Error}
 */
export const validateFSOwner = (owner: IPayloadStepOneFs['owner']): IInsurerFull<'owner'> => {
  if (!owner) {
    throw new Error('Owner is empty on FS, cannot update CarProject');
  }
  return owner;
};

/**
 * @throws {Error}
 */
export const validateFSInsurerPerson = (insurerPerson: IPayloadStepOneFs['insurerPerson']): TInsurerBasePerson | 'insurer' => {
  if (!insurerPerson) {
    throw new Error('InsurerPerson is empty on FS, cannot update CarProject');
  }
  return insurerPerson;
};

/**
 * @throws {Error}
 */
export const validateFSOne = (payload: IPayloadStepOneFs): [
  IInsurerFull<'owner'>,
  TInsurerBasePerson | 'insurer'
] => [validateFSOwner(payload.owner), validateFSInsurerPerson(payload.insurerPerson)];

export const omitMovedFSFields = (updatePayload: IUpdateCarProjectFullSaleFirstStep): IUpdateCarProjectFullSaleFirstStep => omit(updatePayload, [
  'plate', 'vin', 'fromAbroadLastYear', 'carSecurity', 'firstImmatInPoland', 'technicalInspectionDate', 'carKeysNumber', 'holdKeysNumberExtra', 'hasServiceHistory', 'wasModified', 'damageDetails',
]);

export const isStepOneUpdate = (update: TCarProjectUpdate): update is ICarProjectStepOneUpdate => update.step === 1 && !update.isFullSale;

export const isStepTwoUpdate = (update: TCarProjectUpdate): update is ICarProjectStepTwoUpdate => update.step === 2 && !update.isFullSale;

export const isStepThreeUpdate = (update: TCarProjectUpdate): update is ICarProjectStepThreeUpdate => update.step === 3;

export const isStepFourUpdate = (update: TCarProjectUpdate): update is ICarProjectStepFourUpdate => update.step === 4;

export const isStepOneFSUpdate = (update: TCarProjectUpdate): update is ICarProjectStepOneFSUpdate => update.step === 1 && update.isFullSale;

export const isStepTwoFSUpdate = (update: TCarProjectUpdate): update is ICarProjectStepTwoFSUpdate => update.step === 2 && update.isFullSale;

/**
 * @throws {Error}
 */
export const createSaveUpdateInStorage = (stepKey: string, stepPayload: TCarProjectUpdate['stepPayload']) => (response: AxiosResponse): AxiosResponse => {
  if (response?.data?.id) {
    log.debug('save to sessionStorage');
    sessionStorage.setItem(stepKey, JSON.stringify(stepPayload));
    return response;
  }
  throw Error(`Incorect response ${JSON.stringify(response)}`);
};

/**
 * @throws {Error}
 */
export const createVersioningHandle = (step: TCarProjectUpdate['step']) => (response: AxiosResponse): AxiosResponse => {
  log.debug('versiononing', response);
  if (response?.data) {
    return response;
  }
  throw Error(`Versioning failed - step: ${step}`);
};
