import PropTypes from 'prop-types';

import { generateInsurerFieldsConfig, getInsurerTitle } from '../../../../config/InsurerCommonFields';
import { DropDownField } from '../../../../components/fields/DropDownField';
import {
  getMonthsNew, setPersonLicenseAvValues, showMonthLicenseField,
} from '../../../../helpers/FormUtils';
import { isDisableField } from '../../../../helpers/payloadUtils';
import { CheckboxFieldWithContainer } from '../../../../components/fields/CheckboxField';

import { ReduceMargin } from './Insurer.styled';

import type { THandleChangeInsurer } from './Insurer';
import type { IPayloadStepThree } from '../../../../reducers/Payload';
import type { TInsurerType, IInsurer } from '../../../../types/Insurer.type';
import type { IHandleSetValidationSteps, IHandleSetValidationStepsFullSale } from '../../../../types/Steps.type';
import type { TStepOrigin } from './Insurer.types';

interface ILicenseDate<T extends TInsurerType> {
  licenseDate: IInsurer<T>['licenseDate'];
  licenseDateInitMonth: IInsurer<T>['licenseDateInitMonth']; // only to detect manual change month
  hasDrivingLicense: boolean;
  birthdate: IInsurer<T>['birthdate'];
  handleSetValidation: IHandleSetValidationSteps | IHandleSetValidationStepsFullSale;
  handleChange: THandleChangeInsurer;
  typeOfInsurer: T;
  stepOrigin: TStepOrigin;
  coowners: IPayloadStepThree['coowners'];
  mainDriver?: TInsurerType | ''
  orderPrefix: number;
  disableFields: string[];
  isMobile: boolean;
}

const LicenseDate = <T extends TInsurerType>({
  licenseDate,
  licenseDateInitMonth,
  hasDrivingLicense,
  birthdate,
  handleSetValidation,
  handleChange,
  typeOfInsurer, // owner || coOwner || coCoOwner || driver || youngPerson || insurer
  stepOrigin,
  coowners,
  mainDriver,
  orderPrefix,
  disableFields,
  isMobile,
}: ILicenseDate<T>) => {
  const FIELDS_CONF = generateInsurerFieldsConfig(typeOfInsurer, coowners, stepOrigin, '', mainDriver);
  const acceptableAge = typeOfInsurer === 'youngPerson' ? 18 : 16;
  const isMonthDayShown = showMonthLicenseField(licenseDate ? parseInt(licenseDate.slice(0, 4), 10) : false);

  const insurerTitle = getInsurerTitle(coowners)[typeOfInsurer];
  const year = licenseDate?.slice(0, 4);
  const month = licenseDate?.slice(5, 7);

  return (
    <>
      {FIELDS_CONF.licenseYear.isShow && (
        <>
          <DropDownField
            {...FIELDS_CONF.licenseYear}
            disabled={isDisableField(disableFields, FIELDS_CONF.licenseYear) || !hasDrivingLicense}
            order={orderPrefix + 1}
            onChange={(k: `${typeof typeOfInsurer}-licenseYear`, v: string) => {
              handleChange(k, `${v}-01-01`);
            }}
            value={licenseDate ? year : ''}
            validations={hasDrivingLicense && {
              required: `Wybierz rok wydania prawa jazdy ${insurerTitle}`,
            }}
            handleSetValidation={handleSetValidation}
            availableValues={setPersonLicenseAvValues(birthdate, acceptableAge)}
            searchable={!isMobile}
            ignoreFillStatus={!hasDrivingLicense}
          />
          {FIELDS_CONF.hasDrivingLicense.isShow && (
            <ReduceMargin>
              <CheckboxFieldWithContainer
                {...FIELDS_CONF.hasDrivingLicense}
                disabled={isDisableField(disableFields, FIELDS_CONF.hasDrivingLicense) || (isDisableField(disableFields, FIELDS_CONF.licenseYear) && year)}
                onChange={(key: `${typeof typeOfInsurer}-hasDrivingLicense`, value: boolean) => handleChange(key, value)}
                value={!hasDrivingLicense}
                textSize="small"
              >
                {FIELDS_CONF.hasDrivingLicense.checkboxTitle}
              </CheckboxFieldWithContainer>
            </ReduceMargin>
          )}
          <div className="hr" />
        </>
      )}

      {FIELDS_CONF.licenseMonth.isShow && isMonthDayShown && (
        <>
          <DropDownField
            {...FIELDS_CONF.licenseMonth}
            disabled={isDisableField(disableFields, FIELDS_CONF.licenseMonth) || !hasDrivingLicense}
            order={7}
            onChange={(k:`${typeof typeOfInsurer}-licenseMonth`, v: string) => {
              handleChange(k, `${year}-${v}-01`);
            }}
            value={licenseDateInitMonth ? '' : month}
            validations={hasDrivingLicense && {
              required: `Wybierz miesiąc wydania prawa jazdy ${insurerTitle}`,
            }}
            handleSetValidation={handleSetValidation}
            availableValues={getMonthsNew(year)}
            searchable={!isMobile}
          />
          <div className="hr" />
        </>
      )}
    </>
  );
};

LicenseDate.defaultProps = {
  stepOrigin: '',
  insurerName: '',
  coowners: '',
  mainDriver: '',
  orderPrefix: 0,
};

LicenseDate.propTypes = {
  orderPrefix: PropTypes.number,
  licenseDate: PropTypes.string.isRequired,
  birthdate: PropTypes.string.isRequired,
  handleSetValidation: PropTypes.shape({
    handleSetValidation: PropTypes.func.isRequired,
    callBackIfFieldInValid: PropTypes.func.isRequired,
    callBackSetValidationFieldStatus: PropTypes.func.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  typeOfInsurer: PropTypes.oneOf(
    ['owner', 'coOwner', 'coCoOwner', 'driver', 'youngPerson', 'insurer'],
  ).isRequired,
  stepOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  insurerName: PropTypes.string,
  coowners: PropTypes.number,
  mainDriver: PropTypes.string,
  disableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default LicenseDate;
