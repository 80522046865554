export const SET_SENSITIVE_ACTIVE = 'SET_SENSITIVE_ACTIVE';
export const SET_SENSITIVE_INACTIVE = 'SET_SENSITIVE_INACTIVE';

interface ISensitiveActive {
  type: typeof SET_SENSITIVE_ACTIVE;
  payload: string | string[];
}

interface ISensitiveInActive {
  type: typeof SET_SENSITIVE_INACTIVE;
  payload?: string;
}

export type SensitiveActionTypes = ISensitiveActive | ISensitiveInActive;

export const setSensitiveActive = (payload: string[]): ISensitiveActive => ({
  type: SET_SENSITIVE_ACTIVE,
  payload,
});

export const setSensitiveInActive = (payload: string): ISensitiveInActive => ({
  type: SET_SENSITIVE_INACTIVE,
  payload,
});
