/* eslint-disable camelcase */
import moment from 'moment';

import { DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD } from '../config/formats';
import { createUrlInsurersToShow } from '../config/url';
import { ROUTING_FULL_SALE_PERSONAL_DATA, ROUTING_FULL_SALE_VEHICLE_DATA } from '../config/Router.config';
import {
  OWNER, CO_OWNER, CO_CO_OWNER, DRIVER, INSURER,
} from '../config/InsurerPersons.literal';

import {
  convertDateFormat, convertCitiesStreetsToFormat,
} from './FormUtils';
import { getProcessName } from './processName';
import { setHiddenPartnerAgreements, filterTextAgreements } from './FullSaleHelpers/FullSaleFormHelpers';
import { isIInsurerFull } from './types/InsurerType';

import type {
  IPayloadStepOne, IPayloadStepFour, IPayloadStepThree, IPayloadStepOneFs,
} from '../reducers/Payload';
import type { IRootState } from '../reducers';
import type {
  TOwner,
  TInsurerType, IInsurerFull, IInsurer, TCoOwner, TCoCoOwner, TYoungPerson,
} from '../types/Insurer.type';
import type {
  IDaemonOwner, IDaemonCoOwner, IDaemonCoCoOwner, IDaemonDriver, IDaemonYoungPerson, IDaemonInsurer,
} from '../types/DaemonInput.type';

const checkIfAddressRegisteredIsIdentical = (isAddressRegisteredIdentical: unknown) => isAddressRegisteredIdentical !== false;

export type TContactMapData = {phone: IPayloadStepOne['phone'], email: IPayloadStepFour['email']} | Record<string, never>;
export const setOwner = (
  owner: IPayloadStepThree[TOwner] | IPayloadStepOneFs[TOwner],
  contact: TContactMapData,
  isFullSale: boolean,
): IDaemonOwner => {
  const extendedAddressParams = (isFullSale && isIInsurerFull(owner)) ? {
    owner_street_name: owner.address.street,
    owner_street_number: owner.address.building,
    owner_flat_number: owner.address.flat,
  } : {};
  const extendedAddressRegisteredParams = (isFullSale && isIInsurerFull(owner)) ? {
    owner_street_name_registered: owner.addressRegisteredIdentical ? owner.address.street : owner.addressRegistered?.street,
    owner_street_number_registered: owner.addressRegisteredIdentical ? owner.address.building : owner.addressRegistered?.building,
    owner_flat_number_registered: owner.addressRegisteredIdentical ? owner.address.flat : owner.addressRegistered?.flat,
  } : {};

  const additionalContactData = (isFullSale && Object.keys(contact).length === 2) ? {
    owner_phone: contact.phone,
    owner_email: contact.email,
  } : {};

  return {
    ...extendedAddressParams,
    ...extendedAddressRegisteredParams,
    ...additionalContactData,
    firstname: owner?.firstName || null,
    lastname: owner?.lastName || null,
    owner_maiden_name: owner?.maidenName || null,
    pesel: owner?.pesel || null,
    gender: owner?.sex || '',
    birthdate: owner?.birthdate || '',
    license: owner?.hasDrivingLicense ? owner?.licenseDate : null,
    owner_marital_status: owner?.maritalStatus || null,
    owner_address_registered: checkIfAddressRegisteredIsIdentical(owner?.addressRegisteredIdentical),
    // address params
    zip_code_car_owner: owner?.address.zipCode || '',
    city_owner: convertCitiesStreetsToFormat(owner?.address.city),
    owner_district: owner?.address.district || '',
    // address registered params
    owner_zip_registered: owner?.addressRegisteredIdentical ? owner.address.zipCode : owner?.addressRegistered?.zipCode,
    owner_city_registered: convertCitiesStreetsToFormat(owner?.addressRegisteredIdentical ? owner.address.city : owner?.addressRegistered?.city),
    owner_district_registered: owner?.addressRegisteredIdentical ? owner.address.district : owner?.addressRegistered?.district,
  };
};

export const setCoOwner = (
  coOwner: null | IInsurer<TCoOwner> | IInsurerFull<TCoOwner>,
  contact: TContactMapData,
  isFullSale: boolean,
): IDaemonCoOwner | Record<string, never> => {
  if (coOwner !== null) {
    const extendedAddressParams = (isFullSale && isIInsurerFull(coOwner)) ? {
      coowner_street_name: coOwner.address?.street || '',
      coowner_street_number: coOwner.address?.building || '',
      coowner_flat_number: coOwner.address?.flat || '',
    } : {};

    const extendedAddressRegisteredParams = (isFullSale && isIInsurerFull(coOwner)) ? {
      coowner_street_name_registered: coOwner.addressRegisteredIdentical ? coOwner.address.street : coOwner?.addressRegistered?.street,
      coowner_street_number_registered: coOwner.addressRegisteredIdentical ? coOwner.address.building : coOwner?.addressRegistered?.building,
      coowner_flat_number_registered: coOwner.addressRegisteredIdentical ? coOwner.address.flat : coOwner?.addressRegistered?.flat,
    } : {};

    const additionalContactData = (isFullSale && Object.keys(contact).length === 2) ? {
      coowner_phone: contact.phone,
      coowner_email: contact.email,
    } : {};

    return ({
      ...extendedAddressParams,
      ...extendedAddressRegisteredParams,
      ...additionalContactData,
      coowner_givenname: coOwner.firstName,
      coowner_familyname: coOwner.lastName,
      coowner_maiden_name: coOwner.maidenName || null,
      coowner_pesel: coOwner.pesel,
      coowner_sex: coOwner.sex,
      coowner_birthdate: coOwner.birthdate,
      coowner_license: coOwner?.hasDrivingLicense ? coOwner?.licenseDate : null,
      coowner_marital_status: coOwner.maritalStatus,
      coowner_address_registered: coOwner.addressRegisteredIdentical,
      // address params
      coowner_zip: coOwner.address.zipCode,
      coowner_city: convertCitiesStreetsToFormat(coOwner.address.city),
      coowner_district: coOwner.address.district,
      // address registered params
      coowner_zip_registered: coOwner.addressRegisteredIdentical ? coOwner.address.zipCode : coOwner?.addressRegistered?.zipCode,
      coowner_city_registered: convertCitiesStreetsToFormat(coOwner.addressRegisteredIdentical ? coOwner.address.city : coOwner?.addressRegistered?.city),
      coowner_district_registered: coOwner.addressRegisteredIdentical ? coOwner.address.district : coOwner?.addressRegistered?.district,
    });
  }

  return {};
};

export const setCoCoOwner = (
  coCoOwner: null | IInsurer<TCoCoOwner> | IInsurerFull<TCoCoOwner>,
  contact: TContactMapData,
  isFullSale: boolean,
):
  IDaemonCoCoOwner | Record<string, never> => {
  if (coCoOwner !== null) {
    const extendedAddressParams = (isFullSale && isIInsurerFull(coCoOwner)) ? {
      co_coowner_street_name: coCoOwner.address.street,
      co_coowner_street_number: coCoOwner.address.building,
      co_coowner_flat_number: coCoOwner.address.flat,
    } : {};

    const extendedAddressRegisteredParams = (isFullSale && isIInsurerFull(coCoOwner)) ? {
      co_coowner_street_name_registered: coCoOwner.addressRegisteredIdentical ? coCoOwner.address.street : coCoOwner?.addressRegistered?.street,
      co_coowner_street_number_registered: coCoOwner.addressRegisteredIdentical ? coCoOwner.address.building : coCoOwner?.addressRegistered?.building,
      co_coowner_flat_number_registered: coCoOwner.addressRegisteredIdentical ? coCoOwner.address.flat : coCoOwner?.addressRegistered?.flat,
    } : {};

    const additionalContactData = (isFullSale && Object.keys(contact).length === 2) ? {
      co_coowner_phone: contact.phone,
      co_coowner_email: contact.email,
    } : {};

    return ({
      ...extendedAddressParams,
      ...extendedAddressRegisteredParams,
      ...additionalContactData,
      co_coowner_firstname: coCoOwner.firstName,
      co_coowner_lastname: coCoOwner.lastName,
      co_coowner_maiden_name: coCoOwner.maidenName || null,
      co_coowner_pesel: coCoOwner.pesel,
      co_coowner_sex: coCoOwner.sex,
      co_coowner_birthdate: coCoOwner.birthdate,
      co_coowner_license: coCoOwner?.hasDrivingLicense ? coCoOwner?.licenseDate : null,
      co_coowner_marital_status: coCoOwner.maritalStatus,
      co_coowner_address_registered: coCoOwner.addressRegisteredIdentical,
      // address params
      co_coowner_zip: coCoOwner.address.zipCode,
      co_coowner_city: coCoOwner.address.city ? convertCitiesStreetsToFormat(coCoOwner.address.city) : '',
      co_coowner_district: coCoOwner.address.district,
      // address registered params
      co_coowner_zip_registered: coCoOwner.addressRegisteredIdentical ? coCoOwner.address.zipCode : coCoOwner?.addressRegistered?.zipCode,
      co_coowner_city_registered: convertCitiesStreetsToFormat(coCoOwner.addressRegisteredIdentical ?
        coCoOwner.address.city : coCoOwner?.addressRegistered?.city),
      co_coowner_district_registered: coCoOwner.addressRegisteredIdentical ? coCoOwner.address.district : coCoOwner?.addressRegistered?.district,
    });
  }
  return {};
};

export const setDriver = (driver: IInsurer<TInsurerType> | IInsurerFull<TInsurerType>, isFullSale: boolean): IDaemonDriver => {
  const extendedAddressParams = (isFullSale && isIInsurerFull(driver)) ? {
    driver_street_name: driver.address.street,
    driver_street_number: driver.address.building,
    driver_flat_number: driver.address.flat,
  } : {};
  const extendedAddressRegisteredParams = (isFullSale && isIInsurerFull(driver)) ? {
    driver_street_name_registered: driver.addressRegisteredIdentical ? driver.address.street : driver?.addressRegistered?.street,
    driver_street_number_registered: driver.addressRegisteredIdentical ? driver.address.building : driver?.addressRegistered?.building,
    driver_flat_number_registered: driver.addressRegisteredIdentical ? driver.address.flat : driver?.addressRegistered?.flat,
  } : {};

  return {
    ...extendedAddressParams,
    ...extendedAddressRegisteredParams,
    driver_firstname: driver.firstName,
    driver_lastname: driver.lastName,
    driver_maiden_name: driver.maidenName || null,
    driver_pesel: driver.pesel,
    driver_sex: driver.sex,
    driver_birthdate: driver.birthdate,
    driver_license: driver?.hasDrivingLicense ? driver?.licenseDate : null,
    driver_marital_status: driver.maritalStatus,
    driver_address_registered: driver.addressRegisteredIdentical,
    // address params
    driver_zip: driver.address.zipCode,
    zip_code_car: driver.address.zipCode,
    night_parking_lot: driver.address.zipCode,
    driver_city: convertCitiesStreetsToFormat(driver.address.city),
    city: convertCitiesStreetsToFormat(driver.address.city),
    driver_district: driver.address.district,
    // address registered params
    driver_zip_registered: driver.addressRegisteredIdentical ? driver.address.zipCode : driver?.addressRegistered?.zipCode,
    driver_city_registered: convertCitiesStreetsToFormat(driver.addressRegisteredIdentical ?
      driver.address.city : driver?.addressRegistered?.city),
    driver_district_registered: driver.addressRegisteredIdentical ? driver.address.district : driver?.addressRegistered?.district,
  };
};

export const setYoungPerson = (youngPerson: null | IInsurer<TYoungPerson>): IDaemonYoungPerson | Record<string, never> => {
  if (youngPerson !== null) {
    return ({
      young_person_birthdate: youngPerson.birthdate,
      young_person_age: moment().diff(moment(youngPerson.birthdate), 'years'),
      young_person_license: youngPerson?.hasDrivingLicense && youngPerson.licenseDate ? moment().diff(moment(youngPerson.licenseDate), 'years') : null,
      young_person_license_year: youngPerson?.hasDrivingLicense && youngPerson.licenseDate ? moment(youngPerson.licenseDate, DATE_FORMAT_YYYY_MM_DD).format('YYYY') : null,
      young_person_license_date: youngPerson?.hasDrivingLicense ? youngPerson.licenseDate : null,
    });
  }
  return {};
};

export const setInsurerPerson = (insurer: null | IInsurerFull<TInsurerType>): IDaemonInsurer | Record<string, never> => {
  if (insurer !== null) {
    return ({
      insurer_firstname: insurer.firstName,
      insurer_lastname: insurer.lastName,
      insurer_maiden_name: insurer.maidenName || null,
      insurer_pesel: insurer.pesel,
      insurer_brithdate: insurer.birthdate,
      insurer_sex: insurer.sex,
      // address params
      insurer_city: convertCitiesStreetsToFormat(insurer.address.city),
      insurer_zip: insurer.address?.zipCode,
      insurer_district: insurer.address?.district,
      insurer_street_name: insurer.address?.street,
      insurer_street_number: insurer.address?.building,
      insurer_flat_number: insurer.address?.flat,
      // address registered params
      insurer_zip_registered: insurer.addressRegisteredIdentical ? insurer.address.zipCode : insurer?.addressRegistered?.zipCode,
      insurer_city_registered: convertCitiesStreetsToFormat(insurer.addressRegisteredIdentical ?
        insurer.address.city : insurer?.addressRegistered?.city),
      insurer_district_registered: insurer.addressRegisteredIdentical ? insurer.address.district : insurer?.addressRegistered?.district,
      insurer_street_name_registered: insurer.addressRegisteredIdentical ? insurer.address.street : insurer?.addressRegistered?.street,
      insurer_street_number_registered: insurer.addressRegisteredIdentical ? insurer.address.building : insurer?.addressRegistered?.building,
      insurer_flat_number_registered: insurer.addressRegisteredIdentical ? insurer.address.flat : insurer?.addressRegistered?.flat,
    });
  }

  return {};
};

export const mapInsurerType = (type) => {
  if (type === OWNER) return 'owner';
  if (type === CO_OWNER) return 'co_owner';
  if (type === CO_CO_OWNER) return 'second_co_owner';
  if (type === DRIVER) return 'other';
  if (type === INSURER) return 'insurer';
  return '';
};

export function damageDetail(damaged) {
  if (damaged === 'scratch') {
    return {
      aviva: 'SCRATCH',
      link4: 'body_or_paint',
    };
  }
  if (damaged === 'dent') {
    return {
      aviva: 'DENT',
      link4: 'body_or_paint',
    };
  }
  if (damaged === 'serious') {
    return {
      aviva: 'SERIOUS',
      link4: 'body_or_paint',
    };
  }
  return null;
}

export function setInsurer(insurer) {
  // only Lower Case
  const insurerMapping = {
    allianz: 'allianz',
    aviva: 'aviva',
    'axa direct': 'axa',
    axa: 'axa',
    benefia: 'benefia',
    compensa: 'compensa',
    'ergo hestia': 'hestia',
    euroins: 'euroins',
    gefion: 'gefion',
    generali: 'generali',
    gothaer: 'gothaer',
    link4: 'link4',
    mtu24: 'mtu_stu',
    'mtu 24 ergo hestia': 'mtu',
    'mtu stu ergo hestia': 'mtu_stu',
    proama: 'proama',
    pzu: 'pzu',
    trasti: 'trasti',
    tuz: 'tuz',
    uniqa: 'uniqa',
    warta: 'warta',
    'you can drive': 'ycd',
    inny: 'inny',
  };

  if (insurer && insurer !== '') {
    if (Object.keys(insurerMapping).includes(insurer.toLowerCase())) {
      return insurerMapping[insurer.toLowerCase()];
    }
    return insurer.toLowerCase();
  }
  return null;
}

export const setLastInsurer = (lastInsurerName, lastInsurerId) => {
  if (lastInsurerName) return setInsurer(lastInsurerName);
  if (lastInsurerId) {
    const lastInsurerList = JSON.parse(localStorage.getItem(createUrlInsurersToShow()) || '');
    if (!lastInsurerList) {
      return null;
    }
    return lastInsurerList[lastInsurerId] ? setInsurer(lastInsurerList[lastInsurerId]) : null;
  }
  return null;
};

const setInsuranceHistoryParams = (payload: any = {}, role) => {
  if (payload === null) return {};
  const { ocInsuranceHistory, acInsuranceHistory } = payload;
  const acInsuranceHistoryPayload = payload?.insuranceHistorySame ? ocInsuranceHistory : acInsuranceHistory;
  return payload ? {
    // oc
    [`${role}_years_buying_oc`]: ocInsuranceHistory?.yearsBuying || 0,
    [`${role}_damages_paid_from_policies`]: ocInsuranceHistory?.damagesPaid || 0,
    [`${role}_first_damage`]: ocInsuranceHistory?.firstDamage || '',
    [`${role}_second_damage`]: ocInsuranceHistory?.secondDamage || '',
    [`${role}_third_damage`]: ocInsuranceHistory?.thirdDamage || '',
    [`${role}_fourth_damage`]: ocInsuranceHistory?.fourthDamage || '',
    [`${role}_fifth_damage`]: ocInsuranceHistory?.fifthDamage || '',
    // AFTER REFACTOR DEMON WE WILL NOT TO SEND AC IF FLAG TRUE
    // ac
    [`${role}_years_buying_ac`]: acInsuranceHistoryPayload?.yearsBuying || 0,
    [`${role}_damages_paid_from_policies_ac`]: acInsuranceHistoryPayload?.damagesPaid || 0,
    [`${role}_first_damage_ac`]: acInsuranceHistoryPayload?.firstDamage || '',
    [`${role}_second_damage_ac`]: acInsuranceHistoryPayload?.secondDamage || '',
    [`${role}_third_damage_ac`]: acInsuranceHistoryPayload?.thirdDamage || '',
    [`${role}_fourth_damage_ac`]: acInsuranceHistoryPayload?.fourthDamage || '',
    [`${role}_fifth_damage_ac`]: acInsuranceHistoryPayload?.fifthDamage || '',
    // historySame flag
    [`${role}_the_same_ac`]: payload?.insuranceHistorySame,
  } : {};
};

export const setModCovers = ({
  name,
  covers,
  ac_ins_variant,
  ac_ins_depreciation,
  as_ins_tow_distance,
  as_ins_variant,
  ac_ins_contribution,
  ac_ins_contribution_value,
  ac_ins_damage_settlement,
  ac_ins_consumption,
  ac_ins_extension,
  as_ins_rep_vehicle_days,
  as_ins_sum,
  nnw_ins_sum,
  nnw_insurance_sum,
  glass_ins,
  tires_ins,
  mini_ac,
  ac_aso,
  ac_ins_underwriter_excess,
  nnw_covered_persons,
}: IRootState['offerDetails']) => {
  const modParams = {
    ac_insurance: covers.ac,
    ac_ins_variant: ac_ins_variant || null,
    ac_ins_depreciation: ac_ins_depreciation || null,
    as_insurance: covers.as,
    nnw_insurance: covers.nnw,
    glass_ins: glass_ins || covers.glass,
    tires_ins: tires_ins || covers.tires,
    as_ins_tow_distance: as_ins_tow_distance || null,
    as_ins_variant: as_ins_variant || null,
    ac_ins_contribution: ac_ins_contribution || null,
    ac_ins_contribution_value: (ac_ins_contribution_value || ac_ins_contribution_value === 0) ? ac_ins_contribution_value : null,
    ac_ins_damage_settlement: ac_ins_damage_settlement || null,
    ac_ins_consumption: ac_ins_consumption || null,
    ac_ins_extension: ac_ins_extension || null,
    as_ins_rep_vehicle_days: as_ins_rep_vehicle_days || null,
    as_ins_sum: as_ins_sum || null,
    nnw_ins_sum: nnw_ins_sum || nnw_insurance_sum || null,
    mini_ac: mini_ac || null,
    ac_aso: ac_aso === false ? ac_aso : null,
    nnw_covered_persons: nnw_covered_persons || null,
  };
  if (name === 'aviva') {
    (modParams as any).ac_ins_contribution_type = ac_ins_underwriter_excess ? 'underwriter-excess' : 'excess';
  }
  return modParams;
};

const setStepTwoFs = ({
  partnerName, partnerAgreements, stepOneFs, stepTwoFs, offer, eurotaxVersionType,
}) => {
  const { ac_mini_risk: acMiniRisk, covers } = offer;
  const {
    carKeysNumber, carSecurity, damagesDetails,
    holdKeysNumberExtra, wasModified, hasServiceHistory,
  } = stepOneFs;
  const {
    installments, paymentType, userPartnerAgreements, installmentsDetails,
  } = stepTwoFs;
  // remap partner agreements
  const agreements = {};
  const agreementsWithCollectingStage = {};

  // Remove text agreements
  const filteredPartnerAgreements = filterTextAgreements(partnerAgreements);

  if (filteredPartnerAgreements && userPartnerAgreements) {
    Object.keys(filteredPartnerAgreements).forEach((o1) => {
      if (filteredPartnerAgreements[o1].collectingStage) {
        agreementsWithCollectingStage[filteredPartnerAgreements[o1].externalId] = filteredPartnerAgreements[o1].collectingStage;
      }
      Object.keys(userPartnerAgreements).forEach((o2) => {
        if (parseInt(partnerAgreements[o1].name, 10) === parseInt(o2, 10) && partnerAgreements[o1].externalId) {
          const value = setHiddenPartnerAgreements(partnerName, filteredPartnerAgreements)
            .includes(filteredPartnerAgreements[o1].name) ? true : userPartnerAgreements[o2];
          agreements[filteredPartnerAgreements[o1].externalId] = value;
        }
      });
    });
  }

  const additionalKeys: any = {};

  if (covers?.ac && (partnerName === 'generali' || partnerName === 'proama')) {
    additionalKeys.car_protected = carSecurity;
    additionalKeys.damage_detail = { [partnerName]: damagesDetails };
    additionalKeys.hold_keys_number = carKeysNumber;
  }

  if (partnerName === 'link4' && eurotaxVersionType && eurotaxVersionType === 30) {
    additionalKeys.hasServiceHistory = hasServiceHistory;
    additionalKeys.wasModified = wasModified;
  }

  if ((acMiniRisk || covers?.ac) && partnerName === 'tuz') {
    additionalKeys.car_protected = acMiniRisk ? null : carSecurity;
    additionalKeys.hold_keys_number = carKeysNumber;
    additionalKeys.hold_keys_number_extra = holdKeysNumberExtra;
  }

  return {
    installments_no: installments,
    installments: installmentsDetails,
    payment_type: paymentType,
    agreements,
    agreementsWithCollectingStage,
    data_origin: 'full',
    ...additionalKeys,
  };
};

const insertSpaces = (phoneN) => {
  let newPhone = phoneN.replace(/\s/g, '');
  const addOneSpace = (idx, phone) => [phone.slice(0, idx), ' ', phone.slice(idx)].join('');
  const insertSpace = (indexes) => {
    const usedIndexes = [...indexes];
    if (usedIndexes.length > 0) {
      const currentIndex = usedIndexes.pop();
      newPhone = addOneSpace(currentIndex, newPhone);
      insertSpace(usedIndexes);
    }
  };
  insertSpace([2, 5, 7]);

  return newPhone;
};

export const mapStateToDataSendByWS = (
  state: Pick<IRootState, 'user' | 'payload' | 'offerDetails'> & Partial<Pick<IRootState, 'activeFullSaleInsurer' | 'partnerAgreements' | 'email' | 'step'>>,
  additionalParams = {},
) => {
  const {
    offerDetails,
    activeFullSaleInsurer: partnerName,
    partnerAgreements,
    user,
    payload: {
      stepOne: {
        ac,
        nnw,
        protectionBegins,
        vehicle: {
          productionYear,
          lpg,
          eurotaxVersionCode,
          eurotaxVersionType,
          infoexVersionCode,
        },
      },
      stepThree: {
        mainDriver, coowners, owner, coOwner, coCoOwner, youngPerson,
      },
      stepTwo,
      stepThree,
      stepFour: {
        email,
        ownerInsuranceHistory,
        coOwnerInsuranceHistory,
        coCoOwnerInsuranceHistory,
        driverInsuranceHistory,
      },
      stepOneFs,
      stepOneFs: {
        owner: ownerFromFs,
        coOwner: coOwnerFromFs,
        coCoOwner: coCoOwnerFromFs,
        company,
        insurerPerson,
        phoneFromFs,
        emailFromFs,
        phoneFromFsAdditional,
        emailFromFsAdditional,
      },
      stepTwoFs,
    },
  } = state;

  const isFullSaleStepOne = window?.location?.pathname?.indexOf(ROUTING_FULL_SALE_PERSONAL_DATA) > -1;
  const isFullSaleStepTwo = window?.location?.pathname?.indexOf(ROUTING_FULL_SALE_VEHICLE_DATA) > -1;
  const isFullSale = isFullSaleStepOne || isFullSaleStepTwo;
  const isFullSaleFilled = isFullSale && !!stepOneFs?.insurerPerson;

  const step1 = {
    source: 'porowneo-form',
    protect_begins: protectionBegins,
    protect_ends: moment(protectionBegins, 'DD-MM-YYYY').add(1, 'years').subtract(1, 'days').format('DD-MM-YYYY'),
    cover_type: `OC${ac ? ' + AC' : ''}${nnw ? ' + NNW' : ''}`,
    oc: true,
    ac,
    nnw,
    phone: `+48${state.user.phoneNumber.replace(/\s/g, '')}`,
    phoneHuman: insertSpaces(state.user.phoneNumber),
    contact_firstname: state.user.name,
    contact_lastname: state.user.surname,
    // manual
    seat_number: 5,
    // must send
    car_id: eurotaxVersionCode && productionYear ? `${eurotaxVersionCode}-${productionYear}` : null,
    car_infoexp_id: infoexVersionCode,
    gpl: lpg ? 'tak' : 'nie',
    car_annee: productionYear,
  };

  const step2 = {
    usage: stepTwo.carUsage,
    actual_km: stepTwo.actualKm,
    mileage: parseInt(stepTwo.mileageEstimator, 10),
    which_owner_in_order: stepTwo.whichOwner,
    first_country: stepTwo.firstCountry,
    right_wheel: stepTwo.rightWheel,
    night: stepTwo.night,
    abroad: stepTwo.abroad,
    damage: stepTwo.damage,
    damage_detail: stepTwo.damage ? damageDetail(stepTwo.damageDetail) : null,
    plate: stepTwo.plate || 'XXX1234', // check in demon XXX1234
    last_insurer: setLastInsurer(stepTwo.lastInsurerName, stepTwo.lastInsurerOc) || null,
    car_registered_on: stepTwo.carRegisteredOn || null,
    first_immat: convertDateFormat(stepTwo.firstImmat, DATE_FORMAT_YYYY_MM_DD, DATE_FORMAT),
    date_owner_registered: convertDateFormat(stepTwo.whichOwner === 'first' ? stepTwo.firstImmat : stepTwo.ownerRegisteredDate, DATE_FORMAT_YYYY_MM_DD, DATE_FORMAT),
    year_owner_registered: stepTwo.yearOwnerRegistered,
  };

  const driverPayload = !isFullSaleFilled ? stepThree[mainDriver] : stepOneFs[mainDriver];

  const step3 = {
    co_owners: coowners === 0 ? 'no' : (coowners === 1 ? 'one' : 'two'),
    main_driver: mapInsurerType(mainDriver),
    ownership: coowners > 0,
    driver_under_26: stepThree.driverUnder26,
    has_children_under_26: stepThree.hasChildrenU26,
    has_children: stepThree.numberOfChilds && stepThree.numberOfChilds > 0 ? 'tak' : 'nie',
    number_of_child: stepThree.numberOfChilds || 0,
    age_of_older_child: stepThree.ageOldestChild,
    ...setOwner(
      !isFullSaleFilled ? owner : ownerFromFs,
      !isFullSaleFilled ? {} : { phone: phoneFromFs, email: emailFromFs },
      isFullSaleFilled,
    ),
    ...setCoOwner(
      !isFullSaleFilled ? coOwner : coOwnerFromFs,
      isFullSaleFilled && mainDriver === 'coOwner' ? {
        phone: phoneFromFsAdditional,
        email: emailFromFsAdditional,
      } : {},
      isFullSaleFilled,
    ),
    ...setCoCoOwner(
      !isFullSaleFilled ? coCoOwner : coCoOwnerFromFs,
      isFullSaleFilled && mainDriver === 'coCoOwner' ? {
        phone: phoneFromFsAdditional,
        email: emailFromFsAdditional,
      } : {},
      isFullSaleFilled,
    ),
    ...(mainDriver !== '' ? setDriver(driverPayload, isFullSaleFilled) : {}),
    ...setYoungPerson(youngPerson),
  };

  const step4 = {
    ...setInsuranceHistoryParams(ownerInsuranceHistory, 'owner'),
    ...setInsuranceHistoryParams(coOwnerInsuranceHistory, 'co_owner'),
    ...setInsuranceHistoryParams(coCoOwnerInsuranceHistory, 'co_co_owner'),
    ...setInsuranceHistoryParams(driverInsuranceHistory, 'driver'),
    email: email || '',
  };

  // send this data when step is filled - based on chosen insurerPerson
  const step1Fs = isFullSaleFilled ? {
    ...(insurerPerson !== '' ? setInsurerPerson(stepOneFs[insurerPerson]) : {}),
    insurer_country: 'Polska',
    insurer_citizenship: 'polskie',
    insurer_person_phone: stepOneFs?.phoneFromFs?.replace(/\s/g, '') || '',
    insurer_person_email: stepOneFs?.emailFromFs || '',
    insurer_person: mapInsurerType(stepOneFs?.insurerPerson),
    first_immat_in_poland: stepOneFs?.firstImmatInPoland || '',
    technical_inspection_date: stepOneFs?.technicalInspectionDate || '',
    from_abroad_last_year: stepOneFs?.fromAbroadLastYear ? 'yes' : 'no',
    data_origin: 'full',
    vin_number: stepOneFs.vinNumber,
    plate: stepOneFs.carRegistrationNumber,
    soleTraderNip: company.nip || null,
    soleTraderVat: company.nip && company.vatType ? company.vatType : null,
  } : {};

  const step2Fs = isFullSaleStepTwo ? setStepTwoFs({
    partnerName,
    partnerAgreements,
    stepOneFs,
    stepTwoFs,
    offer: offerDetails,
    eurotaxVersionType,
  }) : {};

  const tuzModifications = offerDetails.name === 'tuz' ? {
    priority: 0,
    skipCalculationRegistration: !isFullSale,
  } : {};

  const partnersParams = isFullSale ? {
    partners_params: {
      [offerDetails.name]: {
        ...setModCovers(offerDetails),
        installments_no: (additionalParams as any)?.numberOfInstallments || 1,
        ...tuzModifications,
      },
    },
  } : {};

  const others = {
    calculation_type: 'default',
    multipriced: true,
    underage: 0,
    owner_marital: 'żonaty',
    locator_full: state.user.locator,
    carProjectId: state.payload.projectId,
    carProjectVersionId: state.payload.carProjectVersionId,
    process: getProcessName(user?.businessProcess),
  };

  const dataSendToApi = {
    ...step1,
    ...step2,
    ...step3,
    ...step4,
    ...step1Fs,
    ...step2Fs,
    ...partnersParams,
    ...others,
  };

  return { ...dataSendToApi };
};
