import moment from 'moment';

import { createUrlGetCarData } from '../config/url';
import { promoCodes } from '../config/main';

import { getPropertyFromLocalStorageById } from './StoreUtils';
import { getCookieByName } from './FormUtils';

import type { IUserInsuranceHistoryQuote, TDamagesPaid } from '../api/carProject/CarProjectFields.types';
import type {
  IInsurer, IInsurerHistoryPayload, TInsurerType, TInsurerBasePerson,
} from '../types/Insurer.type';
import type { IPayload } from '../reducers/Payload';
import type { IUser, ISale } from '../reducers/index';
import type { TCarProjectQuote } from '../api/carProject/CarProject.types';

export const mappingSendToTransientData = ({
  step,
  user: {
    phoneNumber,
    sessionId,
  },
  payload: {
    projectId,
    ktApiProjectId,
    stepOne,
    stepTwo,
    stepThree,
    stepFour,
    stepOneFs,
    mgm,
    locator,
  },
  sale,
}: {
  step: number,
  user: Pick<IUser, 'phoneNumber' | 'sessionId'>
  payload: IPayload,
  sale: ISale[],
}): TCarProjectQuote => {
  const setPhoneNumber = () => {
    const phone = stepOneFs.isFullSale ? stepOneFs?.phoneFromFs : phoneNumber;
    return phone?.replace(/\s+/g, '') || '';
  };

  const {
    productionYear, eurotaxVersion, model, fuel, transmission, eurotaxVersionCode, eurotaxVersionType, brand,
  } = stepOne.vehicle || {};

  const brandName = getPropertyFromLocalStorageById(
    createUrlGetCarData({
      type: 'eurotaxVersion', productionYear, model, fuel, transmission,
    }),
    eurotaxVersion || 0,
    'brandName',
  );

  const isFullSaleFilled = stepOneFs?.insurerPerson;

  const nullifySexIfInvalid = <T extends TInsurerType>(ins: IInsurer<T> | null) => (ins ? {
    ...ins,
    sex: ins.sex || null,
  } : null);

  const getInsurerFromStep = <T extends TInsurerType & (keyof typeof stepOneFs | keyof typeof stepThree)>(ins: T, isFS: string) => (
    isFS ? stepOneFs?.[ins as T & keyof typeof stepOneFs] : stepThree?.[ins as T & keyof typeof stepThree]
  ) || null;

  const getYearsBuying = (yearsBuying?: string | number | null) => (typeof yearsBuying === 'number' ? yearsBuying : parseInt(yearsBuying || '', 10) || null);

  const parseYearsBuying = <T extends TInsurerBasePerson>(history: IInsurerHistoryPayload<T> | null): IUserInsuranceHistoryQuote | null => (history ? {
    ...history,
    ocInsuranceHistory: {
      ...history.ocInsuranceHistory,
      yearsBuying: getYearsBuying(history.ocInsuranceHistory.yearsBuying),
      damagesPaid: typeof history.ocInsuranceHistory?.damagesPaid === 'string' ?
        (parseInt(history.ocInsuranceHistory?.damagesPaid || '', 10) as TDamagesPaid) || null :
        history.ocInsuranceHistory?.damagesPaid || null,
    },
    acInsuranceHistory: (history.insuranceHistorySame || !history.acInsuranceHistory) ? null : {
      ...history.acInsuranceHistory,
      type: 'ac',
      yearsBuying: getYearsBuying(history.acInsuranceHistory?.yearsBuying),
      damagesPaid: typeof history.acInsuranceHistory?.damagesPaid === 'string' ?
        (parseInt(history.acInsuranceHistory?.damagesPaid || '', 10) as TDamagesPaid) || null :
        history.acInsuranceHistory?.damagesPaid || null,
    },
  } : null);

  const isMgmCodeValid = (mgmObj: typeof mgm): mgmObj is Exclude<TCarProjectQuote['mgm'], null> => (
    !!mgm.code && !!mgm.status &&
    (promoCodes.discount.includes(mgm.code as typeof promoCodes.discount[number]) || promoCodes.influ.includes(mgm.code as typeof promoCodes.influ[number]))
  );

  if (projectId === null) {
    throw new Error('Cannot save payload in redis with empty CarProject ID');
  }

  if (productionYear === null || eurotaxVersion === null || eurotaxVersionCode === null || eurotaxVersionType === null || brand === null) {
    throw new Error('Cannot save payload in redis with invalid step one vehicle data');
  }

  return {
    ktApiProjectId,
    carProjectId: projectId,
    // step 1
    vehicle: {
      ...stepOne.vehicle,
      productionYear,
      eurotaxVersion,
      eurotaxVersionCode,
      eurotaxVersionType,
      brand,
      brandName: typeof brandName === 'string' ? brandName : undefined,
    },
    // step 2
    carUsage: stepTwo.carUsage,
    actualKm: stepTwo.actualKm,
    mileageEstimator: stepTwo.mileageEstimator || null,
    whichOwner: stepTwo.whichOwner || null,
    firstCountry: stepTwo.firstCountry || null,
    rightWheel: stepTwo.rightWheel,
    night: stepTwo.night || null,
    abroad: stepTwo.abroad,
    damage: stepTwo.damage,
    damageDetail: stepTwo.damageDetail || null,
    plate: !isFullSaleFilled ? (stepTwo.plate || '') : stepOneFs?.carRegistrationNumber,
    lastInsurerOc: stepTwo.lastInsurerOc || null,
    lastInsurerAc: stepTwo.lastInsurerAc || null,
    carRegisteredOn: stepTwo.carRegisteredOn || null,
    firstImmat: stepTwo.firstImmat || '',
    ownerRegisteredDate: stepTwo.whichOwner === 'first' ? stepTwo.firstImmat : stepTwo.ownerRegisteredDate,
    yearOwnerRegistered: stepTwo.yearOwnerRegistered,
    modification: false,
    regInPl: stepTwo.firstCountry === 'pol',
    modDetail: null,
    mgm: isMgmCodeValid(mgm) ? mgm : null,
    // step 3
    owner: nullifySexIfInvalid(getInsurerFromStep('owner', isFullSaleFilled)),
    coOwner: [1, 2].includes(stepThree.coowners) ? (nullifySexIfInvalid(getInsurerFromStep('coOwner', isFullSaleFilled))) : null,
    coCoOwner: stepThree.coowners === 2 ? (nullifySexIfInvalid(getInsurerFromStep('coCoOwner', isFullSaleFilled))) : null,
    driver: stepThree.mainDriver === 'driver' ? (nullifySexIfInvalid(getInsurerFromStep('driver', isFullSaleFilled))) : null,
    insurer: nullifySexIfInvalid(getInsurerFromStep('insurer', isFullSaleFilled)),
    youngPerson: stepThree.driverUnder26 ? nullifySexIfInvalid(stepThree.youngPerson) : null,
    driverUnder26: stepThree.driverUnder26 || false,
    coowners: stepThree.coowners,
    mainDriver: stepThree.mainDriver || 'owner',
    numberOfChilds: stepThree.numberOfChilds,
    hasChildrenU26: stepThree.hasChildrenU26 || false,
    ageOldestChild: stepThree.ageOldestChild,
    // step 4
    protectionBegins: stepOne.protectionBegins,
    ac: stepOne.ac,
    nnw: stepOne.nnw,
    ownerInsuranceHistory: parseYearsBuying(stepFour.ownerInsuranceHistory),
    coOwnerInsuranceHistory: parseYearsBuying(stepFour.coOwnerInsuranceHistory),
    coCoOwnerInsuranceHistory: parseYearsBuying(stepFour.coCoOwnerInsuranceHistory),
    driverInsuranceHistory: parseYearsBuying(stepFour.driverInsuranceHistory),
    // step 5 or fs - we update this info on fs
    contact: {
      type: 'contact',
      userId: null,
      pesel: null,
      birthdate: null,
      sex: null,
      licenseDate: null,
      maritalStatus: null,
      address: null,
      addressRegistered: null,
      addressRegisteredIdentical: null,
      phone: setPhoneNumber(),
      email: (stepOneFs.isFullSale ? stepOneFs.emailFromFs : stepFour.email) || null,
      firstName: isFullSaleFilled ? stepOneFs[stepOneFs?.insurerPerson]?.firstName : null,
      lastName: isFullSaleFilled ? stepOneFs[stepOneFs?.insurerPerson]?.lastName : null,
    },
    current_step: step,
    sessionId,
    created_at: moment().format('YYYY-MM-DD'),
    householdCars: null,
    vin: stepOneFs.vinNumber || null,
    carKeysNumber: stepOneFs.carKeysNumber,
    carSecurity: stepOneFs.carSecurity?.toString() || '',
    dataOrigin: 'www2',
    hasChildren: stepThree.hasChildren,
    hasServiceHistory: (stepOneFs.hasServiceHistory && stepOneFs.hasServiceHistory === 'yes') || false,
    insurerUserId: stepOneFs.insurerUserId,
    locatorFull: locator,
    wasModified: (stepOneFs.wasModified && stepOneFs.wasModified === 'yes') || false,
    proposal: null,
    damageDetails: null,
    ga: getCookieByName('_ga'),
    sale,
  };
};

export default mappingSendToTransientData;
