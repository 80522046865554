import { Component } from 'react';
import type { ComponentType } from 'react';

import { sendError } from 'porowneo-ui';
import { debug } from 'loglevel';

import * as ACTIONS from '../../actions/index';
import { requiredAgreements } from '../../config/main';
import { saveAgreements } from '../../helpers/FormUtils';

import type { IUser } from '../../reducers';
import type { TAgreement } from 'porowneo-ui';
import type { Dispatch } from 'redux';
import type { IContactData } from '../../types/ContactData.type';
import type { IPersonalData } from '../../types/PersonalData.type';

interface IAgreementsHOC {
  dispatch: Dispatch,
  user: IUser
  email: IContactData['email']
  userAgreements: IUser['userAgreements']
  agreements: TAgreement<'api'>[] | TAgreement<'kt_api'>[],
  neededAgreements: string[]
  ownerPesel?: IPersonalData['pesel']
}

type TAgreementSendParams = Pick<IUser, 'name' | 'surname' | 'sessionId' | 'phoneLeaveContact'>

export interface IWithAgreementsHOCProps {
  setDefaultLeadUpdate: () => void
  sendAgreements: (params: TAgreementSendParams) => void
  requiredAgreements: typeof requiredAgreements
}

export type TAgreementsHOCProps = IAgreementsHOC & IWithAgreementsHOCProps;

const agreementsHoc = <P extends IWithAgreementsHOCProps>(FormContainer: ComponentType<P>) => (
  class extends Component<Omit<P & TAgreementsHOCProps, keyof IWithAgreementsHOCProps>> {
  setDefaultLeadUpdate = () => {
    const { dispatch } = this.props;
    dispatch(ACTIONS.setStatusLeadUpdate(''));
  }

  setLeadUpdateError = (error: unknown) => {
    const { dispatch } = this.props;
    dispatch(ACTIONS.setStatusLeadUpdate('failed'));
    debug('Agreements saving failed', error);
    sendError(error, false, {
      method: 'sendAgreements',
      component: 'agreementsHoc',
    });
  }

  sendAgreements = ({
    name,
    surname,
    sessionId,
    phoneLeaveContact,
  }: TAgreementSendParams) => {
    const {
      ownerPesel,
      agreements,
      userAgreements,
      neededAgreements,
      email,
      user,
    } = this.props;

    const agrementsInfo = {
      phone: phoneLeaveContact.replace(/\s+/g, ''),
      first_name: name,
      last_name: surname,
      pesel: ownerPesel,
      mail: email,
    };
    saveAgreements({
      agreements,
      userAgreements,
      sessionId,
      agrementsInfo,
      sendTheseAgreements: neededAgreements,
      locatorFull: user?.locator,
    }).catch(this.setLeadUpdateError);
  }

  override render() {
    const {
      props,
      setDefaultLeadUpdate,
      sendAgreements,
    } = this;

    return (
      <FormContainer
        {...props as P}
        setDefaultLeadUpdate={setDefaultLeadUpdate}
        sendAgreements={sendAgreements}
        requiredAgreements={requiredAgreements}
      />
    );
  }
  });

agreementsHoc.defaultProps = {
  ownerPesel: '',
};

export default agreementsHoc;
