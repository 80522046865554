import {
  btnSimple, getButtonBorderRadius, VARS, btnPrimary, btnPrimaryHover,
} from 'porowneo-ui';
import styled from 'styled-components';

export const LinkButton = styled.a`
  font-size: ${VARS.fontSize};
  min-width: 76px;
  min-height: 3rem;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 0.875rem 1.5rem 0.675rem 1.5rem;
  border-radius: ${getButtonBorderRadius};
  transition: all 0.15s ease-in-out;
  font-style: normal;
  border: 1px solid ${props => btnSimple('border', props)};
  color: ${props => btnSimple('color', props)};
  background-color: ${props => btnSimple('background', props)};
  outline: 0;
  cursor: pointer;
  margin: 0 1rem 0 0;
  text-decoration: none;
  text-transform: ${({ theme }) => theme?.button?.buttonPrimary?.textTransform || 'inherit'};
  color: ${props => btnPrimary('color', props)};
  background-color: ${props => btnPrimary('background', props)};
  border: 1px solid ${props => btnPrimary('border', props)};

  &:hover {
    color: ${props => btnPrimaryHover('color', props)};
    background-color: ${props => btnPrimaryHover('background', props)};
    border: 1px solid ${props => btnPrimaryHover('border', props)};
  }
`;
LinkButton.displayName = 'LinkButton';
