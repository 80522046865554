import type { IInsurerFull, TInsurerType } from '../../types/Insurer.type';

export const isIInsurerFull = (insurer: unknown): insurer is IInsurerFull<TInsurerType> => {
  if (insurer !== null) {
    const keys: string[] = Object.keys((insurer as IInsurerFull<TInsurerType>)?.address);

    return (keys.length === 7 && keys.every(key => ['type', 'zipCode', 'city', 'district', 'street', 'flat', 'building'].includes(key)));
  }
  return false;
};
