import { Component } from 'react';
import * as React from 'react';

import log from 'loglevel';
import size from 'lodash/size';
import { sendError } from 'porowneo-ui';

interface IErrorBoundaryProps {
  customErrorMessage?: string;
  children: React.ReactChildren | any;
}
interface IErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, errorMessage: error };
  }

  // eslint-disable-next-line class-methods-use-this
  override componentDidCatch(error: Error, info: React.ErrorInfo) {
    const { errorMessage } = this.state;
    const { customErrorMessage } = this.props;
    const customErrorMsg = customErrorMessage?.length ? { customErrorMessage } : {};
    const errorMsg = errorMessage ? { errorMessage: errorMessage?.toString() } : {};
    const customData = size(customErrorMsg) || size(errorMsg) ?
      {
        ...customErrorMsg,
        ...errorMsg,
      } : false;
    sendError(
      error,
      ['ErrorBoundary'],
      customData,
    );
    log.warn(info);
  }

  override render() {
    const { hasError, errorMessage } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <p className="text-red">{errorMessage ? errorMessage.toString() : ''}</p>;
    }

    return children;
  }
}

export default ErrorBoundary;
