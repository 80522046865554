import { useContext } from 'react';

import styled, { ThemeContext } from 'styled-components';
import { above, VARS } from 'porowneo-ui';

import { ReactComponent as Savings } from '../../assets/icon/savings.svg';
import { ReactComponent as Protected } from '../../assets/icon/protected.svg';
import { ReactComponent as Join } from '../../assets/icon/join.svg';
import { isAionTheme } from '../../helpers/setupTheme';
import {
  AppContainer, MainSection, MainSectionWrapper,
} from '../../styled/gridHelpers';

export const ADVANTAGES_CONFIG = [
  {
    image: Savings,
    text: 'Oszczędzisz nawet do 50% kosztów OC/AC',
  },
  {
    image: Protected,
    text: 'Twoje dane są u nas bezpieczne',
  },
  {
    image: Join,
    text: 'Dołącz do 300 tys. osób, które porównały najlepsze oferty ubezpieczeń',
  },
] as const;

const AdvatnatagesWrapper = styled.div`
  display: none;

  ${above(VARS.breakpoints.lg)} {
    display: flex;
    justify-content: space-around;
    margin-top: 0;
    max-width: 832px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Advantage = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 208px;
  text-align: center;
`;

const AdvantageImageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const AdvantageText = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
`;

export const Advantages = ({ currentStep } : { currentStep: number }): JSX.Element => {
  const { id } = useContext(ThemeContext) || {};

  return (
    <>
      {!isAionTheme(id) && currentStep === 1 && (
        <AppContainer>
          <div className="row">
            <div className="col-xl-9 col-sm-12">
              <MainSectionWrapper>
                <MainSection>
                  <AdvatnatagesWrapper>
                    {
                      ADVANTAGES_CONFIG.map(({
                        image,
                        text,
                      }) => {
                        const AdvantageImage = image;
                        return (
                          <Advantage
                            key={text}
                          >
                            <AdvantageImageWrapper>
                              <AdvantageImage />
                            </AdvantageImageWrapper>
                            <AdvantageText>{text}</AdvantageText>
                          </Advantage>
                        );
                      })
                    }
                  </AdvatnatagesWrapper>
                </MainSection>
              </MainSectionWrapper>
            </div>
          </div>
        </AppContainer>
      )}
    </>
  );
};
