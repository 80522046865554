export const fullSaleNeededAgreements = ['MARK_TEL', 'MARK_MAIL'];
export const fullSaleModalAgreements = ['MARK_TEL'];

export const disclaimerAcBenefia = 'COMPENSA Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group zastrzega sobie prawo do wykonania oględzin ubezpieczanego pojazdu. Oględziny wykonywane są losowo, przeważnie w ciągu kliku dni od daty zawarcia umowy ubezpieczenia. Jeżeli odmówisz przedstawienia pojazdu do oględzin, COMPENSA Towarzystwo Ubezpieczeń S.A. Vienna Insurance Group ma prawo odstąpić od zawarcia umowy ubezpieczenia Autocasco AC.';

export const disclaimerAcAviva = 'Informujemy, że Aviva może zlecić wykonanie oględzin Pana/Pani pojazdu w trakcie całego okresu ubezpieczenia. W takim przypadku Aviva skontaktuje się z Panem/ią wysyłając SMS z instrukcjami dalszego postępowania.';

export const disclaimerAcUniqa = 'Po opłaceniu polisy Towarzystwo zastrzega sobie możliwość wykonania zdjęć Twojego pojazdu oraz dokumentów (min. dowodu rejestracyjnego). Dla Twojej wygody inspekcja może odbyć się mobilnie.';

export const disclaimerAcEuroins = 'Warunkiem objęcia ochroną ubezpieczenia w zakresie EUROINS Autocasco jest przeprowadzenie inspekcji pojazdu. W tym celu skontaktuje się z Panem/Panią pracownik firmy zewnętrznej, który wykona inspekcję online za pośrednictwem Pana/Pani smartfona. Niezbędna jest w tym celu instalacja przez Pana/Panią na swoim smartfonie aplikacji „Oględziny Online” dostępnej w sklepie Google Play, AppStore lub AppGallery oraz uruchomienia jej w trakcie wykonywania oględzin. Osoba dzwoniąca w celu przeprowadzenia inspekcji sama przeprowadzi inspekcję i poinstruuje Pana/Panią w zakresie szczegółów.';
