import { companyPhoneNumber, aionPhoneNumber, renPhoneNumber } from '../config/main';

import { isAionTheme } from './setupTheme';
import { themeName } from './Envutils';
import { isRen } from './FormUtils';

export type TPhoneNumber = typeof renPhoneNumber | typeof aionPhoneNumber | typeof companyPhoneNumber;

export const getPhoneNumber = (): TPhoneNumber => {
  if (isRen()) { return renPhoneNumber; }
  if (isAionTheme(themeName)) { return aionPhoneNumber; }
  return companyPhoneNumber;
};

// Changes only based on env (build-time), lets return a static value
export const phoneNumber = getPhoneNumber();
