import { ROUTING_BASE, ROUTING_ROP, ROUTING_REN } from '../../config/Router.config';
import { isRopRen } from '../FormUtils';

import type { IUser } from '../../reducers/index';

export const getRopRenRoute = (process: 'ROP' | 'REN'): typeof ROUTING_ROP | typeof ROUTING_REN => (process === 'ROP' ? ROUTING_ROP : ROUTING_REN);

export const createNavLink = (
  destPath: string,
  { locator, sessionId, businessProcess }: Pick<IUser, 'locator' | 'sessionId' | 'businessProcess'>,
): string => {
  const path = !destPath.includes(ROUTING_BASE) ? `${ROUTING_BASE}/${destPath}` : destPath;

  if (isRopRen(businessProcess) && locator && sessionId) {
    const procesPath = getRopRenRoute(businessProcess);
    const newPath = destPath.includes(procesPath) ? path : `${path}/${procesPath}`;

    return `${newPath}?l=${locator}&s=${sessionId}`;
  }

  return path;
};

export const getHrefAndQueryString = (baseUrl: string): [string, string] => {
  if (baseUrl?.includes('?')) {
    const [href, queryString] = baseUrl.split('?');
    if (typeof href === 'string' && typeof queryString === 'string') {
      return [href, queryString];
    }
  }
  return [baseUrl, ''];
};
