import PropTypes from 'prop-types';
import {
  ValidationHoc,
} from 'porowneo-ui';
import { Button } from 'porowneo-design';

import { createUrlGetCarData } from '../../../config/url';
import { BaseCarDataSection } from '../../../views/stepOne/components/baseCarDataSection/BaseCarDataSection';
import { makeCarDictionaryLabel } from '../../../views/stepOne/utils/DataUtils';
import { getPropertyFromLocalStorageById } from '../../../helpers/StoreUtils';
import { InfoHeader, InfoData } from '../Modal.styled';
import { Spacer } from '../../../styled/Spacer';

const InfoExpert = ({
  triggerValidation,
  handleChange,
  filters,
  status,
  vehicle: {
    productionYear,
    brand,
    model,
    fuel,
    transmission,
    eurotaxVersion,
    infoexVersion,
  },
  locator,
  sessionId,
  handleSetValidation,
}) => {
  const {
    brandName,
    modelGroup,
    name,
    hp,
    body,
    doors,
    capacity,
  } = getPropertyFromLocalStorageById(
    createUrlGetCarData({
      type: 'eurotaxVersion', productionYear, model, fuel, transmission,
    }),
    eurotaxVersion,
  ) as Record<string, string>;

  return (
    <>
      <p className="wrapper__section-title-smal">
        <InfoHeader>
          Marka i model:
          {' '}
        </InfoHeader>
        <InfoData>
          {brandName?.toUpperCase()}
          {' '}
          {modelGroup?.toUpperCase()}
        </InfoData>
      </p>
      <p className="wrapper__section-title-smal">
        <InfoHeader>
          Wersja Eurotax:
          {' '}
        </InfoHeader>
        <InfoData>{makeCarDictionaryLabel(name || '', parseInt(hp || '', 10), body || '', parseInt(doors || '', 10), parseInt(capacity || '', 10))}</InfoData>
      </p>

      <BaseCarDataSection
        locator={locator}
        sessionId={sessionId}
        handleChange={handleChange}
        handleSetValidation={handleSetValidation}
        handleFilteredBy={() => {}}
        disableFields={[]}
        vehicle={{
          productionYear,
          brand,
          model,
          fuel,
          transmission,
          eurotaxVersion,
          infoexVersion,
        }}
        filters={filters}
        ifMatchHideInfoex={false}
        noRenderFields={['productionYear', 'brand', 'model', 'fuel', 'transmission', 'eurotaxVersion', 'infoBoxInfoEkspert']}
        origin="modal"
      />
      <Spacer top={20} bottom={20} />
      <Button
        id="fillDataButton"
        variant="contained"
        fullWidth
        handleClick={() => (status !== 'validating' ? triggerValidation() : '')}
      >
        Uzupełnij dane
      </Button>
    </>
  );
};

InfoExpert.propTypes = {
  status: PropTypes.string.isRequired,
  filters: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSetValidation: PropTypes.func.isRequired,
  triggerValidation: PropTypes.func.isRequired,
  vehicle: PropTypes.shape({
    productionYear: PropTypes.number,
    brand: PropTypes.number,
    model: PropTypes.string,
    fuel: PropTypes.string,
    lpg: PropTypes.bool,
    transmission: PropTypes.string,
    eurotaxVersion: PropTypes.number,
    infoexVersion: PropTypes.number,
  }).isRequired,
  locator: PropTypes.string.isRequired,
  sessionId: PropTypes.string.isRequired,
};

export default ValidationHoc<any>(InfoExpert);
