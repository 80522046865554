import { FieldContainer, Input } from 'porowneo-design';
import { ValidationFieldHoc } from 'porowneo-ui';

import { withTooltipData } from '../TooltipDataProvider';

import { setInputFieldListener } from './utils/setInputFieldListener';
import { pickValidationColor } from './utils/pickValidationColor';

import type { IInputFieldProps } from './InputField.types';

export const InputWithoutHOC = ({
  id,
  value,
  fullWidth = true,
  size = 'medium',
  type = 'text',
  startAdornment,
  endAdornment,
  handleChange,
  mask,
  maxLength,
  placeholder,
  disabled = false,
  name,
  autoComplete,
  ariaLabel,
  // Form field
  listener = 'onBlur',
  sensitiveData = [],
  accessInputLength,
  setSensitiveDataInactive,
  validationStatus = 'init',
}: IInputFieldProps & {validationStatus?: 'init' | 'valid' | 'invalid'}) => {
  const isSensitiveDataActive: boolean = sensitiveData && sensitiveData.includes(id);

  return (
    <Input
      {...setInputFieldListener({
        id,
        listener,
        handleChange,
        accessInputLength,
        isSensitiveDataActive,
        setSensitiveDataInactive,
      })}
      id={id}
      value={value}
      fullWidth={fullWidth}
      color={pickValidationColor(validationStatus)}
      size={size}
      type={isSensitiveDataActive ? 'sensitiveData' : type}
      disabled={disabled}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      mask={mask}
      maxLength={maxLength}
      placeholder={placeholder}
      name={name}
      autoComplete={autoComplete}
      ariaLabel={ariaLabel}
    />
  );
};

export const InputField = withTooltipData(ValidationFieldHoc(InputWithoutHOC, FieldContainer as any));
