import log from 'loglevel';

import type NoSleep from 'nosleep.js';

// eslint-disable-next-line consistent-return
export const noSleepAction = (noSleepInstance: NoSleep, action: 'enable' | 'disable' = 'enable'): void => {
  try {
    switch (action) {
    case 'enable': {
      noSleepInstance
        .enable()
        .catch(err => (log.error(`There was some error with enabling noSleep: ${err}`)));
      break;
    }
    case 'disable': {
      noSleepInstance.disable();
      break;
    }
    default: return action;
    }
  } catch {
    log.info(`NoSleep no: ${action}`);
  }
};
