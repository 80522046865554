export const getRoutingBase = (
  routes: string[],
): null | string => {
  const pathName = window.location.pathname;
  let pathToReturn: null | string = null;

  routes.forEach((route) => {
    if (route !== '' && pathName.startsWith(route)) {
      pathToReturn = route;
    }
  });

  return pathToReturn;
};

export const addProcesToPath = (
  route: string,
  avProcess: string[],
): string => {
  const pathName = window.location.pathname;
  let returnRoute = route;

  avProcess.forEach((process) => {
    if (returnRoute === route && process !== '' && pathName.includes(process)) {
      returnRoute = `${route}/${process}`;
    }
  });

  return returnRoute;
};
