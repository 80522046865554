export const selectCommsConsent = { event: 'selectCommsConsent', dataName: '' };
export const selectMarketingConsent = { event: 'selectMarketingConsent', dataName: '' };
export const selectTerms = { event: 'selectTerms', dataName: '' };

export const submitDanePojazdu = { event: 'submitDanePojazdu', dataName: 'renewal_date_diff' };
export const inputExitPopupFirstname = { event: 'inputExitPopupFirstname', dataName: '' };
export const inputExitPopupSurname = { event: 'inputExitPopupSurname', dataName: '' };
export const inputExitPopupPhone = { event: 'inputExitPopupPhone', dataName: '' };
export const selectExitPopupComms = { event: 'selectExitPopupComms', dataName: '' };
export const selectExitPopupMarketing = { event: 'selectExitPopupMarketing', dataName: '' };
export const selectExitPopupTerms = { event: 'selectExitPopupTerms', dataName: '' };
export const inputPhoneNo = { event: 'inputPhoneNo', dataName: '' };

interface IEvent {
  event: string,
  dataName: string,
  formStep?: number,
}

export const EVENTS: Record<string, IEvent> = {
  [inputPhoneNo.event]: { event: inputPhoneNo.event, dataName: inputPhoneNo.dataName, formStep: 1 },
  [submitDanePojazdu.event]: { event: submitDanePojazdu.event, dataName: submitDanePojazdu.dataName, formStep: 1 },
  [selectCommsConsent.event]: { event: selectCommsConsent.event, dataName: selectCommsConsent.dataName, formStep: 1 },
  [selectMarketingConsent.event]: { event: selectMarketingConsent.event, dataName: selectMarketingConsent.dataName, formStep: 1 },
  [selectTerms.event]: { event: selectTerms.event, dataName: selectTerms.dataName, formStep: 1 },
  [inputExitPopupFirstname.event]: { event: inputExitPopupFirstname.event, dataName: inputExitPopupFirstname.dataName },
  [inputExitPopupSurname.event]: { event: inputExitPopupSurname.event, dataName: inputExitPopupSurname.dataName },
  [inputExitPopupPhone.event]: { event: inputExitPopupPhone.event, dataName: inputExitPopupPhone.dataName },
  [selectExitPopupComms.event]: { event: selectExitPopupComms.event, dataName: selectExitPopupComms.dataName },
  [selectExitPopupMarketing.event]: { event: selectExitPopupMarketing.event, dataName: selectExitPopupMarketing.dataName },
  [selectExitPopupTerms.event]: { event: selectExitPopupTerms.event, dataName: selectExitPopupTerms.dataName },
};
