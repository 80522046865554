export const themeName = process.env.REACT_APP_THEME || '';
export const isRadioZetTheme = themeName === 'radiozetTheme';

const utmParametersToSendToDatabase = ['utm_source', 'utm_medium', 'utm_campaign', 'sub_id'];

export default utmParametersToSendToDatabase;

export const companyPhoneNumber = '22 563 29 67';
export const aionPhoneNumber = '22 563 25 57';
export const renPhoneNumber = '519 902 385';
export const modalContainer = 'modalContainer';

// Agreements config

export const REG_PPL = 'REG_PPL';
export const MARK_TEL = 'MARK_TEL';
export const MARK_MAIL = 'MARK_MAIL';

export const agreementsTelAndReg = [REG_PPL, MARK_TEL];

export const neededAgreements = ['REG_PPL', 'MARK_TEL'];
export const agreementsStepFour = ['MARK_MAIL'];

export const requiredAgreements = ['REG_PPL'];
export const agreementsOrder = {
  REG_PPL: 1,
  MARK_TEL: 2,
  MARK_MAIL: 3,
  REG_INFLU100: 4,
};

// Control if promo code section is visible
export const IS_PROMO_CODES = false;
// DELETE IT AFTER CREAT MECHANISM TO INFLU AGREEMENTS
export const promoCodeAgreements = {
  influ: ['REG_INFLU100'],
  discount: ['REG_ULOTKA'],
};

export const promoCodes = {
  influ: ['INFLU_01', 'INFLU_02'],
  discount: [
    'RABAT-GRODZISK',
    'RABAT-GRODZISK21',
    'RABAT-KOZIENICE',
    'RABAT-KOZIENICE21',
    'RABAT-BLONIE',
    'RABAT-BLONIE21',
    'RABAT-SPORT',
    'RABAT-SPORT21',
  ],
} as const;
// end DELETE

// Hide partner errors
export const errorCodeToDefaultMessage = [
  '10001', '10002', '10004', '10005', '10006', '10007', '20301', '20901', '3000', '10010', '10011', '10013', '10018', '10020',
];
export const INFO_EXPERT_CODE = '10003';
export const UFG_CODE = '10008';
export const ERGO_POSTAL_CODE = '10015' as const;
export const EUROINS_PAYMENT_CODE = '21002' as const;
export const defaultResultErrorMsg = 'Ubezpieczyciel nie przedstawił oferty dla podanych parametrów';
export const RENEW_SESSION_MESSAGE = 'Chyba poszedłeś po kawę? :) W międzyczasie Twoja sesja została automatycznie odświeżona';

// used in footer
export const disclaimer = 'Administratorem Pani/a danych osobowych jest Porowneo.pl Sp. z o.o. z siedzibą w Warszawie przy ul. Inflanckiej 4B,\n' +
  '            00-189 Warszawa, nr KRS: 0000439594. Administrator wyznaczył Inspektora Ochrony Danych, z którym mogą się\n' +
  '            Państwo skontaktować pisemnie (na adres siedziby Administratora) lub elektronicznie tj. za pośrednictwem adresu\n' +
  '            poczty elektronicznej: ado@porowneo.pl. Jednocześnie informujemy, że przysługuje Państwu prawo do żądania\n' +
  '            dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania jak również prawo do\n' +
  '            wniesienia sprzeciwu wobec przetwarzania, a także przenoszenia danych. Ponadto mają Państwo prawo wnieść\n' +
  '            skargę do Prezesa Urzędu Ochrony Danych Osobowych w związku z przetwarzaniem przez Administratora Państwa\n' +
  '            danych osobowych. Szczegółowe informacje na temat podstawy prawnej przetwarzania Państwa danych osobowych,\n' +
  '            podmiotach którym te dane mogą być przekazywane, okresie czasu przez który dane osobowe będą\n' +
  '            przechowywane jak również inne wymagane prawem informacje dostępne są w dokumencie <a href="https://www.porowneo.pl/regulamin?rules=polityka_prywatnosci" target="_blank" rel="noopener noreferrer">„POLITYKA PRYWATNOŚCI</a>”.';

// CSD related with startDate filed (stepOne)
export const CSD_MORE_THAN_30 = 'Drogi kliencie. Wybrałeś datę startu ubezpieczenia odległą o ponad 30 dni w przyszłości. Chcemy tylko poinformować, że niektóre towarzystwa nie pozwolą kupić takiego ubezpieczenia ale zobaczysz prawdziwe ceny.';

export const CSD_MORE_THAN_180 = 'Drogi kliencie. Wybrałeś datę startu ubezpieczenia odległą o ponad 180 dni w przyszłości. Chcemy tylko poinformować, że większość towarzystw nie pozwoli kupić takiego ubezpieczenia. Ale nie martw się. W przypadku takich ubezpieczycieli podamy Ci bardzo dobrze przybliżone ceny jakie będą obowiązywać w przyszłości.';

export const MODAL_TYPES = {
  offerDetailsCompare: 'offerDetailsCompare',
  mobileFilterModal: 'mobileFilterModal',
  leaveContactOffer: 'leaveContactOffer',
  needContact: 'needContact',
  leasingContact: 'leasingContact',
  completeFields: 'completeFields',
  fullSaleConfirmModal: 'fullSaleConfirmModal',
  infoModal: 'infoModal',
  lockModal: 'lockModal',
  loadCalculationModal: 'loadCalculationModal',
  sensitiveDataFullSaleConfirmation: 'sensitiveDataFullSaleConfirmation',
  offerRemind: 'offerRemind',
  leavePhoneNumber: 'leavePhoneNumber',
  freeMonth: 'freeMonth',
  aionAccessDenied: 'aionAccessDenied',
  tooltip: 'tooltip',
} as const;

export const SHOW_BEST_OFFERS_ON_RESULTS = true;

// TODO remove this after demon start receiving variant for all baskets -> FORMMOTO-2283
export const AC_MINI_BASKETS_ID = [33, 46];

const generateFieldsNames = (prefixesDisable: string[], fieldsToDisable: string[]): string[] => {
  const disabledFieldsNames: string[] = [];

  prefixesDisable.forEach((user) => {
    fieldsToDisable.forEach((fieldName) => {
      disabledFieldsNames.push(`${user}-${fieldName}`);
    });
  });

  return disabledFieldsNames;
};

export const COMMON_ROP_REN_DISABLED_VEHICLE_FIELDS = [
  'productionYear',
  'brand',
  'model',
  'fuel',
  'transmission',
  'eurotaxVersion',
  'infoexVersion',
];

export const COMMON_ROP_REN_DISABLED_STEP_TWO_FIELDS = [
  'whichOwnerInOrder',
  'firstCountry',
  'firstDateRegistered',
  'carRegistrationNumber',
  'dateRegisteredActualOwner',
];

// id list disabled filled fields after load in ROP form
export const ROP_DISABLED_FIELDS_AFTER_LOAD = [
  ...COMMON_ROP_REN_DISABLED_VEHICLE_FIELDS,
  'gpl',
  'lpg',
  ...COMMON_ROP_REN_DISABLED_STEP_TWO_FIELDS,
];

export const ROP_REN_ALWAYS_DISABLE_FIELDS = ['carWheelSide'];

export const FIELDS_ALWAYS_BEING_DROPDOWNS = [
  'brand',
  'model',
  'eurotaxVersion',
  'infoexVersion',
  ...generateFieldsNames([
    'eurotaxVersion-filter',
  ], [
    'doors',
    'hp',
    'capacity',
  ]),
  ...generateFieldsNames([
    'owner',
    'coOwner',
    'coCoOwner',
    'driver',
    'insurer',
  ], [
    'street',
    'street-registered',
  ]),
];

export const FIELDS_ALWAYS_BEING_SELECT_BUTTONS = [
  'mainDriver',
  'coverType',
  'carSecurity',
];

export const FIELDS_TO_COVERT_FROM_SELECT_BUTTON_TO_CHECKBOX = [
  'fuel',
  'transmission',
];

export const REN_DISABLED_FIELDS_AFTER_LOAD = [
  // Step 1
  ...COMMON_ROP_REN_DISABLED_VEHICLE_FIELDS,
  'phone',
  // Step 2
  ...COMMON_ROP_REN_DISABLED_STEP_TWO_FIELDS,
  'carRegisteredOn',
  'carUse',
  'carRegistrationNumber',
  'plate',
  'carWheelSide',
  'lastInsurer',
  // Step 3
  ...generateFieldsNames([
    'owner',
    'coOwner',
    'coCoOwner',
    'driver',
  ],
  [
    'firstName',
    'lastName',
    'pesel',
    'sex',
    'birthdate',
    'licenseYear',
    'licenseMonth',
  ]),
  'coowners',
];
