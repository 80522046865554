import uniqBy from 'lodash/uniqBy';
import { createReducer } from 'redux-create-reducer';

import { SET_ERRORS_PER_INSURER, RESET_ERRORS_PER_INSURER } from '../actions/ResultsErrors';
import { OFFER_LIST_INSURERS } from '../config/Partners.config';

import type { Action } from 'redux';
import type { IErrorMessages } from '../types/DaemonOutput.type';
import type { TPartnerName } from '../types/Partners.type';

export type TDaemonErrorsPerInsurer = Record<TPartnerName, IErrorMessages[]>;

export const initialErrorsState: Record<string, never> | TDaemonErrorsPerInsurer = OFFER_LIST_INSURERS.reduce((acc, partner) => (
  { ...acc, [partner]: [] }
), {});

const setErrorsPerInsurer = (state: Record<string, never> | TDaemonErrorsPerInsurer = initialErrorsState, { payload }) => {
  if (payload && payload.status === 'error') {
    const { name, errorMessages } = payload;
    return ({
      ...state,
      [name]: uniqBy([...state[name], ...errorMessages], 'errorCode'),
    });
  }
  return state;
};

function resetErrorsPerInsurer() {
  return initialErrorsState;
}

type TResultsErrorReducerActions = typeof SET_ERRORS_PER_INSURER | typeof RESET_ERRORS_PER_INSURER;

const resultsErrorsReducer = createReducer<Record<string, never> | TDaemonErrorsPerInsurer, Action<TResultsErrorReducerActions>>(
  initialErrorsState, {
    [SET_ERRORS_PER_INSURER]: setErrorsPerInsurer,
    [RESET_ERRORS_PER_INSURER]: resetErrorsPerInsurer,
  },
);

export default resultsErrorsReducer;
