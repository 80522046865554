import {
  AVIVA, BEESAFE, BENEFIA, EUROINS, GENERALI, LINK4, MTU, PROAMA, TRASTI, TUZ, UNIQA, YCD, PZU, ERGO_HESTIA_GROUP,
} from '../../config/Partners.config';

import type { TPartnerName, TFullSalePartnerName } from '../../types/Partners.type';

const partnerNameArray: TPartnerName[] = [AVIVA, BEESAFE, BENEFIA, EUROINS, GENERALI, LINK4, MTU, PROAMA, TRASTI, TUZ, UNIQA, YCD, PZU];
const fullSalePartnerNameArray: TFullSalePartnerName[] = [AVIVA, BEESAFE, BENEFIA, EUROINS, GENERALI, LINK4, MTU, PROAMA, TRASTI, TUZ, UNIQA, YCD];

export const isTPartnerName = (string: unknown): string is TPartnerName => typeof string === 'string' &&
  partnerNameArray.includes((string as TPartnerName));

export const isTFullSalePartnerName = (string: unknown): string is TFullSalePartnerName => typeof string === 'string' &&
  fullSalePartnerNameArray.includes((string as TFullSalePartnerName));

export const isErgoHestia = (partnerName: '' | TPartnerName): boolean => !!ERGO_HESTIA_GROUP.find(name => name === partnerName);
