import moment from 'moment';

import { getDaysFromNowToYearsInPast } from '../helpers/FormUtils';

import { DATE_FORMAT } from './formats';
import * as regex from './regex';
import { PHONE_MASK } from './masks';
import * as fieldsLength from './FieldsParams/fieldsLength';
import * as fieldsTooltipMessages from './FieldsParams/fieldsTooltipMessages';
import * as fieldsTitles from './FieldsParams/fieldsTitles';
import * as fieldsAvailableValues from './FieldsParams/fieldsAvailableValues';
import * as fieldsAutoComplete from './FieldsParams/fieldsAutoComplete';

export const before2YearsFuture = getDaysFromNowToYearsInPast(2);
export const before18Years = -(getDaysFromNowToYearsInPast(18));
export const after110Years = -(getDaysFromNowToYearsInPast(110));
export const nowDate = moment().format(DATE_FORMAT);

export const GLOBAL_FIELDS = {
  phoneNumber: {
    type: 'numeric',
    title: 'Twój numer telefonu komórkowego',
    tooltip: { message: 'Numer telefonu komórkowego składający się z 9 cyfr.' },
    startAdornment: '+48',
    mask: PHONE_MASK,
    name: 'phone',
    ariaLabel: 'phone',
    validations: {
      phone: true,
      phoneHLR: true,
      requiredPhone: true,
      phoneForbidden: true,
    },
  },
};

// type TAvFieldsValues = readonly Record<string, string>[] | Record<string, string> | Record<number, string>;

// interface IFieldsConfig {
//   [key: string]: {
//     id: string;
//     title?: string;
//     name?: string;
//     required?: boolean;
//     defaultValue?: readonly unknown[] | string | number;
//     validations?: {
//       [key: string]: boolean | number;
//     };
//     availableValues?: TAvFieldsValues;
//     [key: string]: unknown;
//   };
// }

const FIELDS = {
  // STEP 1
  phoneLeaveContact: {
    ...GLOBAL_FIELDS.phoneNumber,
    id: 'phoneLeaveContact',
  },

  carUseDifferent: {
    id: 'carUseDifferent',
    title: 'Wybierz jaki:',
    required: false,
    validations: {
      required: true,
    },
    availableValues: {
      taxi: 'Taxi / Przewóz osób',
      sales: 'Przedstawiciel handlowy',
      courier: 'Przesyłki kurierskie',
      replacementVehicle: 'Pojazd zastępczy',
      dangerousGoods: 'Przewóz towarów niebezpiecznych',
      drivingCourse: 'Nauka jazdy',
      testDrives: 'Jazdy próbne',
      intervention: 'Jazdy interwencyjne, patrole',
      incomeRental: 'Zarobkowy wynajem',
      prop: 'Rekwizyt',
      race: 'Rajdy, wyścigi, konkursy',
    },
  },
  technicalResearch: {
    id: 'technicalResearch',
    title: 'Pojazd posiada ważne badanie techniczne?',
    tooltip: {
      message: <p>Ważność badań technicznych powinna być potwierdzona stemplem oraz datą w dowodzie rejestracyjnym.</p>,
    },
    errors: {},
    availableValues: {
      yes: 'Tak',
      no: 'No',
    },
  },
  carSecurity: {
    id: 'carSecurity',
    defaultValue: [],
    title: 'Zabezpieczenie pojazdu:',
    required: false,
    availableValues: {
      immobilizer: 'Immobilizer',
      autoalarm: 'Autoalarm',
      transmissions: 'Blokada skrzyni biegów',
      gps: 'Monitoring GPS',
    },
  },

  // STEP 3
  hasChildrenU26: {
    id: 'hasChildrenU26',
    defaultValue: 0,
    titleMainDriverOwner: 'Czy główny kierowca ma dzieci poniżej 26 roku życia?',
    titleMainDriveOther: 'Czy właściciel ma dzieci poniżej 26 roku życia?',
    tooltip: {
      message: fieldsTooltipMessages.otherUserDefaultMessage,
    },
    availableValues: [
      { value: 'yes', label: 'Tak' },
      { value: 'no', label: 'Nie' },
    ],
    validations: {
      hasChildrenUnder26: true,
    },
    relativeFields: ['mainDriver'],
    dmonKey: 'has_children_under_26',
  },
  numberOfChilds: {
    id: 'numberOfChilds',
    defaultValue: 0,
    title: 'Liczba dzieci do 18 roku życia głównego kierowcy',
    tooltip: {
      message: fieldsTooltipMessages.otherUserDefaultMessage,
    },
    validations: {
      numberOfChild: true,
    },
    availableValues: [
      { value: 0, label: '0' },
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4+' },
    ],
  },
  ageOldestChild: {
    id: 'ageOldestChild',
    defaultValue: '',
    titleMainDriverOwner: 'Wiek najstarszego dziecka głównego kierowcy',
    titleMainDriveOther: 'Wiek najstarszego dziecka właściciela',
    tooltip: {
      message: fieldsTooltipMessages.otherUserDefaultMessage,
    },
    validationMsgMainDriverOwner: 'Wybierz wiek najstarszego dziecka głównego kierowcy.',
    validationMsgMainDriverOther: 'Wybierz wiek najstarszego dziecka właściciela.',
    availableValues: [
      { value: '0', label: 'poniżej 1 roku' },
      { value: '1', label: '1 rok' },
      { value: '2', label: '2 lata' },
      { value: '3', label: '3 lata' },
      { value: '4', label: '4 lata' },
      { value: '5', label: '5 lat' },
      { value: '6', label: '6 lat' },
      { value: '7', label: '7 lat' },
      { value: '8', label: '8 lat' },
      { value: '9', label: '9 lat' },
      { value: '10', label: '10 lat' },
      { value: '11', label: '11 lat' },
      { value: '12', label: '12 lat' },
      { value: '13', label: '13 lat' },
      { value: '14', label: '14 lat' },
      { value: '15', label: '15 lat' },
      { value: '16', label: '16 lat' },
      { value: '17', label: '17 lat' },
      { value: '18', label: '18 lat' },
      { value: '19', label: '19 lat' },
      { value: '20', label: '20 lat' },
      { value: '21', label: '21 lat' },
      { value: '22', label: '22 lata' },
      { value: '23', label: '23 lata' },
      { value: '24', label: '24 lata' },
      { value: '25', label: '25 lat' },
    ],
    dmonKey: 'age_of_older_child',
  },
  havingChildren: {
    id: 'havingChildren',
    defaultValue: 'Wybierz',
    title: 'Czy posiadasz dzieci?',
    tooltip: {
      message: fieldsTooltipMessages.defaultMsg,
    },
    validations: {
      required: true,
    },
    availableValues: {
      no: 'Nie',
      one: 'Tak - jedno',
      two: 'Tak - Dwójkę',
      three: 'Tak - Trójkę',
      more: 'Tak - Więcej niż trójkę',
    },
  },
  havingOneChild: {
    id: 'havingOneChild',
    title: 'Podaj wiek dziecka',
    hidden: true,
    required: false,
  },
  havingMoreChildrenYoungest: {
    id: 'havingMoreChildrenYoungest',
    title: 'Podaj wiek najmłodszego dziecka',
    hidden: true,
    required: false,
  },
  havingMoreChildrenOldest: {
    id: 'havingMoreChildrenOldest',
    title: 'Podaj wiek najstarszego dziecka',
    hidden: true,
    required: false,
  },
  ownerDriver: {
    id: 'ownerDriver',
    title: 'Właściciel jest jednocześnie główym kierowcą',
    validations: {
      required: true,
    },
    availableValues: {
      yes: 'Tak',
      no: 'Nie',
    },
  },
  mainDriver: {
    id: 'mainDriver',
    title: 'Kto będzie głównym kierowcą pojazdu?',
    tooltip: {
      message: <p>Zaznacz, kto będzie głównym użytkownikiem pojazdu. Jeśli nie będzie to żaden z właścicieli zaznacz &quot;Inna osoba&quot;.</p>,
    },
    validations: { required: true },
    availableValues: [
      { value: 'owner', label: 'Właściciel' },
      { value: 'coOwner', label: 'Pierwszy współwłaściciel' },
      { value: 'coCoOwner', label: 'Drugi współwłaściciel' },
      { value: 'driver', label: 'Inna osoba' },
    ],
  },
  coowners: {
    id: 'coowners',
    defaultValue: 0,
    title: 'Czy są inni współwłaściciele?',
    tooltip: {
      message: <p>Zaznacz, jeśli pojazd ma współwłaściciela/li. Informację o tym znajdziesz w środku dowodu rejestracyjnego w sekcji adnotacji urzędowych.</p>,
      imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny8v2.jpg`,
    },
    availableValues: [
      { value: 0, label: 'Nie' },
      { value: 1, label: 'Jeden' },
      { value: 2, label: 'Dwóch' },
    ],
  },
  driverUnder26: {
    id: 'driverUnder26',
    defaultValue: '',
    title: 'Czy pojazd będą prowadziły osoby inne niż wymienione, poniżej 26 roku życia lub mające prawo jazdy krócej niż 3 lata?',
    tooltip: {
      message: (
        <p>
          Jeśli autem będzie kierowała osoba młodsza i nie została wymieniona wyżej,
          podaj jej dane aby prawidłowo wyliczyć składkę za ubezpieczenie.
        </p>
      ),
    },
    validations: {
      requiredWithTextMsg: true,
    },
    availableValues: [
      { value: 'yes', label: 'Tak' },
      { value: 'no', label: 'Nie' },
    ],
  },
  otherDrivers: {
    id: 'otherDrivers',
    title: 'Czy pojazd będą prowadziły inne osoby?',
    validations: {
      required: true,
    },
    availableValues: {
      no: 'Nie',
      one: 'Jeden',
      two: 'Dwóch',
    },
  },
  firstDamageAc: {
    id: 'firstDamageAc',
    title: fieldsTitles.whenWosFirstDamage,
    tooltip: {
      message: <p>Przypomnij sobie kiedy zgłaszana była pierwsza szkoda, z której wypłacane było odszkodowanie.</p>,
    },
    validations: {
      required: true,
    },
    availableValues: fieldsAvailableValues.firstBrake,
  },
  // STEP 4
  email: {
    id: 'email',
    title: 'Adres e-mail',
    ariaLabel: 'e-mail',
    mask: regex.MAIL,
    placeholder: 'Podaj adres e-mail',
    validations: {
      email: true,
      emailNeutrino: true,
    },
    defaultValue: '',
  },
  // ReRevert FORMMOTO-3258
  phone: {
    ...GLOBAL_FIELDS.phoneNumber,
    validations: {
      phone: true,
      phoneHLR: true,
      phoneForbidden: true,
    },
    id: 'phone',
    subtitle: 'Łatwo i szybko wrócisz do kalkulacji zapisując ją pod swoim numerem telefonu.',
    placeholder: '999 999 999',
  },
  // END
  // LEAVE CONTACT MODAL && NEED CONTACT MODAL && LEASING CONTACT
  name: {
    id: 'name',
    title: 'Imię',
    name: 'fname',
    ariaLabel: 'first name',
    autoComplete: fieldsAutoComplete.givenName,
    mask: regex.NAME,
    maxLength: fieldsLength.NAME.max,
    validations: {
      minName: fieldsLength.NAME.min,
      max: fieldsLength.NAME.max,
      required: true,
      name: true,
    },
    defaultValue: '',
  },
  surname: {
    id: 'surname',
    title: 'Nazwisko',
    name: 'lname',
    ariaLabel: 'last name',
    autoComplete: fieldsAutoComplete.familyName,
    mask: regex.SURNAME,
    maxLength: fieldsLength.SURNAME.max,
    validations: {
      minSurname: fieldsLength.SURNAME.min,
      max: fieldsLength.SURNAME.max,
      required: true,
      surname: true,
    },
    defaultValue: '',
  },
  infoBoxNameSurnamePesel: {
    id: 'infoBoxNameSurnamePesel',
    content: (
      <>
        <p>
          Aby uzyskać więcej ofert i lepsze składki podaj imię, nazwisko i PESEL.
          <br />
          Towarzystwa ubezpieczeniowe będą mogły sprawdzić Twoją historię.
        </p>
        <p>Twoje dane są chronione certyfikatem SSL i nie będą nigdzie przekazywane.</p>
      </>
    ),
    type: 'text',
  },
  ownerNameModal: {
    id: 'ownerName',
    title: 'Imię właściciela',
    mask: regex.NAME,
    maxLength: fieldsLength.NAME.max,
    isInput: true,
    required: true,
    name: 'fname',
    autoComplete: fieldsAutoComplete.givenName,
    validations: {
      required: true,
      minName: fieldsLength.NAME.min,
      max: fieldsLength.NAME.max,
      name: true,
    },
  },
  ownerSurnameModal: {
    id: 'ownerSurname',
    title: 'Nazwisko właściciela',
    name: 'lname',
    autoComplete: fieldsAutoComplete.familyName,
    mask: regex.SURNAME,
    isInput: true,
    required: true,
    maxLength: fieldsLength.SURNAME.max,
    validations: {
      required: true,
      minSurname: fieldsLength.SURNAME.min,
      max: fieldsLength.SURNAME.max,
      surname: true,
    },
  },
  ownerPeselModal: {
    id: 'ownerPesel',
    title: 'PESEL właściciela',
    mask: regex.PESEL,
    relativeFields: ['birthdate', 'gender'],
    isInput: true,
    required: true,
    validations: {
      required: true,
      peselUFG: true,
    },
  },
  coOwnerNameModal: {
    id: 'coOwnerName',
    title: 'Imię pierwszego współwłaściciela',
    mask: regex.NAME,
    maxLength: fieldsLength.NAME.max,
    isInput: true,
    required: true,
    name: 'fname',
    autoComplete: fieldsAutoComplete.givenName,
    validations: {
      required: true,
      minName: fieldsLength.NAME.min,
      max: fieldsLength.NAME.max,
      name: true,
    },
  },
  coOwnerSurnameModal: {
    id: 'coOwnerSurname',
    title: 'Nazwisko pierwszego współwłaściciela',
    name: 'lname',
    autoComplete: fieldsAutoComplete.familyName,
    mask: regex.SURNAME,
    maxLength: fieldsLength.SURNAME.max,
    isInput: true,
    required: true,
    validations: {
      required: true,
      minSurname: fieldsLength.SURNAME.min,
      max: fieldsLength.SURNAME.max,
      surname: true,
    },
  },
  coOwnerPeselModal: {
    id: 'coOwnerPesel',
    title: 'PESEL pierwszego współwłaściciela',
    mask: regex.PESEL,
    relativeFields: ['birthdate', 'gender'],
    isInput: true,
    required: true,
    validations: {
      required: true,
      peselUFG: true,
    },
  },
  secondCoOwnerNameModal: {
    id: 'secondCoOwnerName',
    title: 'Imię drugiego współwłaściciela',
    mask: regex.NAME,
    name: 'fname',
    autoComplete: fieldsAutoComplete.givenName,
    maxLength: fieldsLength.NAME.max,
    isInput: true,
    required: true,
    validations: {
      required: true,
      minName: fieldsLength.NAME.min,
      max: fieldsLength.NAME.max,
    },
  },
  secondCoOwnerSurnameModal: {
    id: 'secondCoOwnerSurname',
    title: 'Nazwisko drugiego współwłaściciela',
    name: 'lname',
    autoComplete: fieldsAutoComplete.familyName,
    mask: regex.SURNAME,
    maxLength: fieldsLength.SURNAME.max,
    isInput: true,
    required: true,
    validations: {
      required: true,
      minSurname: fieldsLength.SURNAME.min,
      max: fieldsLength.SURNAME.max,
      surname: true,
    },
  },
  secondCoOwnerPeselModal: {
    id: 'secondCoOwnerPesel',
    title: 'PESEL drugiego współwłaściciela',
    mask: regex.PESEL,
    relativeFields: ['birthdate', 'gender'],
    isInput: true,
    required: true,
    validations: {
      required: true,
      peselUFG: true,
    },
  },
  driverNameModal: {
    id: 'driverName',
    title: 'Imię głównego kierowcy',
    name: 'fname',
    autoComplete: fieldsAutoComplete.givenName,
    mask: regex.NAME,
    maxLength: fieldsLength.NAME.max,
    isInput: true,
    required: true,
    validations: {
      required: true,
      minName: fieldsLength.NAME.min,
      max: fieldsLength.NAME.max,
      name: true,
    },
  },
  driverSurnameModal: {
    id: 'driverSurname',
    title: 'Nazwisko głównego kierowcy',
    name: 'lname',
    autoComplete: fieldsAutoComplete.familyName,
    mask: regex.SURNAME,
    maxLength: fieldsLength.SURNAME.max,
    isInput: true,
    required: true,
    validations: {
      required: true,
      minSurname: fieldsLength.SURNAME.min,
      max: fieldsLength.SURNAME.max,
      surname: true,
    },
  },
  driverPeselModal: {
    id: 'driverPesel',
    title: 'PESEL głównego kierowcy',
    mask: regex.PESEL,
    relativeFields: ['birthdate', 'gender'],
    isInput: true,
    required: true,
    validations: {
      peselUFG: true,
      required: true,
    },
  },
  versionInfoEkspertModal: {
    id: 'versionInfoEkspert',
    title: 'Wersja pojazdu',
    searchable: true,
    fromBegining: false,
    validations: {
      required: true,
    },
  },
} as const;

export default FIELDS;
