import type { IFilter } from '../views/stepOne/components/baseCarDataSection/BaseCarDataSection.types';
import type {
  setHistoryPerCover,
  IPayload,
  IPayloadStepOne,
  IPayloadStepTwo,
  IPayloadStepThree,
  IPayloadStepFour,
  IPayloadStepOneFs,
  IPayloadStepTwoFs,
} from '../reducers/Payload';
import type { OneOf } from '../helpers/TypeHelpers';
import type { AnyAction } from 'redux';
import type { IVehicle } from '../types/Car.type';
import type {
  TInsurer,
  TInsurerBasePerson,
  TYoungPerson,
  IInsurerHistoryPerCover,
} from '../types/Insurer.type';

export const EDIT_PARAM = 'EDIT_PARAM';
export const EDIT_STEP_ONE_PARAM = 'EDIT_STEP_ONE_PARAM';
export const EDIT_STEP_TWO_PARAM = 'EDIT_STEP_TWO_PARAM';
export const EDIT_STEP_THREE_PARAM = 'EDIT_STEP_THREE_PARAM';
export const EDIT_STEP_THREE_INSURER_PARAM = 'EDIT_STEP_THREE_INSURER_PARAM';
export const EDIT_STEP_FOUR_PARAM = 'EDIT_STEP_FOUR_PARAM';
export const EDIT_STEP_FOUR_HISTORY_PARAM = 'EDIT_STEP_FOUR_HISTORY_PARAM';
export const EDIT_STEP_FOUR_HISTORY_PARAM_BY_COVER = 'EDIT_STEP_FOUR_HISTORY_PARAM_BY_COVER';
export const EDIT_STEP_ONE_FS_PARAM = 'EDIT_STEP_ONE_FS_PARAM';
export const EDIT_STEP_ONE_FS_INSURER_PARAM = 'EDIT_STEP_ONE_FS_INSURER_PARAM';
export const EDIT_STEP_TWO_FS_PARAM = 'EDIT_STEP_TWO_FS_PARAM';
export const RESET_STEP_TWO_FS_PAYLOAD = 'RESET_STEP_TWO_FS_PAYLOAD';
export const SET_PAYLOAD = 'SET_PAYLOAD';
export const RESET_PAYLOAD = 'RESET_PAYLOAD';
export const RESET_FULL_SALE_PAYLOAD = 'RESET_FULL_SALE_PAYLOAD';

type PartialPayload = Partial<IPayload>;

export const setPayload = (payload: PartialPayload) => ({
  type: SET_PAYLOAD,
  payload,
});

export const editPayloadParam = (payload: PartialPayload) => ({
  type: EDIT_PARAM,
  payload,
});

export const editStepOneParam = (
  payload: Partial<IPayloadStepOne> | Partial<IVehicle> | Partial<IFilter>,
  nestedKey: '' | 'vehicle' | 'filters' = '',
  keysToReset?: Array<keyof IPayloadStepOne> | Array<keyof IVehicle> | Array<keyof IFilter>,
) => ({
  type: EDIT_STEP_ONE_PARAM,
  payload,
  nestedKey,
  keysToReset,
}) as const;

export type TEditStepOneParamAction = ReturnType<typeof editStepOneParam>;

export const editStepTwoParam = (
  payload: Partial<IPayloadStepTwo>,
  keysToReset?: Array<keyof IPayloadStepTwo>,
): AnyAction => ({
  type: EDIT_STEP_TWO_PARAM,
  payload,
  keysToReset,
}) as const;

export type TEditStepTwoAction = ReturnType<typeof editStepTwoParam>

export const editStepThreeParam = (payload: Partial<IPayloadStepThree>) => ({
  type: EDIT_STEP_THREE_PARAM,
  payload,
});

type RequiredProperty<T> = { [P in keyof T]: Required<NonNullable<T[P]>>; };

type TInsurerPayloadStepThree = Pick<IPayloadStepThree, TInsurerBasePerson | TYoungPerson>;
type TInsurerPayloadStepThreeNotNull = RequiredProperty<TInsurerPayloadStepThree>;
type ValueOf<T> = T[keyof T];
type TInsurersStepThree = ValueOf<TInsurerPayloadStepThreeNotNull>
type TInsurersStepThreePartial = Partial<TInsurersStepThree>;

export const editStepThreeInsurerParam = (payload: TInsurersStepThreePartial) => ({
  type: EDIT_STEP_THREE_INSURER_PARAM,
  payload,
});

export const editStepFourParam = (payload: Partial<IPayloadStepFour>) => ({
  type: EDIT_STEP_FOUR_PARAM,
  payload,
});

type TInsurerHistoryPayload = Pick<IPayloadStepFour, 'coCoOwnerInsuranceHistory' | 'ownerInsuranceHistory' | 'coOwnerInsuranceHistory' | 'driverInsuranceHistory'>;
type TInsurerHistoryPayloadNotNull = RequiredProperty<TInsurerHistoryPayload>;
type TInsurerHistoryPayloadNotNullValues = ValueOf<TInsurerHistoryPayloadNotNull>
type TOneTInsurerHistoryPayload = Partial<TInsurerHistoryPayloadNotNullValues> & {
  insuranceHistorySame?: boolean,
  acInsuranceHistory?: ReturnType<typeof setHistoryPerCover> | null,
};

export const editStepFourInsuranceHistoryParam = (payload: TOneTInsurerHistoryPayload, who: TInsurerBasePerson) => ({
  type: EDIT_STEP_FOUR_HISTORY_PARAM,
  payload,
  who,
});
export const editStepFourInsuranceHistoryParamByCover = (payload: Partial<IInsurerHistoryPerCover<'oc' | 'ac', string | number>>, who: TInsurerBasePerson, cover: 'oc' | 'ac') => ({
  type: EDIT_STEP_FOUR_HISTORY_PARAM_BY_COVER,
  payload,
  cover,
  who,
});

export const editStepOneFsParam = (payload: Partial<IPayloadStepOneFs>) => ({
  type: EDIT_STEP_ONE_FS_PARAM,
  payload,
});

type TInsurerPayloadStepOneFs = Pick<IPayloadStepOneFs, TInsurerBasePerson | TInsurer>;
type TOneInsurerPayloadStepOneFs = Partial<OneOf<TInsurerPayloadStepOneFs>>;

export const editStepOneFsInsurerParam = (payload: TOneInsurerPayloadStepOneFs) => ({
  type: EDIT_STEP_ONE_FS_INSURER_PARAM,
  payload,
});

export const editStepTwoFsParam = (payload: Partial<IPayloadStepTwoFs>) => ({
  type: EDIT_STEP_TWO_FS_PARAM,
  payload,
});

export const resetPayload = () => ({ type: RESET_PAYLOAD });
export const resetFullSalePayload = () => ({ type: RESET_FULL_SALE_PAYLOAD });
export const resetStepTwoFSPayload = () => ({ type: RESET_STEP_TWO_FS_PAYLOAD });
