import { editStepOneParam } from '../../../actions/Payload';
import { editUser } from '../../../actions';

import type { Dispatch } from 'redux';

interface IPayload {
  phone: string;
  step: number | 'results';
}

export const setPhoneNumberFromModal = (dispatch: Dispatch, payload: IPayload) => {
  const { phone, step } = payload;

  if (step < 4 || step === 'results') {
    dispatch(editStepOneParam({ phone }));
    dispatch(editUser({ phoneNumber: phone, phoneLeaveContact: phone }));
  }
};
