import moment from 'moment';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import { REN_DISABLED_FIELDS_AFTER_LOAD, ROP_DISABLED_FIELDS_AFTER_LOAD } from '../config/main';
import {
  initialPayload,
  generateInitialAddress,
} from '../reducers/Payload';
import { DATE_FORMAT } from '../config/formats';
import { createUrlInsurersToShow } from '../config/url';
import dictionary from '../api/dictionary';
import { ADDRESS_AND_REGISTERED } from '../config/Address.literal';

import { isTAddress } from './types/AddressType.util';
import { getProcessFromLocation } from './FormUtils';

import type { TRegistered, TAddressAndRegistered } from '../types/Address.type';
import type {
  IPayload, IPayloadStepOne, IPayloadStepTwo, IPayloadStepThree, IPayloadStepFour,
} from '../reducers/Payload';
import type {
  IInsurer, IInsurerFull, TInsurerType,
} from '../types/Insurer.type';
import type { ISale } from '../reducers';
import type { TCarProjectQuote } from '../api/carProject/CarProject.types';

export const setViewValue = (val: boolean | number | string | null): string => {
  if (val === null) return '';
  if (val === 0) return '0';
  if (typeof val === 'boolean') return val ? 'yes' : 'no';
  if (val) {
    if (typeof val === 'number') return val.toString();
    if (typeof val === 'string') return val;
  }
  return '';
};

export interface ICreatePayload {
  payload: IPayload,
  contact: {
    phone: string,
    email: string
  };
  additional: {
    coverType: string[],
    startDate: string,
    sessionId: string,
    sale: ISale[]
  }
}

// because we don`t want set only undefined and null the others are ok
const isValue = (value: unknown): boolean => typeof value !== 'undefined' && value !== null;

const isObject = (value: unknown): value is Record<string, unknown> => value !== null && typeof value === 'object' && !Array.isArray(value);

export const handleActualKmPayload = (km: unknown): null | number => {
  if (typeof km === 'number') {
    if (km >= 1000 && km < 999000) {
      const round = Math.round(km / 1000) * 1000; // round number to nearest 1000
      return round < 1000000 ? round : 999000;
    }
    if (km >= 999000) return 999000;
  }
  return null;
};

export const convertFullInsurerToInsurer = <T extends TInsurerType = TInsurerType> (
  insurer: null | IInsurerFull<T> | IInsurer<T>,
): null | IInsurer<T> => {
  if (insurer !== null) {
    const {
      address,
      addressRegistered,
      addressRegisteredIdentical,
    } = insurer;

    const addressData = omit(address, ['flat', 'street', 'building']);
    const addressRegisteredData = addressRegisteredIdentical ? null : omit(addressRegistered, ['flat', 'street', 'building']);

    return ({
      ...insurer,
      address: isTAddress<TAddressAndRegistered>(addressData) ? addressData : generateInitialAddress<TAddressAndRegistered>(ADDRESS_AND_REGISTERED),
      addressRegistered: (isTAddress<TRegistered>(addressRegisteredData) && addressRegisteredData !== null) ? addressRegisteredData : null,
    });
  }
  return null;
};

export const mapOneOjectToAnother = <TargetType = Record<string, unknown>> (load: Record<string, unknown>, target: TargetType): TargetType => {
  const newTarget = target;

  Object.entries(target).forEach(([targetKey, targetValue]) => {
    const loadValue = load[targetKey];

    if (isValue(loadValue)) {
      if (isObject(loadValue) && isObject(targetValue)) {
        newTarget[targetKey] = mapOneOjectToAnother(loadValue, targetValue);
      } else {
        newTarget[targetKey] = loadValue;
      }
    }
  });

  return newTarget;
};

export const createPayload = (data: TCarProjectQuote): ICreatePayload => {
  let payload: IPayload = initialPayload;
  const contactData: ICreatePayload['contact'] = {
    phone: '',
    email: '',
  };
  const additional: ICreatePayload['additional'] = {
    coverType: [],
    startDate: '',
    sessionId: '',
    sale: [],
  };

  if (isObject(data)) {
    payload = {
      ...initialPayload,
      stepOne: mapOneOjectToAnother<IPayloadStepOne>(data, initialPayload.stepOne),
      stepTwo: mapOneOjectToAnother<IPayloadStepTwo>(data, initialPayload.stepTwo),
      stepThree: mapOneOjectToAnother<IPayloadStepThree>(data, initialPayload.stepThree),
      stepFour: mapOneOjectToAnother<IPayloadStepFour>(data, initialPayload.stepFour),
    };

    // manual collect stepData
    const {
      carProjectId,
      ktApiProjectId,
      protectionBegins,
      ac,
      nnw,
      session_id: sessionIdFromRedis, // if data from redis
      sessionId, // if data from db
      contact,
      sale,
    } = data;

    const email = contact?.email || null;
    const phone = contact?.phone || null;
    const formatedPhone = phone !== null ? phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3') : '';

    const mapPossiblyInvalidMileage = (mileage: unknown): IPayload['stepTwo']['mileageEstimator'] | null => {
      if (!mileage || (typeof mileage !== 'string' && typeof mileage !== 'number')) {
        return null;
      }
      const mileageString = typeof mileage === 'number' ? mileage.toString() : mileage;
      const mileageIsValidEstimator = (possibleMileage: string): possibleMileage is IPayload['stepTwo']['mileageEstimator'] => (
        ['2500', '7500', '12500', '17500', '25000', '35000', '50000', '70000', '90000', '120000'].includes(possibleMileage)
      );
      return mileageIsValidEstimator(mileageString) ? mileageString : null;
    };

    const mapPossiblyInvalidDamage = (payloadDamageDetail: unknown): IPayload['stepTwo']['damageDetail'] => {
      if (!payloadDamageDetail || typeof payloadDamageDetail !== 'string' || payloadDamageDetail === 'no') {
        return null;
      }
      const isValidDamageDetail = (possibleDamage: string): possibleDamage is Exclude<IPayload['stepTwo']['damageDetail'], null> => (
        ['scratch', 'dent', 'serious'].includes(possibleDamage)
      );
      return isValidDamageDetail(payloadDamageDetail) ? payloadDamageDetail : null;
    };

    payload.projectId = carProjectId;
    payload.ktApiProjectId = ktApiProjectId;
    payload.stepOne.dataOrigin = 'www2';
    payload.stepOne.phone = formatedPhone;
    payload.stepTwo.actualKm = handleActualKmPayload(payload.stepTwo.actualKm);
    payload.stepTwo.mileageEstimator = mapPossiblyInvalidMileage(data?.mileageEstimator) || '';
    payload.stepTwo.damageDetail = mapPossiblyInvalidDamage(data?.damageDetail);
    payload.stepFour.email = email || '';

    const insurersPersons = ['owner', 'coOwner', 'coCoOwner', 'driver'] as const;
    const insurerPossibleNullValues = ['firstName', 'lastName', 'pesel', 'email', 'phone'] as const;

    const staticInsuranceHistoryKeys = ['yearsBuying', 'damagesPaid', 'firstDamage', 'secondDamage', 'thirdDamage', 'fourthDamage', 'fifthDamage'];

    insurersPersons.forEach((insurer) => {
      const insurerObject = payload.stepThree[insurer];

      if (insurerObject) {
        insurerPossibleNullValues.forEach((possibleNullValue) => {
          if (insurerObject[possibleNullValue] === '') {
            insurerObject[possibleNullValue] = null;
          }
        });

        if (insurerObject.licenseDate) {
          insurerObject.licenseDateInitMonth = false;
        }
      }

      const insurerHistoryObject = payload.stepFour[`${insurer}InsuranceHistory` as const];
      const hasHistoryData = insurerHistoryObject?.ocInsuranceHistory && insurerHistoryObject?.acInsuranceHistory;
      const isInvalidInsuranceHistorySameParam = insurerHistoryObject?.insuranceHistorySame === null;
      if (insurerHistoryObject && hasHistoryData && isInvalidInsuranceHistorySameParam) {
        insurerHistoryObject.insuranceHistorySame = isEqual(
          pick(insurerHistoryObject?.ocInsuranceHistory, staticInsuranceHistoryKeys),
          pick(insurerHistoryObject?.acInsuranceHistory, staticInsuranceHistoryKeys),
        );
      }

      /*
       * ! This is required due to available values shown for insurer history on FormMoto
       * ! For yearsBuying = 0, damageTime '1' is available
       * ! For yearsBuying = 1, damageTime '1' and '2' are available
       * ! It gets consistent from yearsBuying = 2+, only matching damageTimes are available
       * ! This fixes the case, where a user would pick yearsBuying = 1 and damageTime = 2
       * ! If this data were to be bumped by API's REN script, it would return yearsBuying = 2 and damageTime = 3,
       * ! which is invalid on FormMoto
       * ! This is handled by dropping the damageTime by a single value in such a case (confirmed with Analists)
       */
      const covers = ['oc', 'ac'] as const;
      const damageTimes = ['first', 'second', 'third', 'fourth', 'fifth'];
      if (insurerHistoryObject) {
        covers.forEach((insCover) => {
          const coverHistoryObject = insurerHistoryObject[`${insCover}InsuranceHistory` as const];
          const shouldValidateDamageTimes = coverHistoryObject && typeof coverHistoryObject.yearsBuying === 'number' && coverHistoryObject.yearsBuying > 1;
          if (shouldValidateDamageTimes) {
            damageTimes.forEach((damageTime) => {
              const damageKey = `${damageTime}Damage`;
              if (coverHistoryObject?.[damageKey] && coverHistoryObject?.yearsBuying && coverHistoryObject[damageKey] > coverHistoryObject.yearsBuying) {
                coverHistoryObject[damageKey] -= 1;
              }
            });
          }

          // If damagesPaid came from backend as null we need to set it as 0
          if (coverHistoryObject?.damagesPaid === null) {
            coverHistoryObject.damagesPaid = 0;
          }
        });
      }
    });

    const isPossiblyInvalidCoowners = !payload.stepThree.coowners;
    const isInvalidMainDriver = !payload.stepThree.mainDriver;
    const isInvalidCoowners = isPossiblyInvalidCoowners && payload.stepThree.coOwner;

    if (isInvalidCoowners) {
      if (payload.stepThree.coCoOwner) {
        payload.stepThree.coowners = 2;
      } else {
        payload.stepThree.coowners = 1;
      }
    }

    if (isInvalidMainDriver) {
      payload.stepThree.mainDriver = 'owner';
      payload.stepThree.driver = null;
      payload.stepFour.driverInsuranceHistory = null;
    }

    contactData.phone = formatedPhone;
    contactData.email = email || '';

    additional.startDate = moment(protectionBegins).format(DATE_FORMAT);
    additional.sale = Array.isArray(sale) ? sale : [];
    if (ac) additional.coverType.push('ac');
    if (nnw) additional.coverType.push('nnw');
    additional.sessionId = sessionId || sessionIdFromRedis || '';

    if (isObject(data.mgm)) {
      payload.mgm = {
        ...payload.mgm,
        ...data.mgm,
      };
    }
    const vinFromData = data?.vinNumber || data?.vin;
    payload.stepOneFs.vinNumber = typeof vinFromData === 'string' ? vinFromData : '';
  }

  return { payload, contact: contactData, additional };
};

export const checkDisableField = (
  filedId: string,
  fieldValue: string | number | boolean,
  fieldsToDisable = getProcessFromLocation() === 'ROP' ? ROP_DISABLED_FIELDS_AFTER_LOAD : REN_DISABLED_FIELDS_AFTER_LOAD,
): boolean => (
  fieldsToDisable.includes(filedId) && (fieldValue === 0 || !!fieldValue) // field value can equal 0 bc it's data from field on form
);

export const setDisableAndKeptFields = (
  payload: { [key: string]: any },
  fieldsToKeep: string[],
  fieldsToDisable = getProcessFromLocation() === 'ROP' ? ROP_DISABLED_FIELDS_AFTER_LOAD : REN_DISABLED_FIELDS_AFTER_LOAD,
): [string[], string[]] => {
  const fields: string[] = [];
  const kept: string[] = [];
  Object.keys(payload).forEach((key: string) => {
    if (checkDisableField(key, payload[key], fieldsToDisable) && !fieldsToKeep.includes(key)) {
      fields.push(key);
    } else {
      kept.push(key);
    }
  });
  return [fields, kept];
};

export const mapCarUserFormPayloadToField = <T extends TInsurerType> (user: T, userPayload: null | IInsurer<T>) => ({
  [`${user}-firstName`]: userPayload?.firstName,
  [`${user}-lastName`]: userPayload?.lastName,
  [`${user}-pesel`]: userPayload?.pesel,
  [`${user}-sex`]: userPayload?.sex,
  [`${user}-birthdate`]: userPayload?.birthdate,
  [`${user}-licenseYear`]: userPayload?.licenseDate?.slice(0, 4),
  [`${user}-licenseMonth`]: userPayload?.licenseDate?.slice(5, 7),
});

export const isDisableField = (disableFields: string[], { id }: { id: string }): boolean => disableFields?.includes(id);

export const getLastInsurer = async (lastInsurerId: number, locatorFromUrl: string, sessionIdFromUrl: string) => {
  const localLastInsurer = localStorage.getItem(createUrlInsurersToShow());

  if (localLastInsurer) {
    const parseLocalLastInsurer = JSON.parse(localLastInsurer);

    return parseLocalLastInsurer[lastInsurerId];
  }

  const lastInsurerList = await dictionary.getInsurersToShow(locatorFromUrl, sessionIdFromUrl);
  return lastInsurerList.data[lastInsurerId];
};

export const getAgreementsForRopRen = (user: { [key: string]: boolean }) => {
  const localStorageState = localStorage.getItem('state');
  const parsedState = typeof localStorageState === 'string' ? JSON.parse(localStorageState) : {};
  const userAgreements = parsedState?.user?.userAgreements;
  const isRopLoadedFromStorage = sessionStorage.getItem('isRopLoadedFromStorage') === 'true';

  return { userAgreements: isRopLoadedFromStorage && userAgreements ? userAgreements : user.userAgreements };
};
