import { isTPartnerName } from './types/PartnersType.util';

import type { IMarketingMaterial } from '../config/marketingMaterial';

export const parseOptionalInt = (string: string | undefined): number | undefined => (string ? parseInt(string, 10) : undefined);

/**
 * * URL for testing purposes, eg:
 * * ?marketingInsurer=benefia&marketingRankedPosition=5&marketingPriceDifferenceAC=500&marketingPriceDifferenceOC=300&
 * *   &marketingDisplayStart=2022-01-26&marketingDisplayEnd=2022-01-30
 */
export const createDummyMarketingMaterial = ({ urlVars, isDev, isStage }: {
  urlVars: Record<string, string>, isDev: boolean, isStage: boolean,
}): IMarketingMaterial[] => {
  if (!isStage && !isDev) {
    return [];
  }

  const {
    marketingInsurer,
    marketingRankedPosition,
    marketingPriceDifferenceAC,
    marketingPriceDifferenceOC,
    marketingDisplayStart,
    marketingDisplayEnd,
  } = urlVars;

  if (marketingInsurer && isTPartnerName(marketingInsurer) && marketingRankedPosition &&
  (marketingPriceDifferenceAC || marketingPriceDifferenceOC) &&
  marketingDisplayStart && marketingDisplayEnd) {
    return [{
      insurer: marketingInsurer,
      rankedPosition: parseInt(marketingRankedPosition, 10),
      priceDifference: {
        ac: parseOptionalInt(marketingPriceDifferenceAC),
        noAc: parseOptionalInt(marketingPriceDifferenceOC),
      },
      displayTime: {
        startDate: marketingDisplayStart,
        endDate: marketingDisplayEnd,
      },
    }];
  }

  return [];
};
