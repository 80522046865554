import axios from 'axios';

import { API_V2, axiosConfigWithSession } from '../config/url';

export const apiAxiosInstance = axios.create({ baseURL: `${API_V2}/api/`, timeout: 12000 });

const promoCodesApi = {
  verifyPromoCode: (locator: string, code: string, sessionId: string) => apiAxiosInstance.post('verification-mgm-code', { locator, code }, axiosConfigWithSession(sessionId, locator)),
  pinPromoCode: (locator: string, code: string, sessionId: string) => apiAxiosInstance.post('mgm-locator-code', { locator, code }, axiosConfigWithSession(sessionId, locator)),
  unpinPromoCode: (locator: string, code: string, sessionId: string) => apiAxiosInstance.post('remove-mgm-locator-code', { locator, code }, axiosConfigWithSession(sessionId, locator)),
};

export default promoCodesApi;
