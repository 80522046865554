import type { IPayloadStepThree } from '../../../../reducers/Payload';
import type { TInsurerType, IInsurer } from '../../../../types/Insurer.type';

export const checkIfNeedToFillUFG = (payload: IPayloadStepThree): boolean => {
  const {
    owner, coowners, coOwner, coCoOwner, driver, mainDriver,
  } = payload;
  const checkInsurer = <T extends TInsurerType>(insObj: null | IInsurer<T>) => (!insObj?.firstName || !insObj?.lastName || !insObj?.pesel);

  let needToFill = false;
  if (checkInsurer(owner)) needToFill = true;
  if (coowners === 1 && checkInsurer(coOwner)) needToFill = true;
  if (coowners === 2 && checkInsurer(coCoOwner)) needToFill = true;
  if (mainDriver === 'driver' && checkInsurer(driver)) needToFill = true;
  return needToFill;
};
