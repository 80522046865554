import PropTypes from 'prop-types';
import { ValidationHoc } from 'porowneo-ui';
import { Button } from 'porowneo-design';

import { INSURER_PROPTYPES } from '../../../config/proptypes';

import UfgInsurerSection from './UfgInsurerSection';

const UFG = ({
  owner,
  coOwner,
  coCoOwner,
  driver,
  coowners,
  mainDriver,
  handleChange,
  triggerValidation,
  handleSetValidation: handleSetValidationFromHoc,
  handleCallGtmFieldError,
  sensitiveData,
  status,
  setSensitiveDataInactive,
}) => {
  const handleSetValidation = {
    handleSetValidation: handleSetValidationFromHoc,
    callBackIfFieldInValid: handleCallGtmFieldError,
  };
  return (
    <>
      <UfgInsurerSection
        {...owner}
        coowners={coowners}
        title={coowners !== 0 || mainDriver === 'driver' ? 'Dane właściciela' : ''}
        orderPrefix={0}
        handleChange={handleChange}
        handleSetValidation={handleSetValidation}
        sensitiveData={sensitiveData}
        setSensitiveDataInactive={setSensitiveDataInactive}
      />

      {(coowners === 1 || coowners === 2) && (
        <>
          <div className="hr" />
          <UfgInsurerSection
            {...coOwner}
            coowners={coowners}
            title={coowners === 1 ? 'Dane współwłaściciela' : 'Dane pierwszego współwłaściciela'}
            orderPrefix={10}
            handleChange={handleChange}
            handleSetValidation={handleSetValidation}
            sensitiveData={sensitiveData}
            setSensitiveDataInactive={setSensitiveDataInactive}
          />
        </>
      )}

      {coowners === 2 && (
        <>
          <div className="hr" />
          <UfgInsurerSection
            {...coCoOwner}
            coowners={coowners}
            title="Dane drugiego współwłaściciela"
            orderPrefix={20}
            handleChange={handleChange}
            handleSetValidation={handleSetValidation}
            sensitiveData={sensitiveData}
            setSensitiveDataInactive={setSensitiveDataInactive}
          />
        </>
      )}

      {mainDriver === 'driver' && (
        <>
          <div className="hr" />
          <UfgInsurerSection
            {...driver}
            coowners={coowners}
            title="Dane głównego kierowcy"
            orderPrefix={30}
            handleChange={handleChange}
            handleSetValidation={handleSetValidation}
            sensitiveData={sensitiveData}
            setSensitiveDataInactive={setSensitiveDataInactive}
          />
        </>
      )}

      <Button
        id="fillDataButton"
        variant="contained"
        fullWidth
        handleClick={() => (status !== 'validating' ? triggerValidation() : '')}
      >
        Uzupełnij dane
      </Button>
    </>
  );
};

UFG.propTypes = {
  status: PropTypes.string.isRequired,
  owner: INSURER_PROPTYPES.isRequired,
  coOwner: INSURER_PROPTYPES.isRequired,
  coCoOwner: INSURER_PROPTYPES.isRequired,
  driver: INSURER_PROPTYPES.isRequired,
  coowners: PropTypes.number.isRequired,
  mainDriver: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  triggerValidation: PropTypes.func.isRequired,
  handleSetValidation: PropTypes.func.isRequired,
  handleCallGtmFieldError: PropTypes.func.isRequired,
  setSensitiveDataInactive: PropTypes.func.isRequired,
  sensitiveData: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ValidationHoc<any>(UFG);
