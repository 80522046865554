import type { TStepOrigin } from '../Insurer.types';

export const keysToResetInsurerAddress = (key: string, stepOrigin: TStepOrigin) => {
  const baseAddress = {
    city: '', district: '',
  };

  switch (key) {
  case 'zipCode':
    if (stepOrigin !== 'fs') {
      return baseAddress;
    }
    return {
      ...baseAddress, street: null, building: null, flat: null,
    };
  case 'city':
    if (stepOrigin !== 'fs') {
      return {};
    }
    return { street: null, building: null, flat: null };
  case 'street':
    return { building: null, flat: null };
  default:
    return {};
  }
};
