import { torem, getLinkTextFieldColor } from 'porowneo-ui';
import { InterjectionIcon } from 'porowneo-design';
import styled from 'styled-components';

import { getSidebarConfig } from '../utils/getSidebarContent';

import type { TSteps } from '../../StepContainer/StepContainer';
import type { TProcess } from 'porowneo-ui/build/types/main';

const Header = styled('span')`
  color: ${props => getLinkTextFieldColor(props)};
  font-weight: 700;
  font-size: ${torem(16)};
  display: inline-block;
  padding-left: 40px;
  padding-top: 5px;
`;

const Content = styled.div`
  font-weight: 500;
  font-size: ${torem(16)};
  line-height: 24px;
  padding-left: 18px;
`;

const Wrapper = styled.div<{ isHeader: boolean }>`
  display: flex;
  flex-direction: row;
  align-self: flex-start;

  padding-top: ${({ isHeader }) => (isHeader ? '13px' : '26px')};
`;

const IconWrapper = styled('div')`
  width: 22px;
  height: 22px;
`;

export const SidebarInfo = ({
  step,
  process,
}: {
  step: TSteps,
  process: TProcess
}) => {
  const sidebarConfig = getSidebarConfig({ step, process });
  return (
    <>
      {sidebarConfig?.header ? <Header>{sidebarConfig.header}</Header> : null}
      {
        sidebarConfig?.content ? (
          <Wrapper
            isHeader={!!sidebarConfig?.header}
          >
            <IconWrapper>
              <InterjectionIcon
                color="porowneo"
              />
            </IconWrapper>
            <Content>
              {sidebarConfig?.content}
            </Content>
          </Wrapper>
        ) : null
      }
    </>
  );
};
