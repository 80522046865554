import { setStepTwoGtm } from '../../../../helpers/tagManager/TagManagerUtils';

import * as GTMUtils from './HandleGTM.utils';

import type { IStepTwoValues } from '../../StepTwo.types';
import type { IPayloadStepTwo } from '../../../../reducers/Payload';

const handleGTM = <K extends keyof IStepTwoValues>(key: K, value: IStepTwoValues[K], autoSelect: boolean, plate: IPayloadStepTwo['plate']): void => {
  switch (key) {
  case 'firstDateRegistered':
  case 'dateRegisteredActualOwner':
    return GTMUtils.handleGTMDateChange(key, value);
  case 'carUse':
  case 'carRegisteredOn':
  case 'carWheelSide':
  case 'parkingPlace':
  case 'lastInsurer':
  case 'timeUsedAbroad':
  case 'damaged':
  case 'planToDriveThisYear':
    return GTMUtils.handleGTMNoAutoselectChange(key, value, autoSelect);
  case 'whichOwnerInOrder':
  case 'firstCountry':
    return setStepTwoGtm(key, value);
  case 'carMileage': return GTMUtils.handleGTMCarMileageChange(value as IStepTwoValues['carMileage']);
  case 'carRegistrationNumber': return GTMUtils.handleGTMCarRegistrationNumberChange(value as IStepTwoValues['carRegistrationNumber'], plate);
  default: return key;
  }
};

export default handleGTM;
