import ButtonCheckboxField from '../../../../components/fields/ButtonCheckboxField';

import { generateInsuranceHistorySameConfig } from './insuranceHistoryFields';
import InsuranceHistoryByCover from './InsuranceHistoryByCover';

import type { IHandleSetValidationSteps } from '../../../../types/Steps.type';
import type {
  THandleChangeStepFour,
  TInsuranceHistorySame,
} from '../../StepFour.types';
import type { TCoverType } from '../../../../reducers/Payload';
import type { IInsurerHistoryPayload, TInsurerBasePerson } from '../../../../types/Insurer.type';

export type TInsuranceHistoryHandleChange = <I extends TInsurerBasePerson>(
  key: string,
  value: string | number | null,
  insurerType: I,
  insuranceCover: '' | TCoverType,
) => void;

interface IInsuranceHistory<T extends TInsurerBasePerson> {
  handleChange: THandleChangeStepFour;
  insurerType: T;
  coowners: number;
  handleSetValidation: IHandleSetValidationSteps;
  insurerHistoryPayload: IInsurerHistoryPayload<T>;
}

const InsuranceHistory = <T extends TInsurerBasePerson> ({
  handleChange,
  insurerType,
  coowners,
  handleSetValidation,
  insurerHistoryPayload,
}: IInsuranceHistory<T>) => {
  const { insuranceHistorySame, ocInsuranceHistory, acInsuranceHistory } = insurerHistoryPayload;

  const config = generateInsuranceHistorySameConfig(insurerType, coowners);

  return (
    <>
      <InsuranceHistoryByCover
        insuranceHistory={ocInsuranceHistory}
        cover="oc"
        insurerType={insurerType}
        handleChange={handleChange}
        coowners={coowners}
        handleSetValidation={handleSetValidation}
      />

      <ButtonCheckboxField
        {...config.insuranceHistorySame}
        onChange={(
          key: TInsuranceHistorySame[keyof TInsuranceHistorySame]['id'],
          value: typeof config.insuranceHistorySame.availableValues[0 | 1]['value'],
          // @ts-ignore
          // TODO fix type FORMMOTO-3713
        ) => handleChange({
          key,
          value,
          insurerType,
          insuranceCover: '',
        })}
        value={insuranceHistorySame ? 'yes' : 'no'}
        handleSetValidation={handleSetValidation}
        multiSelect={false}
      />

      <div className="hr" />

      {!insuranceHistorySame && acInsuranceHistory && (
        <InsuranceHistoryByCover
          insuranceHistory={acInsuranceHistory}
          cover="ac"
          insurerType={insurerType}
          handleChange={handleChange}
          coowners={coowners}
          handleSetValidation={handleSetValidation}
        />
      )}
    </>
  );
};

export default InsuranceHistory;
