// @ts-nocheck
import omit from 'lodash/omit';
import size from 'lodash/size';
import omitBy from 'lodash/omitBy';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';

import type {
  ICarDictionaryData,
  IFilter,
} from '../../../../views/stepOne/components/baseCarDataSection/BaseCarDataSection.types';

export const getUniqSortedList = <K extends string | number, V>(
  propName: K,
  allData: ICarDictionaryData[],
  filter: IFilter,
) => {
  const props: typeof allData = [];
  const otherFilters = omit(filter, [propName]);

  Object.keys(allData)
    .forEach((car) => {
      // consider other filters
      if (size(otherFilters) > 0) {
        // if this car data suit for exist other filter
        const fitToFilter = omitBy(
          otherFilters,
          (v, k) => String(allData[car][k]) === String(v),
        );

        if (size(fitToFilter) === 0) {
          props.push({ [propName]: allData[car][propName] } as Record<K, V>);
        }
      } else {
        props.push({ [propName]: allData[car][propName] } as Record<K, V>);
      }
    });

  const propsUniq = uniqWith(props, isEqual);

  return sortBy(propsUniq, [i => i[propName]]);
};
