/* eslint-disable @typescript-eslint/no-floating-promises */
import { Component } from 'react';

import { PartnerLogo, sendError } from 'porowneo-ui';
import axios from 'axios';

import { updateCarProjectContact } from '../../api/carProject';
import { userUpdate } from '../../helpers/socketsCalculations';
import ContactFormWithValidation from '../ContactForm';
import SentSection from '../SentSection';
import ThankYouSection from '../ThankYouSection';
import agreementsHoc, { type IWithAgreementsHOCProps } from '../hoc/agreementsHoc';
import {
  createConfigForFullSaleEdits,
  submitCheckoutErrorPopup,
} from '../../helpers/tagManager/TagManagerUtils';
import { modalProcessConfig } from '../../config/modalProcess';
import { axiosConfigWithSession, createGetAllAgreements } from '../../config/url';
import { setAgreements } from '../../actions';

import { FullSaleModalErrorHeader } from './FullSaleModalErrorHeader';
import { setPhoneNumberFromModal } from './utils/dispatchPhoneNumber';

import type { IContactFormWithValidation, TUserType } from '../ContactForm';
import type { TModalProcessType } from '../../api/carProject';
import type { Dispatch } from 'redux';

interface INeedContactState {
  user: TUserType;
  stepOrigin: number;
}

interface INeedContactProps extends Omit<IContactFormWithValidation, 'handleChange' | 'stepOrigin' | 'showTheseAgreements' | 'handleSetAgreements'>, IWithAgreementsHOCProps {
  modalContainer: string;
  modalSource: TModalProcessType;
  dispatch: Dispatch;
  closeModal: () => void;
  requiredAgreements: Array<any>;
  neededAgreements: Array<any>;
  protectionStart: string;
  activeFullSaleInsurer?: string;
  fullSaleError?: boolean;
  handleSetAgreements: (key: any, value: any) => void;
  setDefaultLeadUpdate: () => void;
  handleCallGtmFieldError?: (id: string) => void;
  dataForGtm?: any;
  carProjectId?: number | null;
  statusLeadUpdate: 'done' | 'sent' | 'failed' | '';
  setStatusLeadUpdate: (payload: 'sent' | '') => void;
  step: number,
  warningMessages?: any[],
}

export class NeedContact extends Component<INeedContactProps, INeedContactState> {
  phoneNumberField: Element | null = document.querySelector('input#phoneNumber');

  constructor(props: INeedContactProps) {
    super(props);
    const { user } = props;
    this.state = {
      stepOrigin: 900,
      user: {
        locator: user.locator,
        phoneLeaveContact: user.phoneLeaveContact,
        sessionId: user.sessionId,
        surname: user.surname,
        name: user.name,
        businessProcess: user.businessProcess,
      },
    };
  }

  override componentDidMount() {
    const {
      agreements,
      user,
      dispatch,
    } = this.props;
    this.settingLeadUpdate();

    // logic for autocomplete
    if (this.phoneNumberField) this.phoneNumberField.setAttribute('autoComplete', 'none');

    // fetch agreements if they are empty
    if (agreements.length === 0) {
      axios.get(
        createGetAllAgreements(),
        axiosConfigWithSession(user.sessionId, user.locator),
      ).then(({ data }) => {
        dispatch(setAgreements(data));
      }).catch((err) => {
        sendError(
          err,
          false,
          {
            method: 'getAllAgreements',
            component: 'NeedContact',
            customErrorMessage: 'There was an error while fetching agreements',
          },
        );
      });
    }
  }

  override componentWillUnmount() {
    // logic for autocomplete
    if (this.phoneNumberField) this.phoneNumberField.setAttribute('autoComplete', 'tel');
  }

  handleChange = (key: string, value: string | number) => {
    const { modalSource, handleCallGtm } = this.props;
    if ([modalProcessConfig.formExit, modalProcessConfig.fullFormExit].includes((modalSource as any)) && handleCallGtm) {
      handleCallGtm(key, value);
    }
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [key]: value,
      },
    }));
  }

  settingLeadUpdate: () => void = () => {
    const { setStatusLeadUpdate, statusLeadUpdate } = this.props;
    const leadStatus = (statusLeadUpdate === 'done' || statusLeadUpdate === 'sent') ? 'sent' : '';

    setStatusLeadUpdate(leadStatus);
  }

  send = () => {
    const {
      modalSource,
      dispatch,
      sendAgreements,
      fullSaleError,
      dataForGtm,
      user: { locator, businessProcess, sessionId },
      carProjectId,
      protectionStart,
      // Revert FORMMOTO-3258
      step,
      // END
    } = this.props;

    const { user } = this.state;

    sendAgreements(user);

    if (fullSaleError) {
      submitCheckoutErrorPopup(createConfigForFullSaleEdits({
        insurerOffer: locator,
        dataForGtm,
      }));
    }

    if (carProjectId) {
      updateCarProjectContact({
        firstName: user.name || '',
        lastName: user.surname || '',
        phone: user.phoneLeaveContact.replace(/\s/g, ''),
        process: modalSource,
      }, carProjectId, sessionId, locator);
    }

    // Revert FORMMOTO-3258
    setPhoneNumberFromModal(dispatch, { phone: user.phoneLeaveContact, step });
    // END

    userUpdate({
      firstname: user.name,
      lastname: user.surname,
      locator_full: user.locator,
      session_id: user.sessionId,
      businessProcess,
      phone: user.phoneLeaveContact.replace(/\s/g, ''),
      source: modalSource,
      protection_start: protectionStart,
      sessionId: user.sessionId,
    }, dispatch);
  }

  override render() {
    const {
      agreements,
      statusLeadUpdate,
      closeModal,
      fullSaleError,
      activeFullSaleInsurer,
      handleSetAgreements,
      userAgreements,
      requiredAgreements,
      setDefaultLeadUpdate,
      modalContainer,
      handleCallGtmFieldError,
      neededAgreements,
      warningMessages = [],
    } = this.props;

    const {
      user,
      stepOrigin,
    } = this.state;

    const {
      send,
      handleChange,
    } = this;

    const formSection = (
      <div className="col p-0">
        <h1 className="wrapper__header-title wrapper__header-title--contact">
          {activeFullSaleInsurer || fullSaleError ? (
            'Zakup polisy - zostaw kontakt'
          ) : (
            <>
              Wolisz porozmawiać?
              <br />
              Zostaw kontakt - oddzwonimy!
            </>
          )}
        </h1>
        {fullSaleError && (
          <>
            <FullSaleModalErrorHeader warningMessages={warningMessages} />
            <div className="text-center">
              <PartnerLogo
                partnerName={activeFullSaleInsurer}
                maxHeight="42px"
                maxMobileHeight="32px"
              />
            </div>
          </>
        )}

        <div className="modal-default">
          {statusLeadUpdate === 'failed' && (
            <h5 className="text-error">
              Przepraszamy, niestety nie udało zapisać się prośby o kontakt.
              <br />
              Możesz spróbować jeszcze raz.
            </h5>
          )}

          <ContactFormWithValidation
            user={user}
            userAgreements={userAgreements}
            agreements={agreements}
            stepOrigin={stepOrigin}
            handleChange={handleChange}
            callBackIfValid={send}
            handleSetAgreements={handleSetAgreements}
            requiredAgreements={requiredAgreements}
            showTheseAgreements={neededAgreements}
            containerId={modalContainer}
            handleCallGtmFieldError={handleCallGtmFieldError}
          />

        </div>
      </div>
    );

    switch (statusLeadUpdate) {
    case 'done':
      return (<ThankYouSection closeModal={closeModal} />);
    case 'sent':
      return (<SentSection setDefaultLeadUpdate={setDefaultLeadUpdate} />);
    default:
      return formSection;
    }
  }
}

export default agreementsHoc(NeedContact);
