import moment from 'moment';
import axios from 'axios';
import { sendError } from 'porowneo-ui';
// TODO MOVE IT TO .ENV
import find from 'lodash/find';
import startsWith from 'lodash/startsWith';

import { DEV_HIDE_LOCK_MODAL } from '../config/dev.config';
import { DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD } from '../config/formats';
import {
  createDocsApiSearchLabel,
  createUrlAgreementsSaveInDB,
  createGetQuote,
  axiosConfigWithSession,
} from '../config/url';
import {
  ROUTING_BASE,
  ROUTING_FULL_SALE_PERSONAL_DATA,
  ROUTING_FULL_SALE_VEHICLE_DATA,
  ROUTING_REN,
  ROUTING_RESULTS,
  ROUTING_ROP,
} from '../config/Router.config';
import { setUsedEmail, showAlert } from '../actions';
import {
  CSD_MORE_THAN_30, CSD_MORE_THAN_180, agreementsStepFour,
  promoCodes,
  promoCodeAgreements,
} from '../config/main';
import { DISABLE_BUY_BTN_ON_WARNINGS } from '../config/Partners.config';

import { isAionTheme } from './setupTheme';

import type { ILockedWebsockets } from '../reducers/Steps';
import type { IDaemonBasket, IErrorMessages } from '../types/DaemonOutput.type';
import type { Dispatch } from 'redux';
import type { IUsedEmail, TBusinessProcess } from '../reducers';
import type { TMgmCode, TMgmCodeKeys, TMgmStatus } from '../reducers/Payload';

interface IGenericValueMap {
  value: string
  label: string
}

export const getYears = (minYear = 1970, maxYear = new Date().getFullYear()): Record<number, string> => {
  let currentYear = maxYear;
  const years: Record<number, string> = {};
  while (currentYear >= minYear) {
    years[currentYear] = currentYear.toString();
    currentYear -= 1;
  }
  return years;
};

// eslint-disable-next-line valid-typeof
const containsOnlyOneType = (array, type) => array.every(elem => typeof elem === type);

export const getGenderFromPesel = (pesel?: unknown) => {
  if (pesel && typeof pesel === 'string' && pesel.length === 11 && /^\d+$/.test(pesel)) {
    if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
      return 'male';
    }
    return 'female';
  }
  return null;
};

export const dateFromPesel = (value?: unknown) => {
  if (value && typeof value === 'string' && value.length === 11 && /^\d+$/.test(value)) {
    const arr: number[] = [];
    for (let i = 0; i < 11; i += 1) {
      arr[i] = parseInt(value.substring(i, i + 1), 10);
    }
    const [yearNum1, yearNum2, monthNum1, monthNum2, dayNum1, dayNum2] = arr;
    if (typeof yearNum1 === 'undefined' ||
        typeof yearNum2 === 'undefined' ||
        typeof monthNum1 === 'undefined' ||
        typeof monthNum2 === 'undefined' ||
        typeof dayNum1 === 'undefined' ||
        typeof dayNum2 === 'undefined'
    ) {
      return null;
    }
    let year = 1900 + (yearNum1 * 10) + yearNum2;
    if (monthNum1 >= 2 && monthNum1 < 8) {
      year += Math.floor(monthNum1 / 2) * 100;
    }
    if (monthNum1 >= 8) {
      year -= 100;
    }

    let day: string | number = (dayNum1 * 10) + dayNum2;
    let month: string | number = ((monthNum1 % 2) * 10) + monthNum2;

    if (day < 10) {
      day = `0${day}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    return (`${day}-${month}-${year}`);
  }
  return null;
};

export const getDateYearsBefore = yearsBefore => moment().subtract(yearsBefore, 'years');

export const getDaysFromNowToYearsInPast = dateYearsBefore => moment().diff(getDateYearsBefore(dateYearsBefore), 'days', true);

export const convertDateFormat = (date, format = DATE_FORMAT, desiredFormat = DATE_FORMAT_YYYY_MM_DD) => (date ? moment(date, format).format(desiredFormat) : '');

export const showMonthLicenseField = (licenseYear: number | boolean) => {
  const availableYear = moment().year() - 3;
  return typeof licenseYear === 'number' && licenseYear > availableYear;
};

export const daysOfYear = (year: number) => {
  if (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)) {
    return 366;
  }
  return 365;
};

export const getDaysToEndOfYear = (year: number, dayOfYear: number) => daysOfYear(year) - dayOfYear;

export const didHideAcRisc = (productionYear) => {
  if (typeof productionYear === 'number' && productionYear > 0) {
    return moment().year() - 16 < productionYear;
  }
  return true;
};

export const agreementsStatus = (requiredAgreements, userAgreements = {}) => {
  if (requiredAgreements && userAgreements &&
    typeof requiredAgreements === 'object' &&
    typeof userAgreements === 'object' &&
    requiredAgreements.length > 0 &&
    Object.keys(userAgreements).length > 0) {
    let status = true;
    requiredAgreements.forEach((agree) => {
      if (!Object.keys(userAgreements).includes(agree)) {
        status = false;
      }
      if (userAgreements[agree] === false) {
        status = false;
      }
    });
    return status;
  }
  return false;
};

export const getCookieByName = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i]?.split('=');
    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (name === cookiePair?.[0]?.trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair?.[1] || '');
    }
  }
  // Return null if not found
  return null;
};

export const setFetchDocumentsUrl = (covers, insurer) => {
  const coversUsedByDocs = ['oc', 'ac', 'nnw'];
  const selectedCoversUsedByDocs = [...coversUsedByDocs.filter(id => covers[id]), 'brak'];
  const docsApiLabel = selectedCoversUsedByDocs.join(',');
  const insurerLabel = insurer === 'mtu' ? 'mtu24' : insurer;
  return createDocsApiSearchLabel(insurerLabel, docsApiLabel);
};

export const checkUrlParams = (urlParams) => {
  const urlParamsKeys = Object.keys(urlParams);
  const requiredKeys = ['session_id', 'locator_full', 'policy_number']; // add more params?

  return requiredKeys.every(key => urlParamsKeys.includes(key));
};

export const eachFirstLetterUpperCase = (string) => {
  if (typeof string === 'string') {
    let newString = `${string.split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}`;
    newString = `${newString.split('-').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join('-')}`;
    newString = `${newString.split('—').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join('—')}`;
    return newString;
  }
  return '';
};

export const convertToRomanNumeral = string => (string ? string.replace(/iii/gi, 'III') : '');

export const convertCitiesStreetsToFormat = (val?: string) => eachFirstLetterUpperCase(convertToRomanNumeral(val));

export const convertArrayToAvailabeValues = (cities) => {
  if (cities && Array.isArray(cities) && cities.length > 0 && containsOnlyOneType(cities, 'string')) {
    const sortedCities = [...cities].sort((a, b) => a.localeCompare(b));
    const availabeValues = {};
    for (let i = 0; i < sortedCities.length; i += 1) {
      availabeValues[sortedCities[i].toLowerCase()] = convertCitiesStreetsToFormat(sortedCities[i]);
    }
    return availabeValues;
  }
  return {};
};

export const convertArrayToAvailabeValuesNew = (cities) => {
  if (cities && Array.isArray(cities) && cities.length > 0 && containsOnlyOneType(cities, 'string')) {
    const sortedCities = [...cities].sort((a, b) => a.localeCompare(b));
    const availableValues: IGenericValueMap[] = [];
    for (let i = 0; i < sortedCities.length; i += 1) {
      availableValues.push({ value: sortedCities[i].toLowerCase(), label: convertCitiesStreetsToFormat(sortedCities[i]) });
    }
    return availableValues;
  }
  return [];
};

interface ICity {
  city: string;
  district: string;
  commune: string;
  voivodeship: string;
  postalCode: string;
}

export const getCitiesStringArray = (cities?: ICity[]) => (Array.isArray(cities) && cities?.map(cityData => cityData.city)) || [];

/**
 * Finds city by name (full or partial)
 *
 * @param citiesData - Array of Cities
 * @param cityName - City to find, it may not be full city name e.g. 'Warsaw', 'War', ...
 * @returns Object with properties described in ICity or empty object when city wasn't found
 */
export const findCityByName = (citiesData: ICity[], cityName: string): ICity | Record<string, never> => (
  Array.isArray(citiesData) && find(citiesData, (cityData => (
    cityData?.city?.toLowerCase() === cityName?.toLowerCase() ||
    startsWith(cityData?.city?.toLowerCase(), cityName?.toLowerCase()))))
) || {};

export const saveUsedEmailInStore = (
  dispatch: Dispatch<ReturnType<typeof setUsedEmail>>,
  email: string,
  usedEmail: IUsedEmail,
  key: keyof IUsedEmail,
): void => {
  dispatch(setUsedEmail({ ...usedEmail, [key]: [...usedEmail[key], email] }));
};

export const setInsurersToShowValues = <T extends string>(data: Record<string, T>) => {
  const convertedData: {
    id: string,
    value: T,
    label: string,
  }[] = [];
  Object.entries(data).forEach(([id, val]) => {
    if (val === 'BRAK') {
      return convertedData.push({ id, value: val, label: 'Brak ubezpieczenia' });
    }
    return convertedData.push({ id, value: val, label: val });
  });
  convertedData.sort((a, b) => {
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA === 'BRAK UBEZPIECZENIA' || nameB === 'INNY') return -1;
    if (nameA === 'INNY' || nameB === 'BRAK UBEZPIECZENIA') return 1;
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  return [...convertedData];
};

export const prodDate = (year) => {
  const monthNow = moment().format('MM');
  return `01-${monthNow}-${year}`;
};

export const maxFirstDateRegistered = (year: number, maxRegistrationDelay: number) => {
  const yearNow = moment().year();
  const daysToEndOfYear = getDaysToEndOfYear((year + maxRegistrationDelay), moment().dayOfYear());
  return getDaysFromNowToYearsInPast(yearNow - year - maxRegistrationDelay) - daysToEndOfYear + 1;
};

export const getYearsNew = (minYear = 1970) => {
  let currentYear = new Date().getFullYear();

  const years: IGenericValueMap[] = [];
  while (currentYear >= minYear) {
    years.push({ value: currentYear.toString(), label: currentYear.toString() });
    currentYear -= 1;
  }
  return years;
};

export const getMonthsNew = (licenseYear) => {
  const currentMonth = moment().format('MM');
  const currentYear = moment().format('YYYY');

  const allMonths: IGenericValueMap[] = [];

  for (let i = 0; i < 12; i += 1) {
    allMonths.push({
      value: moment().month(i).format('MM').toString(),
      label: moment().month(i).format('MMMM'),
    });
  }

  if (currentYear === licenseYear) {
    return allMonths.filter(month => month.value <= currentMonth);
  }
  return allMonths;
};

export const setPersonLicenseAvValues = (birthdate?: string, acceptableAge = 0) => (birthdate ?
  getYearsNew(parseInt(birthdate?.toString()?.slice(0, 4)?.split('-')?.[0] || '', 10) + acceptableAge) :
  getYearsNew(parseInt(moment().subtract(100, 'years').format('YYYY'), 10)));

export const setCoOwnersSectionTitle = coowners => (coowners === 2 ? 'Dane pierwszego współwłaściciela' : 'Dane współwłaściciela');

// TO DO MOVE IT TO SERVICES
export const saveAgreements = ({
  agreements,
  userAgreements,
  sessionId,
  agrementsInfo,
  sendTheseAgreements,
  locatorFull,
}) => {
  const agreementsData: {
    id: string,
    value: string
  }[] = [];

  if (agreements && agreements.length > 0) {
    agreements.forEach((agree) => {
      if (sendTheseAgreements.includes(agree.name)) {
        agreementsData.push({
          id: agree.id,
          value: userAgreements[agree.name] || false,
        });
      }
    });
  }

  const config = {
    agreements: agreementsData,
    source: 'porowneo-form-motor',
    locatorFull,
    ...agrementsInfo,
  };
  return axios
    .post(
      createUrlAgreementsSaveInDB(),
      config,
      axiosConfigWithSession(sessionId, locatorFull),
    )
    .catch((err) => {
      sendError(err, false, {
        method: 'saveAgreements',
        url: createUrlAgreementsSaveInDB(),
      });
      throw err;
    });
};

export const showLockModal = (lockedWebsockets: Record<string, never> | ILockedWebsockets = {}): boolean => !!(
  lockedWebsockets &&
  lockedWebsockets?.data &&
  Object.keys(lockedWebsockets.data).length > 0 &&
  lockedWebsockets?.data?.code === 'LOCKED' &&
  !DEV_HIDE_LOCK_MODAL
);

export const setPaymentPayload = (urlParams) => {
  const paymentStatus = urlParams?.error ? 'error' : 'success';
  return ({
    ...urlParams,
    email: urlParams?.email ? decodeURIComponent(urlParams.email) : '',
    paymentStatus,
  });
};

export const getLabelByValueFromAvailableValues = (availableValues, searchedValue) => {
  const searchedObject = availableValues.find(({ value }) => value === searchedValue);
  return searchedObject ? searchedObject?.label : null;
};

export const getCSDInfoBar = (date) => {
  if (typeof date === 'string') {
    const diff = moment(date, DATE_FORMAT_YYYY_MM_DD).diff(moment(), 'days');

    if (diff >= 30 && diff <= 179) return CSD_MORE_THAN_30;
    if (diff >= 180) return CSD_MORE_THAN_180;
  }
  return '';
};

export const catchROPRENLoadError = (alertConfig, error, locator, dispatch, redirectLink = ROUTING_BASE) => {
  window.location.href = redirectLink;
  if (redirectLink === ROUTING_BASE) dispatch(showAlert(alertConfig));
  sendError(error, false, { method: 'getPayload', component: 'Form', url: createGetQuote(locator) });
};

export const compareROPRENCredentials = (locatorOld, locatorNew, sessionIdOld, sessionIdNew) => {
  if (locatorOld && sessionIdOld) {
    return (
      (locatorNew !== locatorOld && sessionIdNew === sessionIdOld) ||
      (locatorNew === locatorOld && sessionIdNew !== sessionIdOld)
    );
  }
  return false;
};

export const getProcessFromLocation = (): 'ROP' | 'REN' => {
  const { pathname } = window.location;
  if (pathname.includes(ROUTING_ROP)) return 'ROP';
  return 'REN';
};

export const isRen = () => (window.location?.pathname.includes(ROUTING_REN));

export const isRopRen = (process: TBusinessProcess): process is 'ROP' | 'REN' => ['ROP', 'REN'].includes(process);

export interface IPromoInfo {
  isPromoCode: boolean,
  promoType: TMgmCodeKeys,
}

export const detectPromo = (mgmStatus: TMgmStatus | '', mgmCode: TMgmCode | string, theme: NodeJS.ProcessEnv['REACT_APP_THEME']): IPromoInfo => {
  let promo: IPromoInfo = {
    isPromoCode: false,
    promoType: 'discount',
  };

  // Returning a falsy object here saves us from changing Step 4 validation too much
  if (isAionTheme(theme)) { return promo; }

  if (mgmStatus === 'valid' && mgmCode) {
    Object.entries(promoCodes)
      .forEach(([promoType, codes]) => {
        if (codes.flat().includes(mgmCode as TMgmCode)) {
          promo = {
            isPromoCode: true,
            promoType: promoType as TMgmCodeKeys, // Because TS doesn't understand promoType's type in forEach
          };
        }
      });
  }

  return promo;
};

interface IAgreementsConfig {
  neededAgreements: string[],
  requiredAgreements: string[],
}

export const createMgmAgreements = (isPromo: boolean, promoType: keyof typeof promoCodeAgreements): IAgreementsConfig => {
  let agreementsConfig: IAgreementsConfig = {
    neededAgreements: agreementsStepFour,
    requiredAgreements: [],
  };

  if (isPromo) {
    agreementsConfig = {
      neededAgreements: [...agreementsStepFour, ...promoCodeAgreements[promoType]],
      requiredAgreements: promoCodeAgreements[promoType],
    };
  }

  return agreementsConfig;
};

export const reverseYYYYMMDD = (date: string) => (date?.includes('-') ?
  date.split('-').reverse().join('-') :
  date);

export const checkErrorCode = (code: IErrorMessages['errorCode'], wanted: string[]): boolean => (wanted.includes(code));

export const checkBuyButtonDisable = (offer: IDaemonBasket): boolean => {
  const insurerConfig = DISABLE_BUY_BTN_ON_WARNINGS[offer?.name];
  const offerWarnings = offer?.warningMessages;
  if (!Array.isArray(offerWarnings) || !insurerConfig) return false;
  return offerWarnings.some(({ warningCode }) => checkErrorCode(warningCode, insurerConfig));
};

export const checkIfIsFullSale = () => window?.location?.pathname?.indexOf(ROUTING_FULL_SALE_PERSONAL_DATA) > -1 ||
  window?.location?.pathname?.indexOf(ROUTING_FULL_SALE_VEHICLE_DATA) > -1;
export const checkIfIsResultPage = () => window?.location?.pathname?.indexOf(ROUTING_RESULTS) > -1;
