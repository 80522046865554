import type { IRootState } from '../../../reducers';
import type FIELDS from '../../../config/fields';

export const renderAvailableValuesMainDriver = (
  owners: IRootState['payload']['stepThree']['coowners'],
  drivers: typeof FIELDS.mainDriver.availableValues,
) => {
  if (owners === 0) {
    return drivers.filter(driver => !['coOwner', 'coCoOwner'].includes(driver.value));
  }
  if (owners === 1) {
    return drivers.filter(driver => !['coCoOwner'].includes(driver.value));
  }
  if (owners === 2) {
    return drivers;
  }
  return {};
};
