import moment from 'moment';

import { updateInsurerStore } from '../../../../../helpers/StoreUtils';
import { ADDRESS, ADDRESS_AND_REGISTERED, REGISTERED } from '../../../../../config/Address.literal';
import { generateInitialAddress } from '../../../../../reducers/Payload';
import { YOUNG_PERSON } from '../../../../../config/InsurerPersons.literal';
import { YEAR } from '../../../../../config/formats';

import type {
  IAddress, TAddress, TAddressAndRegistered, TRegistered,
} from '../../../../../types/Address.type';
import type { TInsurerType } from '../../../../../types/Insurer.type';
import type { TStepOrigin } from '../Insurer.types';
import type { Dispatch } from 'redux';

interface IHandleChangeDTO {
  key: string;
  value: string | boolean;
  stepOrigin: TStepOrigin;
  dispatch: Dispatch;
  address:IAddress<TAddressAndRegistered | TAddress>;
  licenseDate?: null | string,
  type: TInsurerType,
}

export type THandleChangeUtil = ({
  key,
  value,
  stepOrigin,
  dispatch,
  address,
  licenseDate,
  type,
}: IHandleChangeDTO) => void;

const handleChangeSex = ({
  value, dispatch, stepOrigin, type, key,
}: Pick<IHandleChangeDTO, 'value' | 'dispatch' | 'stepOrigin' | 'type' | 'key'>) => {
  if (value === 'male') {
    dispatch(updateInsurerStore(stepOrigin, { maidenName: '', type }));
  }
  dispatch(updateInsurerStore(stepOrigin, { [key]: value, type }));
};

export const handleChangeUtil: THandleChangeUtil = ({
  key, value, stepOrigin, dispatch, address, licenseDate, type,
}): void => {
  switch (key) {
  case 'addressRegisteredIdentical':
    dispatch(updateInsurerStore(stepOrigin, {
      addressRegisteredIdentical: !!value,
      address: { ...address, type: value ? ADDRESS_AND_REGISTERED : ADDRESS },
      addressRegistered: value ?
        null :
        generateInitialAddress<TRegistered>(REGISTERED),
      type,
    }));
    break;
  case 'licenseYear':
    dispatch(updateInsurerStore(stepOrigin, { licenseDate: value, licenseDateInitMonth: true, type }));
    break;
  case 'licenseMonth':
    dispatch(updateInsurerStore(stepOrigin, { licenseDate: value, licenseDateInitMonth: false, type }));
    break;
  case 'birthdate':
    if (licenseDate) {
      const yearParam = type === YOUNG_PERSON ? 18 : 16;
      const licenseYear = licenseDate.slice(0, 4);
      if (licenseYear !== '' && parseInt(moment(value as string).format(YEAR), 10) + yearParam > parseInt(licenseYear, 10)) {
        dispatch(updateInsurerStore(stepOrigin, {
          licenseDate: '', type,
        }));
      }
    }
    dispatch(updateInsurerStore(stepOrigin, { [key]: value, type }));
    break;
  case 'sex':
    handleChangeSex({
      value, dispatch, stepOrigin, type, key,
    });
    break;
  case 'hasDrivingLicense':
    if (value) {
      dispatch(updateInsurerStore(stepOrigin, { licenseDate: null, type }));
    }
    dispatch(updateInsurerStore(stepOrigin, { [key]: !value, type }));
    break;
  default:
    dispatch(updateInsurerStore(stepOrigin, { [key]: value, type }));
    break;
  }
};
