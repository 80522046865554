import { setStepThreeGtm } from '../../../helpers/tagManager/TagManagerUtils';

import type { IStepThreeKeyValues } from '../StepThree.types';

export const handleCallGtmStepThree = (key: keyof IStepThreeKeyValues, value: string | boolean | number) => {
  switch (key) {
  case 'mainDriver':
  case 'coowners':
  case 'numberOfChilds':
  case 'ageOldestChild':
  case 'driverUnder26':
  case 'hasChildrenU26':
    setStepThreeGtm(key, value);
    break;
  default:
    break;
  }
};
