import { useState, memo, useEffect } from 'react';

import { Calculating } from 'porowneo-ui';
import NoSleep from 'nosleep.js';

import { noSleepAction } from '../../helpers/mobileUtils/NoSleep';

import { useProgress, componentShouldUpdate } from './PorowneoCalculating.util';

import type { IPorowneoCalculating, ILoaderProps } from './PorowneoCalculating.type';

export const Loader = ({
  timeToHide, msg, background, forceComplete, callbackOnAutoHide,
}: ILoaderProps) => {
  const noSleep = new NoSleep();
  const [progress] = useProgress(timeToHide, forceComplete);

  if (progress === 100) {
    callbackOnAutoHide();
  }

  useEffect(() => {
    noSleepAction(noSleep, 'enable');
    return () => noSleepAction(noSleep, 'disable');
  }, []);

  const progressProps = timeToHide > 0 ? { progress } : {};

  return (
    <Calculating {...progressProps} msg={msg} background={background} forcePartnerLogo="porowneoTheme" />
  );
};

export const PorowneoCalculating = memo(({
  show,
  msg,
  background,
  timeToHide = 0,
  callbackOnAutoHide,
}: IPorowneoCalculating) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [dynamicTime, setDynamicTime] = useState<number>(timeToHide);

  useEffect(() => {
    if (timeToHide > 0 && !show && loader) {
      setDynamicTime(1);
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    } else {
      setLoader(show);
      setDynamicTime(timeToHide);
    }
  }, [show]);

  const callBack = () => {
    if (show && callbackOnAutoHide) {
      callbackOnAutoHide();
    }
  };

  return (
    <>
      {loader && (
        <Loader timeToHide={dynamicTime} msg={msg} background={background} forceComplete={!show} callbackOnAutoHide={callBack} />
      )}
    </>
  );
}, componentShouldUpdate);
