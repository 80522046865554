import { memo } from 'react';
import { Sticky } from 'react-sticky';

import styled from 'styled-components';
import {
  VARS, getProgressBarColor, getWhiteHintedColor, above,
} from 'porowneo-ui';

export const StepContainerHeading = styled.h2`
  grid-row-start: 1;
  grid-row-end: 2;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.4;
  margin: 0 0 -8px 0;

  ${above(VARS.breakpoints.md)} {
    font-size: 1.5rem;
    line-height: 1.33;
    margin: 10px 18px 0 0;
    grid-column-start: 1;
    grid-column-end: 2;
  }
`;

const StickyWrapper = styled('div')`
  margin: 0 -16px;

  ${above(VARS.breakpoints.xl)} {
    margin: 0 -33px;
  }
`;

export const TitleAndProgressWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-template-columns: none;
  padding: 0 16px;
  ${above(VARS.breakpoints.md)} {
    grid-template-rows: none;
    gap: 8px;
    grid-template-columns: auto 1fr auto;
  };

  ${above(VARS.breakpoints.xl)} {
    padding: 0 33px;
  }
`;

export const Percentage = styled('div')`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  width: 100%;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: unset;
  text-align: right;

  ${above(VARS.breakpoints.md)} {
    padding-top: 12px;
    width: auto;
    grid-column-start: 3;
    grid-row-start: unset;
    grid-row-end: unset;
  }
`;

const Wrapper = styled.div`
  background-color: ${VARS.white};
  width: 100%;
  display: flex;
  height: 32px;
  grid-row-start: 3;
  grid-column-start: unset;
  grid-column-end: unset;

  ${above(VARS.breakpoints.md)} {
    height: 48px;
    padding-top: 20px;
    grid-row-start: unset;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

const ProgressContainer = styled.div`
  height: 5px;
  background-color: ${props => getWhiteHintedColor(props)};
  width: 100%;

  ${above(VARS.breakpoints.md)} {
    height: 8px;
  }
`;

const ProgressLine = styled.div<{ width: string }>`
  height: 5px;
  background-color: ${props => getProgressBarColor(props)};
  width: ${({ width }) => width || 0};
  transition: width ease 0.3s;

  ${above(VARS.breakpoints.md)} {
    height: 8px;
  }
`;

export interface IStepProgressBar {
  title: string;
  isProgressStepBar: boolean;
  fillStatusPercent?: string;
}

const stickyStyles = (isSticky: boolean) => (isSticky ? {
  backgroundColor: 'white',
  padding: '20px 0 10px 0',
  borderRadius: '0 0 10px 10px',
  boxShadow: '0 50px 50px rgba(23, 41, 53, 0.04)',
} : {});

const formatProgressBarFillStatus = (fillStatus: string): string => `${Math.round(parseInt(fillStatus.replace('%', ''), 10)).toString()}%`;

export const StepProgressBar = memo(({ title, isProgressStepBar, fillStatusPercent }: IStepProgressBar) => (
  <TitleAndProgressWrapper>
    <StepContainerHeading>{title}</StepContainerHeading>
    {isProgressStepBar && fillStatusPercent && (
      <>
        <Wrapper>
          <ProgressContainer>
            <ProgressLine width={fillStatusPercent} />
          </ProgressContainer>
        </Wrapper>
        <Percentage>{formatProgressBarFillStatus(fillStatusPercent)}</Percentage>
      </>
    )}
  </TitleAndProgressWrapper>
));

const StepProgressBarSticky = memo((props: IStepProgressBar) => (
  <StickyWrapper>
    <Sticky>
      {({ style, isSticky }) => (
        <div
          className="sticky-progress-bar" style={{
            ...style, zIndex: 2998, ...stickyStyles(isSticky),
          }}
        >
          <StepProgressBar {...props} />
        </div>
      )}
    </Sticky>
  </StickyWrapper>
));

export default StepProgressBarSticky;
