import { Component } from 'react';

import { Button, ButtonCheckbox } from 'porowneo-design';
import styled from 'styled-components';

import CloseButton from './CloseButton';

import type { AVAILABLE_COVERS } from '../../views/Results/Results.config';

const ButtonWrapper = styled('div')`
  padding-top: 10px;
`;

class MobileFilterModal extends Component<any, any> {
  constructor(props) {
    super(props);
    const { filteredCover, sortedCover } = props;
    this.state = {
      filter: filteredCover,
      sort: sortedCover,
    };
  }

  handleChangeFilter = ({
    preValues = {},
    values = {},
    showAll = false,
  }: {
    preValues?: Record<keyof typeof AVAILABLE_COVERS, boolean> | Record<never, never>,
    values?: Record<keyof typeof AVAILABLE_COVERS, boolean> | Record<never, never>,
    showAll?: boolean,
  }) => {
    const {
      props: {
        onChange,
      },
      state: {
        filter,
      },
    } = this;

    if (showAll) {
      this.setState({
        filter: onChange('showAll'),
      });
    }
    if (Object.keys(preValues).length > 0 && Object.keys(values).length > 0) {
      Object.keys(values).forEach((cover) => {
        if (preValues[cover] !== values[cover]) {
          this.setState({
            filter: onChange(cover, filter),
          });
        }
      });
    }
  }

  handleSort = (sorted) => {
    const { onChangeSort } = this.props;
    this.setState({
      sort: onChangeSort(sorted),
    });
  }

  override render() {
    const {
      props: {
        availableCovers,
        closeModal,
      },
      state: {
        filter,
        sort,
      },
      handleSort,
      handleChangeFilter,
    } = this;

    return (
      <div className="wrapper wrapper--filter">
        <CloseButton closeModal={closeModal} />
        <div className="wrapper__header--filter">

          <h1 className="wrapper__header-title">Filtry</h1>
        </div>

        <h3 className="wrapper__filter-title">Sortuj</h3>
        <Button
          id="descSortButton"
          handleClick={() => handleSort('desc')}
          color={sort ? 'primary' : 'secondary'}
          fullWidth
        >
          Najwyższa cena
        </Button>
        <Button
          id="ascSortButton"
          handleClick={() => handleSort('asc')}
          color={!sort ? 'primary' : 'secondary'}
          fullWidth
        >
          Najniższa cena
        </Button>

        <div className="hr" />

        <h3 className="wrapper__filter-title">Zakres ubezpieczenia</h3>
        <div className="btn-inline-list" role="group" id="mobileFilter">
          <ButtonCheckbox
            id="mobileFilterButtons"
            availableValues={Object.entries(availableCovers as typeof AVAILABLE_COVERS).map(([key, val]: [string, 'OC' | 'AC' | 'NNW']) => ({
              value: key,
              label: val,
            }))}
            value={filter}
            multiSelect
            disabled={filter.filter(val => !Object.keys(availableCovers as typeof AVAILABLE_COVERS).includes(val))}
            handleChange={(_id, value) => {
              const preValues = {
                oc: filter.includes('oc'),
                ac: filter.includes('ac'),
                nnw: filter.includes('nnw'),
              };

              const values = {
                oc: value.includes('oc'),
                ac: value.includes('ac'),
                nnw: value.includes('nnw'),
              };

              handleChangeFilter({ preValues, values });
            }}
          />
          <ButtonWrapper>
            <Button
              id="showAllButton"
              color="secondary"
              variant="contained"
              fullWidth
              handleClick={() => handleChangeFilter({ showAll: true })}
            >
              Pokaż wszystkie
            </Button>
          </ButtonWrapper>
        </div>
        <ButtonWrapper>
          <Button
            id="saveButton"
            variant="contained"
            fullWidth
            handleClick={() => closeModal()}
          >
            Zapisz
          </Button>
        </ButtonWrapper>
      </div>
    );
  }
}

export default MobileFilterModal;
