import styled from 'styled-components';

import { StepOne } from '../../views/stepOne/StepOne';
import StepTwoWithHocValidation from '../../views/stepTwo/StepTwo';
import StepThreeWithHocValidation from '../../views/stepThree/StepThree';
import StepFourWithHocValidation from '../../views/stepFour/StepFour';

export const Wrapper = styled.div`
  padding-bottom: 43px;
`;

const StepsWrapper = ({
  step,
  prevStep,
  nextStep,
}) => (
  <Wrapper>
    {step === 1 && (
      <StepOne
        callBackIfValid={nextStep}
      />
    )}
    {step === 2 && (
      <StepTwoWithHocValidation
        callBackIfValid={nextStep}
        prevStep={prevStep}
      />
    )}
    {step === 3 && (
      <StepThreeWithHocValidation
        callBackIfValid={nextStep}
        prevStep={prevStep}
      />
    )}
    {step === 4 && (
      <StepFourWithHocValidation
        callBackIfValid={nextStep}
        prevStep={prevStep}
      />
    )}
  </Wrapper>
);

export default StepsWrapper;
