/* eslint-disable camelcase,import/no-self-import */
import log from 'loglevel';
import moment from 'moment';
import pickBy from 'lodash/pickBy';
import findIndex from 'lodash/findIndex';
import {
  mapOfferDetailsData, sendError,
} from 'porowneo-ui';

import FIELDS from '../../config/fields';
import { FULL_SALE_INSURERS } from '../../config/Partners.config';
import { DATE_FORMAT_YYYY_MM_DD, YEAR } from '../../config/formats';
import { getBestOffers } from '../../views/Results/utils/data/Offers';
import PZU_MOCK from '../../config/pzuMockResult';
import { configForOffersDetails } from '../OfferDetailsHelpers/MapDataForMail';

import * as EVENT_NAMES from './TagManagerEventNames';
import * as TMU from './TagManagerUtils';

import type { TCovers } from '../../types/Covers.type';
import type { TUserAgreements } from 'porowneo-ui';
import type { TLocator } from '../../types/Locator.type';
import type { IRecommendedBasket } from '../../views/Results/utils/data/Offers';
import type { IStepFourKeyValues } from '../../views/stepFour/StepFour.types';
import type { TFullSalePartnerName, TPartnerName } from '../../types/Partners.type';
import type { TSteps } from '../../components/StepContainer/StepContainer';
import type { THandleChangeValue } from '../../views/stepOne/StepOne.types';
import type { IDaemonBasket } from '../../types/DaemonOutput.type';
import type { IStepTwoValues } from '../../views/stepTwo/StepTwo.types';
import type { TAddress, TAddressRegistered } from '../../types/Address.type';
import type { TInsurerBasePerson, TInsurerType, TYoungPerson } from '../../types/Insurer.type';
import type { TCoverType, TDate, TPaymentType } from '../../reducers/Payload';
import type {
  IExitDataLayer,
  TFullSaleStepNameDataLayer,
  IThxModalDataLayerParams,
  ITranslatedInsurerType,
  TTranslatedInsurerType,
  IConfigForFullSaleEditsReturnType,
  IExtraData,
  IGetDataReturnType,
  IListTypes,
  ISourceTypes,
  TAgreementKey,
  TEcommerceAction,
  TGtmStep,
  TMappedPayment,
  TOfferDetails,
  TPushFunctionDataProps,
  TPushFunctionEventName,
  TSource,
  ICreateConfigOfferDetailsMainData,
  IProductAllInCommonData,
  ICreateConfigOfferDetailsOfferData,
  IDataForGtm,
  IMappedDataCommon,
  TCreateConfigOfferDetailsReturnType,
  IProductCommon,
  TEcommerceActionBase,
  ICreateConfigForFullSaleEdits,
  TStepOneGtm,
  TStepThreeGtm,
  IMappedAditional, TMainDriver,
} from '../types/TagManagerUtils.type';
import type { IRowCovers } from '../ResultsHelpers/ResultsFilterUtils';

export const createArrayWithUniqValues = <T extends string | number>(arr: T[]) => (arr && Array.isArray(arr) ? Array.from(new Set(arr)) : []);

const {
  EVENTS, selectCommsConsent, selectMarketingConsent, selectTerms,
} = EVENT_NAMES;

const { dataLayer } = window;

export const INFO_GTM_ERROR = 'There was an error while setting up error about payment info in GTM';

export const pushStepToDataLayer = (step: TSteps) => {
  const mapStepsToGTMEvents = (stepNumber: TSteps) => {
    const GTMStepMapping = {
      1: 'dane-pojazdu',
      2: 'dane-podstawowe',
      3: 'dane-uzytkownikow',
      4: 'dane-ubezpieczenia',
      5: 'porownanie-ofert',
    } as const;
    return GTMStepMapping[stepNumber];
  };

  dataLayer.push({ stepName: mapStepsToGTMEvents(step), event: 'virtualPageView' });
};

export const pushExitPopup = () => {
  dataLayer.push({ event: 'Exit Popup' });
};

export const pushExitPopupLead = () => {
  dataLayer.push({ event: 'Lead Exit Popup' });
};

export const exitDataLayer: IExitDataLayer = { pushExitPopup, pushExitPopupLead };

export const pushBuyOnlineOnOfferDetailsToDataLayer = (insurer: TPartnerName) => {
  dataLayer.push({ insuranceCompany: insurer, event: 'InsuranceDetailsBuy' });
};

export const pushBuyOnlineOnResultStepToDataLayer = (insurer: TPartnerName) => {
  dataLayer.push({
    insuranceCompany: insurer,
    product: 'Moto',
    event: 'clickButtonBuyOnline',
  });
};

export const pushFullSaleStepToDataLayer = (insurer: TPartnerName, price: IDaemonBasket['total'], stepName: TFullSaleStepNameDataLayer = 'dane-do-polisy') => {
  dataLayer.push({
    insuranceCompany: insurer,
    insurancePremium: price,
    stepName,
    event: 'virtualPageView',
  });
};

export const pushCallThxModalToDataLayer = (params: IThxModalDataLayerParams) => {
  const {
    name, total, installmentPayment, installmentsNumber, policyNumber, locator,
  } = params;

  dataLayer.push({
    insuranceCompany: name,
    insurancePremium: total,
    paymentPrice: installmentPayment,
    paymentPlan: installmentsNumber,
    product: 'Moto',
    policyID: policyNumber,
    userLocator: locator,
    event: 'Purchase',
  });
};

export const pushCallModalUfgFullSaleToDataLayer = (insurer: TFullSalePartnerName) => {
  dataLayer.push({ insuranceCompany: insurer, event: 'PersonalDetailsModal' });
};

export const pushSaveModalUfgDataFullSaleToDataLayer = (insurer: TFullSalePartnerName) => {
  dataLayer.push({ insuranceCompany: insurer, event: 'PersonalDetailsModalSaved' });
};

export const setStepOneGtm = (key: TStepOneGtm, value: THandleChangeValue) => {
  const events = {
    brand: { event: 'selectBrand', brand: value, source: 'other' },
    model: { event: 'selectModel', car_model: value },
    productionYear: { event: 'selectProductionYear', car_prod_year: value },
    fuel: { event: 'selectFuelType', car_fuel_type: value },
    transmission: { event: 'selectTransmission', car_trans: value },
    eurotaxDoors: { event: 'selectEurotaxDoors', eurotax_doors: `${value} Liczba drzwi` },
    eurotaxPower: { event: 'selectEurotaxPower', eurotax_power: value },
    eurotaxVersion: { event: 'selectEurotaxModel', eurotax_model: value },
    infoexVersion: { event: 'selectInfoEkspertModel', infoekspert_model: value },
    protectionBegins: { event: 'selectInsuranceDate', insurance_date: value },
    coverTypeSelect: { event: 'selectInsuranceType', insurance_type: value },
    coverTypeDeselect: { event: 'deselectInsuranceType', insurance_type: value },
  };

  if (events[key]) dataLayer.push({ ...events[key], formStep: 1 });
};

export const setStepTwoGtm = (key: keyof IStepTwoValues, value: IStepTwoValues[typeof key] | number) => {
  const events = {
    carRegisteredOn: { event: 'selectUserType', user_type: value },
    carUse: { event: 'selectUsageType', usage_type: value },
    submitDanePodstawowe: { event: 'submitDanePodstawowe', usage_type: value },
    carMileage: { event: 'inputMileage', mileage: value },
    planToDriveThisYear: { event: 'selectPlannedMileage', planned_mileage: value },
    whichOwnerInOrder: { event: 'selectOwner', owner_type: value },
    firstCountry: { event: 'selectRegCountry', first_reg_country: value },
    firstDateRegistered: { event: 'inputRegDate', first_reg_date: value }, // firstDateRegistered
    dateRegisteredActualOwner: { event: 'inputCurrentOwnerRegDate', current_reg_date: value }, // dateRegisteredActualOwner
    carRegistrationNumber: { event: 'inputRegNo', reg_no: value },
    carRegistrationNumberNotKnown: { event: 'selectForgetRegNo' },
    carWheelSide: { event: 'selectSteeringSide', steering_side: value },
    parkingPlace: { event: 'selectParkingLocation', parking_location: value },
    timeUsedAbroad: { event: 'selectMonthsAbroad', months_abroad: value },
    damaged: { event: 'selectDamageStatus', damage_status: value },
    lastInsurer: { event: 'selectLastInsurer', last_insurer: value },
  };

  if (events[key]) dataLayer.push({ ...events[key], formStep: 2 });
};

export const setStepThreeGtm = (
  key: TStepThreeGtm,
  value: string | boolean | number,
  insurerType: '' | TInsurerType = '',
  addressType: '' | TAddress | TAddressRegistered = '',
) => {
  const translateInsurerTypes: ITranslatedInsurerType = {
    owner: 'Owner',
    coOwner: 'CO1',
    coCoOwner: 'CO2',
    driver: 'Main',
    youngPerson: 'Youngest',
  };
  const insurerGtmType: TTranslatedInsurerType = translateInsurerTypes[insurerType];
  const addressGtmType = addressType === 'address' ? 'Residence' : 'Registered';
  const events = {
    firstName: { event: `input${insurerGtmType}Firstname` },
    lastName: { event: `input${insurerGtmType}Surname` },
    pesel: { event: `input${insurerGtmType}PESEL` },
    birthdate: { event: `input${insurerGtmType}Birthday`, birthday: value },
    sex: { event: `select${insurerGtmType}Gender`, gender: value },
    licenseYear: { event: `select${insurerGtmType}LicenseYear`, driving_license_year: value },
    licenseMonth: { event: `select${insurerGtmType}LicenseMonth`, driving_license_month: value },
    maritalStatus: { event: `select${insurerGtmType}MaritalStatus`, marital_status: value },
    zipCode: { event: `input${insurerGtmType}${addressGtmType}PostCode`, [`${addressGtmType?.toLowerCase()}_city`]: value },
    city: { event: `select${insurerGtmType}${addressGtmType}City`, [`${addressGtmType?.toLowerCase()}_city`]: value },
    coowners: { event: 'selectCoownerCount', coowner_count: value },
    mainDriver: { event: 'selectMainDriver', main_driver: value },
    driverUnder26: { event: 'selectBelow26Driver', under_26: value },
    hasChildrenU26: { event: 'selectOwnerChildren', owner_children: value },
    numberOfChilds: { event: 'selectUnder18Driver', under_18_count: value },
    ageOldestChild: { event: 'selectEldestChildrenAge', eldest_child_age: value },
  };

  if (events[key]) dataLayer.push({ ...events[key], formStep: 3 });
};

export const setStepFourGtm = (
  key: keyof IStepFourKeyValues,
  value: IStepFourKeyValues[typeof key],
  type: TInsurerBasePerson | TYoungPerson,
  cover: TCoverType,
) => {
  const translateInsurerTypes: ITranslatedInsurerType = {
    owner: 'Owner', coOwner: 'CO1', coCoOwner: 'CO2', driver: 'Main', youngPerson: 'Youngest',
  };
  const insurerType = translateInsurerTypes[type] || '';
  const coverTypeEvent = cover?.toUpperCase() || '';
  const coverTypeDataName = cover?.toLowerCase() || '';

  const events = {
    yearsBuying: { event: `select${insurerType}${coverTypeEvent}Years`, [`${coverTypeDataName}_years`]: value }, // oc
    damagesPaid: { event: `select${insurerType}${coverTypeEvent}DamagesPaid`, [`claim_from_${coverTypeDataName}`]: value }, // check what to send
    firstDamage: { event: `select${insurerType}${coverTypeEvent}FirstDamage`, damage_period: value },
    secondDamage: { event: `select${insurerType}${coverTypeEvent}secondDamage`, damage_period: value },
    thirdDamage: { event: `select${insurerType}${coverTypeEvent}ThirdDamage`, damage_period: value },
    fourthDamage: { event: `select${insurerType}${coverTypeEvent}FourthDamage`, damage_period: value },
    fifthDamage: { event: `select${insurerType}${coverTypeEvent}FifthDamage`, damage_period: value },
    insuranceHistorySame: { event: `select${insurerType}ACHistory`, ac_same_history: value },
  };

  if (events[key]) dataLayer.push({ ...events[key], formStep: 4 });
};

export const pushFieldEventToDataLayer = (
  eventType: string,
  data: number | string | TUserAgreements | boolean | null | Date | string[],
  sourceType = '',
) => {
  const foundEvent = EVENTS[eventType];
  if (foundEvent) {
    const { formStep, event, dataName } = foundEvent;
    const step = formStep ? { formStep } : {};
    const source = sourceType ? { source: sourceType } : {};
    const value = dataName.length ? { [dataName]: data } : {};
    dataLayer.push({
      event, ...value, ...source, ...step,
    });
  }
};

// HELPERS
export const pushAgreementsToDataLayer = (
  agreementKey: TAgreementKey,
  value: number | string | TUserAgreements | boolean | null | Date | string[],
) => {
  if (agreementKey) {
    switch (agreementKey) {
    case 'REG_PPL':
      return TMU.pushFieldEventToDataLayer(selectTerms.event, value);
    case 'MARK_TEL':
      return TMU.pushFieldEventToDataLayer(selectCommsConsent.event, value);
    case 'MARK_MAIL':
      return TMU.pushFieldEventToDataLayer(selectMarketingConsent.event, value);
    default: {
      const x: never = agreementKey;
      log.debug(`Error in pushAgreementsToDataLayer. Invalid agreement key: ${x as string}`);
      break;
    }
    }
  }
  return null;
};

const preparePushFunction = (eventName: TPushFunctionEventName) => (dataProperties: TPushFunctionDataProps = {}) => {
  dataLayer.push({ event: eventName, ...dataProperties });
};

const getQuestionnaire = (step: TGtmStep) => `Questionnaire - step ${step}`;

export const gtmStepMapping = (step: string | number) => {
  switch (step?.toString()) {
  case '1':
    return getQuestionnaire('1');
  case '2':
    return getQuestionnaire('2');
  case '3':
    return getQuestionnaire('3');
  case '4':
    return getQuestionnaire('4');
  default:
    return step ?? '';
  }
};

export const pushInsurerRedirectToDataLayer = preparePushFunction('Redirect');
export const pushLeaveContactModalEventToDataLayer = preparePushFunction('Contact modal');
export const pushLeadModalEventToDataLayer = preparePushFunction('Lead modal');
export const pushLockModalEventToDataLayer = preparePushFunction('Lock modal');
export const pushClickProductToDataLayer = preparePushFunction('clickProduct');
export const pushOfferDetailsToDataLayer = preparePushFunction('InsuranceDetails');
export const viewCheckoutPersonalData = preparePushFunction('viewCheckoutPersonalData');
export const clickChangeInsurance = preparePushFunction('clickChangeInsurance');
export const submitCheckoutPersonalData = preparePushFunction('submitCheckoutPersonalData');
export const viewCheckoutCarData = preparePushFunction('viewCheckoutCarData');
export const clickInsuranceDetails = preparePushFunction('clickInsuranceDetails');

export const clickEditPersonalData = preparePushFunction('clickEditPersonalData');
export const selectInstallmentOption = preparePushFunction('selectInstallmentOption');
export const selectPaymentMethod = preparePushFunction('selectPaymentMethod');
export const submitCheckoutCarData = preparePushFunction('submitCheckoutCarData');
export const viewThankYouPopup = preparePushFunction('viewThankYouPopup');
export const viewPaymentErrorPopup = preparePushFunction('viewPaymentErrorPopup');
export const viewCheckoutError = preparePushFunction('viewCheckoutError');
export const submitCheckoutTryAgain = preparePushFunction('submitCheckoutTryAgain');
export const submitCheckoutErrorPopup = preparePushFunction('submitCheckoutErrorPopup');
export const viewCheckoutErrorPopup = preparePushFunction('viewCheckoutErrorPopup');
export const closeCheckoutErrorPopup = preparePushFunction('closeCheckoutErrorPopup');

export const startQuestionnaire = preparePushFunction('startQuestionnaire');
export const viewInsuranceList = preparePushFunction('viewInsuranceList');
export const selectInsuranceFilter = preparePushFunction('selectInsuranceFilter');
export const deselectInsuranceFilter = preparePushFunction('deselectInsuranceFilter');
export const sortPriceAscending = preparePushFunction('sortPriceAscending');
export const sortPriceDescending = preparePushFunction('sortPriceDescending');
export const clickEditData = preparePushFunction('clickEditData');
export const clickCompleteData = preparePushFunction('clickCompleteData');
export const viewNoOffers = preparePushFunction('viewNoOffers');
export const clickShowOCOffers = preparePushFunction('clickShowOCOffers');
export const viewExtraDataPopup = preparePushFunction('viewExtraDataPopup');
export const closeExtraDataPopup = preparePushFunction('closeExtraDataPopup');
export const completeExtraDataPopup = preparePushFunction('completeExtraDataPopup');
export const validationError = preparePushFunction('validationError');

// Load data modal
export const viewWelcomeAgainPopup = preparePushFunction('viewWelcomeAgainPopup');
export const submitWelcomeAgainPopup = preparePushFunction('submitWelcomeAgainPopup');
export const closeWelcomeAgainPopup = preparePushFunction('closeWelcomeAgainPopup');

export const handleCallGtmFieldErrorStep = (step: string | number) => (id: string) => {
  TMU.validationError({
    source: gtmStepMapping(step),
    field_name: FIELDS?.[id]?.title ?? FIELDS?.[id]?.name ?? id ?? '',
  });
};

export const convertToYAndN = (value: 'Tak' | string) => {
  if (value?.toLowerCase() === 'tak') {
    return 'Y';
  }
  return 'N';
};

const joinWithComa = (initial: string, current: string) => (initial === '' ? `${current}` : `${initial}, ${current}`);

export const getCovers = (covers: Record<string, unknown>) => Object.keys(covers).reduce((initial, current) => (
  covers[current] ? joinWithComa(initial, current) : initial
), '');

export const getDateDiff = (date: null | TDate, unitOfTime: 'days' | 'years', dateFormat: string) => {
  const given = moment(date, dateFormat).startOf(unitOfTime);
  const current = moment().startOf(unitOfTime);
  return given.diff(current, unitOfTime);
};

export const getPaymentMethodMapping = (value: TPaymentType | TMappedPayment): TMappedPayment => (value?.toLowerCase() === 'banktransfer' ? 'banktransfer' : 'payu');

const generateMainDriver = (driver: TInsurerBasePerson): TMainDriver => {
  const ownersMapping = {
    owner: 'Owner',
    coOwner: 'Co-owner 1',
    coCoOwner: 'Co-owner 2',
    driver: 'Other',
  } as const;
  return ownersMapping[driver];
};

export const createConfigForFullSaleEdits = ({
  insurerOffer,
  dataForGtm = {},
}: ICreateConfigForFullSaleEdits): IConfigForFullSaleEditsReturnType => {
  const offerId = localStorage?.getItem('fullFormBasketId');
  const offer_id = dataForGtm?.basketId ? dataForGtm.basketId : offerId;

  try {
    const {
      installment_option, payment_method, error_message, error_type,
    } = dataForGtm;
    const offerIdData = offer_id ? { offer_id } : {};
    const MAX_LENGTH_ERROR_MESSAGE = 1024;

    if (typeof insurerOffer !== 'string' && !insurerOffer?.calculation_id) {
      throw new Error('Calculation id is required');
    }

    const installmentsData = installment_option ? { installment_option } : {};
    const paymentData: { payment_method?: TMappedPayment } = payment_method ? { payment_method: getPaymentMethodMapping(payment_method) } : {};
    let errMsg = error_message;
    if (typeof error_message === 'string' && error_message?.length > MAX_LENGTH_ERROR_MESSAGE) {
      errMsg = error_message.slice(0, MAX_LENGTH_ERROR_MESSAGE);
    }
    const errorData = errMsg ? { error_message: errMsg } : {};
    const errorType = error_type ? { error_type } : {};
    const calculationId = typeof insurerOffer === 'string' ? insurerOffer : insurerOffer?.calculation_id;

    return {
      calculation_id: calculationId,
      ...offerIdData,
      ...installmentsData,
      ...paymentData,
      ...errorData,
      ...errorType,
    };
  } catch (e) {
    log.warn('There was an error while creating config for full sale GTM edits');
    sendError(e, false, {
      method: 'createConfigForFullSaleEdits',
    });
    return {};
  }
};

export const getPositionFromRecommendation = (type: 'max' | 'optimum' | string) => {
  if (type === 'max') {
    return 3;
  }
  if (type === 'optimum') {
    return 2;
  }
  return 1;
};

export const sourceTypes: ISourceTypes = { insuranceListSource: 'insurance list', offerDetailsSource: 'szczegoly oferty' };

export const listTypes: IListTypes = { carGeneralList: 'car - general', carRecommendedList: 'car - recommended' };

export const getData = (
  props: TOfferDetails,
  ecommerce: TEcommerceAction,
  source: TSource,
  additionalData: Partial<IExtraData> = {},
): IGetDataReturnType => {
  const extraData: typeof additionalData = {};

  if (additionalData) {
    Object.entries(additionalData).forEach(([param, val]) => {
      extraData[param] = val;
    });
  }
  const {
    policyNumber,
    error_type,
    error_message,
  } = extraData;

  const sourceInfo = source ? { source } : {};
  const ecommerceInfo = ecommerce ? { ecommerce } : {};
  const policyNumberInfo = policyNumber ? { transaction_id: policyNumber } : {};
  const errorInfo = error_type && error_message ? { error_type, error_message } : {};

  const coverSelectedOnResults = sessionStorage.getItem('filteredCover');
  const {
    offerDetails,
    payload,
    payload: {
      locator,
      stepOne: { protectionBegins, vehicle: { productionYear } },
      stepThree: {
        owner, coOwner, coCoOwner, coowners, driverUnder26, mainDriver,
      },
      stepFour: {
        ownerInsuranceHistory: {
          insuranceHistorySame,
          ocInsuranceHistory: {
            damagesPaid,
            yearsBuying,
          },
        },
      },
    },
  } = props;

  const fullFormBasketId = localStorage.getItem('fullFormBasketId');
  const basketIdFromForm = fullFormBasketId ? { basket_id: Number(fullFormBasketId) } : {};

  return {
    insurerOffer: { ...offerDetails, ...basketIdFromForm },
    dataForGtm:
        {
          locator,
          productionYear,
          coowners,
          protectionBegins: protectionBegins || null,
          dateOfBirthOwner: payload?.stepThree?.owner?.birthdate,
          filteredCover: coverSelectedOnResults ? JSON.parse(coverSelectedOnResults) : [],
          installment_option: payload?.stepTwoFs?.installments || 1,
          payment_method: payload?.stepTwoFs?.paymentType,
          installment_price: offerDetails.totalInstalmentsCalc,
          dateOfBirthCoOwner: coOwner?.birthdate || '',
          dateOfBirthCoCoOwner: coCoOwner?.birthdate || '',
          licenseDateOwner: owner.licenseDate,
          damagesPaidOcOwner: damagesPaid,
          yearsBuyingOcOwner: yearsBuying,
          insuranceHistorySameOwner: insuranceHistorySame,
          driverUnder26,
          mainDriver,
          ...policyNumberInfo,
          ...errorInfo,
        },
    ...sourceInfo,
    ...ecommerceInfo,
  };
};

export const createConfigOfferDetails = (
  mainData: ICreateConfigOfferDetailsMainData,
): TCreateConfigOfferDetailsReturnType => {
  try {
    const {
      insurerOffer,
      // could be done in results
      dataForGtm: {
      // from store
        locator,
        productionYear,
        protectionBegins,
        dateOfBirthOwner,
        dateOfBirthCoOwner,
        dateOfBirthCoCoOwner,
        licenseDateOwner,
        insuranceHistorySameOwner,
        driverUnder26,
        mainDriver,
        damagesPaidOcOwner,
        yearsBuyingOcOwner,
        coowners,
        // from result state
        notHiddenList,
        // payment
        installment_option,
        payment_method,
        installment_price,
        transaction_id,
      },
      // custom
      source,
      ecommerce,
      position,
      list,
    } = mainData;

    // TODO FORMMOTO-3155 remap it so it will be using now UI no separate implementation
    const offerData: ICreateConfigOfferDetailsOfferData = configForOffersDetails(insurerOffer, { year: productionYear });

    const getPosition = () => {
      if (list === listTypes.carRecommendedList) {
        return getPositionFromRecommendation(insurerOffer?.type || '');
      }
      if (notHiddenList?.some(row => row.total)) {
        return findIndex(notHiddenList, row => row.total === insurerOffer?.total) + 2;
      }
      return 0;
    };

    const positionInResults = getPosition();

    const filteredCovers = pickBy(insurerOffer?.covers, (_value: boolean, key: string) => key !== 'glass' && key !== 'tires');

    const insuranceType: string[] = [];

    if (insurerOffer?.covers) {
      Object.entries(insurerOffer?.covers).forEach(([cover, isIncluded]) => {
        if (isIncluded) {
          insuranceType.push(cover);
        }
      });
    }

    const coversAsString = getCovers(filteredCovers);

    const offerListFromResults = sessionStorage.getItem('offer_list') || list;

    const offerList = offerListFromResults ? { offer_list: offerListFromResults } : {};

    const idOfBasket = insurerOffer?.basket_id || localStorage.getItem('fullFormBasketId');

    const basket = idOfBasket?.toString?.();

    const offer_name = `${insurerOffer?.name} - ${coversAsString} - ${idOfBasket || ''}`;

    const sourceData = source ? { source } : {};

    const positionObject = positionInResults ? { position: positionInResults } : {};

    const installmentsOption = installment_option ? { installment_option } : {};

    const installmentsPrice = installment_price ? { installment_price } : {};

    const transactionId = transaction_id ? { transaction_id } : {};

    const paymentMethod = payment_method ? { payment_method: getPaymentMethodMapping(payment_method) } : {};

    const productAllInCommon = (data: IProductAllInCommonData) => {
      const {
        id, productName, price, category, brand, variant,
      } = data;
      return ({
        id,
        name: productName,
        price,
        category,
        brand,
        variant,
      });
    };

    const productCommon = productAllInCommon({
      id: basket || '',
      productName: offer_name,
      price: insurerOffer?.total?.toString() || '',
      category: 'moto',
      brand: insurerOffer?.name || '',
      variant: coversAsString,
    });

    const ecommerceConfig = {
      add: {
        ecommerce: {
          currencyCode: 'PLN',
          add: {
            products: [
              {
                ...productCommon,
                list,
                quantity: 1,
                ...positionObject,
              },
            ],
          },
        },
      },
      remove: {
        ecommerce: {
          currencyCode: 'PLN',
          remove: {
            products: [{
              ...productCommon,
              quantity: 1,
            }],
          },
        },
      },
      detail: {
        ecommerce: {
          currencyCode: 'PLN',
          detail: {
            products: [{
              ...productCommon,
              ...positionObject,
              list,
            }],
          },
        },
      },
      click: {
        ecommerce: {
          currencyCode: 'PLN',
          click: {
            actionField: { list },
            products: [{
              ...productCommon,
              ...positionObject,
            }],
          },
        },
      },
      checkout: {
        ecommerce: {
          currencyCode: 'PLN',
          checkout: {
            actionField: { step: 1 },
            products: [{
              ...productCommon,
              quantity: 1,
            }],
          },
        },
      },
      checkoutStep: {
        ecommerce: {
          checkout: {
            actionField: { step: 2 },
          },
        },
      },
      checkoutStepSuccess: {
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
              option: `${installmentsOption.installment_option || ''} | ${paymentMethod.payment_method || ''}`,
            },
          },
        },
      },
    };

    const eCommerceData = ecommerce && ecommerce !== 'impressions' && ecommerceConfig?.[ecommerce] ? ecommerceConfig[ecommerce] : {};

    const {
      oc: {
        oc_bls,
      },
      ac: {
        offerExist: acOfferExist,
        ac_type,
        ac_damage_to_be_insured,
        ac_when_ac_work,
        ac_insurance_sum,
        ac_territorial_scope,
        ac_ins_contribution,
        ac_minimum_damage_value,
        ac_ins_damage_settlement,
        ac_repair_on_parts,
        ac_fix_in_aso,
        ac_ins_depreciation_value,
      },
      nnw: {
        offerExist: nnwOfferExist,
        nnw_insurance_sum,
        nnw_covered_persons,
        nnw_territorial_scope,
      },
      as: {
        offerExist: asOfferExist,
        as_when_aid_is_available,
        as_territorial_scope,
        as_insurance_sum,
        as_how_far_from_home,
        as_ins_tow_distance,
        as_onsite_repair,
        as_substitute_car,
        as_additional_help,
      },
    } = offerData;

    const mappedDataCommon: IMappedDataCommon = {
      insurance_company: insurerOffer?.name || '',
      calculation_id: locator,
      offer_id: basket,
      offer_name,
      car_age: parseInt(moment().format(YEAR), 10) - (productionYear ?? 0),
      insurance_type: insuranceType,
      renewal_date_diff: getDateDiff(protectionBegins || null, 'days', DATE_FORMAT_YYYY_MM_DD),
      owner_age: getDateDiff(dateOfBirthOwner, 'years', DATE_FORMAT_YYYY_MM_DD) * -1,
      coowner_count: coowners,

      ac_insurance: convertToYAndN(acOfferExist?.value[0] || 'Brak'),
      nnw_insurance: convertToYAndN(nnwOfferExist?.value[0] || 'Brak'),
      assist_insurance: convertToYAndN(asOfferExist?.value[0] || 'Brak'),
      direct_claim: convertToYAndN(oc_bls?.value[0] || 'Brak'),
    };

    const coOwnerAge = dateOfBirthCoOwner ? getDateDiff(dateOfBirthCoOwner, 'years', DATE_FORMAT_YYYY_MM_DD) * -1 : undefined;
    const coCoOwnerAge = dateOfBirthCoCoOwner ? getDateDiff(dateOfBirthCoCoOwner, 'years', DATE_FORMAT_YYYY_MM_DD) * -1 : undefined;
    const ageDiffOwnerAndCo = coOwnerAge ? mappedDataCommon.owner_age - coOwnerAge : undefined;
    const ageDiffCoAndCoCoOwner = coOwnerAge && coCoOwnerAge ? coOwnerAge - coCoOwnerAge : undefined;

    const mappedDataAditional: IMappedAditional = {
      coowner_age_1: coOwnerAge,
      coowner_age_2: coCoOwnerAge,
      age_diff_1: ageDiffOwnerAndCo,
      age_diff_2: ageDiffCoAndCoCoOwner,
      driving_license_year: licenseDateOwner,
      main_driver: mainDriver ? generateMainDriver(mainDriver) : undefined,
      oc_years: yearsBuyingOcOwner || undefined,
      under_26: driverUnder26 ? 'Y' : 'N',
      claim_from_oc: damagesPaidOcOwner ? 'Y' : 'N',
      ac_same_history: insuranceHistorySameOwner ? 'Y' : 'N',
    };

    if (source === 'viewThankYouPopup') {
      return {
        ...mappedDataCommon,

        ...offerList,
        insurance_price: insurerOffer?.total,

        ...installmentsPrice,
        ...installmentsOption,
        ...paymentMethod,
        ...transactionId,
      };
    }

    if (source === 'clickInsuranceDetails') {
      return {
        ...mappedDataCommon,
        ...offerList,
        insurance_price: insurerOffer?.total,
      };
    }

    if (ecommerce === 'checkoutStepSuccess') {
      return {
        ...mappedDataCommon,

        ...installmentsPrice,
        ...installmentsOption,
        ...paymentMethod,
        ...offerList,
        insurance_price: insurerOffer?.total,

        ...eCommerceData,
      };
    }

    if (ecommerce === 'checkoutStep') {
      return {
        ...mappedDataCommon,

        ...offerList,
        insurance_price: insurerOffer?.total,

        ...eCommerceData,
      };
    }

    if (source === 'fullSaleStepTwo') {
      return {
        ...mappedDataCommon,

        ...offerList,
        insurance_price: insurerOffer?.total,
      };
    }

    if (ecommerce === 'impressions') {
      return {
        ...productCommon,
        position,
        list,
      };
    }

    if (ecommerce === 'remove') {
      return ({
        calculation_id: locator,
        offer_id: basket,
        offer_name,
        insurance_price: insurerOffer?.total,
        ...offerList,
        ...sourceData,

        ...eCommerceData,
      });
    }
    if (ecommerce === 'checkout') {
      return {
        ...mappedDataCommon,

        ...offerList,
        insurance_price: insurerOffer?.total,

        ...eCommerceData,
      };
    }

    if (ecommerce === 'click') {
      return {
        ...mappedDataCommon,

        ...offerList,
        internal_checkout: FULL_SALE_INSURERS.includes(insurerOffer?.name as TFullSalePartnerName), // Narrow with assertion

        ...eCommerceData,
      };
    }

    return {
      ...mappedDataCommon,
      ...mappedDataAditional,
      offer_list: list,
      ...sourceData,

      // from mapped offerData
      direct_claim: convertToYAndN(oc_bls?.value[0] || 'Brak'),
      ac_insurance: convertToYAndN(acOfferExist.value[0] || 'Brak'),
      ac_type: ac_type?.value[0],
      ac_insured: ac_damage_to_be_insured?.value,
      ac_when: ac_when_ac_work?.value,
      ac_sum: ac_insurance_sum?.value[0],
      ac_where: ac_territorial_scope?.value.join(' '),
      ac_own_contribution: ac_ins_contribution?.value[0],
      ac_min_value: ac_minimum_damage_value?.value[0],
      ac_injury_elimination: ac_ins_damage_settlement?.value[0],
      ac_repair_parts: ac_repair_on_parts?.value[0],
      ac_repair_aso: ac_fix_in_aso?.value[0],
      ac_broken_parts: ac_ins_depreciation_value?.value[0],
      nnw_insurance: convertToYAndN(nnwOfferExist?.value[0] || 'Brak'),
      nnw_sum: nnw_insurance_sum?.value[0],
      nnw_coverage: nnw_covered_persons?.value,
      nnw_where: nnw_territorial_scope?.value,
      assist_insurance: convertToYAndN(asOfferExist?.value[0] || 'Brak'),
      assist_when: as_when_aid_is_available?.value,
      assist_where: as_territorial_scope?.value[0],
      assist_sum: as_insurance_sum?.value[0],
      assist_min_distance: as_how_far_from_home?.value[0],
      assist_towing: as_ins_tow_distance?.value[0],
      assist_on_site_repair: convertToYAndN(as_onsite_repair?.value[0] || 'Brak'),
      assist_replacement_car: as_substitute_car?.value[0],
      assist_additional_service: as_additional_help?.value,

      internal_checkout: FULL_SALE_INSURERS.includes(insurerOffer?.name as TFullSalePartnerName), // Narrow with assertion
      ...eCommerceData,
    };
  } catch (e) {
    log.warn('There was an error while creating config offer details for GTM');
    sendError(e, false, {
      method: 'createConfigOfferDetails',
    });
    return {};
  }
};

export const createConfigOfferDetailsPzu = (
  name: TPartnerName,
  locator: TLocator,
  basketId: IDaemonBasket['basket_id'],
  source: IListTypes['carGeneralList'] | IListTypes['carRecommendedList'],
) => {
  const sourceData = source ? { source } : {};

  return {
    ...sourceData,
    insuranceCompany: name,
    calculation_id: locator,
    offer_id: basketId,
    offer_name: 'pzu',
    internal_checkout: 'N',
  };
};

const pzuVariantOrEmpty = (pzuVariant: string) => (pzuVariant.length ? ` - ${pzuVariant}` : '');

export const getPzuEcommerceImpressionData = (pzuVariant: string) => ({
  id: 'pzu',
  name: `pzu${pzuVariantOrEmpty(pzuVariant)}`,
  price: '0',
  category: 'moto',
  brand: 'pzu',
  variant: pzuVariant,
  position: 1,
  list: listTypes.carGeneralList,
});
// TODO typing mapAdditionalRisksForRow https://porowneo.atlassian.net/browse/UI-166
export const isResultWithAdditionalOptions = (
  result: IDaemonBasket | IRecommendedBasket | typeof PZU_MOCK,
) => mapOfferDetailsData.mapDataAdditionalRisksHelpers.mapAdditionalRisksForRow(result as any)?.length > 0;

const isNotEmptyPrice = (total: unknown) => typeof (total) === 'number' && total > 0;

export const setInfoAboutResutlsInGtm = (
  resultWebsocets: IDaemonBasket[],
  coverType: TCovers[],
  locator: TLocator,
  fromStepFour: 'checkout' | 'questionnaire',
  matchCover: (
    rowCovers: IRowCovers,
    filteredCover: IDataForGtm['filteredCover'],
    type: 'results' | 'resultsWithSameRisk') => boolean,
  getDataForGtm: (...args: any[]) => IDataForGtm,
) => {
  try {
    const dataForGtm: IDataForGtm = getDataForGtm();

    const { filteredCover } = dataForGtm;

    const pzuVariant = filteredCover.length ? filteredCover.join(', ') : '';

    const ecommerce = 'impressions';

    const bestOfferResults = getBestOffers(resultWebsocets, coverType.includes('ac'));

    let displayedResults: IDaemonBasket[] = [];
    let displayedResultsWithSameRisk: IDaemonBasket[] = [];

    if (resultWebsocets && resultWebsocets.length && filteredCover) {
      displayedResults = resultWebsocets.filter(row => matchCover(row.covers, filteredCover, 'results') && isNotEmptyPrice(row.total) && row.status !== 'error');
      displayedResults = displayedResults.sort((a, b) => a.total - b.total);

      displayedResultsWithSameRisk = resultWebsocets.filter(row => matchCover(row.covers, filteredCover, 'resultsWithSameRisk') && isNotEmptyPrice(row.total) && row.status !== 'error');
      displayedResultsWithSameRisk = displayedResultsWithSameRisk.sort((a, b) => a.total - b.total);
    }

    const displayedResultsModifiedForGtm = [...displayedResults, ...displayedResultsWithSameRisk].map((insurerOffer, i) => createConfigOfferDetails({
      insurerOffer,
      dataForGtm,
      list: listTypes.carGeneralList,
      position: i + 2,
      ecommerce,
    }));

    const bestOfferResultsResultsModifiedForGtm = [...bestOfferResults].map(insurerOffer => createConfigOfferDetails({
      insurerOffer,
      dataForGtm,
      list: listTypes.carRecommendedList,
      position: getPositionFromRecommendation(insurerOffer?.type),
      ecommerce,
    }));

    const allDisplayedResults = [PZU_MOCK, ...displayedResults, ...displayedResultsWithSameRisk, ...bestOfferResults];

    const allResultsModifiedForGtm = [
      getPzuEcommerceImpressionData(pzuVariant),
      ...displayedResultsModifiedForGtm,
      ...bestOfferResultsResultsModifiedForGtm,
    ];

    viewInsuranceList({
      calculation_id: locator,
      offer_id: createArrayWithUniqValues(allDisplayedResults.map(result => result?.basket_id)),
      offer_count: allDisplayedResults.length,
      offer_additional_options: allDisplayedResults.filter(isResultWithAdditionalOptions)?.length,
      offer_missing_data: window.document.querySelectorAll('.results--empty button.result__details').length,
      source: fromStepFour || 'questionnaire',
      offer_name: createArrayWithUniqValues(allResultsModifiedForGtm.map(offer => (offer as IProductCommon)?.name)),
      ecommerce: {
        currencyCode: 'PLN',
        impressions: allResultsModifiedForGtm,
      },
    });
  } catch (e) {
    log.warn('There was an error while trying to set info in GTM about dislayed offer');
    sendError(e, false, {
      method: 'setInfoAboutResutlsInGtm',
    });
  }
};

export const offerHelperForGtmEvents = (
  offer: IDaemonBasket,
  dataForGtm: IDataForGtm,
  clickedBasketId: number,
  setClickedBasketId: (basketId: number) => void,
  list: IListTypes['carGeneralList'] | IListTypes['carRecommendedList'],
  sourceList: false | ISourceTypes['insuranceListSource'] | ISourceTypes['offerDetailsSource'],
  clickedBasketIds: number[],
) => {
  const {
    basket_id: basketId,
  } = offer;

  const createConfigForGtm = (source: false | typeof sourceList, ecommerce: TEcommerceActionBase) => createConfigOfferDetails({
    insurerOffer: offer,
    dataForGtm,
    source,
    ecommerce,
    list,
  });

  const configForGtm = createConfigForGtm(sourceList, 'add');

  const configForGtmContact = createConfigForGtm(sourceList, null);

  const configForGtmClick = createConfigForGtm(false, 'click');

  const clickedProduct = () => {
    if (clickedBasketId !== basketId && !clickedBasketIds?.includes(basketId)) {
      setClickedBasketId(basketId);
      pushClickProductToDataLayer(configForGtmClick);
    }
  };
  return {
    configForGtm,
    configForGtmContact,
    clickedProduct,
    createConfigForGtm,
  };
};

export const gtmValidPhoneNumber = (_id: string, val: string): void => pushFieldEventToDataLayer(EVENT_NAMES.inputPhoneNo.event, `VALUE: ${val}`, '');

export const goPageView = () => dataLayer.push({ event: 'goPageView' });
