import styled from 'styled-components';
import {
  PartnerLogo, PartnerGroupTooltip, VARS, priceFormatter, above, getWhiteHintedColor,
} from 'porowneo-ui';

import ResultCoversTitle from '../../views/Results/components/row/ResultCoversTitle';

const Container = styled.div`
  background-color: ${props => getWhiteHintedColor(props)};
  display: flex;
  flex-direction: column;
  margin: 0 -25px;
  width: calc(100% + 50px);
  padding: 16px 20px;

  ${above(VARS.breakpoints.md)} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 -40px;
    width: calc(100% + 80px);
    padding: 22px 40px;
  }
`;
Container.displayName = 'Container';

interface IBox {
  onlyMobile?: boolean;
  onlyDesktop?: boolean;
}

const Box = styled.div<IBox>`
  display: ${({ onlyDesktop }) => (onlyDesktop ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;

  ${above(VARS.breakpoints.md)} {
    display: ${({ onlyMobile }) => (onlyMobile ? 'none' : 'flex')};
  }
`;
Box.displayName = 'Box';

const Price = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 0;

  ${above(VARS.breakpoints.md)} {
    font-size: 24px;
    line-height: 32px;
  }
`;
Price.displayName = 'Price';

const PartnerBox = styled(Box)`
  && {
    flex-direction: row;
    justify-content: space-between;
  }
`;

interface ICovers {
  oc: boolean;
  ac: boolean;
  as: boolean;
  nnw: boolean;
}

interface IOfferRemindHeader {
  partnerName?: string;
  covers: ICovers;
  totalPrice?: number;
  acVariant?: string;
  basketId?: number;
}

const OfferRemindHeader = ({
  partnerName = '',
  covers = {
    oc: false,
    ac: false,
    as: false,
    nnw: false,
  },
  totalPrice = 100,
  acVariant = '',
  basketId = 0,
}: IOfferRemindHeader) => (
  <Container>
    <PartnerBox>
      <Box>
        <PartnerLogo
          partnerName={partnerName}
          maxWidth="90px"
          maxHeight="42px"
          maxMobileWidth="90px"
          maxMobileHeight="32px"
        />
        <PartnerGroupTooltip
          partner={partnerName}
        />
      </Box>
      <Box
        onlyMobile
      >
        <Price>{priceFormatter(totalPrice, false)}</Price>
      </Box>
    </PartnerBox>

    <Box>
      <ResultCoversTitle
        covers={covers}
        variant={acVariant}
        basketId={basketId}
        offerDetails
      />
    </Box>

    <Box
      onlyDesktop
    >
      <Price>{priceFormatter(totalPrice, false)}</Price>
    </Box>
  </Container>
);

export default OfferRemindHeader;
