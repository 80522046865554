import { phoneNumber } from '../../helpers/phone';
import { SELECT_OPTION_LABEL, DATE_FORMAT } from '../../config/formats';
import * as regex from '../../config/regex';
import * as fieldsLength from '../../config/FieldsParams/fieldsLength';
import { MODAL_TYPES } from '../../config/main';

export const LEASING_MODAL_PROPS = {
  name: 'aviva_leasing',
  typeOfModal: MODAL_TYPES.leasingContact,
  modalContainerId: 'modalContainer',
  modalSource: 'aviva_leasing',
  background: 'secondary',
  showClose: true,
} as const;

export const STEP_TWO_FIELDS = {
  carUse: {
    id: 'carUse',
    defaultValue: 'private',
    title: 'Jak korzystasz z samochodu?',
    fieldContainerOverrides: {
      validationStatus: 'init', // * Only affects FieldContainer's Legend
    },
    tooltip: {
      message: (
        <>
          <p>
            <strong>Prywatnie</strong>
            {' '}
            - zaznacz tę odpowiedź, jeśli wykorzystujesz samochód do celów prywatnych,
            również, gdy prowadzisz jednoosobową działalność gospodarczą, a samochód służy Ci jedynie do dojazdów do pracy.
          </p>
          <p>
            <strong>W inny sposób</strong>
            {' '}
            - np.: dowóz towarów, przewóz osób, rekwizyt, nauka jazdy, przedstawiciel handlowy, kurier, rajdy i wyścigi, wynajem...
          </p>
        </>
      ),
    },
    validations: {
      required: true,
      carUse: true,
    },
    hiddenInvalidMark: true,
    availableValues: [
      { value: 'private', label: 'Użytek własny' },
      { value: 'taxi', label: 'Taxi / Przewóz osób' },
      { value: 'learn', label: 'Nauka jazdy' },
      { value: 'transport', label: 'Usługi kurierskie / Transport' },
      { value: 'rally', label: 'Rajdy, wyścigi, konkursy, treningi' },
      { value: 'other', label: 'Inaczej (np.: Przedstawiciel handlowy, zastępcze...)' },
    ],
  },
  carRegisteredOn: {
    id: 'carRegisteredOn',
    title: 'Na kogo zarejestrowany jest samochód?',
    fieldContainerOverrides: {
      validationStatus: 'init', // * Only affects FieldContainer's Legend
    },
    tooltip: {
      message: (
        <>
          <p>
            <strong>Osobę fizyczną</strong>
            {' '}
            - wybierz, jeżeli jesteś właścicielem i pojazd jest zarejestrowany na Ciebie.
          </p>
          <p>
            <strong>Jednoosobową działalność gospodarczą</strong>
            {' '}
            - wybierz, jeśli samochód zarejestrowany został na osobę fizyczną prowadzącą jednoosobową działalność gospodarczą.
          </p>
          <p>
            <strong>Firmę leasingową</strong>
            {' '}
            - wybierz, jeśli samochód jest w leasingu. Aktualnie ubezpieczenie takiego pojazdu możliwe jest jedynie telefonicznie. Zadzwoń
            {' '}
            {phoneNumber}
          </p>
          <p>
            <strong>Firmę</strong>
            {' '}
            - wybierz, jeśli w dowodzie rejestracyjnym samochodu jest wpisana firma.
            Aktualnie ubezpieczenie takiego pojazdu możliwe jest jedynie telefonicznie. Zadzwoń
            {' '}
            {phoneNumber}
          </p>
        </>
      ),
    },
    validations: {
      requiredReqisteredOn: true,
      registeredOn: true,
    },
    hiddenInvalidMark: true,
    availableValues: [
      { value: 'person', label: 'Osobę fizyczną' },
      { value: 'selfEmployment', label: 'Jednoosobową działalność' },
      { value: 'leasingCompany', label: 'Firmę leasingową' },
      { value: 'company', label: 'Firmę' },
    ],
    dmonKey: 'car_registered_on',
  },
  carMileage: {
    id: 'carMileage',
    title: 'Stan licznika',
    endAdornment: 'tys. km',
    type: 'numeric',
    tooltip: {
      message: (<p>Aktualny przebieg możesz odczytać z licznika w Twoim samochodzie.</p>),
    },
    mask: regex.MILAGE,
    maxLength: fieldsLength.MILAGE,
    isInput: true,
    validations: {
      required: true,
      mileage: true,
    },
  },
  planToDriveThisYear: {
    id: 'planToDriveThisYear',
    title: 'Ile km planujesz przejechać w ciągu 12 miesięcy?',
    tooltip: {
      message: <p>Podaj przybliżoną liczbę kilometrów, jaką planujesz przejechać w ciągu najbliższych 12 miesięcy od daty startu ubezpiczenia.</p>,
    },
    validations: {
      requiredPlanToDriveThisYear: true,
    },
    searchable: true,
    availableValues: [
      { value: '2500', label: 'poniżej 5 tys. km' },
      { value: '7500', label: '5 tys. - 10 tys. km' },
      { value: '12500', label: '10 tys. - 15 tys. km' },
      { value: '17500', label: '15 tys. - 20 tys. km' },
      { value: '25000', label: '20 tys. - 30 tys. km' },
      { value: '35000', label: '30 tys. - 40 tys. km' },
      { value: '50000', label: '40 tys. - 60 tys. km' },
      { value: '70000', label: '60 tys. - 80 tys. km' },
      { value: '90000', label: '80 tys. - 100 tys. km' },
      { value: '120000', label: '100 tys. km i więcej' },
    ],
  },
  whichOwnerInOrder: {
    id: 'whichOwnerInOrder',
    title: 'Którym właścicielem pojazdu jest aktualny właściciel?',
    tooltip: {
      message: <p>Jeśli samochód był sprowadzany z zagranicy uwzględnij także właścieli z zagranicy.</p>,
    },
    validations: {
      whichOwnerInOrder: true,
    },
    availableValues: [
      { value: 'first', label: 'Pierwszym' },
      { value: 'next', label: 'Kolejnym' },
    ],
  },
  firstCountry: {
    id: 'firstCountry',
    title: 'Kraj pierwszej rejestracji',
    tooltip: {
      message: (
        <>
          <p>Jeżeli samochód, który ubezpieczasz został kupiony w polskim salonie, wybierz &quot;Polska&quot;.</p>
          <p>Jeżeli samochód został sprowadzony do Polski z zagranicy, wybierz kraj/obszar pochodzenia.</p>
        </>
      ),
    },
    validations: {
      required: true,
    },
    availableValues: [
      { value: 'pol', label: 'Polska' },
      { value: 'eur', label: 'Europa (bez Wielkiej Brytanii)' },
      { value: 'gbr', label: 'Wielka Brytania' },
      { value: 'usa', label: 'USA / Kanada' },
      { value: 'oth', label: 'Inne' },
    ],
  },
  carWheelSide: {
    id: 'carWheelSide',
    defaultValue: 'left',
    title: 'Po której stronie znajduje się kierownica?',
    format: DATE_FORMAT,
    validations: {
      required: true,
    },
    availableValues: [
      { value: 'left', label: 'Lewej' },
      { value: 'right', label: 'Prawej' },
    ],
  },
  firstDateRegistered: {
    id: 'firstDateRegistered',
    defaultValue: DATE_FORMAT,
    title: 'Data pierwszej rejestracji',
    tooltip: {
      message: <p>Datę pierwszej rejestracji samochodu znajdziesz na pierwszej stronie dowodu rejestracyjnego w polu B.</p>,
      imageSrc: `${process.env.PUBLIC_URL}/img/tooltips/DowodRejestracyjny7v2.jpg`,
    },
    format: DATE_FORMAT,
    isInput: true,
    showYearDropdown: true,
    relativeFields: ['productionYear'],
    maxRegistrationDelay: 3,
    validations: {
      dateInFuture: true,
      firstDateRegistered: true,
      firstDateRegisteredFuture: true,
      isDateBeforeProductionYear: true,
      before: 0,
      start: -60000,
    },
  },
  dateRegisteredActualOwner: {
    id: 'dateRegisteredActualOwner',
    defaultValue: DATE_FORMAT,
    title: 'Data rejestracji pojazdu przez obecnego właściciela',
    tooltip: {
      message: <p>Datę rejestracji samochodu przez obecnego właściciela znajdziesz na pierwszej stronie dowodu rejestracyjnego w pozycji I.</p>,
    },
    format: DATE_FORMAT,
    showYearDropdown: true,
    relativeFields: ['firstDateRegistered'],
    validations: {
      dateRegisteredActualOwner: true,
      dateRegisteredActualOwnerFuture: true,
      dateRegisteredActualOwnerPast: true,
      before: 0,
      start: -10000,
    },
  },
  parkingPlace: {
    id: 'parkingPlace',
    title: 'Gdzie najczęściej parkujesz w nocy?',
    tooltip: {
      message: <p>Pytamy o to, aby uzyskać dla Ciebie jak najkorzystniejszą ofertę ubezpieczenia, uwzględniającą wszystkie zniżki.</p>,
    },
    validations: {
      required: true,
    },
    availableValues: [
      { value: 'street', label: 'Ulica / Parking niestrzeżony ' },
      { value: 'guarded_parking', label: 'Parking strzeżony' },
      { value: 'shared_garage', label: 'Garaż wspólny' },
      { value: 'private_garage', label: 'Garaż indywidualny' },
      { value: 'driveway', label: 'Teren zamknięty (posesja)' },
    ],
  },
  timeUsedAbroad: {
    id: 'timeUsedAbroad',
    defaultValue: 'none',
    title: 'Czy samochód będzie użytkowany za granicą dłużej niż 1 miesiąc?',
    validations: {
      required: true,
    },
    availableValues: [
      { value: 'none', label: 'Nie będzie' },
      { value: 'less_1_mo', label: 'Tak - do 1 miesiąca' },
      { value: '1_to_3_mo', label: 'Tak - od 1 do 3 miesięcy' },
      { value: 'more_3_mo', label: 'Tak - powyżej 3 miesięcy' },
    ],
  },
  damaged: {
    id: 'damaged',
    defaultValue: 'no',
    title: 'Czy samochód posiada uszkodzenia?',
    validations: {
      required: true,
    },
    availableValues: [
      { value: 'no', label: 'Nie' },
      { value: 'scratch', label: 'Tak - drobne rysy i pęknięcia' },
      { value: 'dent', label: 'Tak - wgniecenia karoserii i zderzaków' },
      { value: 'serious', label: 'Tak - poważne uszkodzenia' },
    ],
  },
  lastInsurer: {
    id: 'lastInsurer',
    title: 'Ostatni ubezpieczyciel',
    format: SELECT_OPTION_LABEL,
    validations: {
      requiredWithTextMsg: true,
    },
    defaultValue: '',
    searchable: true,
  },
  infoBoxCarRegisteredOnLeasingCompany: {
    id: 'infoBoxCarRegisteredOnLeasingCompany',
    content: 'Aktualnie nie oferujemy możliwości porównania ofert ubezpieczenia dla pojazdów zarejestrowanych na firmę leasingową.',
    type: 'error',
    fullWidth: true,
  },
  infoBoxCarRegisteredOnCompany: {
    id: 'infoBoxCarRegisteredOnCompany',
    content: 'Aktualnie nie oferujemy możliwości porównania ofert ubezpieczenia dla pojazdów zarejestrowanych na firmę.',
    type: 'error',
    fullWidth: true,
  },
  infoBoxCarUse: {
    id: 'infoBoxCarUse',
    content: 'Aktualnie nie oferujemy możliwości porównania ofert ubezpieczenia dla pojazdów użytkowanych w ten sposób.',
    type: 'error',
    fullWidth: true,
  },
  carRegistrationNumber: {
    id: 'carRegistrationNumber',
    title: 'Numer rejestracyjny',
    type: 'textUpperCase',
    required: false,
    tooltip: {
      message: (
        <>
          <p>Podanie numeru rejestracyjnego pozwala znacznie obniżyć składkę u wielu ubezpieczycieli.</p>
          <p>Gdy pozostawisz to pole puste nie będziemy mogli dokładnie policzyć Ci ofert m.in. Generali, Uniqa, Trasti.</p>
        </>
      ),
    },
    mask: regex.CAR_REGISTRATION_NUMBER,
    maxLength: fieldsLength.CAR_REGISTRATION_NUMBER,
    validations: {
      carRegistrationNumberMin: fieldsLength.CAR_REGISTRATION_NUMBER,
    },
  },
} as const;
