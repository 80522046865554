import type { TCoverType } from '../../../reducers/Payload';
import type { TClearKey } from '../StepFour.types';

const { dataLayer } = window;

const translateInsurerTypes = {
  owner: 'Owner', coOwner: 'CO1', coCoOwner: 'CO2', driver: 'Main', youngPerson: 'Youngest',
};

export const handleCallGtmStepFour = (key: TClearKey, value: string | number | null | typeof Symbol.iterator, type: keyof typeof translateInsurerTypes, cover: '' | TCoverType) => {
  const insurerType = translateInsurerTypes[type] || '';
  const coverTypeEvent = cover?.toUpperCase() || '';
  const coverTypeDataName = cover?.toLowerCase() || '';

  const events = {
    yearsBuying: { event: `select${insurerType}${coverTypeEvent}Years`, [`${coverTypeDataName}_years`]: value }, // oc
    damagesPaid: { event: `select${insurerType}${coverTypeEvent}DamagesPaid`, [`claim_from_${coverTypeDataName}`]: value }, // check what to send
    firstDamage: { event: `select${insurerType}${coverTypeEvent}FirstDamage`, damage_period: value },
    secondDamage: { event: `select${insurerType}${coverTypeEvent}secondDamage`, damage_period: value },
    thirdDamage: { event: `select${insurerType}${coverTypeEvent}ThirdDamage`, damage_period: value },
    fourthDamage: { event: `select${insurerType}${coverTypeEvent}FourthDamage`, damage_period: value },
    fifthDamage: { event: `select${insurerType}${coverTypeEvent}FifthDamage`, damage_period: value },
    insuranceHistorySame: { event: `select${insurerType}ACHistory`, ac_same_history: value },
  };

  if (events[key]) dataLayer.push({ ...events[key], formStep: 4 });
};
