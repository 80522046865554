import type { TCoverType } from '../../../../reducers/Payload';
import type { TInsurerBasePerson } from '../../../../types/Insurer.type';

export const damageAvailableValues = [
  { value: '1', label: 'W ostatnim roku' },
  { value: '2', label: 'Ponad rok temu ' },
  { value: '3', label: 'Ponad dwa lata temu ' },
  { value: '4', label: 'Ponad trzy lata temu ' },
  { value: '5', label: 'Ponad cztery lata temu' },
  { value: '6', label: 'Ponad pięć lat temu' },
  { value: '7', label: 'Ponad sześć lat temu' },
] as const;

export const filterAvailableValues = (availableValues: typeof damageAvailableValues, yearsBuying: number | string) => {
  const filterFunc = (value: string | number, param: number) => (typeof value === 'number' ? value : parseInt(value, 10) < param);
  switch (yearsBuying.toString()) {
  case '0':
    return availableValues.filter(({ value }) => filterFunc(value, 2));
  case '1':
    return availableValues.filter(({ value }) => filterFunc(value, 3));
  case '2':
    return availableValues.filter(({ value }) => filterFunc(value, 3));
  case '3':
    return availableValues.filter(({ value }) => filterFunc(value, 4));
  case '4':
    return availableValues.filter(({ value }) => filterFunc(value, 5));
  case '5':
    return availableValues.filter(({ value }) => filterFunc(value, 6));
  case '6':
    return availableValues.filter(({ value }) => filterFunc(value, 7));
  case '7':
    return availableValues;
  case '8':
    return availableValues;
  case '9':
    return availableValues;
  case '10':
    return availableValues;
  default:
    return '';
  }
};

const yearsBuyingOcAvailableValues = [
  { value: 0, label: 'Brak lub poniżej roku' },
  { value: 10, label: '10 i więcej lat' },
  { value: 9, label: '9 lat' },
  { value: 8, label: '8 lat' },
  { value: 7, label: '7 lat' },
  { value: 6, label: '6 lat' },
  { value: 5, label: '5 lat' },
  { value: 4, label: '4 lata' },
  { value: 3, label: '3 lata' },
  { value: 2, label: '2 lata' },
  { value: 1, label: '1 rok' },
] as const;

const damagesPaidFromPolicies2 = [
  { value: 0, label: 'Nie' },
  { value: 1, label: 'Tak - jedno' },
  { value: 2, label: 'Tak - dwa' },
  { value: 3, label: 'Tak - trzy' },
  { value: 4, label: 'Tak - cztery' },
  { value: 5, label: 'Tak - pięć lub więcej' },
] as const;

const firstDamage = 'Kiedy była pierwsza szkoda?';
const secondDamage = 'Kiedy była druga szkoda?';
const thirdDamage = 'Kiedy była trzecia szkoda?';
const fourthDamage = 'Kiedy była czwarta szkoda?';
const fifthDamage = 'Kiedy była piąta szkoda?';

type TTitleConf = {
  [key in TInsurerBasePerson]: string;
}

const setTitle = (coowners: number, insurerType: TInsurerBasePerson): string => {
  const conf: TTitleConf = {
    owner: 'właściciela',
    coOwner: `${coowners === 2 ? 'pierwszego' : ''} współwłaściciela`,
    coCoOwner: 'drugiego współwłaściciela',
    driver: 'głównego kierowcy',
  };
  return conf[insurerType];
};

const setTitleYearBuing = (coowners: number, insurerType: TInsurerBasePerson) => {
  const conf: TTitleConf = {
    owner: 'właściciel',
    coOwner: `${coowners === 2 ? 'pierwszy' : ''} współwłaściciel`,
    coCoOwner: 'drugi współwłaściciel',
    driver: 'główny kierowca',
  };
  return conf[insurerType];
};

export interface IConfigInsuranceHistoryPerCover {
  [key: string]: {
    validations: {
      required?: boolean;
    };
    availableValues: {
      label: string;
      value: string | number;
    }[],
    id: string;
    title: string;
    order: number;
  }
}

export const generateInsuranceHistoryPerCover = (
  coowners: number,
  insurerType: TInsurerBasePerson,
  cover: TCoverType,
  orderPrefix: number,
) => {
  const yearsBuyingTitle = setTitleYearBuing(coowners, insurerType);
  const visibleCover = cover.toUpperCase() as Uppercase<TCoverType>;
  return ({
    yearsBuying: {
      validations: { requiredNumeric: true },
      availableValues: yearsBuyingOcAvailableValues,
      id: `${insurerType}YearsBuying${visibleCover}`,
      title: `Ile lat ${yearsBuyingTitle} kupuje ubezpieczenie ${visibleCover}?`,
      order: orderPrefix + 1,
    },
    damagesPaid: {
      validations: { requiredNumeric: true },
      availableValues: damagesPaidFromPolicies2,
      id: `${insurerType}DamagesPaid${visibleCover}`,
      title: `Czy z polis ${visibleCover} były wypłacone odszkodowania?`,
      order: orderPrefix + 2,
    },
    firstDamage: {
      title: firstDamage,
      validations: { required: true },
      availableValues: damageAvailableValues,
      id: `${insurerType}FirstDamage${visibleCover}`,
      order: orderPrefix + 3,
    },
    secondDamage: {
      title: secondDamage,
      validations: { required: true },
      availableValues: damageAvailableValues,
      id: `${insurerType}SecondDamage${visibleCover}`,
      order: orderPrefix + 4,
    },
    thirdDamage: {
      title: thirdDamage,
      validations: { required: true },
      availableValues: damageAvailableValues,
      id: `${insurerType}ThirdDamage${visibleCover}`,
      order: orderPrefix + 5,
    },
    fourthDamage: {
      title: fourthDamage,
      validations: { required: true },
      availableValues: damageAvailableValues,
      id: `${insurerType}FourthDamage${visibleCover}`,
      order: orderPrefix + 6,
    },
    fifthDamage: {
      title: fifthDamage,
      validations: { required: true },
      availableValues: damageAvailableValues,
      id: `${insurerType}FifthDamage${visibleCover}`,
      order: orderPrefix + 7,
    },
  }) as const;
};

export const generateInsuranceHistorySameConfig = (insurerType: TInsurerBasePerson, coowners: number) => {
  const title = setTitle(coowners, insurerType);
  return {
    insuranceHistorySame: {
      validations: { required: true },
      availableValues: [
        { value: 'no', label: 'Nie' },
        { value: 'yes', label: 'Tak' },
      ],
      id: `${insurerType}InsuranceHistorySame`,
      title: `Czy historia ubezpieczenia AC ${title} jest taka sama jak dla OC?`,
    },
  } as const;
};
