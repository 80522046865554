import { useMemo } from 'react';

import { DropDownField } from '../../../../components/fields/DropDownField';
import { isDisableField } from '../../../../helpers/payloadUtils';
import { carDictionaryDataAvValues } from '../../utils/DataUtils';
import { CAR_FIELDS_BASE } from '../../config/BaseCarDataSection.config';
import { DropDownFilterBy } from '../../../../components/fields/dropDownFilterBy/DropDownFilterBy';
import { getUniqSortedList } from '../../../../components/fields/dropDownFilterBy/utils/getUniqSortedList';

import type { IEuroTaxSection } from './EuroTaxSection.types';

export const EuroTaxSection = ({
  data,
  eurotaxVersion,
  transmission,
  handleChange,
  disableFields,
  handleSetValidation,
  handleFilteredBy,
  filters,
  isMobile,
}: IEuroTaxSection): JSX.Element => {
  const shouldShowFilters = useMemo(() => (
    !isDisableField(disableFields, CAR_FIELDS_BASE.eurotaxVersion) &&
    CAR_FIELDS_BASE.eurotaxVersion.filters.reduce<boolean>((acc, { propName }) => (
      getUniqSortedList(propName, data as any, filters as any).length > 1 || acc
    ), false)), [eurotaxVersion, transmission, data, disableFields]);

  return (
    <>
      {
        shouldShowFilters && (
          CAR_FIELDS_BASE.eurotaxVersion.filters.length > 0 &&
          CAR_FIELDS_BASE.eurotaxVersion.filters.map(filter => (
            <DropDownFilterBy
              id={`${CAR_FIELDS_BASE.eurotaxVersion.id}-filter-${filter.propName}`}
              filterConfig={filter}
              allData={data}
              onChange={handleFilteredBy}
              filter={filters}
              isMobile={isMobile}
              disabled={isDisableField(disableFields, CAR_FIELDS_BASE.eurotaxVersion)}
            />
          ))
        )
      }
      <DropDownField
        {...CAR_FIELDS_BASE.eurotaxVersion}
        value={eurotaxVersion}
        onChange={(key: 'eurotaxVersion', value: number | null) => {
          if (value !== eurotaxVersion) {
            handleChange({
              key, value, allData: data.find(({ value: dataValue }) => dataValue === value),
            });
          }
        }}
        fullWidth
        handleSetValidation={handleSetValidation}
        availableValues={carDictionaryDataAvValues(data, eurotaxVersion, filters)}
        disabled={isDisableField(disableFields, CAR_FIELDS_BASE.eurotaxVersion)}
        searchable={!isMobile}
      />
    </>
  );
};
