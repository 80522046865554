import { isMobile } from 'react-device-detect';

import { DropDownField } from '../../../../components/fields/DropDownField';

import { generateInsuranceHistoryPerCover, damageAvailableValues, filterAvailableValues } from './insuranceHistoryFields';

import type { IHandleSetValidationSteps } from '../../../../types/Steps.type';
import type { TClearKey, THandleChangeStepFour } from '../../StepFour.types';
import type { TCoverType } from '../../../../reducers/Payload';
import type { IInsurerHistoryPerCover, TInsurerBasePerson } from '../../../../types/Insurer.type';

interface IInsuranceHistoryByCover<T extends TCoverType> {
  cover: T;
  insurerType: TInsurerBasePerson;
  handleChange: THandleChangeStepFour;
  insuranceHistory: IInsurerHistoryPerCover<T>;
  handleSetValidation: IHandleSetValidationSteps;
  coowners: number;
}

const InsuranceHistoryByCover = <T extends TCoverType> ({
  cover,
  insurerType,
  handleChange,
  insuranceHistory,
  handleSetValidation,
  coowners,
}: IInsuranceHistoryByCover<T>) => {
  const { yearsBuying, damagesPaid } = insuranceHistory;
  const damageFilteredAvailableValues = filterAvailableValues(damageAvailableValues, yearsBuying === null ? '' : yearsBuying);
  const config = generateInsuranceHistoryPerCover(coowners, insurerType, cover, 0);

  const checkedDamagesPaid = typeof damagesPaid === 'number' ? damagesPaid : damagesPaid ? parseInt(damagesPaid, 10) : 0;

  const damageField = (damageKey: TClearKey, val: null | string) => (
    <DropDownField
      {...config[damageKey]}
      searchable={!isMobile}
      onChange={(
        key: typeof config[typeof damageKey]['id'],
        value: typeof config[typeof damageKey]['availableValues'][number]['value'],
      // TODO FORMMOTO-3632
      // @ts-ignore
      ) => handleChange({
        key,
        value,
        insurerType,
        insuranceCover: cover,
      })}
      value={val}
      availableValues={damageFilteredAvailableValues}
      handleSetValidation={handleSetValidation}
    />
  );

  return (
    <div>
      {config.yearsBuying?.availableValues && Array.isArray(config.yearsBuying.availableValues) && (
        <DropDownField
          {...config.yearsBuying}
          searchable={!isMobile}
          onChange={(
            key: typeof config.yearsBuying['id'],
            value: typeof config.yearsBuying['availableValues'][number]['value'],
            // TODO FORMMOTO-3632
            // @ts-ignore
            // eslint-disable-next-line sonarjs/no-identical-functions
          ) => handleChange({
            key,
            value,
            insurerType,
            insuranceCover: cover,
          })}
          value={yearsBuying}
          handleSetValidation={handleSetValidation}
        />
      )}

      <div className="hr" />

      {yearsBuying !== null && (
        <>
          <DropDownField
            {...config.damagesPaid}
            searchable={!isMobile}
            onChange={(
              key: typeof config.damagesPaid['id'],
              value: typeof config.damagesPaid['availableValues'][number]['value'],
            // TODO FORMMOTO-3632
            // @ts-ignore
            // eslint-disable-next-line sonarjs/no-identical-functions
            ) => handleChange({
              key,
              value,
              insurerType,
              insuranceCover: cover,
            })}
            value={damagesPaid}
            handleSetValidation={handleSetValidation}
          />

          <div className="hr" />
        </>
      )}

      {checkedDamagesPaid > 0 && yearsBuying !== '' && damageField('firstDamage', insuranceHistory.firstDamage?.toString() || null)}
      {checkedDamagesPaid > 1 && yearsBuying !== '' && damageField('secondDamage', insuranceHistory.secondDamage?.toString() || null)}
      {checkedDamagesPaid > 2 && yearsBuying !== '' && damageField('thirdDamage', insuranceHistory.thirdDamage?.toString() || null)}
      {checkedDamagesPaid > 3 && yearsBuying !== '' && damageField('fourthDamage', insuranceHistory.fourthDamage?.toString() || null)}
      {checkedDamagesPaid === 5 && yearsBuying !== '' && damageField('fifthDamage', insuranceHistory.fifthDamage?.toString() || null)}
    </div>
  );
};

export default InsuranceHistoryByCover;
