/* eslint-disable camelcase */
import { combineReducers } from 'redux';
import union from 'lodash/union';

import {
  SET_STEP,
  SET_USER,
  SET_AGREEMENTS,
  VISITED_STEP,
  SET_STATUS_LEAD_UPDATE,
  FROM_STEP_FOUR,
  ACTIVE_FULL_SALE_INSURER,
  SET_SENT_LEAD_UPDATE,
  SET_USED_EMAIL,
  SET_OFFER_DETAIL,
  RESET_OFFER_DETAIL,
  SET_PARTNER_AGREEMENTS,
  SET_PARTNER_REQ_AGREEMENTS,
  SET_FULL_SALE_STATUS,
  SHOW_ALERT,
  CLOSE_ALERT,
  RESET_VISITED_STEP,
  RESET_FROM_STEP_FOUR,
  IS_CALCULATED,
  SET_SENT_OFFER_REMIND,
  RESET_SENT_OFFER_REMIND,
  SET_DISABLE_FIELDS,
  SET_KEPT_FIELDS,
  EDIT_USER,
  SET_IDD_LAST_EMAIL,
  SET_SALE_DATA,
  SET_MARKETING_MATERIALS,
  SHOW_MODAL,
  UPDATE_MODAL,
  CLOSE_MODAL,
} from '../actions';
import { neededAgreements } from '../config/main';

import {
  completeWebsocets,
  connectWebsocets,
  disconnectWebsocets,
  lockedWebsockets,
  resultErrorsWebsocets,
  resultWebsocets,
  selectedCoverType,
  selectedStartDate,
  typeEmail,
  typeName,
  typePhoneLeaveContact,
  typeSurname,
} from './Steps';
import setSensitiveReducer from './SensitiveReducer';
import resultsErrorsReducer from './ResultsErrorsReducer';
import payloadReducer from './Payload';

import type { TSteps } from '../components/StepContainer/StepContainer';
import type { TFullSalePartnerName } from '../types/Partners.type';
import type { IContactData } from '../types/ContactData.type';
import type { IPersonalData } from '../types/PersonalData.type';
import type { TLocator, TShortLocator } from '../types/Locator.type';
import type { TCoverage, IMarketingMaterial } from '../config/marketingMaterial';
import type { TAgreement } from 'porowneo-ui';
import type { IDaemonBasket } from '../types/DaemonOutput.type';
import type { TModalData } from '../components/modal/Modal.type';
import type { updateModal, showModal, closeModal } from '../actions';

interface IAlertConfig {
  type: string;
  content: string;
  open: boolean;
}

const initialAlertConfig: IAlertConfig = {
  type: 'success',
  content: '',
  open: false,
};

const alertConfig = (state = initialAlertConfig, action: {
  type: typeof SHOW_ALERT | typeof CLOSE_ALERT,
  payload: Partial<IAlertConfig>,
}): IAlertConfig => {
  switch (action.type) {
  case SHOW_ALERT:
    return {
      ...state,
      ...action.payload,
      open: true,
    };
  case CLOSE_ALERT:
    return initialAlertConfig;
  default:
    return state;
  }
};

export const initialUsedEmail: IUsedEmail = {
  promotions: [],
  offers: [],
};

export interface IUsedEmail {
  offers: string[];
  promotions: string[];
}

const setUsedEmail = (state = initialUsedEmail, action: {
  type: typeof SET_USED_EMAIL,
  payload: IUsedEmail,
}): IUsedEmail => {
  if (action.type === SET_USED_EMAIL) {
    return action.payload;
  }
  return state;
};

interface IMarketingMaterialLoaded extends Omit<IMarketingMaterial, 'priceDifference'> {
  priceDifference: number;
}

export type IMarketingMaterials = Partial<Record<TCoverage, IMarketingMaterialLoaded>>;

export const initialMarketingMaterials: IMarketingMaterials = {};

const setMarketingMaterials = (state: IMarketingMaterials = initialMarketingMaterials, action: {
  type: typeof SET_MARKETING_MATERIALS,
  payload: IMarketingMaterials,
}) => {
  if (action.type === SET_MARKETING_MATERIALS) {
    return action.payload;
  }
  return state;
};

export type TLeadStatus = '' | 'failed' | 'done' | 'sent';

const setStatusLeadUpdate = (state: TLeadStatus = '', action: {
  type: typeof SET_STATUS_LEAD_UPDATE,
  payload: TLeadStatus,
}) => {
  if (action.type === SET_STATUS_LEAD_UPDATE) {
    return action.payload;
  }
  return state;
};

const setSentLeadUpdate = (state = '', action: {
  type: typeof SET_SENT_LEAD_UPDATE,
  payload: string,
}) => {
  if (action.type === SET_SENT_LEAD_UPDATE) {
    return action.payload;
  }
  return state;
};

export type TSentOfferRemind = number[];

const setSentOfferRemind = (state: TSentOfferRemind = [], { type, payload }: {
  type: typeof SET_SENT_OFFER_REMIND | typeof RESET_SENT_OFFER_REMIND,
  payload: TSentOfferRemind,
}) => {
  switch (type) {
  case SET_SENT_OFFER_REMIND:
    return payload;
  case RESET_SENT_OFFER_REMIND:
    return [];
  default:
    return state;
  }
};

const currentStep = (state: TSteps = 1, { type, payload }: {
  type: typeof SET_STEP,
  payload: TSteps,
}) => {
  if (type === SET_STEP) {
    if (payload === 1 || (payload >= 1 && payload <= 5 && Math.abs(state - payload) <= 1)) {
      return payload;
    }
    return state;
  }
  return state;
};

const visitedSteps = (state: number[] = [], action: {
  type: typeof VISITED_STEP | typeof RESET_VISITED_STEP,
  payload: number,
}) => {
  switch (action.type) {
  case VISITED_STEP:
    return union(state, [action.payload]);
  case RESET_VISITED_STEP:
    return [];
  default:
    return state;
  }
};

const initialUserAgreements = (): {[key: string]: boolean} => {
  const agreements: { [key: string]: boolean } = {};
  neededAgreements.forEach((agree: string) => {
    agreements[agree] = false;
  });
  return agreements;
};

export type TBusinessProcess = 'NB' | 'ROP' | 'REN';

export interface ISale {
  saleId: number;
  saleProduct: 'moto' | 'house';
}

const initialSale: ISale[] = [];

const setSale = (state: ISale[] = initialSale, action: { type: typeof SET_SALE_DATA, payload: ISale[] }): ISale[] => {
  if (action.type === SET_SALE_DATA) {
    return action.payload;
  }
  return state;
};

export interface IUser {
  businessProcess: TBusinessProcess;
  name: IPersonalData['firstName'];
  surname: IPersonalData['lastName'];
  phoneNumber: IContactData['phone'];
  phoneLeaveContact: IContactData['phone'];
  email: IContactData['email'];
  locator: TLocator;
  shortLocator: TShortLocator;
  sessionId: string;
  userAgreements: { [key: string]: boolean };
  expire: number;
  expireTime: number;
}

export const initialUserState: IUser = {
  businessProcess: 'NB',
  name: null,
  surname: null,
  phoneNumber: '',
  phoneLeaveContact: '',
  email: '',
  locator: '',
  shortLocator: '',
  sessionId: '',
  userAgreements: initialUserAgreements(),
  expire: 0,
  expireTime: 0,
};

const setUser = (state = initialUserState, action: {
  type: typeof SET_USER | typeof EDIT_USER,
  payload: typeof initialUserState,
  nestedKey: '' | 'userAgreements',
}): IUser => {
  switch (action.type) {
  case SET_USER:
    return action.payload;
  case EDIT_USER:
    if (action.nestedKey === 'userAgreements') {
      return ({
        ...state,
        [action.nestedKey as keyof IUser['userAgreements']]: {
          ...state[action.nestedKey],
          ...action.payload,
        },
      });
    }
    return ({
      ...state,
      ...action.payload,
    });
  default:
    return state;
  }
};

const setAgreements = (state: TAgreement<'api'>[] = [], action: {
  type: typeof SET_AGREEMENTS,
  payload: TAgreement<'api'>[],
}): TAgreement<'api'>[] => {
  if (action.type === SET_AGREEMENTS) {
    return action.payload;
  }
  return state;
};

const setPartnerAgreements = (state: TAgreement<'kt_api'>[] = [], action: {
  type: typeof SET_PARTNER_AGREEMENTS,
  payload: TAgreement<'kt_api'>[],
}): TAgreement<'kt_api'>[] => {
  if (action.type === SET_PARTNER_AGREEMENTS) {
    return action.payload;
  }
  return state;
};

const setPartnerReqAgreements = (state: string[] = [], action: {
  type: typeof SET_PARTNER_REQ_AGREEMENTS,
  payload: string[],
}) => {
  if (action.type === SET_PARTNER_REQ_AGREEMENTS) {
    return action.payload;
  }
  return state;
};

export type TFromStepFour = 'checkout' | 'questionnaire';

const fromStepFour = (state = false, { type, payload }: {
  type: typeof FROM_STEP_FOUR | typeof RESET_FROM_STEP_FOUR,
  payload: boolean | TFromStepFour,
}): boolean | TFromStepFour => {
  switch (type) {
  case FROM_STEP_FOUR:
    return payload;
  case RESET_FROM_STEP_FOUR:
    return false;
  default:
    return state;
  }
};

const isCalculated = (state = false, { type, payload }: {
  type: typeof IS_CALCULATED,
  payload: boolean,
}) => {
  if (type === IS_CALCULATED) {
    return payload;
  }
  return state;
};

const activeFullSaleInsurer = (state: '' | TFullSalePartnerName = '', { type, payload }: {
  type: typeof ACTIVE_FULL_SALE_INSURER,
  payload: '' | TFullSalePartnerName,
}): '' | TFullSalePartnerName => {
  if (type === ACTIVE_FULL_SALE_INSURER) {
    return payload;
  }
  return state;
};

type TFullSaleStatus = '' | 'error' | 'success';

const setFullSaleStatus = (state: TFullSaleStatus = '', { type, payload }: {
  type: typeof SET_FULL_SALE_STATUS,
  payload: TFullSaleStatus,
}) => {
  if (type === SET_FULL_SALE_STATUS) {
    return payload;
  }
  return state;
};

export type TOfferDetails = Record<string, never> | IDaemonBasket;

const offerDetails = (state: Record<string, never> | TOfferDetails = {}, action: {
  type: typeof SET_OFFER_DETAIL | typeof RESET_OFFER_DETAIL,
  payload: IDaemonBasket,
}): TOfferDetails => {
  switch (action.type) {
  case SET_OFFER_DETAIL:
    return {
      ...state,
      ...action.payload,
    };
  case RESET_OFFER_DETAIL:
    return {};
  default:
    return state;
  }
};

const setDisableFields = (state: string[] = [], action: {
  type: typeof SET_DISABLE_FIELDS,
  payload: string[],
}) => {
  if (action.type === SET_DISABLE_FIELDS) {
    return action.payload;
  }
  return state;
};

const setKeptFields = (state: string[] = [], action: {
  type: typeof SET_KEPT_FIELDS,
  payload: string[],
}) => {
  if (action.type === SET_KEPT_FIELDS) {
    return action.payload;
  }
  return state;
};

export const initialIDDLastEmail = '';

const setIddEmail = (state = initialIDDLastEmail, action: {
  type: typeof SET_IDD_LAST_EMAIL,
  payload: string,
}) => {
  if (action.type === SET_IDD_LAST_EMAIL) {
    return action.payload;
  }
  return state;
};

export interface IModalState {
  isModal: boolean;
  modalProps: TModalData | { typeOfModal: '', showClose: false };
}

export const initialModalState: IModalState = {
  isModal: false,
  modalProps: { typeOfModal: '', showClose: false },
};

export type TModalActions = ReturnType<typeof showModal> | ReturnType<typeof closeModal> | ReturnType<typeof updateModal>;

const setModalState = (state = initialModalState, action: TModalActions): IModalState => {
  switch (action.type) {
  case SHOW_MODAL: return { isModal: true, modalProps: action.payload };
  case CLOSE_MODAL: return initialModalState;
  case UPDATE_MODAL: return { ...state, modalProps: { ...state.modalProps, ...action.payload } as IModalState['modalProps'] };
  default: return state;
  }
};

const combineReducer = combineReducers({
  lockedWebsockets,
  connectWebsocets,
  disconnectWebsocets,
  resultWebsocets,
  resultErrorsWebsocets,
  completeWebsocets,
  usedEmail: setUsedEmail,
  step: currentStep,
  user: setUser,
  agreements: setAgreements,
  statusLeadUpdate: setStatusLeadUpdate,
  sentetLeadUpdate: setSentLeadUpdate,
  sentOfferRemind: setSentOfferRemind,
  startDate: selectedStartDate,
  coverType: selectedCoverType,
  phoneLeaveContact: typePhoneLeaveContact,
  // step4
  email: typeEmail,
  // Leave contact
  name: typeName,
  surname: typeSurname,
  visitedSteps,
  fromStepFour,
  isCalculated,
  activeFullSaleInsurer,
  fullSaleStatus: setFullSaleStatus,
  offerDetails,
  partnerAgreements: setPartnerAgreements,
  reqPartnerAgreements: setPartnerReqAgreements,
  sensitiveData: setSensitiveReducer,
  resultsErrorsPerInsurer: resultsErrorsReducer,
  payload: payloadReducer,
  // GLOBAL SYSTEM ALERT
  alertConfig,
  disableFields: setDisableFields,
  keepFields: setKeptFields,
  IDDLastEmail: setIddEmail,
  sale: setSale,
  marketingMaterials: setMarketingMaterials,
  modal: setModalState,
});

export type IRootState = ReturnType<typeof combineReducer>;

const rootReducer = (state: IRootState | undefined, action) => {
  let newState: IRootState | undefined = state;
  const {
    type,
  } = action;

  if (type === 'RESET_STORE') {
    // undefined set store to init state
    newState = undefined;
  }

  return combineReducer(newState, action);
};

export default rootReducer;
