import { createReducer } from 'redux-create-reducer';

import {
  SET_SENSITIVE_ACTIVE,
  SET_SENSITIVE_INACTIVE,
} from '../actions/SensitiveData';

import type { SensitiveActionTypes } from '../actions/SensitiveData';

const initialSensitiveState: string[] = [];

const setSensitiveActive = (state = initialSensitiveState, { payload }: SensitiveActionTypes) => {
  if (typeof payload === 'string' && !state.includes(payload)) {
    return [...state, payload];
  }
  if (Array.isArray(payload)) {
    return [...state, ...payload];
  }
  return state;
};

const setSensitiveInActive = (state = initialSensitiveState, { payload }: SensitiveActionTypes) => [...state.filter(value => value !== payload)];

const setSensitiveReducer = createReducer(initialSensitiveState, {
  [SET_SENSITIVE_ACTIVE]: setSensitiveActive,
  [SET_SENSITIVE_INACTIVE]: setSensitiveInActive,
});

export default setSensitiveReducer;
