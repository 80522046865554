import moment from 'moment';

import { DATE_FORMAT_YYYY_MM_DD } from '../../../config/formats';
import { editStepOneParam, editStepTwoParam } from '../../../actions/Payload';
import { editUser } from '../../../actions';

import type { THandleChangeStepOneUtil } from '../StepOne.types';
import type { ICarDictionaryData } from '../components/baseCarDataSection/BaseCarDataSection.types';
import type { IVehicle } from '../../../types/Car.type';

export const resetVehicle = (key: keyof IVehicle): Array<keyof IVehicle> => {
  const productionYear = 'productionYear';
  const brand = 'brand';
  const model = 'model';
  const fuel = 'fuel';
  const transmission = 'transmission';
  const lpg = 'lpg';
  const eurotaxVersion = 'eurotaxVersion';
  const eurotaxVersionCode = 'eurotaxVersionCode';
  const eurotaxVersionType = 'eurotaxVersionType';
  const infoexVersion = 'infoexVersion';
  const infoexVersionCode = 'infoexVersionCode';

  switch (key) {
  case productionYear:
    return [brand, model, fuel, lpg, transmission, eurotaxVersion, eurotaxVersionCode, eurotaxVersionType, infoexVersion, infoexVersionCode];
  case brand:
    return [model, fuel, lpg, transmission, eurotaxVersion, eurotaxVersionCode, eurotaxVersionType, infoexVersion, infoexVersionCode];
  case model:
    return [fuel, lpg, transmission, eurotaxVersion, eurotaxVersionCode, eurotaxVersionType, infoexVersion, infoexVersionCode];
  case fuel:
    return [lpg, transmission, eurotaxVersion, eurotaxVersionCode, eurotaxVersionType, infoexVersion, infoexVersionCode];
  case transmission:
    return [eurotaxVersion, eurotaxVersionCode, eurotaxVersionType, infoexVersion, infoexVersionCode];
  case eurotaxVersion:
    return [infoexVersion, infoexVersionCode];
  default:
    return [];
  }
};

export const isICarDictionaryData = (data: unknown): data is ICarDictionaryData => (
  typeof data === 'object' && !Array.isArray(data) &&
    (typeof (data as ICarDictionaryData)?.code === 'number') &&
    ((!!(data as ICarDictionaryData)?.versionType && typeof (data as ICarDictionaryData)?.versionType === 'number') ||
      (data as ICarDictionaryData)?.versionType === undefined)
);
export const isStringArray = (data: unknown): data is string[] => Array.isArray(data) && data?.length > 0 && data.every(i => (typeof i === 'string'));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const handleChange: THandleChangeStepOneUtil = (allValues): void => {
  const {
    dispatch,
    allData,
    key,
  } = allValues;

  const resetFilters = () => dispatch(editStepOneParam({}, 'filters', ['hp', 'doors', 'capacity']));

  switch (key) {
  case 'productionYear': {
    const { value } = allValues;
    dispatch(editStepOneParam({ productionYear: value }, 'vehicle', resetVehicle(key)));
    resetFilters();
    dispatch(editStepTwoParam({}, ['ownerRegisteredDate', 'yearOwnerRegistered', 'firstImmat']));
    break;
  }
  case 'brand':
  case 'model':
  case 'fuel':
  case 'transmission': {
    const { value } = allValues;
    dispatch(editStepOneParam({ [key]: value }, 'vehicle', resetVehicle(key)));
    resetFilters();
    break;
  }
  case 'eurotaxVersion': {
    const { value, selectedFuel } = allValues;
    dispatch(editStepOneParam({
      [key]: value,
      // If selected fuel contains gas and eurotax version is only petrol
      // we should send lpg set to true
      lpg: selectedFuel === 'benzyna + gaz' && isICarDictionaryData(allData) && allData?.fuel === 'benzyna',
      eurotaxVersionCode: (isICarDictionaryData(allData) && allData.code) || null,
      eurotaxVersionType: (isICarDictionaryData(allData) && allData.versionType) || null,
    },
    'vehicle',
    resetVehicle(key)));
    break;
  }
  case 'infoexVersion': {
    const { value } = allValues;
    dispatch(editStepOneParam({
      [key]: value,
      infoexVersionCode: (isICarDictionaryData(allData) && allData.code) || (value === 0 ? value : null),
    }, 'vehicle'));
    break;
  }
  case 'protectionBegins': {
    const { value } = allValues;
    if (value === null) {
      dispatch(editStepOneParam({ [key]: '' }));
    }
    if (value instanceof Date) {
      dispatch(editStepOneParam({ protectionBegins: moment(value).format(DATE_FORMAT_YYYY_MM_DD) }));
    }
    break;
  }
  case 'coverType': {
    const { value } = allValues;
    sessionStorage.removeItem('filteredCover');
    dispatch(editStepOneParam({
      ac: isStringArray(value) && value.includes('ac'),
      nnw: isStringArray(value) && value.includes('nnw'),
    }));
    break;
  }
  case 'phone': {
    const { value } = allValues;
    if (typeof value === 'string') {
      dispatch(editStepOneParam({ [key]: value }));
      dispatch(editUser({ phoneNumber: value }));
    }
    break;
  }
  case 'REG_PPL':
  case 'MARK_TEL': {
    const { value } = allValues;
    if (typeof value === 'boolean') {
      dispatch(editUser({ [key]: value }, 'userAgreements'));
    }
    break;
  }
  default:
    break;
  }
};
