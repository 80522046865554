import { MODAL_TYPES } from '../../config/main';

import ModalsContainer from './ModalsWrapper';

import type { IUser } from '../../reducers';

export interface ILockedData {
  shortLocator: IUser['shortLocator'];
  sfUserName: string;
}

const LockModal = ({ lockedData }: { lockedData?: ILockedData }) => (
  <ModalsContainer
    modalProps={{
      typeOfModal: MODAL_TYPES.lockModal,
      lockedData,
      modalContainerProps: {
        background: 'secondary',
        showClose: false,
        isCentered: true,
      },
    }}
  />
);

export default LockModal;
