import { VARS, torem } from 'porowneo-ui';
import styled from 'styled-components';

import consultant from '../../../assets/img/consultant.png';
import { phoneNumber } from '../../../helpers/phone';

const PhoneNumber = styled.a`
  color: ${VARS.pickledBluewood};
  text-decoration: none;
  font-size: ${torem(20)};
  background: none;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: 0;

  &:hover {
    text-decoration: underline;
  }
`;
PhoneNumber.displayName = 'PhoneNumber';

const ConsultantImg = styled.img`
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 29px;
`;

const Content = styled.div`
  font-weight: 500;
  font-size: ${torem(16)};
  line-height: 24px;
  padding-bottom: 8px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const clickablePhone = `tel:${phoneNumber}`;

export const SidebarContact = () => (
  <Wrapper>
    <ConsultantImg
      src={consultant}
      alt="consultant"
      width="50px"
      height="50px"
    />
    <div>
      <Content>
        Porozmawiaj z naszym agentem!
      </Content>
      <PhoneNumber href={clickablePhone}>
        {phoneNumber}
      </PhoneNumber>
    </div>
  </Wrapper>
);
