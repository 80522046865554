import { useState } from 'react';
import { StickyContainer } from 'react-sticky';
import { useDispatch, useSelector } from 'react-redux';

import lowerFirst from 'lodash/lowerFirst';
import { ValidationHoc, AgreementsContainerV2 as AgreementsContainer } from 'porowneo-ui';
import isEqual from 'lodash/isEqual';
import { InfoBox } from 'porowneo-design';

import StepContainer from '../../components/StepContainer/StepContainer';
import {
  pushAgreementsToDataLayer,
  handleCallGtmFieldErrorStep,
} from '../../helpers/tagManager/TagManagerUtils';
import FIELDS from '../../config/fields';
import { setUser } from '../../actions';
import { agreementsOrder, promoCodeAgreements } from '../../config/main';
import { createMgmAgreements, detectPromo } from '../../helpers/FormUtils';
import { themeName } from '../../helpers/Envutils';
import { isDisableField } from '../../helpers/payloadUtils';
import { StepSectionTitle } from '../../steps/styled/Steps.styled';
import { isTAgreementKey } from '../../helpers/types/AgreementType.util';
import { CheckboxField } from '../../components/fields/CheckboxField';
import { InputField } from '../../components/fields/inputField/InputField';

import InsuranceHistory from './components/InsuranceHistory/InsuranceHistory';
import { selectStepFourPropsFromStore } from './utils/selectStepFourPropsFromStore';
import { handleChangeStepFour } from './utils/handleChangeStepFour';
import { setValidationsEmail } from './utils/setValidationEmail';
import { setCustomValidationMessageAgreements } from './utils/setCustomValidationMessageAgreements';
import { handleCallGtmStepFour } from './utils/handleCallGtmStepFour';

import type { TUserAgreements } from 'porowneo-ui';
import type { IHandleSetValidationSteps } from '../../types/Steps.type';
import type { IRootState } from '../../reducers';
import type {
  THandleChangeStepFour,
  TSelectedStepFourPropsFromStore,
  TClearKey,
  IStepFourProps,
} from './StepFour.types';

const callBackIfFieldInValid = handleCallGtmFieldErrorStep(4);

export const StepFour = ValidationHoc((props: IStepFourProps) => {
  const {
    fillStatusPercent,
    triggerValidation,
    handleSetValidation: handleSetValidationFromHoc,
    callBackSetValidationFieldStatus,
    handleSetFilledFieldStatus,
    prevStep,
  } = props;
  const dispatch = useDispatch();
  const {
    coowners,
    mainDriver,
    // email, ??
    agreements,
    user,
    user: { userAgreements },
    visitedSteps,
    payload: {
      ownerInsuranceHistory,
      coOwnerInsuranceHistory,
      coCoOwnerInsuranceHistory,
      driverInsuranceHistory,
      email,
    },
    isCalculated,
    disableFields,
    mgm: {
      status: mgmStatus,
      code: mgmCode,
    },
  } = useSelector<IRootState, TSelectedStepFourPropsFromStore>(selectStepFourPropsFromStore, isEqual);

  const [isStartFilling, setIsStartFilling] = useState(false);

  const handleChange: THandleChangeStepFour = (allValues) => {
    const {
      key, value, insurerType, insuranceCover,
    } = allValues;

    const fieldCover = insuranceCover?.toUpperCase() as Uppercase<typeof insuranceCover>;
    const clearKey = lowerFirst(key.replace(insurerType, '').replace(fieldCover, '')) as TClearKey;

    if (!isStartFilling) setIsStartFilling(true);

    handleCallGtmStepFour(clearKey, value, insurerType, insuranceCover);

    handleChangeStepFour({
      ...allValues,
      dispatch,
      fieldCover,
      clearKey,
    });
  };

  const handleSetAgreements = (key: string, value: TUserAgreements | boolean) => {
    if (value && isTAgreementKey(key)) {
      pushAgreementsToDataLayer(key, value);
    }
    // FORMMOTO-3632
    //  TODO CHECK WHY WHY WE CANT USE VALUE WITH ONLY BOOLEAN
    dispatch(setUser({
      ...user,
      userAgreements: {
        ...user.userAgreements,
        [key]: typeof value === 'boolean' ? value : false,
      },
    }));
  };

  const handleSetValidation: IHandleSetValidationSteps = {
    handleSetValidation: handleSetValidationFromHoc,
    callBackIfFieldInValid,
    callBackSetValidationFieldStatus,
    handleSetFilledFieldStatus,
  };

  const isProgressStepBar = isStartFilling || visitedSteps.includes(4) || isCalculated;
  const { isPromoCode, promoType } = detectPromo(mgmStatus, mgmCode, themeName);
  const { neededAgreements, requiredAgreements } = createMgmAgreements(isPromoCode, promoType);

  return (
    <StickyContainer>
      <StepContainer
        step={4}
        btnOneConfig={{ show: true, btnText: 'Wróć', func: prevStep }}
        btnTwoConfig={{ show: true, btnText: 'Porównaj oferty', func: triggerValidation }}
        title="Historia ubezpieczenia"
        isProgressStepBar={isProgressStepBar}
        fillStatusPercent={fillStatusPercent}
      >
        <div>
          <InsuranceHistory
            handleChange={handleChange}
            insurerType="owner"
            coowners={coowners}
            handleSetValidation={handleSetValidation}
            insurerHistoryPayload={ownerInsuranceHistory}
          />

          {coOwnerInsuranceHistory && coowners >= 1 && (
            <>
              <StepSectionTitle>
                {coowners === 2 ? 'Historia ubezpieczenia pierwszego współwłaściciela' : 'Historia ubezpieczenia współwłaściciela'}
              </StepSectionTitle>
              <InsuranceHistory
                handleChange={handleChange}
                insurerType="coOwner"
                coowners={coowners}
                handleSetValidation={handleSetValidation}
                insurerHistoryPayload={coOwnerInsuranceHistory}
              />
            </>
          )}

          {coCoOwnerInsuranceHistory && coowners >= 2 && (
            <>
              <StepSectionTitle>Historia ubezpieczenia drugiego współwłaściciela</StepSectionTitle>
              <InsuranceHistory
                handleChange={handleChange}
                insurerType="coCoOwner"
                coowners={coowners}
                handleSetValidation={handleSetValidation}
                insurerHistoryPayload={coCoOwnerInsuranceHistory}
              />
            </>
          )}

          {driverInsuranceHistory && mainDriver === 'driver' && (
            <>
              <StepSectionTitle>Historia ubezpieczenia głównego kierowcy</StepSectionTitle>
              <InsuranceHistory
                handleChange={handleChange}
                insurerType="driver"
                coowners={coowners}
                handleSetValidation={handleSetValidation}
                insurerHistoryPayload={driverInsuranceHistory}
              />
            </>
          )}

          <InputField
            {...FIELDS.email}
            {...(isPromoCode ? (
              Object.fromEntries(promoCodeAgreements[promoType].map(promo => [
                promo, userAgreements[promo],
              ]))
            ) : {})}
            handleChange={(
              key: typeof FIELDS.email['id'],
              value: string,
            ) => handleChange({
              key,
              value,
              insurerType: 'owner',
              insuranceCover: '',
            })}
            disabled={isDisableField(disableFields, FIELDS.email)}
            value={email}
            handleSetValidation={handleSetValidation}
            validations={{
              ...FIELDS.email.validations,
              ...(setValidationsEmail({
                isPromoCode, userAgreements, promoType,
              })),
            }}
            // when ignoreFillStatus will changed -> we uninitialized field
            ignoreFillStatus={!userAgreements.MARK_MAIL}
            relativeFields={
              [
                ...(isPromoCode ? promoCodeAgreements[promoType] : ['MARK_MAIL']),
              ]
            }
            MARK_MAIL={userAgreements.MARK_MAIL}
          />

          <InfoBox
            id={`${FIELDS.email}-InfoBox`}
            content={(
              <>
                <p>Podanie adresu mailowego umożliwi nam przesłanie informacji o ofertach wraz z wymaganymi prawem dokumentami.</p>
                <p>Szanujemy Twoją prywatność - podany e-mail nie zostanie udostępniony żadnej firmie trzeciej.</p>
              </>
            )}
            fullWidth
            type="text"
          />

          <AgreementsContainer
            id="stepFourAgreements"
            agreements={agreements}
            neededAgreements={neededAgreements}
            handleChange={handleSetAgreements}
            userAgreements={userAgreements}
            startOrder={60}
            handleSetValidation={handleSetValidation}
            agreementsOrder={agreementsOrder}
            requiredAgreements={requiredAgreements}
            isPrivacyPolicyParagraph={false}
            customValidationMsg={setCustomValidationMessageAgreements(
              isPromoCode,
              promoType,
            )}
            stepOrigin={4}
            CheckboxComponent={CheckboxField as any} // TODO FORMMOTO-3709 Fix after moving agreementsContainer to porowneo-design
          />
        </div>
      </StepContainer>
    </StickyContainer>
  );
});

export default StepFour;
