import size from 'lodash/size';

import { DropDownField } from '../DropDownField';

import { getUniqSortedList } from './utils/getUniqSortedList';
import { getAvailabeValuesForFilter } from './utils/getAvailableOptionsForFilter';
import { setDropdownFilterByValue } from './utils/setDropdownFilterByValue';

import type { TFilterByEuroTaxKey } from '../../../actions/Steps';
import type { THandleFilterBy } from '../../../views/stepOne/StepOne.types';
import type { ICarDictionaryData, IFilter } from '../../../views/stepOne/components/baseCarDataSection/BaseCarDataSection.types';
import type { TFiltersConfig } from '../../../views/stepOne/config/BaseCarDataSection.config';

type TDropDownFieldInnerProps = {
  id: string;
  filterConfig: TFiltersConfig[number] ;
  filter: IFilter;
  disabled?: boolean ;
  allData: ICarDictionaryData[];
  onChange: THandleFilterBy;
  isMobile: boolean;
};

export const DropDownFilterBy = (props: TDropDownFieldInnerProps) => {
  const {
    id,
    allData,
    disabled,
    onChange,
    filter = {},
    filterConfig,
    isMobile,
  } = props;

  const propsSorted = getUniqSortedList(filterConfig.propName, allData, filter);
  const availableValues = getAvailabeValuesForFilter(propsSorted, filterConfig.propName, filterConfig.label, allData);
  const availableValuesWithAllOption = [{ label: 'Wszystkie', value: '' }, ...availableValues];
  const setValue = setDropdownFilterByValue(availableValuesWithAllOption, filter[filterConfig.propName]);

  return (
    size(propsSorted) > 0 && !disabled ? (
      <>
        <DropDownField
          title={filterConfig.title}
          tooltip={filterConfig.tooltip}
          required={false}
          id={id}
          placeholder={filterConfig.label}
          color="secondary"
          value={setValue}
          availableValues={availableValuesWithAllOption}
          onChange={(key: TFilterByEuroTaxKey, value: number | '') => {
            onChange(key, filterConfig.propName, value ? Number(value) : '', allData);
          }}
          fullWidth
          searchable={!isMobile}
        />
        <div className="hr" />
      </>
    ) : null
  );
};
