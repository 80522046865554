import { Checkbox, FieldContainer } from 'porowneo-design';
import { ValidationFieldHoc } from 'porowneo-ui';

import { withTooltipData } from './TooltipDataProvider';

import type { ICheckboxProps } from 'porowneo-design';

type TCheckboxComponent = Omit<ICheckboxProps, 'size'> & {
  textSize?: ICheckboxProps['size'];
  onChange: ICheckboxProps['handleChange'];
  stepOrigin: string;
  title: string;
}

const CheckboxFieldWithoutHOC = ({
  id,
  value,
  children,
  onChange,
  stepOrigin,
  disabled = false,
  title,
  color = 'primary',
  textSize = 'medium',
  ariaExpanded = false,
  ariaPressed = false,
  ariaLabel = '',
  fullWidth = false,
}: TCheckboxComponent) => {
  const clearId = id.replace(`-${stepOrigin}`, '');
  return (
    <Checkbox
      id={clearId}
      handleChange={() => (!disabled && onChange(clearId, !value))}
      disabled={disabled}
      value={value}
      color={color}
      size={textSize}
      ariaExpanded={ariaExpanded}
      ariaPressed={ariaPressed}
      ariaLabel={ariaLabel}
      fullWidth={fullWidth}
    >
      {children || title}
    </Checkbox>
  );
};

export const CheckboxFieldWithContainer = withTooltipData(ValidationFieldHoc(CheckboxFieldWithoutHOC, FieldContainer as any));
export const CheckboxField = ValidationFieldHoc(CheckboxFieldWithoutHOC);
