import styled, { css } from 'styled-components';
import { above, VARS } from 'porowneo-ui';

import { appContainer, MainSection } from '../../styled/gridHelpers';

export const StepContainerWrapper = styled.div`
  ${appContainer}
  position: relative;
  display: block;
`;

export const MainFormStyle = css`
  box-shadow: 0 2px 10px 0 rgba(23, 41, 53, 0.1);
  outline: 1px solid ${VARS.hintOfRed};
  border-radius: 10px;
  background: white;
`;

export const StepMainSection = styled(MainSection)`
  ${MainFormStyle}
  padding: 24px 16px 24px 16px;

  ${above(VARS.breakpoints.xl)} {
    padding: 38px 33px 33px 33px;
  };
`;

export const StepProgressBarFromMobile = styled.div`
  ${above(VARS.breakpoints.xl)} {
    display: none;
  };
`;

export const HrProgress = styled.hr`
  margin: 24px 0 24px 0;
`;
