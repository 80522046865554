import type { RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

const options = {
  root: undefined,
  rootMargin: '-150px 0px 8000px 0px',
  treshold: 0.1,
};

export const useIntersectionObserver = (
  width?: number,
  heigth?: number,
): [number, boolean, RefObject<HTMLButtonElement>, boolean] => {
  const [rowHeight, setRowHeight] = useState(150);
  const [isNotInView, setIsInView] = useState(true);

  const observedElement = useRef<HTMLButtonElement>(null);
  const rootElement = document.querySelector<HTMLDivElement>('#root');
  const bodyElement = document.querySelector<HTMLBodyElement>('body');
  const isContentHidden = !!bodyElement && bodyElement.classList.contains('stepOne') && bodyElement.classList.contains('hide-content');
  const observedElementCurrent = observedElement?.current;

  useEffect(() => {
    if (observedElementCurrent && width && heigth) {
      const handleIntersect: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          const boundingRoot = rootElement && rootElement.getBoundingClientRect();
          const rootBottom = boundingRoot?.bottom || 150;

          setRowHeight(rootBottom + 50);
          setIsInView(entry.isIntersecting);
        });
      };
      const createObserver = () => {
        const observer = new IntersectionObserver(handleIntersect, options);
        if (observedElement.current !== null) {
          observer.observe(observedElement.current);
        }
      };
      createObserver();
    }
  }, [width, heigth, rootElement, observedElementCurrent]);
  return [rowHeight, isNotInView, observedElement, isContentHidden];
};
