import type { IRootState } from '../../../reducers';

export const selectStepThreePropsFromStore = ({
  visitedSteps,
  sensitiveData,
  payload,
  isCalculated,
  disableFields,
}: IRootState) => ({
  visitedSteps,
  sensitiveData,
  payload,
  isCalculated,
  disableFields,
});
