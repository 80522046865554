import type { TAddressType, IAddress } from '../../types/Address.type';

export const isTAddress = <T extends TAddressType>(address: unknown): address is IAddress<T> => {
  if (address && typeof address === 'object' && !Array.isArray(address)) {
    const keys = Object.keys(address);
    return keys.length >= 4 && keys.every(key => ['type', 'zipCode', 'city', 'district'].includes(key));
  }

  return false;
};
