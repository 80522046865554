import type { IInputProps } from 'porowneo-design';

export const pickValidationColor = (validationStatus: 'valid' | 'invalid' | 'init'): IInputProps['color'] => {
  switch (validationStatus) {
  case 'invalid':
    return 'error';
  case 'valid':
    return 'primary';
  case 'init':
    return 'secondary';
  default: {
    const x: never = validationStatus;
    return x;
  }
  }
};
