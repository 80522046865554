import type { ISetInputFieldListener, TReturnSetInputFieldListener } from './setInputFieldListener.types';

export const setInputFieldListener = ({
  listener,
  accessInputLength,
  handleChange,
  id,
  isSensitiveDataActive,
  setSensitiveDataInactive,
}: ISetInputFieldListener): TReturnSetInputFieldListener => {
  if (handleChange) {
    const handleFocus = () => {
      handleChange(id, '');
      if (setSensitiveDataInactive) {
        setSensitiveDataInactive(id);
      }
    };

    switch (listener) {
    case 'onChange':
      return {
        handleChange,
      };
    case 'both':
      return {
        handleChange: (key, value) => {
          if (value.length === accessInputLength) {
            handleChange(key, value);
          }
        },
        handleBlur: (key, value) => handleChange(key, value),
        handleFocus: key => (isSensitiveDataActive && setSensitiveDataInactive && setSensitiveDataInactive(key) ? handleFocus() : {}),
      };
    case 'onAccept':
      return {
        handleAccept: (key, value, mask) => {
          if (mask && mask.masked && mask.masked.isComplete) {
            handleChange(key, value);
          }
          if (!value) handleChange(key, value);
        },
        handleBlur: (key, value) => handleChange(key, value),
        handleFocus: key => (isSensitiveDataActive && setSensitiveDataInactive && setSensitiveDataInactive(key) ? handleFocus() : {}),
      };
    case 'onBlur': {
      return {
        handleBlur: (key, value) => handleChange(key, value),
        handleFocus: key => (isSensitiveDataActive && setSensitiveDataInactive && setSensitiveDataInactive(key) ? handleFocus() : {}),
      };
    }
    default: {
      const x: never = listener;
      return x;
    }
    }
  }
  return ({});
};
