import { Button } from 'porowneo-design';
import { below, VARS } from 'porowneo-ui';
import styled from 'styled-components';

interface ISentSection {
  setDefaultLeadUpdate: () => void;
}

const SectionWrapper = styled('div')`
  text-align: center;

  min-height: 152px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${below(VARS.breakpoints.md)} {
    min-height: 132px;
  }
`;
SectionWrapper.displayName = 'SectionWrapper';

export default ({ setDefaultLeadUpdate }: ISentSection) => (
  <div className="col text-center">
    <div className="modal-default modal-default--center">
      <SectionWrapper>
        <h1 className="wrapper__header-title wrapper__header-title--text-centered wrapper__header-title--margin">
          Rozmowa została już zamówiona. Czy chcesz zamówić kolejną?
        </h1>
        <Button
          id="takeNextCallButton"
          handleClick={setDefaultLeadUpdate}
          size="small"
          variant="contained"
          fullWidth
        >
          Tak, chcę zamówić kolejną rozmowę
        </Button>
      </SectionWrapper>
    </div>
  </div>
);
