import { promoCodeAgreements } from '../../../config/main';
import { promoCodesErrors } from '../config/stepFour.config';

import type { IPromoInfo } from '../../../helpers/FormUtils';

export const setCustomValidationMessageAgreements = (isPromoCode?: IPromoInfo['isPromoCode'], promoType?: IPromoInfo['promoType']) => {
  if (isPromoCode && promoType) {
    return Object.fromEntries(promoCodeAgreements[promoType].map(promo => [promo, promoCodesErrors[promoType]]));
  }
  return {};
};
