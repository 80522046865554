import moment from 'moment';

import { editStepTwoParam } from '../../../../actions/Payload';
import { DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD } from '../../../../config/formats';

import type { Dispatch } from 'redux';
import type { IStepTwoValues, TInsurerValues } from '../../StepTwo.types';
import type { IPayloadStepTwo } from '../../../../reducers/Payload';

export const handleFirstDateRegisteredChange = (dispatch: Dispatch, value: IStepTwoValues['firstDateRegistered'], whichOwner: IPayloadStepTwo['whichOwner']): void => {
  if (value === null) {
    dispatch(editStepTwoParam({ firstImmat: null }));
    return;
  }
  if (whichOwner === 'first') {
    dispatch(editStepTwoParam({ yearOwnerRegistered: moment(value, DATE_FORMAT).year() }));
  }
  dispatch(editStepTwoParam({ firstImmat: moment(value).format(DATE_FORMAT_YYYY_MM_DD) }));
};

export const handleDateRegisteredActualOwnerChange = (dispatch: Dispatch, value: IStepTwoValues['dateRegisteredActualOwner']): void => {
  if (value === null) {
    dispatch(editStepTwoParam({ ownerRegisteredDate: null }));
    return;
  }
  dispatch(editStepTwoParam({
    yearOwnerRegistered: moment(value, DATE_FORMAT).year(),
    ownerRegisteredDate: moment(value, DATE_FORMAT).format(DATE_FORMAT_YYYY_MM_DD),
  }));
};

export const handleCarUseChange = (dispatch: Dispatch, value: IStepTwoValues['carUse']): void => {
  dispatch(editStepTwoParam({ carUsage: value }));
};

export const handleCarRegisteredOnChange = (dispatch: Dispatch, value: IStepTwoValues['carRegisteredOn']): void => {
  dispatch(editStepTwoParam({ carRegisteredOn: value }));
};

export const handlePlanToDriveThisYearChange = (dispatch: Dispatch, value: IStepTwoValues['planToDriveThisYear']): void => {
  if (value) {
    dispatch(editStepTwoParam({ mileageEstimator: value }));
  }
};

export const handleWhichOwnerInOrderChange = (dispatch: Dispatch, value: IStepTwoValues['whichOwnerInOrder'], firstImmat: IPayloadStepTwo['firstImmat']): void => {
  if (value === 'first') {
    if (firstImmat) {
      dispatch(editStepTwoParam({ yearOwnerRegistered: moment(firstImmat, DATE_FORMAT).year() }));
    }
    dispatch(editStepTwoParam({ ownerRegisteredDate: null }));
  }
  dispatch(editStepTwoParam({ whichOwner: value }));
};

export const handleFirstCountryChange = (dispatch: Dispatch, value: IStepTwoValues['firstCountry']): void => {
  dispatch(editStepTwoParam({ firstCountry: value }));
};

export const handleCarWheelSideChange = (dispatch: Dispatch, value: IStepTwoValues['carWheelSide']): void => {
  dispatch(editStepTwoParam({ rightWheel: value === 'right' }));
};

export const handleParkingPlaceChange = (dispatch: Dispatch, value: IStepTwoValues['parkingPlace']): void => {
  dispatch(editStepTwoParam({ night: value }));
};

export const handleLastInsurerChange = (dispatch: Dispatch, value: IStepTwoValues['lastInsurer'], insurersData: TInsurerValues = []): void => {
  const insurer = insurersData.find(insurerEntry => insurerEntry.value === value);
  if (insurer) {
    dispatch(editStepTwoParam({ lastInsurerOc: parseInt(insurer.id, 10), lastInsurerAc: parseInt(insurer.id, 10) }));
  }
  dispatch(editStepTwoParam({ lastInsurerName: value }));
};

export const handleTimeUsedAbroadChange = (dispatch: Dispatch, value: IStepTwoValues['timeUsedAbroad']): void => {
  dispatch(editStepTwoParam({ abroad: value }));
};

export const handleDamagedChange = (dispatch: Dispatch, value: IStepTwoValues['damaged']): void => {
  dispatch(editStepTwoParam({
    damageDetail: value === 'no' ? null : value,
    damage: !['', 'no', null].includes(value),
  }));
};

export const handleCarMileageChange = (dispatch: Dispatch, value: IStepTwoValues['carMileage']): void => {
  dispatch(editStepTwoParam({ actualKm: parseInt(value, 10) * 1000 || null }));
};

export const handleCarRegistrationNumberChange = (dispatch: Dispatch, value: IStepTwoValues['carRegistrationNumber']): void => {
  dispatch(editStepTwoParam({ plate: value?.toUpperCase() || null }));
};
