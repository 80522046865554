import mapValues from 'lodash/mapValues';
import method from 'lodash/method';

import { didHideAcRisc, getYears } from '../../../helpers/FormUtils';

import type { TCovers } from '../../../types/Covers.type';
import type { IFilter, ICarDictionaryData, IFilterConfig } from '../components/baseCarDataSection/BaseCarDataSection.types';
import type { ICarDictionaryDataItem } from '../../../types/Car.type';
import type {
  ICoverTypeAvValues, IDropdownAvValues, IRadioButtonAvValues,
} from './DataUtils.type';

export const coverTypePickAvValues = (
  availableValues: readonly ICoverTypeAvValues[],
  productionYear: null | number,
): ICoverTypeAvValues[] => {
  if (productionYear && !didHideAcRisc(productionYear)) {
    return availableValues.filter(avValue => avValue.value !== 'ac');
  }

  return [...availableValues];
};

export const productionYearAvValues = (minYear = 1970, maxYear = new Date().getFullYear()): IDropdownAvValues[] => {
  const yearsFromNow: string[] = Object.values(getYears(minYear, maxYear));

  return yearsFromNow.map((year: string) => ({
    label: year,
    value: parseInt(year, 10),
  }));
};

const compareValue = (filter: number | null, item: number | null, range?: number): boolean => {
  if (!!range && !!filter && !!item) {
    return item < filter + range && item > filter - range;
  }

  return !!filter && !!item && filter === item;
};

export const carDictionaryDataAvValues = (
  data: ICarDictionaryData[],
  value: number | null,
  {
    hp,
    doors,
    capacity,
  }: IFilter = {},
  filterConfig?: IFilterConfig,
): ICarDictionaryData[] => {
  const availableValues: ICarDictionaryData[] = [];

  data.forEach((element: ICarDictionaryData) => {
    if (element.value === value ||
      (
        !doors && !hp && !capacity
      ) ||
      (
        doors && !hp && !capacity &&
        compareValue(parseInt(doors, 10), element.doors, filterConfig?.doors?.range)
      ) ||
      (
        !doors && hp && !capacity &&
        compareValue(parseInt(hp, 10), element.hp, filterConfig?.hp?.range)
      ) ||
      (
        !doors && !hp && capacity &&
        compareValue(parseInt(capacity, 10), element.capacity, filterConfig?.capacity?.range)
      ) ||
      (
        hp && doors && !capacity &&
        compareValue(parseInt(doors, 10), element.doors, filterConfig?.doors?.range) &&
        compareValue(parseInt(hp, 10), element.hp, filterConfig?.hp?.range)
      ) ||
      (
        !hp && doors && capacity &&
        compareValue(parseInt(doors, 10), element.doors, filterConfig?.doors?.range) &&
        compareValue(parseInt(capacity, 10), element.capacity, filterConfig?.capacity?.range)
      ) ||
      (
        hp && !doors && capacity &&
        compareValue(parseInt(hp, 10), element.hp, filterConfig?.hp?.range) &&
        compareValue(parseInt(capacity, 10), element.capacity, filterConfig?.capacity?.range)
      ) ||
      (
        hp && doors && capacity &&
        compareValue(parseInt(hp, 10), element.hp, filterConfig?.hp?.range) &&
        compareValue(parseInt(capacity, 10), element.capacity, filterConfig?.capacity?.range) &&
        compareValue(parseInt(doors, 10), element.doors, filterConfig?.doors?.range)
      )
    ) {
      availableValues.push(element);
    }
  });

  return availableValues;
};

const hpOrEmpty = (hp: ICarDictionaryDataItem['hp']) => (hp ? `(${hp}KM)` : '');
const capacityOrEmpty = (capacity: ICarDictionaryDataItem['capacity']) => (capacity ? `(${capacity} cm3)` : '');
const doorsOrEmpty = (doors: ICarDictionaryDataItem['doors']) => (doors ? `${doors} drzwi` : '');

export const makeCarDictionaryLabel = (
  name: ICarDictionaryDataItem['name'],
  hp: ICarDictionaryDataItem['hp'],
  body: ICarDictionaryDataItem['body'],
  doors: ICarDictionaryDataItem['doors'],
  capacity: ICarDictionaryDataItem['capacity'],
): ICarDictionaryData['label'] => `${name} ${hpOrEmpty(hp)} ${capacityOrEmpty(capacity)} ${doorsOrEmpty(doors)} ${body || ''} `;

export const modifyCarDictionaryDataForDropdown = (
  data: ICarDictionaryDataItem[],
): ICarDictionaryData[] => data.map(({
  id,
  name,
  fuel,
  hp,
  kw,
  capacity,
  body,
  doors,
  code,
  versionType,
}: ICarDictionaryDataItem) => ({
  value: id,
  label: makeCarDictionaryLabel(name, hp, body, doors, capacity),
  code,
  versionType,
  doors,
  hp,
  kw,
  capacity,
  fuel,
}));

type TCoverSetType = TCovers;

const getSessionCovers = (): Set<TCoverSetType> => {
  const sessionCovers = sessionStorage.getItem('filteredCover');
  const defaultCovers = new Set<TCoverSetType>(['oc']);
  if (!sessionCovers) {
    return defaultCovers;
  }
  const parsedCovers = JSON.parse(sessionCovers) as TCoverSetType[];
  return Array.isArray(parsedCovers) ? new Set<TCoverSetType>([...parsedCovers, 'oc']) : defaultCovers;
};

export const modifyCoverTypeValueForSelectButtons = (ac: boolean, nnw: boolean): TCoverSetType[] => {
  const sessionCovers = getSessionCovers();

  if (ac) sessionCovers.add('ac');
  if (nnw) sessionCovers.add('nnw');

  return Array.from(sessionCovers);
};

export const isPopular = (item: unknown): item is { isPopular: boolean } => (
  (typeof item === 'object' && !!item && Object.keys(item).includes('isPopular'))
);

export const groupByIsPopular = (item: unknown): boolean => {
  if (isPopular(item)) {
    return item.isPopular;
  }

  return false;
};

export const modifyFetchedDataForDropdown = (
  label: string,
  value: string,
  groupBy?: (item: { [key: string]: unknown}) => boolean,
) => (
  data: unknown,
): IDropdownAvValues[] | IDropdownAvValues[][] => {
  const map = (item: { [key: string]: unknown}): IDropdownAvValues => ({ value: item[value], label: item[label] }) as IDropdownAvValues;

  let mappedData: IDropdownAvValues[] | IDropdownAvValues[][] = [];

  if (Array.isArray(data)) {
    if (groupBy) {
      const positiveGroup: IDropdownAvValues[] = [];
      const allMapped: IDropdownAvValues[] = [];

      data.forEach((item) => {
        if (groupBy(item)) {
          positiveGroup.push(map(item));
        }
        allMapped.push(map(item));
      });

      mappedData = [
        positiveGroup,
        allMapped,
      ];
    } else {
      mappedData = data.map(item => map(item));
    }
  }

  return mappedData;
};

export const modifyFetchedDataForRadioButtons = (
  value: string,
  label: string,
) => (
  data: unknown,
): IRadioButtonAvValues => {
  const mappedData: IRadioButtonAvValues = {};
  if (Array.isArray(data)) {
    data.forEach((option) => {
      if (!mappedData[option[value]]) {
        mappedData[option[value]] = option[label];
      }
    });
  }
  return mappedData;
};

export const toLowerCaseRadioButtonsLabel = (data: IRadioButtonAvValues): IRadioButtonAvValues => (
  (data && mapValues(data, method('toLowerCase'))) || {}
) as IRadioButtonAvValues;

export const mapAvValuesToNewStandard = (avValues: Record<string | number, string>) => Object.keys(avValues).map(key => ({
  value: key,
  label: avValues[key] ?? key,
}));

export const addPetrolPlusLpgToAvailableValues = (data: IRadioButtonAvValues) => {
  let dataForAvailableOptions: IRadioButtonAvValues = data;
  const PETROL_PLUS_LPG = 'benzyna + gaz';
  if (data.benzyna && !data[PETROL_PLUS_LPG]) {
    const {
      benzyna,
      ...leftFuels
    } = data;
    const dataWithPetrolAndLpg = {
      benzyna,
      [PETROL_PLUS_LPG]: PETROL_PLUS_LPG,
      ...leftFuels,
    };
    dataForAvailableOptions = dataWithPetrolAndLpg;
  }
  return dataForAvailableOptions;
};
