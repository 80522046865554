import type { IRootState } from '../../../reducers';

export const selectStepFourPropsFromStore = ({
  payload: {
    stepThree: {
      coowners,
      mainDriver,
    },
    stepFour,
    mgm,
  },
  email,
  agreements,
  user,
  visitedSteps,
  isCalculated,
  disableFields,
}: IRootState) => ({
  coowners,
  mainDriver,
  email,
  agreements,
  user,
  visitedSteps,
  payload: stepFour,
  isCalculated,
  disableFields,
  mgm,
});
