import { CheckboxField } from 'porowneo-ui';

import type { IHandleSetValidationStepsFullSale, IHandleSetValidationSteps } from '../../types/Steps.type';

export const FetchError = ({
  id,
  handleSetValidation,
}: {
  id: string;
    handleSetValidation: IHandleSetValidationSteps | IHandleSetValidationStepsFullSale | IHandleSetValidationSteps['handleSetValidation'] | IHandleSetValidationStepsFullSale['handleSetValidation'] | IHandleSetValidationSteps['callBackIfFieldInValid'];
}) => (
  <>
    <CheckboxField
      id={id}
      handleSetValidation={typeof handleSetValidation === 'function' ? handleSetValidation : handleSetValidation.handleSetValidation}
      validations={{ required: true }}
      order={0}
      value={0}
      disabled
      hidden
    />
    <p style={{ color: 'red' }}>Błąd sieci</p>
  </>
);
