/* eslint-disable no-undef,camelcase */
import moment from 'moment';
import { sendError } from 'porowneo-ui';

import { createUrlGetCarData } from '../config/url';
import { DATE_FORMAT_YYYY_MM_DD } from '../config/formats';

import type { IRootState } from '../reducers';

declare function mt (type: 'send', eventName: 'pageview', data: ICustomData): void;

type TAvailableSteps = 1 | 2 | 3 | 4 | 5;

interface TBrand {
  id?: number;
  name: string;
}

export interface ICustomData {
  page_url: string;
  phone?: string;
  optin_phone?: number;
  data_konca_ubezpieczenia?: string;
  marka_samochodu?: string;
  lastname?: string;
  optin_confirmation?: number;
  email?: string;
}

export const getBrandName = (brandsData: TBrand[], brandId: null | number) => {
  if (brandsData.length) {
    const brandData = brandsData.find(brand => brand?.id?.toString() === brandId?.toString());
    return brandData?.name || '';
  }
  return '';
};

export const getBrandsData = (productionYear: null | number) => {
  if (productionYear === null) {
    return [];
  }
  const brandsData = localStorage.getItem(createUrlGetCarData({ type: 'brand', productionYear }));
  return brandsData ? JSON.parse(brandsData) : [];
};

export const getStartDate = (startDate: string) => moment(startDate, DATE_FORMAT_YYYY_MM_DD).subtract(1, 'day').format(DATE_FORMAT_YYYY_MM_DD);

export const createPayload = (step: TAvailableSteps, data?: IRootState) => {
  const stepNumberSlug = step === 5 ? 'wyniki' : `Step${step}`;
  const customData: ICustomData = {
    page_url: `${window?.location?.href?.split('?')?.[0]}${stepNumberSlug}` || '',
  };

  if (data) {
    const {
      user: {
        userAgreements: {
          MARK_TEL,
          MARK_MAIL,
        },
        locator,
        phoneNumber,
      },
      payload: {
        stepOne: {
          protectionBegins,
          vehicle: {
            brand,
            productionYear,
          },
        },
        stepFour: {
          email,
        },
      },
    } = data;
    const brandsData = getBrandsData(productionYear) as TBrand[];

    customData.phone = `+48${phoneNumber.replace(/\s/g, '')}`;
    customData.optin_phone = MARK_TEL ? 1 : 0;
    customData.data_konca_ubezpieczenia = getStartDate(protectionBegins);
    customData.marka_samochodu = getBrandName(brandsData, brand);
    customData.lastname = locator;
    customData.optin_confirmation = MARK_MAIL ? 1 : 0;
    customData.email = email || '';
  }

  return {
    ...customData,
  };
};

const sendEventToMautic = (step: TAvailableSteps, data?: IRootState) => {
  try {
    if (mt && typeof mt === 'function') {
      const payload = createPayload(step, data);
      mt('send', 'pageview', payload);
    } else {
      throw new Error('No mt or mt is not a function in global scope');
    }
  } catch (e) {
    sendError(e, false, {
      method: 'sendEventToMautic',
    });
  }
};

export default sendEventToMautic;
