import type { ComponentType } from 'react';

import { useModal } from '../../hooks/useModal';
import { getPhoneNumber } from '../../helpers/phone';

import type { ITooltip } from 'porowneo-design';

interface IWithModalProviderProps {
  tooltip?: ITooltip;
}

export const withTooltipData = <P extends object>(Component: ComponentType<P>): React.FC<P & IWithModalProviderProps> => ({
  ...props
}: IWithModalProviderProps): JSX.Element => {
  const { closeModal, showModal } = useModal();

  const tooltipProp = props?.tooltip ? {
    ...props.tooltip, showModal, closeModal, phoneNumber: getPhoneNumber(),
  } : undefined;

  return (
    <Component {...props as P} tooltip={tooltipProp} />
  );
};
