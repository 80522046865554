import styled from 'styled-components';
import { getTextColor, getSecondaryColor } from 'porowneo-ui';

export const InfoHeader = styled.span`
  color: ${props => getSecondaryColor(props)};
`;

export const InfoData = styled.span`
  color: ${props => getTextColor(props)};
`;

export const ModalTextContainer = styled.div`
  text-align: center;
`;
