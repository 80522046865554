import { WWW_PAGE } from '../../../../config/url';
import { useModal } from '../../../../hooks/useModal';

import {
  FreeMonthBodyWrapper,
  FreeMonthButton,
  FreeMonthButtonWrapper,
  FreeMonthContainer, FreeMonthContent, FreeMonthDescription, FreeMonthHeader, FreeMonthHeaderWrapper, FreeMonthLi, FreeMonthList, FreeMonthReg,
} from './FullSaleFreeMonthModal.styled';

export const FullSaleFreeMonthModal = () => {
  const { closeModal } = useModal();
  return (
    <>
      <FreeMonthContainer>
        <FreeMonthHeaderWrapper>
          <FreeMonthHeader>
            Odbierz miesiąc za darmo
          </FreeMonthHeader>
          <FreeMonthDescription>
            Kup polisę, zarejestruj się w promocji i odbierz składkę za jeden miesiąc ubezpieczenia!
          </FreeMonthDescription>
        </FreeMonthHeaderWrapper>
        <FreeMonthBodyWrapper>
          <FreeMonthContent>
            <FreeMonthList>
              <FreeMonthLi>
                Porównaj składki OC lub OC/AC w 10 towarzystwach ubezpieczeniowych i wybierz najlepszą propozycję
              </FreeMonthLi>
              <FreeMonthLi>
                Zakup polisę samodzielnie online lub skorzystaj z pomocy naszego Agenta
              </FreeMonthLi>
              <FreeMonthLi>
                Opłać polisę jednorazowo przelewem lub kartą zgodnie z instrukcjami na formularzu Towarzystwa
              </FreeMonthLi>
              <FreeMonthLi>
                Wypełnij formularz rejestracyjny
              </FreeMonthLi>
            </FreeMonthList>
            <FreeMonthReg>
              Szczegóły promocji dostępne w
              {' '}
              <a href={`${WWW_PAGE}/regulamin?rules=miesiac_za_darmo`} target="_blank" rel="noreferrer">regulaminie</a>
            </FreeMonthReg>
          </FreeMonthContent>
        </FreeMonthBodyWrapper>
        <FreeMonthButtonWrapper>
          <FreeMonthButton
            onClick={() => closeModal()}
          >
            Rozumiem, zamknij
          </FreeMonthButton>
        </FreeMonthButtonWrapper>
      </FreeMonthContainer>
    </>
  );
};
