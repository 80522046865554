import {
  ValidationHoc, AgreementsContainerV2 as AgreementsContainer,
} from 'porowneo-ui';
import { Button } from 'porowneo-design';
import omit from 'lodash/omit';

import { GLOBAL_FIELDS } from '../../config/fields';
import { CheckboxField } from '../fields/CheckboxField';
import { InputField } from '../fields/inputField/InputField';
import { gtmValidPhoneNumber } from '../../helpers/tagManager/TagManagerUtils';

import { PhoneNumberBox } from './LeavePhoneNumberForm.styled';

import type { ILeavePhoneNumberFormProps } from './LeavePhoneNumberForm.type';

const requiredAgreements = ['MARK_TEL'];

export const LeavePhoneNumberForm = ValidationHoc<ILeavePhoneNumberFormProps>(({
  triggerValidation,
  handleSetValidation,
  handleChange,
  phone,
  agreements,
  userAgreements,
}) => (
  <>
    <PhoneNumberBox>
      <InputField
        {...GLOBAL_FIELDS.phoneNumber}
        id="phone"
        handleChange={handleChange}
        callBackIfFieldValid={gtmValidPhoneNumber}
        handleSetValidation={handleSetValidation}
        value={phone}
        validations={{
          ...omit(GLOBAL_FIELDS.phoneNumber.validations, ['requiredPhone']),
          phoneNotProvided: true,
        }}
      />
    </PhoneNumberBox>

    <AgreementsContainer
      id="stepOneAgreements"
      agreements={agreements}
      neededAgreements={requiredAgreements}
      requiredAgreements={[]}
      handleChange={(key, value) => handleChange(key, typeof value === 'boolean' ? value : false)}
      userAgreements={userAgreements}
      handleSetValidation={handleSetValidation}
      stepOrigin={5}
      isPrivacyPolicyParagraph={false}
      CheckboxComponent={CheckboxField as any} // TODO FORMMOTO-3709 Fix after moving agreementsContainer to porowneo-design
    />

    <Button
      id="goOnButton"
      handleClick={triggerValidation}
      fullWidth
      variant="contained"
    >
      Przejdź dalej
    </Button>
  </>
));
