import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VARS, above, getPrimaryColor } from 'porowneo-ui';

import { AC_MINI_BASKETS_ID } from '../../../../config/main';

const checkIcon = color => `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='18' viewBox='0 0 52 18' fill='none'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23${color.replace('#', '')}' stroke='%23${color.replace('#', '')}'%3E%3C/circle%3E%3Cpath d='M5 9.2L7.54545 12L13 6=' stroke='white' stroke-width='2' stroke-linecap='round='%3E%3C/path%3E%3C/svg%3E%0A")`;

const noCheckIcon = 'url("data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'UTF-8\'%3F%3E%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'51\' height=\'18\' viewBox=\'0 0 51 18\' fill=\'none=\'%3E%3Ccircle cx=\'9\' cy=\'9\' r=\'9\' fill=\'%23B2B2B2\'%3E%3C/circle%3E%3Cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M4.70696 4.75725C4.31643 5.14777 4.31643 5.78094 4.70696 6.17146L7.53549 9L4.70703 11.8285C4.3165 12.219 4.3165 12.8522 4.70703 13.2427C5.09755 13.6332 5.73072 13.6332 6.12124 13.2427L8.94971 10.4142L11.778 13.2425C12.1685 13.6331 12.8017 13.6331 13.1922 13.2425C13.5828 12.852 13.5828 12.2188 13.1922 11.8283L10.3639 9L13.1923 6.17161C13.5828 5.78109 13.5828 5.14792 13.1923 4.7574C12.8018 4.36688 12.1686 4.36688 11.7781 4.7574L8.94971 7.58579L6.12117 4.75725C5.73064 4.36672 5.09748 4.36672 4.70696 4.75725Z\' fill=\'%23F4F4F4\'%3E%3C/path%3E%3C/svg%3E%0A")';

const ChecksWrapperRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  max-width: 250px;

  ${above(VARS.breakpoints.md)} {
    max-width: 140px;
  }
  ${above(VARS.breakpoints.lg)} {
    max-width: 250px;
  }
  ${above(VARS.breakpoints.xl)} {
    grid-template-columns: auto auto auto auto;
    max-width: 450px;
  }
`;
ChecksWrapperRow.displayName = 'ChecksWrapperRow';

const CheckStyled = styled.div<{ isCheck: boolean }>`
  background-image: ${({ isCheck, theme }) => (isCheck ? checkIcon(getPrimaryColor({ theme })) : noCheckIcon)};
  background-repeat: no-repeat;
  padding: 0 10px 0 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ isCheck }) => (isCheck ? VARS.pickledBluewood : VARS.silverChalice)};
  margin-bottom: 5px;

  ${above(VARS.breakpoints.md)} { margin: 2px 0 }
  ${above(VARS.breakpoints.lg)} { margin-bottom: 0 }
`;
CheckStyled.displayName = 'CheckStyled';

const ChecksWrapperOfferDetails = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  max-width: 210px;

  ${CheckStyled} {
    min-width: 94px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
ChecksWrapperOfferDetails.displayName = 'ChecksWrapperOfferDetails';

const ResultCoversTitle = ({
  covers,
  variant,
  offerDetails,
  basketId,
}) => {
  const COVERS = {
    oc: 'OC',
    // TODO basketId after demon start receiving variant for all baskets -> FORMMOTO-2283
    ac: `AC ${(variant === 'mini' || AC_MINI_BASKETS_ID.includes(basketId)) ? 'MINI' : ''}`,
    nnw: 'NNW',
    as: 'Assistance',
  };

  const ChecksWrapper = offerDetails ? ChecksWrapperOfferDetails : ChecksWrapperRow;
  return (
    <ChecksWrapper>
      {Object.keys(covers).map((cover) => {
        if (COVERS?.[cover]) {
          return (
            <CheckStyled
              key={cover}
              isCheck={covers[cover]}
            >
              {COVERS?.[cover]}
            </CheckStyled>
          );
        }
        return null;
      })}
    </ChecksWrapper>
  );
};

ResultCoversTitle.defaultProps = {
  variant: 'full',
  offerDetails: false,
  basketId: null,
};

ResultCoversTitle.propTypes = {
  covers: PropTypes.shape({
    oc: PropTypes.bool.isRequired,
    ac: PropTypes.bool.isRequired,
    nnw: PropTypes.bool.isRequired,
    as: PropTypes.bool.isRequired,
  }).isRequired,
  variant: PropTypes.string,
  offerDetails: PropTypes.bool,
  basketId: PropTypes.number,
};

export default ResultCoversTitle;
