import styled from 'styled-components';
import { above, VARS } from 'porowneo-ui';

export const Wrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 14px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 15px 0 rgba(222, 222, 222, 0.3);
  background: white;
  padding: 20px 16px 34px;

  ${above(VARS.breakpoints.lg)} {
    padding: 19px 32px;
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: ${VARS.redViolet};
  margin-top: 0;
  margin-bottom: 15px;


  ${above(VARS.breakpoints.lg)} {
    margin: 0 0 10px 0;
  }
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

export const FormattedWords = styled.span`
  color: #DE406E;
  font-weight: 700;
`;

export const GiftRightBottom = styled.div`
  position: absolute;
  display: none;

  ${above(VARS.breakpoints.sm)} {
    bottom: -24px;
    width: 76px;
    left: 60px
  }    
  ${above(VARS.breakpoints.lg)} {
    left: inherit;
    bottom: -11px;
    right: 80px;
  }  
  ${above(VARS.breakpoints.xl)} {
    display: block;
    width: 68px;
    right: 100px;
    bottom: -25px;
  }
`;

export const GiftRightTop = styled.div`
  position: absolute;
  bottom: -11px;
  top: -30px;
  width: 82px;
  display: none;

  ${above(VARS.breakpoints.lg)} {
    display: block;
    right: -30px;
    top: 20px;
  }
  ${above(VARS.breakpoints.xl)} {
    right: -25px;
    transform: scale(0.7);
    top: -10px;
  }
`;

export const GiftLeftTop = styled.div`
  position: absolute;
  width: 76px;
  right: -10px;
  bottom: -30px;

  ${above(VARS.breakpoints.lg)} {
    right: 350px;
    top: -35px;
  }
`;
