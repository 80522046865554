import moment from 'moment';

import { DATE_FORMAT_YYYY_MM_DD } from '../../../config/formats';
import { setStepOneGtm, pushAgreementsToDataLayer } from '../../../helpers/tagManager/TagManagerUtils';

import { isICarDictionaryData, isStringArray } from './HandleChange';

import type { TStepOneFieldKeys, THandleChangeValue } from '../StepOne.types';
import type { ICarDictionaryData } from '../components/baseCarDataSection/BaseCarDataSection.types';

export const handleChangeGTM = (
  key: TStepOneFieldKeys,
  value: THandleChangeValue,
  autoSelect?: boolean,
  allData?: ICarDictionaryData | string[],
): void => {
  switch (key) {
  case 'productionYear':
  case 'model':
  case 'fuel':
  case 'transmission':
    if (value && !autoSelect) {
      setStepOneGtm(key, value);
    }
    break;
  case 'brand':
    setStepOneGtm(key, value);
    break;
  case 'coverType':
    if (value === 'ac') {
      if (isStringArray(allData) && !allData.includes('ac')) {
        setStepOneGtm(`${key}Select`, value);
      } else {
        setStepOneGtm(`${key}Deselect`, value);
      }
    }
    if (value === 'nnw') {
      if (isStringArray(allData) && !allData.includes('nnw')) {
        setStepOneGtm(`${key}Select`, value);
      } else {
        setStepOneGtm(`${key}Deselect`, value);
      }
    }
    break;
  case 'protectionBegins':
    if (value instanceof Date) {
      setStepOneGtm(key, moment(value).format(DATE_FORMAT_YYYY_MM_DD));
    }
    if (value === null) {
      setStepOneGtm(key, '');
    }

    break;
  case 'eurotaxVersion':
  case 'infoexVersion':
    if (value && !autoSelect) {
      setStepOneGtm(key, isICarDictionaryData(allData) ? allData.label : '');
    }
    break;
  case 'REG_PPL':
  case 'MARK_TEL':
    pushAgreementsToDataLayer(key, value);
    break;
  default:
    break;
  }
};
