import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { closeModal, showModal, updateModal } from '../actions';

import type { TModalData } from '../components/modal/Modal.type';

export const useModal = () => {
  const dispatch = useDispatch();

  return {
    showModal: useCallback((modalData: TModalData) => dispatch(showModal(modalData)), []),
    closeModal: useCallback(() => dispatch(closeModal()), []),
    updateModal: useCallback((modalData: Partial<TModalData>) => dispatch(updateModal(modalData)), []),
  };
};
