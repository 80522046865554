import { priceFormatter } from 'porowneo-ui';

import { calculateFreeMonthPrice } from '../helpers/ResultsHelpers/ResultsUtils';
import { PromoPriceWrapper, PromoPrice } from '../styled/PromoPriceStyles';
import { MODAL_TYPES } from '../config/main';
import { useModal } from '../hooks/useModal';

export const FreeMonthPrice = ({
  total,
  isMobile = false,
  isFullSale = false,
}: {
  total: number,
  isMobile?: boolean,
  isFullSale?: boolean,
}) => {
  const promoPrice = calculateFreeMonthPrice(total);
  const { showModal } = useModal();
  const handleShowModal = () => showModal({ typeOfModal: MODAL_TYPES.freeMonth });
  return (
    isFullSale ? (
      <PromoPriceWrapper
        isFullSale
        onClick={handleShowModal}
      >
        <PromoPrice
          isFullSale
        >
          Odbierz zwrot
        </PromoPrice>
        <PromoPrice
          isFullSale
        >
          {priceFormatter(promoPrice, false)}
        </PromoPrice>
      </PromoPriceWrapper>
    ) : (
      <PromoPriceWrapper
        hideOnDesktop={isMobile}
      >
        <PromoPrice
          onClick={handleShowModal}
        >
          Odbierz zwrot
          {' '}
          {priceFormatter(promoPrice, false)}
        </PromoPrice>
      </PromoPriceWrapper>
    )
  );
};
