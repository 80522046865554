import { Button } from 'porowneo-design';
import styled from 'styled-components';

import { useModal } from '../../hooks/useModal';

export const ContentWrapperStyled = styled.div`
  padding: 30px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 32px;
  text-align: center;
`;

const InfoModal = () => {
  const { closeModal } = useModal();

  return (
    <ContentWrapperStyled>
      <Title>
        Zakup polisy - informacja
      </Title>
      <p>
        Niestety wprowadzone dane, nie mogą zostać zweryfikowane.
        <br />
        Naciśnij przycisk Zamknij i wybierz ponownie ofertę.
      </p>
      <Button
        id="closeButton"
        handleClick={closeModal}
        fullWidth
        variant="contained"
      >
        Zamknij
      </Button>
    </ContentWrapperStyled>
  );
};

export default InfoModal;
