import { setStepOneGtm } from '../../../helpers/tagManager/TagManagerUtils';

import type { ICarDictionaryData, IFilter } from '../components/baseCarDataSection/BaseCarDataSection.types';

const getPowerDataKw = (allData: ICarDictionaryData[], value: number | string) => {
  let powerKw = 0;

  if (Array.isArray(allData) && allData.length > 0) {
    allData.forEach((carInfoData) => {
      const isFound = carInfoData.hp ? carInfoData.hp === value : false;

      if (isFound) {
        powerKw = carInfoData.kw || 0;
      }
    });
  }
  return powerKw;
};

const createPowerDataInfo = (value: string | number, allData: ICarDictionaryData[]) => {
  const powerData = { km: Number(value), kw: 0 };
  powerData.kw = getPowerDataKw(allData, value);

  return powerData?.km && powerData?.kw ? `${powerData?.km}KM / ${powerData?.kw}kw` : 'Wszystkie';
};

export const setInfoAboutFiltersInGtm = (
  key: keyof IFilter,
  value: string | number,
  allData: ICarDictionaryData[],
) => {
  if (key === 'hp') setStepOneGtm('eurotaxPower', createPowerDataInfo(value, allData));
  if (key === 'doors') setStepOneGtm('eurotaxDoors', value);
};
