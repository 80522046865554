import { memo } from 'react';
import { isMobile } from 'react-device-detect';

import {
  CustomDatePicker, MobileDatePicker, ValidationFieldHoc, DATE_MASK, isValidMask,
} from 'porowneo-ui';
import { FieldContainer } from 'porowneo-design';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import { DATE_FORMAT_YYYY_MM_DD } from '../../config/formats';

import { withTooltipData } from './TooltipDataProvider';

import type { DurationInputArg1, MomentInput } from 'moment';
import type { ICustomDatePicker } from 'porowneo-ui';

type TDatePickerFieldProps = ICustomDatePicker & {
  onChange: (id: string, val: Date | string | null) => void;
  valueOnOpen: MomentInput;
  customMaxDate?: DurationInputArg1;
  placeholder?: string;
  validations: {
    start?: DurationInputArg1;
    before?: DurationInputArg1;
  };
}

const CustomDatePickerFieldNoHOC = ({
  id,
  format,
  value,
  validations,
  onChange,
  valueOnOpen,
  placeholder,
  customMaxDate,
  disabled,
  validationStatus,
}: TDatePickerFieldProps): JSX.Element => {
  const handleDateChange = (e) => {
    e.preventDefault();
    onChange(id, moment(e.target.value).toDate());
  };

  const clickValueOnOpen = () => {
    if (value === '') {
      onChange(id, moment(valueOnOpen, format).format(DATE_FORMAT_YYYY_MM_DD));
    }
  };

  const mobileValue = value === '' ? '' : moment(value, format).format(DATE_FORMAT_YYYY_MM_DD);

  let openToDate = moment(valueOnOpen, format).toDate();
  if (isValidMask(value, DATE_MASK)) {
    const chekedValueOnOpen = moment(valueOnOpen, format).isValid() ? moment(valueOnOpen, format).toDate() : moment().toDate();
    openToDate = value && moment(value, format).isValid() ? moment(value, format).toDate() : chekedValueOnOpen;
  }

  const maxDate = customMaxDate && customMaxDate > 0 ?
    moment().add(-customMaxDate, 'day').toDate() :
    moment().add(validations.before, 'day').toDate();
  const minDate = moment().add(validations.start, 'day').toDate();

  const max = customMaxDate ?
    moment().add(-customMaxDate, 'day').format(DATE_FORMAT_YYYY_MM_DD) :
    moment().add(validations.before, 'day').format(DATE_FORMAT_YYYY_MM_DD);
  const min = moment().add(validations.start, 'day').format(DATE_FORMAT_YYYY_MM_DD);

  const validValue = isValidMask(value, DATE_MASK) ? value : null;

  return !isMobile ? (
    <CustomDatePicker
      id={id}
      handleChange={val => onChange(id, val)}
      minDate={minDate}
      maxDate={maxDate}
      openToDate={openToDate}
      disabled={disabled}
      placeholderText={placeholder}
      fullWidth
      dateMask={[...DATE_MASK]}
      format={format}
      value={validValue || ''}
      validationStatus={validationStatus}
    />
  ) : (
    <MobileDatePicker
      id={id}
      disabled={disabled}
      mobileValue={mobileValue}
      handleDateChange={handleDateChange}
      clickValueOnOpen={clickValueOnOpen}
      min={min}
      max={max}
      validationStatus={validationStatus}
    />
  );
};

export const CustomDatePickerField = withTooltipData(
  ValidationFieldHoc(memo(CustomDatePickerFieldNoHOC, (prev, next) => isEqual(prev, next)), FieldContainer as any),
);
