import { editStepFourParam, editStepThreeParam } from '../../../actions/Payload';
import { generateInitialInsurer, setInsuranceHistory } from '../../../reducers/Payload';
import {
  CO_CO_OWNER, CO_OWNER, DRIVER, YOUNG_PERSON,
} from '../../../config/InsurerPersons.literal';
import { setViewValue } from '../../../helpers/payloadUtils';

import type { THandleChangeStepThreeUtil } from '../StepThree.types';
import type { IPayloadStepThree } from '../../../reducers/Payload';
import type {
  TCoCoOwner, TCoOwner, TDriver, TYoungPerson,
} from '../../../types/Insurer.type';

export const handleChangeStepThree: THandleChangeStepThreeUtil = (allArguments) => {
  const {
    dispatch,
    key,
    coowners,
    mainDriver,
    ageOldestChild,
    numberOfChilds,
    hasChildrenU26,
  } = allArguments;

  switch (key) {
  case 'coowners': {
    const { value } = allArguments;
    if (coowners !== value) {
      dispatch(editStepThreeParam({ mainDriver: '' }));
      if (value === 2) {
        if (coowners === 0) { // if was't coowners => add two init coowners
          dispatch(editStepThreeParam({
            coowners: 2,
            coOwner: generateInitialInsurer<TCoOwner>(CO_OWNER),
            coCoOwner: generateInitialInsurer<TCoCoOwner>(CO_CO_OWNER),
          }));
          dispatch(editStepFourParam({
            coOwnerInsuranceHistory: setInsuranceHistory<TCoOwner>(CO_OWNER),
            coCoOwnerInsuranceHistory: setInsuranceHistory(CO_CO_OWNER),
          }));
        }
        if (coowners === 1) { // if was't coCoOwner => add a init coCoOwner
          dispatch(editStepThreeParam({
            coowners: 2, coCoOwner: generateInitialInsurer<TCoCoOwner>(CO_CO_OWNER),
          }));
          dispatch(editStepFourParam({ coCoOwnerInsuranceHistory: setInsuranceHistory<TCoCoOwner>(CO_CO_OWNER) }));
        }
      } else if (value === 1) {
        if (coowners === 0) { // if was't coowners => add a init coOwner and delete coCoOwner
          dispatch(editStepThreeParam({
            coowners: 1, coOwner: generateInitialInsurer<TCoOwner>(CO_OWNER), coCoOwner: null,
          }));
          dispatch(editStepFourParam({
            coOwnerInsuranceHistory: setInsuranceHistory<TCoOwner>(CO_OWNER), coCoOwnerInsuranceHistory: null,
          }));
        } else { // else delete coCoOwner object
          dispatch(editStepThreeParam({ coowners: 1, coCoOwner: null }));
          dispatch(editStepFourParam({ coCoOwnerInsuranceHistory: null }));
        }
      } else {
        dispatch(editStepThreeParam({ coowners: 0, coOwner: null, coCoOwner: null }));
        dispatch(editStepFourParam({ coOwnerInsuranceHistory: null, coCoOwnerInsuranceHistory: null }));
      }
    }
    break;
  }
  case 'driverUnder26': {
    const { value } = allArguments;
    if (value === 'no') {
      dispatch(editStepThreeParam({ driverUnder26: false, youngPerson: null }));
    } else {
      dispatch(editStepThreeParam({
        driverUnder26: true,
        youngPerson: generateInitialInsurer<TYoungPerson>(YOUNG_PERSON),
      }));
    }
    break;
  }
  case 'mainDriver': {
    const { value } = allArguments;
    if (value !== mainDriver) {
      dispatch(editStepThreeParam({
        numberOfChilds: null, ageOldestChild: null, hasChildrenU26: null, hasChildren: false,
      }));
      if (value === 'driver') {
        dispatch(editStepThreeParam({ mainDriver: value, driver: generateInitialInsurer<TDriver>(value) }));
        dispatch(editStepFourParam({ driverInsuranceHistory: setInsuranceHistory<TDriver>(DRIVER) }));
      } else {
        dispatch(editStepThreeParam({ mainDriver: value, driver: null }));
        dispatch(editStepFourParam({ driverInsuranceHistory: null }));
      }
    }
    break;
  }
  case 'hasChildrenU26': {
    const { value } = allArguments;
    if (value !== setViewValue(hasChildrenU26)) {
      if (value === 'no') {
        dispatch(editStepThreeParam({ hasChildrenU26: false, ageOldestChild: null }));
      } else {
        dispatch(editStepThreeParam({ hasChildrenU26: true }));
      }
    }
    break;
  }
  case 'numberOfChilds': {
    const { value } = allArguments;
    if (value !== numberOfChilds) {
      if (parseInt(`${value}`, 10) === 0) {
        dispatch(editStepThreeParam({ numberOfChilds: 0, hasChildren: false }));
      } else {
        dispatch(editStepThreeParam({
          numberOfChilds: parseInt(`${value}`, 10) as Exclude<IPayloadStepThree['numberOfChilds'], null>,
          hasChildren: true,
        }));
      }
    }
    break;
  }
  case 'ageOldestChild': {
    const { value } = allArguments;
    if (!ageOldestChild || value !== `${ageOldestChild}`) {
      dispatch(editStepThreeParam({ ageOldestChild: parseInt(`${value}`, 10) as Exclude<IPayloadStepThree['ageOldestChild'], null> }));
    }
    break;
  }
  default:
    break;
  }
};
