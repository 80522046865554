import type { ICarDictionaryData } from '../../../../views/stepOne/components/baseCarDataSection/BaseCarDataSection.types';
import type { TFiltersConfig } from '../../../../views/stepOne/config/BaseCarDataSection.config';

export interface IBaseAvailableValues {
  label: string;
  value: number | null;
}

export const getKw = (
  data: ICarDictionaryData[],
  hpArr: Record<'hp', number | null>[],
) => {
  const hpToKw: Record<number, number | null | undefined> = {};
  hpArr.forEach((o) => {
    const carObj = Object.values(data).find(car => car.hp === o.hp);
    if (o.hp) {
      hpToKw[o.hp] = carObj?.kw;
    }
  });

  return hpToKw;
};

export const getOptionLabel = <T extends TFiltersConfig[number]['propName']>(
  prop: Record<T, number | null>,
  propsSorted: Record<T, number | null>[],
  propName: TFiltersConfig[number]['propName'],
  label: TFiltersConfig[number]['label'],
  allDataProps: ICarDictionaryData[],
) => {
  if (propName === 'hp') {
    const hpToKw = getKw(allDataProps, propsSorted as Record<'hp', number | null>[]); // propsSorted are connected to propName
    const hp = prop[propName];
    if (hp) {
      const kw = hpToKw[hp];
      return `${hp}KM / ${kw}kw`;
    }
  }
  if (propName === 'capacity') {
    return `${prop[propName]} cm3`;
  }

  return `${prop[propName]} ${label}`;
};
export const getAvailabeValuesForFilter = <T extends TFiltersConfig[number]['propName']>(
  propsSorted: Record<T, number | null>[],
  propName: T,
  label: TFiltersConfig[number]['label'],
  allDataProps: ICarDictionaryData[],
): IBaseAvailableValues[] => propsSorted.map((prop) => {
    const generatedLabel = getOptionLabel<T>(prop, propsSorted, propName, label, allDataProps);
    return ({
      label: generatedLabel,
      value: prop[propName],
    });
  });
