import { getWhiteHintedColor, torem } from 'porowneo-ui';
import { Question as QuestionIcon } from 'porowneo-design';
import styled from 'styled-components';

const Content = styled.div`
  font-weight: 500;
  font-size: ${torem(16)};
  line-height: 24px;
  margin-bottom: 8px;
  padding-left: 18px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;

  padding-top: 26px;
`;

const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 22px;
  border: 1px solid ${props => getWhiteHintedColor(props)};
  border-radius: 50%;
  box-shadow: 0px 2px 10px rgba(23, 41, 53, 0.05);
  padding: 0;
  background-color: white;
`;

export const SidebarHint = () => (
  <>
    <Wrapper>
      <IconWrapper>
        <QuestionIcon />
      </IconWrapper>
      <Content>
        Jeśli masz wątpliwości sprawdź ikonę
        {' '}
        <QuestionIcon />
        {' '}
        - podpowiemy, gdzie szybko je znaleźć!
      </Content>
    </Wrapper>
  </>
);
