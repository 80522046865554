/* eslint-disable camelcase */
import { Component } from 'react';

import { Button } from 'porowneo-design';

import ThankYouSection from '../ThankYouSection';
import Form from '../OfferRemindForm';
import { csdReminders } from '../../api/carProject';
import { setSentOfferRemind } from '../../actions';
import { saveAgreements } from '../../helpers/FormUtils';

import Header from './OfferRemindHeader';
import { ModalTextContainer } from './Modal.styled';

import type { IDaemonBasket } from '../../types/DaemonOutput.type';
import type { TAgreement } from 'porowneo-ui';
import type { Dispatch } from 'redux';
import type { IUser } from '../../reducers';

interface IOfferRemindModalProps {
  closeModal: () => void;
  insurer: string;
  user: Pick<IUser, 'email' | 'phoneNumber' | 'sessionId' | 'locator' | 'name'>
  total: number;
  covers: IDaemonBasket['covers'];
  neededAgreements: string[];
  handleSetAgreements: (key: any, val: any) => void;
  userAgreements: {
    [key: string]: boolean;
  };
  agreements: TAgreement<'api'>[];
  carProjectId: number;
  basket_id: IDaemonBasket['basket_id'];
  ac_ins_variant: IDaemonBasket['ac_ins_variant'];
  sentOfferRemind: number[];
  dispatch: Dispatch;
  carProjectVersionId: number,
  protectionStart: string,
}

interface IOfferRemindModalState {
  status: string;
  user: Pick<IUser, 'email' | 'phoneLeaveContact'> & { firstName: IUser['name'] };
}

class OfferRemindModal extends Component<IOfferRemindModalProps, IOfferRemindModalState> {
  constructor(props: IOfferRemindModalProps) {
    super(props);
    this.state = {
      status: '',
      user: {
        email: '',
        phoneLeaveContact: '',
        firstName: '',
      },
    };
  }

  override componentDidMount() {
    const {
      props: {
        basket_id: basketId,
        sentOfferRemind,
        user: {
          email,
          phoneNumber,
          name,
        },
      },
    } = this;

    this.setState({
      status: sentOfferRemind.includes(basketId) ? 'sent' : '',
      user: {
        email,
        phoneLeaveContact: phoneNumber,
        firstName: name || '',
      },
    });
  }

  handleChange = (key: string, val: string) => {
    const {
      state: {
        user,
      },
    } = this;

    this.setState({
      user: {
        ...user,
        [key]: val,
      },
    });
  }

  sendData = async () => {
    const {
      props: {
        dispatch,
        sentOfferRemind,
        basket_id: basketId,
        user: {
          sessionId,
          locator,
        },
        carProjectId,
        carProjectVersionId,
        agreements,
        neededAgreements,
        userAgreements,
        userAgreements: {
          MARK_MAIL,
          MARK_TEL,
        },
        protectionStart,
      },
      state: {
        user: {
          email,
          phoneLeaveContact,
          firstName,
        },
      },
    } = this;

    try {
      await csdReminders(
        {
          reminderDate: protectionStart,
          referer: window.location.href,
          markMail: MARK_MAIL || false,
          markTel: MARK_TEL || false,
          email,
          phone: phoneLeaveContact.replace(/\s/g, ''),
          firstName,
          carProjectId,
          latestCarProjectVersion: carProjectVersionId,
        },
        sessionId,
        locator,
      );

      dispatch(setSentOfferRemind([...sentOfferRemind, basketId]));

      await saveAgreements({
        agreements,
        userAgreements,
        sessionId,
        agrementsInfo: { phone: phoneLeaveContact.replace(/\s/g, ''), mail: email },
        sendTheseAgreements: neededAgreements,
        locatorFull: locator,
      });

      this.setState({ status: 'success' });
    } catch {
      this.setState({ status: 'error' });
    }
  }

  override render() {
    const {
      props: {
        insurer: partnerName,
        total: totalPrice,
        ac_ins_variant: acVariant,
        basket_id: basketId,
        closeModal,
        agreements,
        covers,
        neededAgreements,
        handleSetAgreements,
        userAgreements,
      },
      state: {
        status,
        user,
      },
      sendData,
      handleChange,
    } = this;

    if (status === 'success') {
      return (
        <ThankYouSection
          closeModal={closeModal}
          message="Przypomnienie zostało ustawione."
        />
      );
    }

    if (status === 'sent') {
      return (
        <div className="col text-center">
          <div className="modal-default modal-default--center">
            <h4 id="sentMsg" className="modal-default__title">
              Przypomnienie zostało już ustawione. W odpowiednim momencie przypomnimy o ubezpieczeniu.
            </h4>
            <Button
              id="closeButton"
              handleClick={closeModal}
              fullWidth
              variant="contained"
              size="small"
            >
              Zamknij
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="col p-0">
        <h1 id="header" className="wrapper__header-title wrapper__header-title--contact">Poproś o przypomnienie</h1>

        {status === 'error' && (
          <h5 id="errorMsg" className="text-error">
            Przepraszamy nie udało się zapisać prośby o kontakt.
            <br />
            Możesz spróbować jeszcze raz.
          </h5>
        )}

        <Header
          partnerName={partnerName}
          covers={covers}
          acVariant={acVariant || undefined}
          totalPrice={totalPrice}
          basketId={basketId}
        />
        <p id="subtitle" style={{ fontWeight: 500 }}>
          <ModalTextContainer>Zostaw kontakt, a w odpowiednim momencie przypomnimy Ci, że możesz już kupić ubezpieczenie.</ModalTextContainer>
        </p>
        <Form
          agreements={agreements}
          user={user}
          callBackIfValid={sendData}
          handleChange={handleChange}
          userAgreements={userAgreements}
          neededAgreements={neededAgreements}
          handleSetAgreements={handleSetAgreements}
        />
      </div>
    );
  }
}

export default OfferRemindModal;
