import PromoCodeSection from '../PromoCodeSection';
import { isAionTheme } from '../../helpers/setupTheme';
import { themeName } from '../../helpers/Envutils';
import { IS_PROMO_CODES } from '../../config/main';

import { Wrapper } from './Sidebar.styled';
import { SidebarInfo } from './components/SidebarInfo';
import { SidebarContact } from './components/SidebarContact';
import { SidebarHint } from './components/SidebarHint';

import type { TBusinessProcess } from '../../reducers';
import type { TSteps } from '../StepContainer/StepContainer';

export type TProcess = '' | 'aion' | TBusinessProcess;

interface ISidebar {
  step: TSteps;
  businessProcess: TProcess;
}

const Sidebar = ({
  step,
  businessProcess,
}: ISidebar) => (
  <Wrapper>
    <SidebarContact />

    <div className="hr" />

    <SidebarInfo step={step} process={businessProcess} />

    <SidebarHint />

    {IS_PROMO_CODES && step === 2 && !isAionTheme(themeName) && (
      <PromoCodeSection />
    )}
  </Wrapper>
);

export default Sidebar;
