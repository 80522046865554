import { mapOfferDetailsData } from 'porowneo-ui';
import moment from 'moment';

import { AC_MINI_BASKETS_ID } from '../../config/main';
import { DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD } from '../../config/formats';
import { eachFirstLetterUpperCase, convertToRomanNumeral } from '../FormUtils';
import { isErgoHestia, isTFullSalePartnerName } from '../types/PartnersType.util';
import { PARTNERS_WITH_ACTUAL_ADDRESS } from '../../views/fullSaleStepTwo/FullSaleStepTwo.config';
import {
  GENERALI, LINK4, MTU, PROAMA, TRASTI, YCD,
} from '../../config/Partners.config';
import { ROUTING_FULL_SALE_DOCS, ROUTING_BASE } from '../../config/Router.config';

import type { TOfferDetails } from '../../reducers';
import type { TPaymentType } from '../../reducers/Payload';
import type { IDaemonBasket } from '../../types/DaemonOutput.type';
import type { TAgreement } from 'porowneo-ui';
import type { TFullSalePartnerName } from '../../types/Partners.type';

const { mapDataOcHelpers } = mapOfferDetailsData;

export const checkIfRender = (insurer, avInsurers) => {
  if (avInsurers.includes(insurer)) return true;
  return false;
};

export const setHiddenPartnerAgreements = (partnerName, partnerAgreements) => {
  if (partnerName === 'aviva') {
    const hiddenAgreements = ['red'];
    return partnerAgreements
      .filter(agree => hiddenAgreements.includes(agree?.externalId))
      .map(({ name }) => name);
  }
  return [];
};

const mapAgreementsToOneStandard = (partnerAgreements): TAgreement<'kt_api'>[] => {
  const newStandard: TAgreement<'kt_api'>[] = [];
  partnerAgreements.forEach((agree) => {
    newStandard.push({
      id: agree.agreement,
      name: `${agree.agreement}`,
      description: agree.content,
      type: null,
      risk: agree.risk,
      obligatory: agree.obligatory,
      valid_until: {
        date: agree.date_from,
      },
      status: 'active',
      weight: agree.weight,
      externalId: agree.externalId,
      collectingStage: agree.collectingStage,
      printoutEndpoint: agree.printoutEndpoint,
    });
  });
  return newStandard;
};

export const PRINTOUT_ENDPOINT = 'PrintoutEndpoint';

export const isPrintoutEndpoint = (printoutEndpoint: null | string, description: string) => (
  printoutEndpoint && description.includes(`href='${PRINTOUT_ENDPOINT}'`));

export interface IPayloadForDocumentsPage {
  partnerName: '' | TFullSalePartnerName;
  locator: string;
  sessionId: string;
}

export const addPrintoutEndpointToAgreement = (agreements, payloadForDocumentsPage: IPayloadForDocumentsPage) => (agreements.map((agreement) => {
  const { printoutEndpoint, description } = agreement;
  if (isPrintoutEndpoint(printoutEndpoint, description) && payloadForDocumentsPage) {
    const {
      partnerName,
      locator,
      sessionId,
    } = payloadForDocumentsPage;

    const urlPartnerName = `partnerName=${isErgoHestia(partnerName) ? 'ergoHestia' : partnerName}`;
    const urlLocator = `locator=${locator}`;
    const urlSessionId = `sessionId=${sessionId}`;
    const urlPrintoutEndpoint = `printoutEndpoint=${encodeURIComponent(printoutEndpoint)}`;
    const urlFileName = `fileName=${printoutEndpoint.match(/[^/]+(?=\/$|$)/i)}.pdf`;
    const url = `${ROUTING_BASE}/${ROUTING_FULL_SALE_DOCS}?${urlPartnerName}&${urlLocator}&${urlSessionId}&${urlPrintoutEndpoint}&${urlFileName}`;

    return {
      ...agreement,
      description: description.replace(`${PRINTOUT_ENDPOINT}`, url),
    };
  }
  return agreement;
})
);

export const filterAgreements = (partnerName, data, additionalData, conditions: {coowners?: number, insurer?: string} = {}) => {
  switch (partnerName.toLowerCase()) {
  case GENERALI:
  case PROAMA: {
    if (conditions.coowners === 0) {
      return mapAgreementsToOneStandard(data.filter(value => value.externalId !== 'ALL_MOTO_INSURED_OWU_DELIVERED'));
    }
    return mapAgreementsToOneStandard(data);
  }
  case TRASTI: {
    const hiddenAgreements = conditions.insurer !== 'owner' ? ['1-BIG'] : [];
    return mapAgreementsToOneStandard(data.filter(value => !hiddenAgreements.includes(value.externalId)));
  }
  case MTU:
  case YCD: {
    const mappedAgreements = mapAgreementsToOneStandard(data);
    return addPrintoutEndpointToAgreement(mappedAgreements, additionalData);
  }
  default:
    return mapAgreementsToOneStandard(data);
  }
};

export const mapRequiredAgreements = (partnerName, data, conditions: {coowners?: number} = {}) => {
  const requiredAgreements: string[] = [];
  switch (partnerName) {
  case 'generali':
  case 'proama': {
    data.forEach((agreementData) => {
      if (agreementData.obligatory) {
        requiredAgreements.push(`${agreementData.agreement}`);
      }
      if (conditions.coowners !== 0 && agreementData.externalId === 'ALL_MOTO_INSURED_OWU_DELIVERED') {
        requiredAgreements.push(`${agreementData.agreement}`);
      }
    });
    break;
  }
  default:
    data.forEach((agreementData) => {
      if (agreementData.obligatory) {
        requiredAgreements.push(`${agreementData.agreement}`);
      }
    });
    break;
  }
  return requiredAgreements;
};

export const filterTextAgreements = (agreements: TAgreement<'kt_api'>[]) => agreements.filter(({ collectingStage }) => collectingStage?.toLowerCase() !== 'text');

export const createRisks = (offer: TOfferDetails) => {
  const {
    covers,
    driving_school: drivingSchool,
    ac_ins_variant: acInsVariant,
    as_ins_variant: asInsVariant,
    basket_id: basketId,
    internet_discount: internetDiscount,
    hand_luggage_ins: handLuggageIns,
    name,
  } = offer;

  const translateRisks = {
    greenCard: 'Zielona karta - na życzenie',
    bls: 'Bezpośrednia likwidacja szkody (BLS)',
    as: 'Assistance',
    glass: 'Szyby',
    tires: 'Opony',
    ac: `AC ${(acInsVariant === 'mini' || AC_MINI_BASKETS_ID.includes(basketId)) ? 'MINI' : ''}`,
    oc: 'OC',
    nnw: 'NNW',
    drivingSchool: 'Akademia bezpiecznej jazdy',
    internetDiscount: 'Rabat za zakup przez internet',
    handLuggageIns: 'Ubezpieczenie bagażu podręcznego',
  };

  const risks: string[] = [];

  Object.keys(covers).forEach((key) => {
    if (covers[key]) {
      risks.push(translateRisks[key]);
    }
  });

  if (mapDataOcHelpers.mapOcGreenCard(name)[0] === 'Tak') {
    risks.push(translateRisks.greenCard);
  }

  if (mapDataOcHelpers?.mapOcBls?.[name][0] === 'Tak') {
    risks.push(translateRisks.bls);
  }

  const tiresMap = {
    aviva: ['extended', 'max', 'WariantRozszerzony', 'WariantPelny'],
    beesafe: ['standard', 'premium'],
  } as const;

  const hasTires = name in tiresMap && tiresMap[name].includes(asInsVariant);

  if (hasTires) {
    risks.push(translateRisks.tires);
  }

  if (name === 'aviva' && drivingSchool) {
    risks.push(translateRisks.drivingSchool);
  }

  if (name === 'link4') {
    if (internetDiscount) {
      risks.push(translateRisks.internetDiscount);
    }
    if (handLuggageIns) {
      risks.push(translateRisks.handLuggageIns);
    }
  }

  return risks;
};

export const isSensitiveName = (name, surname, sensitiveData) => [name, surname].some(nameString => sensitiveData.includes(nameString));

export const insurerLabel = (user, isSensitive, typeOfPerson) => {
  if (user && !isSensitive) {
    const { firstName, lastName } = user;
    return `${firstName} ${lastName}`;
  }
  return typeOfPerson;
};

const flatOrEmpty = flat => (flat ? ` / ${flat}` : '');

export const makeTextAddress = ({
  zipCode, city, street, building, flat,
}) => (`${eachFirstLetterUpperCase(convertToRomanNumeral(street))} ${building}${flatOrEmpty(flat)}, ${zipCode} ${eachFirstLetterUpperCase(city)}`);

export const makeTooltipAddressMsg = (
  partner = '',
  addressRegisteredIdentical: boolean,
  addressType: 'address' | 'addressRegistered' = 'address',
) => {
  const addressMsg = 'Adres zamieszkania';
  const regAddressMsg = 'Adres zameldowania';
  const addressAndRegAddress = 'Adres zamieszkania i zameldowania';

  if (typeof partner === 'string') {
    if (partner === LINK4 && addressType === 'address') {
      return addressRegisteredIdentical ? addressAndRegAddress : addressMsg;
    }
    if (partner === LINK4 && addressType === 'addressRegistered') {
      return regAddressMsg;
    }
    if (PARTNERS_WITH_ACTUAL_ADDRESS.includes(partner as TFullSalePartnerName)) {
      return addressMsg;
    }
    return regAddressMsg;
  }

  return regAddressMsg;
};

export const fullSaleConfirmTitle = (paymentType: TPaymentType, partnerName: TFullSalePartnerName, isClientVindicated?: boolean) => {
  const defaultEPayment = 'Dziękujemy za zakup i opłacenie polisy';
  const defaultBankTransfer = 'Niezwłocznie opłać polisę by była ważna!';
  const paymentVindicated = 'Zaakceptuj wniosek i następnie niezwłocznie opłać składkę';
  const ergoEPayment = 'Dziękujemy za zakup polisy';

  if (isClientVindicated) {
    return paymentVindicated;
  }

  if (typeof paymentType === 'string' && typeof partnerName === 'string') {
    const partnerNameLowerCase = isTFullSalePartnerName(partnerName.toLowerCase()) ? partnerName : '';
    if (partnerNameLowerCase === 'tuz') {
      return paymentType.toLowerCase() === 'banktransfer' ? 'Niezwłocznie opłać polisę!' : defaultEPayment;
    }
    if (isErgoHestia(partnerNameLowerCase) && paymentType.toLowerCase() === 'onlinepayment') {
      return ergoEPayment;
    }
    return paymentType.toLowerCase() === 'banktransfer' ? defaultBankTransfer : defaultEPayment;
  }
  return defaultBankTransfer;
};

export const getInsuranceRangeTitle = protectionBegins => (`Okres ubezpieczenia: ${moment(protectionBegins, DATE_FORMAT_YYYY_MM_DD).format(DATE_FORMAT)} do ${moment(protectionBegins, DATE_FORMAT_YYYY_MM_DD).add(1, 'years').subtract(1, 'days').format(DATE_FORMAT)}`);

export const isVindicated = ({
  warningMessages = [],
  clientType,
}: {
  warningMessages?: IDaemonBasket['warningMessages'],
  clientType: IDaemonBasket['clientType'],
}): boolean => !!warningMessages?.find(warning => warning.warningCode === '10009') || clientType === 'related';

export const addGuidToUrl = (url: string, guid: string) => (`${url}/${guid}`);

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const mapPartnerNameToDisplay = (partnerName: '' | TFullSalePartnerName) => {
  switch (partnerName) {
  case 'mtu':
    return 'MTU24';
  case 'ycd':
    return 'You Can Drive';
  default:
    return capitalize(partnerName);
  }
};
