import { getRoutingBase, addProcesToPath } from '../helpers/routerUtils/RouterConfigUtils';

const BASE_NAME = process?.env?.REACT_APP_BASE_NAME ?? '/';
const OPTIONAL_BASENAME = process?.env?.REACT_APP_OPTIONAL_BASE_NAME ?? '';

export const ROUTING_ROP = 'rop' as const;
export const ROUTING_REN = 'ren' as const;
export const ROUTING_AV_PROCESS = [ROUTING_ROP, ROUTING_REN];
export const ROUTING_BASE = getRoutingBase([
  BASE_NAME,
  OPTIONAL_BASENAME,
]) || BASE_NAME;

export const ROUTING_RESULTS = addProcesToPath('wyniki', ROUTING_AV_PROCESS);
export const ROUTING_REDIRECT = addProcesToPath('redirect', ROUTING_AV_PROCESS);
export const ROUTING_FULL_SALE_PERSONAL_DATA = addProcesToPath('danedopolisy/dane-osobowe', ROUTING_AV_PROCESS);
export const ROUTING_FULL_SALE_VEHICLE_DATA = addProcesToPath('danedopolisy/dane-pojazdu', ROUTING_AV_PROCESS);
export const ROUTING_FULL_SALE_DOCS = addProcesToPath('danedopolisy/dokumenty', ROUTING_AV_PROCESS);
export const ROUTING_FULL_SALE_PAYMENT = addProcesToPath('danedopolisy/platnosc', ROUTING_AV_PROCESS);
