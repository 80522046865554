import { getUrlVars } from 'porowneo-ui';

import { isStage } from '../helpers/Envutils';

let timeAndTimeConfig = [
  {
    year: 2022,
    month: 1, // starts from 0
    day: 6,
    from: {
      hour: 10,
      minutes: 0,
    },
    to: {
      hour: 17,
      minutes: 45,
    },
  },
];

// CREATE URL for testing purposes ?year=2021&month=10&day=9&fromHour=20&fromMinutes=45&toHour=20&toMinutes=45
if (isStage) {
  const {
    year,
    month,
    day,
    fromHour,
    fromMinutes,
    toHour,
    toMinutes,
  } = getUrlVars();
  if (year && month && day && fromHour && fromMinutes && toHour && toMinutes) {
    timeAndTimeConfig = [{
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day, 10),
      from: {
        hour: parseInt(fromHour, 10),
        minutes: parseInt(fromMinutes, 10),
      },
      to: {
        hour: parseInt(toHour, 10),
        minutes: parseInt(toMinutes, 10),
      },
    }];
  }
}

export const dateToShow = [
  ...timeAndTimeConfig,
];
