import type { Dispatch as ReactDispatch } from 'react';

import type { ILeavePhoneNumberFormState } from './LeavePhoneNumberModal.type';
import type { IRootState } from '../../../reducers';

export const selectPropsFromStore = ({
  payload: {
    stepOne: {
      phone,
    },
  },
  user: {
    userAgreements,
  },
  agreements,
}: IRootState) => ({
  phone,
  agreements,
  userAgreements,
});

export const handleChange = (
  { dispatch, state }: {dispatch: ReactDispatch<ILeavePhoneNumberFormState>, state: ILeavePhoneNumberFormState },
  key: string,
  value: string | boolean,
): void => {
  switch (key) {
  case 'phone': {
    if (typeof value === 'string') {
      dispatch({ ...state, phone: value });
    }
    break;
  }
  case 'MARK_TEL': {
    dispatch({ ...state, userAgreements: { ...state.userAgreements, MARK_TEL: typeof value === 'boolean' ? value : false } });
    break;
  }
  default: {
    break;
  }
  }
};
