import {
  HEADER_RESULTS_GO_BACK_LINK,
  LOCATOR_PLACEHOLDER, PHONE_CONTENT_PLACEHOLDER,
  PHONE_PLACEHOLDER,
  VERSION_PLACEHOLDER,
} from '../config/htmlElementsConstants';

import type { IRootState } from '../reducers';
import type { TPhoneNumber } from './phone';

const STEP_ONE = 'stepOne';
const STEP = 'step';
const RESULTS = 'results';
const FS = 'fs';
const stepNames = [STEP, RESULTS, FS, STEP_ONE];

export const getClassForBodyBasedOnStepType = (step: IRootState['step'], isFullSale: boolean, isResults: boolean) => {
  if (step === 1) {
    return STEP_ONE;
  }
  if (isFullSale) {
    return FS;
  }
  if (isResults) {
    return RESULTS;
  }
  return STEP;
};

export const addClassToBodyBasedOnStepType = (step: IRootState['step'], isFullSale: boolean, isResults) => {
  const bodyElement = document.querySelector('body');
  const classNameToAdd = getClassForBodyBasedOnStepType(step, isFullSale, isResults);

  if (bodyElement) {
    stepNames.forEach((stepName: string) => {
      if (bodyElement.classList.contains(stepName)) {
        bodyElement.classList.remove(stepName);
      }
    });

    bodyElement.classList.add(classNameToAdd);
  }
};

export const addShortLocatorToHeader = (shortLocator: string) => {
  const locatorPlaceHolder = document.querySelector(LOCATOR_PLACEHOLDER);

  if (locatorPlaceHolder && shortLocator) {
    locatorPlaceHolder.innerHTML = shortLocator;
  }
};

export const invokeCallbackOnClick = (callback: () => void, cssSelector: string) => {
  const locatorPlacerHolder = document.querySelector(cssSelector);
  if (locatorPlacerHolder && typeof callback === 'function') {
    locatorPlacerHolder.addEventListener('click', () => {
      callback();
    });
  }
};

export const clickListenerOnGoBackButton = (callback: () => void) => {
  invokeCallbackOnClick(callback, HEADER_RESULTS_GO_BACK_LINK);
};

export const setPhoneNumber = (phoneNumber: TPhoneNumber) => {
  const phonePlaceHolder = document.querySelector<HTMLAnchorElement>(PHONE_PLACEHOLDER);
  const phoneContentPlaceHolder = document.querySelector(PHONE_CONTENT_PLACEHOLDER);

  if (phonePlaceHolder && phoneNumber) {
    phonePlaceHolder.href = `tel:${phoneNumber}`;
    phonePlaceHolder.innerHTML = phoneNumber;
  }
  if (phoneContentPlaceHolder && phoneNumber) {
    phoneContentPlaceHolder.innerHTML = phoneNumber;
  }
};

export const addVersionToHeader = () => {
  if (process.env.REACT_APP_ENV !== 'prod' && process.env.REACT_APP_VERSION_ENV_HASH) {
    const versionPlaceholder = document.querySelector(VERSION_PLACEHOLDER);
    if (versionPlaceholder) {
      versionPlaceholder.innerHTML = `<span style="font-size: 12px"><span>WERSJA: </span><strong>${process.env.REACT_APP_VERSION_ENV_HASH}</strong></span>`;
    }
  }
};
