import log from 'loglevel';
import axios from 'axios';
import { sendError } from 'porowneo-ui';

import { createUrlSaveReco, axiosConfigWithSession } from '../../../config/url';
import { modifyCoverTypeValueForSelectButtons } from '../../stepOne/utils/DataUtils';

import { getBestOffers, type TRecoTypes, getCheapestOfferForSelectedCover } from './data/Offers';

import type { IRootState } from '../../../reducers';
import type { IDaemonBasket } from '../../../types/DaemonOutput.type';

type TRecosToSend = {
  [key in TRecoTypes]: {
    guid: null | IDaemonBasket['guid'],
    basketId: null | IDaemonBasket['basket_id'] | IDaemonBasket['partner'],
    sum: null | IDaemonBasket['total'],
    resultId: null | IDaemonBasket['calculation_id'],
    amountOfPoints: null | IDaemonBasket['recommendation_rating'],
    type: TRecoTypes,
  }
};

export const runReco = (state: Pick<IRootState, 'user' | 'payload' | 'resultWebsocets'>) => {
  const {
    user, payload, resultWebsocets,
  } = state;

  const { locator, sessionId } = user;
  const { ac, nnw } = payload.stepOne;

  const coverType = modifyCoverTypeValueForSelectButtons(ac, nnw);

  const cheapestOfferWithType = getCheapestOfferForSelectedCover({ payload, resultWebsocets });

  const recoToSend = cheapestOfferWithType ? [
    ...getBestOffers(resultWebsocets, coverType.includes('ac'), { ...cheapestOfferWithType, type: 'eco' }),
    cheapestOfferWithType,
  ] : [];

  const mapedRecos: Partial<TRecosToSend> = {};
  recoToSend.forEach((offer) => {
    mapedRecos[offer.type] = {
      guid: offer?.guid ?? null,
      basketId: offer?.basket_id ?? offer?.partner ?? null,
      sum: offer?.total ?? null,
      resultId: offer?.calculation_id ?? null,
      amountOfPoints: offer?.recommendation_rating ?? null,
      type: offer?.type,
    };
  });

  log.debug('LOG INFO ABOUT RECO OFFERS -->', mapedRecos);

  if (Object.keys(mapedRecos).length > 0) {
    axios.post(
      createUrlSaveReco(),
      {
        locator,
        reko: {
          cheapest: mapedRecos?.cheapest || null,
          eco: mapedRecos?.eco || null,
          optimum: mapedRecos?.optimum || null,
          max: mapedRecos?.max || null,
        },
      }, axiosConfigWithSession(sessionId, locator),
    ).then(({ data }) => {
      log.debug(data);
    }).catch((error) => {
      sendError(error, false, {
        method: 'sendInfoAboutReco',
        component: 'Results',
        url: createUrlSaveReco(),
      });
    });
  } else {
    log.debug('NO RECOS');
  }
};
