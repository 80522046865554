import moment from 'moment';

import { DATE_FORMAT_YYYY_MM_DD, DATE_FORMAT } from '../../../config/formats';

import type { IRootState } from '../../../reducers';
import type { TSelectedProps, IStepTwoValues } from '../StepTwo.types';

const selectPropsFromStore = ({
  visitedSteps, isCalculated, disableFields, user, payload: { stepOne, stepTwo },
}: IRootState): TSelectedProps => ({
  ...stepTwo,
  locator: user.locator,
  sessionId: user.sessionId,
  productionYear: stepOne.vehicle.productionYear,
  visitedSteps,
  isCalculated,
  disableFields,
});

export const mapStoreState = (state: TSelectedProps): TSelectedProps & Pick<IStepTwoValues, 'carMileage' | 'carWheelSide' | 'damaged' | 'firstDateRegistered' | 'dateRegisteredActualOwner'> => ({
  ...state,
  carMileage: state.actualKm !== null ? (state.actualKm / 1000).toString() : '',
  carWheelSide: state.rightWheel ? 'right' : 'left',
  damaged: state.damageDetail ? state.damageDetail : 'no',
  firstDateRegistered: state.firstImmat ? moment(state.firstImmat, DATE_FORMAT_YYYY_MM_DD).format(DATE_FORMAT) : '',
  dateRegisteredActualOwner: state.ownerRegisteredDate ?
    moment(state.ownerRegisteredDate, DATE_FORMAT_YYYY_MM_DD).format(DATE_FORMAT) : '',
});

export default selectPropsFromStore;
