import styled from 'styled-components';
import { getPrimaryColor } from 'porowneo-ui';
import { Button } from 'porowneo-design';

import { phoneNumber } from '../../../helpers/phone';
import { fullSaleConfirmTitle, getInsuranceRangeTitle, mapPartnerNameToDisplay } from '../../../helpers/FullSaleHelpers/FullSaleFormHelpers';
import { WWW_PAGE } from '../../../config/url';
import { isErgoHestia } from '../../../helpers/types/PartnersType.util';
import { useModal } from '../../../hooks/useModal';

import type { TFullSalePartnerName } from '../../../types/Partners.type';
import type { TDate } from '../../../reducers/Payload';

const EMAIL_MSG_P1 = 'Na adres e-mail: ';
const EMAIL_MSG_P2 = ' zostaną wysłane wszystkie niezbędne informacje.';

const Wrapper = styled.div`
  padding: 30px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  line-height: 32px;
`;

const EmailSpan = styled.span`
  font-weight: 600;
`;

const PhoneLink = styled.span<{ href: string }>`
  font-weight: 600;
  text-decoration: none;
`;

const ButtonLink = styled.a`
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  display: block;
  height: 48px;
  line-height: 48px;
  width: 100%;
  border: 1px solid ${props => getPrimaryColor(props)};
  color: ${props => getPrimaryColor(props)};
  font-weight: 600;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    background-color: #F7FCF9;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  & > button {
    margin: 0 15px 0 15px;
  }
`;

export interface IFullSaleConfirmModal {
  email: string;
  protectionBegins: TDate;
  partnerName: TFullSalePartnerName;
  policyNumber: string;
  paymentType: {
    event: 'priceChanged' | 'payment',
    type: 'bankTransfer' | 'onlinePayment',
  };
  isVindicated?: boolean,
  totalInstalmentsCalc?: string | number;
  repayment?: () => void;
}

const FullSaleConfirmModal = ({
  email,
  protectionBegins,
  partnerName,
  policyNumber,
  paymentType,
  isVindicated = false,
  totalInstalmentsCalc = 0,
  repayment = () => {},
}: IFullSaleConfirmModal) => {
  const { closeModal } = useModal();

  return (
    <Wrapper>

      {
        paymentType.event === 'priceChanged' ? <Title>Potwierdź zakup polisy w Porowneo.pl</Title> : <Title>Dziękujemy za skorzystanie z Porowneo.pl!</Title>
      }

      {paymentType.event === 'payment' && paymentType.type === 'bankTransfer' && !isVindicated && (
        <>
          <p>
            {`W związku z zakupem polisy ubezpieczeniowej w ${mapPartnerNameToDisplay(partnerName)}.`}
            <br />
            {getInsuranceRangeTitle(protectionBegins)}
          </p>
          <p>
            {fullSaleConfirmTitle(paymentType.type, partnerName)}
            {!isErgoHestia(partnerName) ? (
              <>
                <br />
                {' Numer rachunku i pozostałe dokumenty za chwilę zostaną wysłane na adres '}
                <EmailSpan>{email}</EmailSpan>
                {' osobnym e-mailem.'}
              </>
            ) : (
              <>
                <br />
                {EMAIL_MSG_P1}
                <EmailSpan>{email}</EmailSpan>
                {EMAIL_MSG_P2}
              </>
            )}
          </p>
        </>
      )}

      {paymentType.event === 'payment' && paymentType.type === 'onlinePayment' && !isVindicated && (
        <>
          <p>
            {`${fullSaleConfirmTitle(paymentType.type, partnerName)} ${mapPartnerNameToDisplay(partnerName)} nr. ${policyNumber}`}
            <br />
            {getInsuranceRangeTitle(protectionBegins)}
          </p>
          <p>
            {!isErgoHestia(partnerName) ? (
              <>
                <br />
                {EMAIL_MSG_P1}
                <EmailSpan>{email}</EmailSpan>
                {' zostaną wysłane wszystkie niezbędne dokumenty.'}
              </>
            ) : (
              <>
                <br />
                {EMAIL_MSG_P1}
                <EmailSpan>{email}</EmailSpan>
                {EMAIL_MSG_P2}
              </>
            )}
          </p>
        </>
      )}

      {paymentType.event === 'payment' && isVindicated && (
        <>
          <p>
            {`${fullSaleConfirmTitle(paymentType.type, partnerName, isVindicated)} ${mapPartnerNameToDisplay(partnerName)} nr. ${policyNumber}`}
            <br />
            {getInsuranceRangeTitle(protectionBegins)}
          </p>
          <p>
            {!isErgoHestia(partnerName) ? (
              <>
                <br />
                {EMAIL_MSG_P1}
                <EmailSpan>{email}</EmailSpan>
                {' zostaną wysłane wszystkie niezbędne dokumenty.'}
              </>
            ) : (
              <>
                <br />
                {EMAIL_MSG_P1}
                <EmailSpan>{email}</EmailSpan>
                {EMAIL_MSG_P2}
              </>
            )}
          </p>
        </>
      )}

      {paymentType.event === 'priceChanged' && (
        <>
          <p>
            {getInsuranceRangeTitle(protectionBegins)}
          </p>
          <p>
            {`Wysokość składki: ${totalInstalmentsCalc} zł`}
          </p>
          <p>
            Składka uległa zmianie w wyniku ponownej weryfikacji w Towarzystwie ubezpieczeniowym. Potwierdź chęć zakupu ubezpieczenia.
          </p>
        </>
      )}

      <p>
        Nie musisz podpisywać i odsyłać żadnych dokumentów.
        <br />
        {'W razie pytań prosimy o kontakt na infolinie: '}
        <PhoneLink href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </PhoneLink>
      </p>

      {paymentType.event === 'priceChanged' ? (
        <ButtonWrapper>
          <Button id="abortButton" variant="contained" fullWidth color="secondary" handleClick={closeModal}>Anuluj zakup</Button>
          <Button
            id="buyButton"
            variant="contained"
            fullWidth
            handleClick={() => {
              closeModal();
              repayment();
            }}
          >
            {paymentType.type === 'onlinePayment' ? 'Przejdź do płatności' : 'Kup'}
          </Button>
        </ButtonWrapper>
      ) : <ButtonLink href={WWW_PAGE}>Wróć na Porowneo.pl</ButtonLink>}

    </Wrapper>
  );
};

export default FullSaleConfirmModal;
