import isEqual from 'lodash/isEqual';

export const checkIfPayloadWasSaved = (key: string) => {
  const oldPayload = sessionStorage.getItem(key);
  return typeof oldPayload === 'string';
};

export const savePayload = (key: string, payload: Record<string, unknown>) => {
  const serializedPayload = payload ? JSON.stringify(payload) : '';
  sessionStorage.setItem(key, serializedPayload);
};

export const checkIfSavedPayloadEqualNewOne = (key: string, newPayload: Record<string, unknown>) => {
  const oldPayload = sessionStorage.getItem(key);
  const oldPayloadParsed = JSON.parse(oldPayload || '{}') as unknown;

  savePayload(key, newPayload);

  return isEqual(oldPayloadParsed, newPayload);
};
