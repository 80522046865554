import type { RefObject } from 'react';
import React, { useContext } from 'react';
import { StickyContainer } from 'react-sticky';

import { ProgressBarForm } from 'porowneo-design';
import {
  ProcessContext,
} from 'porowneo-ui';

import StepsNavigateButton from '../../steps/StepsNavigateButton';
import Sidebar from '../Sidebar/Sidebar';
import { StepFieldsetSpacing } from '../../styled/StepFieldsetSpacing';
import { LeftSection, MainSectionWrapper, StepContentStyled } from '../../styled/gridHelpers';
import StepProgressBar from '../StepProgressBar';
import { STEP_CONFIG } from '../../config/steps';

import {
  HrProgress, StepContainerWrapper, StepMainSection, StepProgressBarFromMobile,
} from './StepContainer.styled';
import StepRow from './StepRow';

const defaultProps = {
  btnOneConfig: {
    func: () => {},
    show: false,
    btnText: '',
  },
  btnTwoConfig: {
    func: () => {},
    show: false,
    btnText: '',
  },
  csdInfoBar: '',
};

export type TStepsSidebar = 1 | 2 | 3 | 4;

export type TSteps = TStepsSidebar | 5;

interface IStepContainer {
  step: TSteps;
  children: React.ReactNode;
  btnOneConfig?: {
    func: () => void;
    show: boolean;
    btnText: string;
  };
  btnTwoConfig: {
    func: () => void;
    show: boolean;
    btnText: string;
    refElement?: RefObject<HTMLButtonElement>;
  };
  isProgressStepBar: boolean;
  title: string;
  csdInfoBar?: string;
  fillStatusPercent?: string;
  stepSectionRef?: null | RefObject<HTMLDivElement>;
}

export default function StepContainer({
  children,
  btnOneConfig = defaultProps.btnOneConfig,
  btnTwoConfig = defaultProps.btnTwoConfig,
  isProgressStepBar,
  title,
  step,
  fillStatusPercent,
  csdInfoBar = defaultProps.csdInfoBar,
  stepSectionRef = null,
}: IStepContainer) {
  const businessProcess = useContext(ProcessContext);

  return (
    <StepFieldsetSpacing data-testid="step-wrapper">
      <StepContainerWrapper>
        <StickyContainer>
          <StepRow
            left={
              (
                <MainSectionWrapper>
                  <LeftSection>
                    <ProgressBarForm
                      activeStep={step}
                      steps={STEP_CONFIG}
                    />
                  </LeftSection>
                  <StepMainSection ref={stepSectionRef}>
                    <StepProgressBarFromMobile>
                      <ProgressBarForm
                        activeStep={step}
                        steps={STEP_CONFIG}
                      />
                      <HrProgress className="hr" />
                    </StepProgressBarFromMobile>
                    <StepProgressBar fillStatusPercent={fillStatusPercent} title={title} isProgressStepBar={isProgressStepBar} />
                    <div className="hr" />
                    <StepContentStyled bottomMargin={!csdInfoBar ? 'large' : 'small'}>
                      {children}
                    </StepContentStyled>
                    <StepsNavigateButton
                      csdInfoBar={csdInfoBar}
                      btnOneConfig={btnOneConfig}
                      btnTwoConfig={btnTwoConfig}
                    />
                  </StepMainSection>
                </MainSectionWrapper>
              )
            }
            right={step < 5 && (
              <Sidebar
                step={step}
                businessProcess={businessProcess}
              />
            )}
          />
        </StickyContainer>
      </StepContainerWrapper>
    </StepFieldsetSpacing>
  );
}
