import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { StickyContainer } from 'react-sticky';

import moment from 'moment';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import {
  ValidationHoc, isArrayNotEmpty,
} from 'porowneo-ui';

import { CustomDatePickerField } from '../../components/fields/DatePickerField';
import { InputField } from '../../components/fields/inputField/InputField';
import { setAgreements } from '../../actions';
import { DATE_FORMAT } from '../../config/formats';
import StepContainer from '../../components/StepContainer/StepContainer';
import { isDisableField } from '../../helpers/payloadUtils';
import { handleCallGtmFieldErrorStep, gtmValidPhoneNumber } from '../../helpers/tagManager/TagManagerUtils';
import { getCSDInfoBar } from '../../helpers/FormUtils';
import FIELDS from '../../config/fields';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { ScrollRow } from '../../components/ScrollRow/ScrollRow';
import { useAppSelector } from '../../hooks/useAppSelector';
import ButtonCheckboxField from '../../components/fields/ButtonCheckboxField';

import { STEP_ONE_FIELDS, HIDDEN_CAR_BASE_FIELDS } from './config/StepOne.config';
import { AgreementsSection } from './components/agreementsSection/AgreementsSection';
import { BaseCarDataSection } from './components/baseCarDataSection/BaseCarDataSection';
import { selectPropsFromStore } from './utils/SelectPropsFromStore';
import { handleChange as handleChangeUtil } from './utils/HandleChange';
import { handleFilteredBy as handleFilteredByUtil } from './utils/HandleFilteredBy';
import { handleChangeGTM } from './utils/HandleChangeGTM';
import { coverTypePickAvValues, modifyCoverTypeValueForSelectButtons } from './utils/DataUtils';
import { CoversWrapper } from './StepOne.styled';

import type { TStepsProps, IHandleSetValidationSteps } from '../../types/Steps.type';
import type { Dispatch } from 'redux';
import type { THandleChangeStepOne, THandleFilterBy } from './StepOne.types';
import type { TEditStepOneParamAction, TEditStepTwoAction } from '../../actions/Payload';

export type TUseDispatchStepOne = TEditStepOneParamAction | TEditStepTwoAction;

export const StepOne = ValidationHoc(({
  fillStatusPercent,
  triggerValidation,
  handleSetValidation: handleSetValidationFromHoc,
  callBackSetValidationFieldStatus,
  handleSetFilledFieldStatus,
}: TStepsProps) => {
  const dispatch = useDispatch<Dispatch<TUseDispatchStepOne>>();
  const {
    locator,
    sessionId,
    isCalculated,
    disableFields,
    ac,
    nnw,
    productionYear,
    brand,
    model,
    fuel,
    transmission,
    eurotaxVersion,
    infoexVersion,
    filters,
    protectionBegins,
    phone,
    agreements,
    userAgreements,
    visitedSteps,
  } = useAppSelector<ReturnType<typeof selectPropsFromStore>>(selectPropsFromStore, isEqual);

  const [isStartFilling, setIsStartFilling] = useState<boolean>(false);
  const { width, height } = useWindowSize();
  const [, isInView, refElement, isContentHidden] = useIntersectionObserver(width, height);
  const { ref: stepSectionRef, domRect: stepSectionPosition } = useResizeObserver<HTMLDivElement>();

  const isProgressStepBar = (isStartFilling || isCalculated || visitedSteps.includes(1));
  const coverType: string[] = modifyCoverTypeValueForSelectButtons(ac, nnw);

  const handleChange: THandleChangeStepOne = (allValues) => {
    handleChangeUtil({
      dispatch,
      selectedFuel: fuel,
      ...allValues,
    });
    handleChangeGTM(allValues.key, allValues.value, allValues.autoSelect, allValues.allData);
    if (!isStartFilling && !allValues.autoSelect) setIsStartFilling(true);
  };

  const handleFilteredBy: THandleFilterBy = (
    id,
    key,
    value,
    allData,
  ) => {
    handleFilteredByUtil(dispatch, key, value, allData);
  };

  // Special for progress bar
  const handleSetValidation: IHandleSetValidationSteps = {
    handleSetValidation: handleSetValidationFromHoc,
    callBackSetValidationFieldStatus,
    handleSetFilledFieldStatus,
    callBackIfFieldInValid: handleCallGtmFieldErrorStep(1),
  };

  const progressBarProps = {
    title: 'Dane samochodu',
    isProgressStepBar,
    fillStatusPercent,
  };

  return (
    <StickyContainer>
      <StepContainer
        step={1}
        btnTwoConfig={{
          show: true, btnText: 'Dalej', func: triggerValidation, refElement,
        }}
        {...progressBarProps}
        csdInfoBar={getCSDInfoBar(protectionBegins)}
        stepSectionRef={stepSectionRef}
      >
        <ScrollRow
          progressBarProps={progressBarProps}
          rowButtonProps={{
            triggerValidation,
          }}
          positionData={stepSectionPosition}
          isRowDisplayed={!isInView && !isContentHidden}
        />
        <BaseCarDataSection
          locator={locator}
          sessionId={sessionId}
          handleChange={handleChange}
          handleSetValidation={handleSetValidation}
          handleFilteredBy={handleFilteredBy}
          disableFields={disableFields}
          vehicle={{
            productionYear,
            brand,
            model,
            fuel,
            transmission,
            eurotaxVersion,
            infoexVersion,
          }}
          filters={filters}
          ifMatchHideInfoex
          noRenderFields={HIDDEN_CAR_BASE_FIELDS}
        />

        <CustomDatePickerField
          {...STEP_ONE_FIELDS.protectionBegins}
          order={10}
          onChange={(key: typeof STEP_ONE_FIELDS.protectionBegins.id, value: Date | null) => handleChange({ key, value })}
          value={moment(protectionBegins).isValid() ? moment(protectionBegins).format(DATE_FORMAT) : ''}
          handleSetValidation={handleSetValidation}
          disabled={isDisableField(disableFields, STEP_ONE_FIELDS.protectionBegins)}
        />

        <div className="hr" />

        <CoversWrapper>
          <ButtonCheckboxField
            {...STEP_ONE_FIELDS.coverType}
            order={11}
            onChange={(key: typeof STEP_ONE_FIELDS.coverType.id, value: string[]) => handleChange({ key, value, allData: coverType })}
            value={coverType}
            buttonCheckbox
            handleSetValidation={handleSetValidation}
            disabled={['oc']}
            disableValue="oc"
            ignoreFillStatus
            availableValues={coverTypePickAvValues(STEP_ONE_FIELDS.coverType.availableValues,
              productionYear)}
          />
        </CoversWrapper>

        <div className="hr" />

        <InputField
          {...STEP_ONE_FIELDS.phone}
          handleChange={(key: typeof STEP_ONE_FIELDS.phone.id, value: string) => handleChange({ key, value })}
          value={phone}
          // validation
          order={12}
          callBackIfFieldValid={gtmValidPhoneNumber}
          handleSetValidation={handleSetValidation}
          ignoreFillStatus={!userAgreements.MARK_TEL}
          MARK_TEL={userAgreements.MARK_TEL}
          relativeFields={['MARK_TEL']}
          disabled={isDisableField(disableFields, STEP_ONE_FIELDS.phone)}
          validations={{
            ...FIELDS.phone.validations,
            phoneNotProvided: !!userAgreements.MARK_TEL,
          }}
        />

        <div className="hr" />

        <AgreementsSection
          locator={locator}
          sessionId={sessionId}
          handleSetValidation={handleSetValidation}
          handleChange={(key, value) => handleChange({ key, value })}
          cacheAgreementsInStore={(data) => {
            if (isArrayNotEmpty(data) && (size(agreements) === 0 || !isEqual(agreements, data))) {
              dispatch(setAgreements(data));
            }
          }}
          agreements={agreements}
          userAgreements={userAgreements}
        />

      </StepContainer>
    </StickyContainer>
  );
});
