import {
  AVIVA, LINK4, MTU, TRASTI, YCD,
} from '../../../../../config/Partners.config';
import { YOUNG_PERSON } from '../../../../../config/InsurerPersons.literal';

import type { TStepOrigin } from '../Insurer.types';
import type { TInsurerType } from '../../../../../types/Insurer.type';

export const showAddressParams = (stepOrigin: TStepOrigin, partnerName: string, type: TInsurerType, addressRegisteredIdentical: boolean) => {
  if (stepOrigin === 'fs') {
    switch (partnerName) {
    case LINK4:
      return { isAddress: true, isAddressRegistered: !addressRegisteredIdentical, isAddressCheckbox: true };
    case AVIVA:
    case MTU:
    case YCD:
    case TRASTI:
      return { isAddress: true, isAddressRegistered: false, isAddressCheckbox: false };
    default:
      return { isAddress: false, isAddressRegistered: true, isAddressCheckbox: false };
    }
  } else if (type !== YOUNG_PERSON && stepOrigin === 3) {
    return { isAddress: true, isAddressRegistered: !addressRegisteredIdentical, isAddressCheckbox: true };
  } else {
    return { isAddress: false, isAddressRegistered: false, isAddressCheckbox: false };
  }
};
