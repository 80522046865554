import type { IDropDown } from 'porowneo-design';

type TDropDownOuterValue = string | number | null;

export const setDropdownFilterByValue = (
  availableValues: IDropDown<TDropDownOuterValue, string>['availableValues'],
  value: TDropDownOuterValue | undefined,
) => {
  if (!!availableValues && Array.isArray(availableValues) && availableValues.length) {
    const foundValue = availableValues.flat().find(item => item.value === value);
    if (foundValue && foundValue.value) {
      return foundValue.value;
    }
  }
  return null;
};
