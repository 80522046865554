import { useState } from 'react';

import isEqual from 'lodash/isEqual';

import { LeavePhoneNumberForm } from '../../leavePhoneNumberForm/LeavePhoneNumberForm';
import { useAppSelector } from '../../../hooks/useAppSelector';

import { selectPropsFromStore, handleChange } from './LeavePhoneNumberModal.util';

import type { LeavePhoneNumberModalProps, ILeavePhoneNumberFormState } from './LeavePhoneNumberModal.type';

export const LeavePhoneNumberModal = ({
  savePhoneNumber,
}: LeavePhoneNumberModalProps) => {
  const {
    phone: phoneFromStore,
    agreements,
    userAgreements: userAgreementsFromStore,
  } = useAppSelector<ReturnType<typeof selectPropsFromStore>>(selectPropsFromStore, isEqual);
  const [state, setState] = useState<ILeavePhoneNumberFormState>({
    phone: phoneFromStore,
    userAgreements: userAgreementsFromStore,
  });

  const props: any = {
    ...state,
    callBackIfValid: () => savePhoneNumber({ phone: state.phone, MARK_TEL: !!state.userAgreements.MARK_TEL }),
    agreements,
    handleChange: (key: string, value: string | boolean) => handleChange({ dispatch: setState, state }, key, value),
  };

  return (
    <div className="col p-0">
      <p className="wrapper__header-subtitle wrapper__header-subtitle--contact text-center">
        Aby precyzyjnie monitorować prawidłowy przebieg procesu, uprzejmie prosimy o wpisanie Twojego numeru telefonu:
      </p>
      <LeavePhoneNumberForm {...props} />
    </div>
  );
};
