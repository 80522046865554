/* eslint-disable camelcase */
import { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';

import moment from 'moment';
import 'moment/locale/pl';
import axios from 'axios';
import log from 'loglevel';
import pick from 'lodash/pick';
import {
  scrollToMyRef, getParametrsFromUrl, getUrlVars,
  removeEvent, sendError,
} from 'porowneo-ui';

import { PorowneoCalculating as Calculating } from '../components/porowneoCalculating/PorowneoCalculating';
import * as ACTIONS from '../actions';
import { selectStartDate, selectCoverType, typeEmail } from '../actions/Steps';
import sendEventToMautic from '../helpers/MauticUtils';
import { resetErrorsPerInsurer } from '../actions/ResultsErrors';
import {
  resetFullSalePayload, editPayloadParam, editStepThreeInsurerParam, editStepFourInsuranceHistoryParam, setPayload,
} from '../actions/Payload';
import utmParametersToSendToDatabase, {
  requiredAgreements,
  RENEW_SESSION_MESSAGE,
  neededAgreements,
  ROP_REN_ALWAYS_DISABLE_FIELDS,
  MARK_TEL,
  MARK_MAIL,
  MODAL_TYPES,
} from '../config/main';
import {
  agreementsStatus, getCookieByName, saveAgreements, showLockModal, catchROPRENLoadError, compareROPRENCredentials,
  createMgmAgreements, detectPromo, isRopRen, getProcessFromLocation,
} from '../helpers/FormUtils';
import { createNavLink } from '../helpers/routerUtils/NavUtils';
import {
  createUrlCarSession,
  createUrlCarSessionApiPlatform,
} from '../config/url';
import { ROUTING_RESULTS, ROUTING_BASE } from '../config/Router.config';
import {
  pushFieldEventToDataLayer, pushStepToDataLayer, startQuestionnaire, setStepTwoGtm,
} from '../helpers/tagManager/TagManagerUtils';
import {
  OWNER,
  CO_OWNER,
  CO_CO_OWNER,
  DRIVER,
} from '../config/InsurerPersons.literal';
import { openLockSocket, profileCreate, saveStepDataInRedis } from '../helpers/socketsCalculations';
import * as GTMEvents from '../helpers/tagManager/TagManagerEventNames';
import { DATE_FORMAT_YYYY_MM_DD } from '../config/formats';
import { updateCarProject, createCarProject, getPayload } from '../api/carProject';
import promoCodesApi from '../api/promoCode';
import StepsWrapper from '../components/StepsWrapper/StepsWrapper';
import {
  createPayload,
  setDisableAndKeptFields,
  getLastInsurer,
  getAgreementsForRopRen,
  mapCarUserFormPayloadToField,
} from '../helpers/payloadUtils';
import { getSensitiveFieldsFromState, loadStateFromLS } from '../helpers/StoreUtils';
import { setSensitiveActive } from '../actions/SensitiveData';
import { isAionTheme } from '../helpers/setupTheme';
import { themeName } from '../helpers/Envutils';
import { checkSendIdd } from '../helpers/sentIDDHelpers';
import { getFullUrlFromSessionStorage } from '../helpers/UrlHelpers';
import { FreeMonthInfoBox } from '../components/FreeMonthInfoBox/FreeMonthInfoBox';
import { handlePromotionMail } from '../api/mail/promotion/promotion';
import LockModal from '../components/modal/LockModalContainer';
import { Advantages } from '../components/Advantages/Advantages';
import { STEP_TWO_FIELDS } from '../views/stepTwo/StepTwo.config';
import { ENABLED_PROMOTIONS } from '../config/promotions.config';
import { closeModal } from '../actions';

import type { TSteps } from '../components/StepContainer/StepContainer';
import type {
  TInsurerBasePerson,
  TOwner,
  TCoOwner,
  TCoCoOwner,
  TDriver,
} from '../types/Insurer.type';
import type { IRootState } from '../reducers';
import type { IPromoInfo } from '../helpers/FormUtils';

moment.locale('pl');

type TFormProps = Pick<IRootState, 'step' | 'user' | 'email' | 'startDate' | 'payload' | 'agreements' | 'coverType' | 'offerDetails' | 'lockedWebsockets' | 'IDDLastEmail' | 'usedEmail' | 'keepFields' | 'sale'> & Record<string, any>;

export class Form extends Component <TFormProps, any> {
  constructor(props) {
    super(props);
    const { user: { locator }, dispatch } = this.props;

    if (props.user.locator !== '') {
      openLockSocket({ dispatch, user: { locator } });
    }

    props.dispatch(ACTIONS.result([]));
    props.dispatch(ACTIONS.resultWithError([]));
    props.dispatch(resetErrorsPerInsurer());
    props.dispatch(resetFullSalePayload());
    this.state = {
      loader: false,
      isLoadingSession: false,
    };
  }

  override componentDidMount() {
    const {
      props: {
        dispatch,
        step,
      },
    } = this;

    this.getLocator().catch(this.handleCriticalError('getLocator'));

    const alertMsg = sessionStorage.getItem('infoMsg');
    if (alertMsg) {
      dispatch(ACTIONS.showAlert({ content: alertMsg }));
      // TODO keep value of insfo msg in store
      sessionStorage.removeItem('infoMsg');
    }

    if (step === 5) {
      pushStepToDataLayer(1);
      dispatch(ACTIONS.setStep(1));
      dispatch(ACTIONS.resetVisitedStep());
      dispatch(ACTIONS.resetFromStepFour());
    } else {
      pushStepToDataLayer(step);
    }
  }

  override componentWillUnmount() {
    removeEvent(window, 'scroll', null);
    removeEvent(document, 'mouseout', null);
  }

  handleCriticalError = (method: string, additionalParams: Record<string, unknown> = {}) => (error: unknown) => {
    const { dispatch } = this.props;
    sendError(error, false, {
      method,
      component: 'Form',
      ...additionalParams,
    });
    dispatch(ACTIONS.showAlert({
      content: 'Wystąpił nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie lub skontaktuj się z nami',
      type: 'danger',
    }));
  }

  /**
   * @param {number} expire - time in minutes
   */
  getExpireTime = expire => new Date(new Date().getTime() + (expire * 60 * 1000))
    .getTime();

  getLocator = async () => {
    const { user } = this.props;
    const params = getUrlVars();
    if (!!params.l && !!params.s && user.locator !== params.l) {
      await this.sessionLoad(user, params.l, params.s, getProcessFromLocation());
    } else if (!isRopRen(user?.businessProcess)) {
      this.sessionCreate(user);
    }
  }

  sessionLoad = async (user, locatorFromUrl, sessionIdFromUrl, process) => {
    const {
      props: {
        dispatch,
        keepFields,
      },
    } = this;

    const {
      locator,
      sessionId,
      businessProcess,
    } = user;
    const alertConfig = {
      content: 'Przepraszamy, ale nie udało się wczytać twojej oferty',
      type: 'danger',
    };

    this.setState({ isLoadingSession: true });

    try {
      const loadedPayload = await getPayload(locatorFromUrl, sessionIdFromUrl);
      if (loadedPayload.data) {
        const {
          payload,
          payload: {
            mgm,
            stepOne,
            stepOne: {
              vehicle,
              vehicle: {
                lpg,
              },
            },
            stepTwo,
            stepTwo: {
              carUsage,
              firstImmat,
              ownerRegisteredDate,
              plate,
              whichOwner,
              lastInsurerOc,
            },
            stepThree,
            stepThree: {
              owner,
              coOwner,
              coCoOwner,
              driver,
            },
            stepFour,
          },
          contact,
          additional: {
            coverType,
            startDate,
            sale,
          },
        } = createPayload(loadedPayload.data);

        if (mgm?.code) await this.validatePromoCode(locatorFromUrl, mgm?.code, sessionId);
        dispatch(ACTIONS.setSaleData(sale));

        const lastInsurerName = await getLastInsurer(lastInsurerOc || 0, locatorFromUrl, sessionIdFromUrl);

        dispatch(setSensitiveActive(getSensitiveFieldsFromState(stepThree)));

        coverType.forEach(cover => dispatch(selectCoverType(cover)));

        dispatch(selectStartDate(startDate));

        const agreements = getAgreementsForRopRen(user);

        const localStorageState = loadStateFromLS('state');

        dispatch(ACTIONS.setUser({
          ...user,
          locator: locatorFromUrl,
          businessProcess: process,
          shortLocator: locatorFromUrl?.slice(0, 6),
          sessionId: sessionIdFromUrl,
          phoneNumber: contact.phone,
          email: contact.email,
          ...agreements,
        }));

        const fieldsToKeepLS = localStorageState?.keepFields;
        const isLSLocatorSame = localStorageState?.payload?.locator === locatorFromUrl;

        const fieldsToKeep = Array.isArray(fieldsToKeepLS) && fieldsToKeepLS.length && isLSLocatorSame ? fieldsToKeepLS : keepFields;

        dispatch(typeEmail(contact.email));

        // disable specific fields
        const [vehicleDisabledFields, vehicleKeptFields] = setDisableAndKeptFields({
          ...vehicle,
          gpl: lpg,
        }, fieldsToKeep);

        const [stepOneDisableFields, stepOneKeptFields] = setDisableAndKeptFields({
          ...stepOne,
        }, fieldsToKeep);

        const [stepTwoDisableFields, stepTwoKeptFields] = setDisableAndKeptFields({
          ...stepTwo,
          carUse: carUsage,
          firstDateRegistered: firstImmat,
          dateRegisteredActualOwner: ownerRegisteredDate,
          carRegistrationNumber: plate,
          whichOwnerInOrder: whichOwner,
          lastInsurer: lastInsurerName,
        }, fieldsToKeep);
        let stepTwoKeptFieldsFiltered = stepTwoKeptFields;
        if (stepTwoDisableFields.includes('carRegistrationNumber')) {
          stepTwoDisableFields.push('carRegistrationNumberNotKnown');
          stepTwoKeptFieldsFiltered = stepTwoKeptFields.filter(field => field !== 'carRegistrationNumberNotKnown');
        }

        const [carUsersDisableFields, carUsersKeptFields] = setDisableAndKeptFields({
          ...mapCarUserFormPayloadToField<TOwner>(OWNER, owner),
          ...mapCarUserFormPayloadToField<TCoOwner>(CO_OWNER, coOwner),
          ...mapCarUserFormPayloadToField<TCoCoOwner>(CO_CO_OWNER, coCoOwner),
          ...mapCarUserFormPayloadToField<TDriver>(DRIVER, driver),
        }, fieldsToKeep);

        const [stepThreeDisableFields, stepThreeKeptFields] = setDisableAndKeptFields({
          ...stepThree,
        }, fieldsToKeep);

        const [stepFourDisableFields, stepFourKeptFields] = setDisableAndKeptFields({
          ...stepFour,
        }, fieldsToKeep);

        dispatch(ACTIONS.setDisableFields([
          ...ROP_REN_ALWAYS_DISABLE_FIELDS,
          ...vehicleDisabledFields,
          ...stepOneDisableFields,
          ...stepTwoDisableFields,
          ...carUsersDisableFields,
          ...stepThreeDisableFields,
          ...stepFourDisableFields,
        ]));

        dispatch(ACTIONS.setKeptFields([
          ...vehicleKeptFields,
          ...stepOneKeptFields,
          ...stepTwoKeptFieldsFiltered,
          ...carUsersKeptFields,
          ...stepThreeKeptFields,
          ...stepFourKeptFields,
        ]));

        dispatch(setPayload({
          ...payload,
          locator: locatorFromUrl,
          stepOne: {
            ...stepOne,
            vehicle: {
              ...stepOne.vehicle,
              fuel: lpg ? 'benzyna + gaz' : stepOne.vehicle.fuel,
            },
          },
          stepTwo: {
            ...stepTwo,
            yearOwnerRegistered: stepTwo.ownerRegisteredDate ? moment(stepTwo.ownerRegisteredDate, DATE_FORMAT_YYYY_MM_DD).year() : 0,
            lastInsurerName,
          },
          stepThree: {
            ...stepThree,
            // TODO FORMMOTO-3628
            // @ts-ignore
            youngPerson: stepThree.youngPerson ? pick(stepThree.youngPerson, ['birthdate', 'licenseDate', 'type', 'userId']) : null,
          },
          stepFour: {
            ...stepFour,
          },
        }));
      } else {
        catchROPRENLoadError(alertConfig, 'no data from API', locatorFromUrl, dispatch);
      }
    } catch (error) {
      // if is rop and change only one param, go back to last url else redirect to form
      const redirectLink =
        (isRopRen(businessProcess) && compareROPRENCredentials(locator, locatorFromUrl, sessionId, sessionIdFromUrl)) ?
          createNavLink(ROUTING_BASE, user) : ROUTING_BASE;

      catchROPRENLoadError(alertConfig, error, locatorFromUrl, dispatch, redirectLink);
    } finally {
      this.setState({ isLoadingSession: false });
    }
  }

  clearSession = (withMsg = true, reload = true) => {
    log.debug('clearSession');
    const { dispatch, user } = this.props;
    if (withMsg) {
      dispatch(closeModal());
      sessionStorage.setItem('infoMsg', RENEW_SESSION_MESSAGE);
    }
    // if session end we need to create new carProject because locator was changed
    ['step_1', 'step_2', 'step_3', 'step_4', 'step_fs_1', 'step_fs_2']
      .forEach(key => sessionStorage.removeItem(key));
    dispatch(editPayloadParam({
      carProjectVersionId: null,
      ktApiProjectId: null,
      projectId: null,
      locator: '',
    }));
    dispatch(ACTIONS.setUser({
      ...user,
      sessionId: '',
      locator: '',
      shortLocator: '',
    }));
    dispatch(ACTIONS.setStep(1));
    if (reload) window.location.reload();
  }

  sessionCreateApiPlatform = (data) => {
    axios.post(createUrlCarSessionApiPlatform(), data)
      .then(res => log.debug('sessionCreateApiPlatform', res))
      .catch((err) => {
        log.debug('sessionCreateApiPlatform', err);
        sendError(err, false, { method: 'sessionCreateApiPlatform', component: 'Form', url: createUrlCarSessionApiPlatform() });
      });
  }

  assignPromoCodeToLocator = async () => {
    const {
      user: { sessionId, locator },
      payload: { mgm },
    } = this.props;
    if (mgm?.code) {
      try {
        await promoCodesApi.pinPromoCode(locator, mgm.code, sessionId);
      } catch (e) {
        sendError(e, false, {
          method: 'assignPromoCodeToLocator',
          component: 'Form',
        });
      }
    }
  }

  validatePromoCode = async (locator, code, sessionId) => {
    const { dispatch } = this.props;
    try {
      const { data } = await promoCodesApi.verifyPromoCode(locator, code, sessionId);
      if (data) {
        dispatch(editPayloadParam({
          mgm: {
            code,
            status: data.success ? 'valid' : 'invalid',
            message: data.message,
          },
        }));
        if (data.success) {
          dispatch(ACTIONS.showAlert({
            // eslint-disable-next-line react/no-danger
            content: <div dangerouslySetInnerHTML={{ __html: data.message }} />,
          }));
        }
      }
    } catch (e) {
      sendError(e, false, {
        method: 'validatePromoCode',
        component: 'Form',
      });
    }
  }

  sessionCreate = (user) => {
    const {
      props: {
        dispatch,
      },
    } = this;
    const { expireTime = 0, locator } = user;
    const { clearSession } = this;
    const nowTime = new Date().getTime();
    const parametersToSend = utmParametersToSendToDatabase;
    const ga = getCookieByName('_ga');
    const { f: test, at: accessToken } = getUrlVars();
    const mgmPromoCode = isAionTheme(themeName) ? '' : getUrlVars()?.promo_code || '';

    if (locator === '' || nowTime > expireTime) {
      axios.post(createUrlCarSession(), {
        source: 'moto',
        id: null,
        ga,
        // eslint-disable-next-line no-underscore-dangle
        device_id: (window as any)?.amplitude?._instances?.$default_instance?.options?.deviceId || null,
        referer: getFullUrlFromSessionStorage(),
        aion: isAionTheme(themeName),
        token: accessToken,
        ...getParametrsFromUrl(getUrlVars, parametersToSend),
      }).then((response) => {
        if (response.status === 200 && response.data.fullLocator !== '') {
          if (test) {
            this.sessionCreateApiPlatform({
              ga,
              test,
              locatorFull: response.data.fullLocator,
              locatorShort: response.data.shortLocator,
              sessionId: response.data.id,
            });
          }
          const sessionStorageState = JSON.parse(sessionStorage.getItem('state') || '{}') || {};
          const sessionStorageLocator = sessionStorageState?.user?.locator;

          if (!sessionStorageLocator) {
            startQuestionnaire({ calculation_id: response.data.fullLocator });
          }

          clearSession(false, false);

          dispatch(ACTIONS.setUser({
            ...user,
            ...{
              locator: response.data.fullLocator,
              shortLocator: response.data.shortLocator,
              sessionId: response.data.id,
              expire: response.data.expire, // minut
              expireTime: this.getExpireTime(response.data.expire),
            },
          }));

          dispatch(editPayloadParam({ locator: response.data.fullLocator }));

          openLockSocket({
            dispatch,
            user: { locator: response.data.fullLocator },
          });

          if (mgmPromoCode) {
            // timeout because front is faster than backend ;D
            setTimeout(
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              () => (this.validatePromoCode(response.data.fullLocator, mgmPromoCode, response.data.id)),
              2000,
            );
          }

          setTimeout(
            clearSession,
            parseInt(response.data.expire, 10) * 60 * 1000,
          );
        } else {
          log.debug(response); // TODO Handle this case.
        }
      }).catch((err) => {
        if (isAionTheme(themeName) && err.response.status === 403) {
          dispatch(ACTIONS.showModal({
            typeOfModal: MODAL_TYPES.aionAccessDenied,
            background: 'secondary',
            showClose: false,
          }));

          sendError(err, false, {
            method: 'sessionCreate - accessDenied (AION)',
            component: 'Form',
            url: createUrlCarSession(),
          });
        } else {
          sendError(err, false, {
            method: 'sessionCreate',
            component: 'Form',
            url: createUrlCarSession(),
          });
        }
      });
    } else {
      // set Timeout depending on the time of termination of the session.
      const expireIn = parseInt(user.expireTime, 10) - new Date().getTime(); // ms
      log.debug(`expire in ${expireIn}ms (${expireIn / 1000}s or ${(expireIn / 60000)}min)`);
      setTimeout(clearSession, expireIn);
    }
  }

  prevStep = () => {
    const { step, dispatch } = this.props;
    const newStep = (step - 1) as TSteps;
    scrollToMyRef(scroll);
    sessionStorage.setItem('GTMStep', newStep?.toString());
    pushStepToDataLayer(newStep);
    dispatch(ACTIONS.setStep(newStep));
  }

  saveUsersIds = (resp: { data?: { users?: { id: number, type: string }[]}}) => {
    const { dispatch, payload: { stepThree } } = this.props;
    log.debug('saveUsersIds', resp);
    if (resp?.data?.users) {
      resp.data.users.forEach(({ type, id }) => {
        if (type !== 'contact' && stepThree[type] !== null) { // What do I do with this contact?
          dispatch(editStepThreeInsurerParam({ userId: id, type: type as TInsurerBasePerson }));
          dispatch(editStepFourInsuranceHistoryParam({ carUser: id }, type as TInsurerBasePerson));
        }
      });
      return true;
    }
    log.warn('saveUsersIds', resp);
    return false;
  }

  saveStepData = () => new Promise((resolve, reject) => {
    const {
      step,
      dispatch,
      user: {
        sessionId, locator, phoneNumber, businessProcess,
      },
      IDDLastEmail,
      payload: { projectId: newProjectId, carProjectVersionId },
      payload,
    } = this.props;

    try {
      profileCreate(this.props, {}, !!phoneNumber).then((response) => {
        const { projectId: ktApiProjectId } = response as any;
        if (step === 1) {
          if (newProjectId === null) {
            createCarProject(this.props, ktApiProjectId)
              .then(async ({ data }) => {
                if (data?.id && data.locator?.locatorFull) {
                  dispatch(editPayloadParam({
                    ktApiProjectId,
                    projectId: data.id,
                    locator: data.locator.locatorFull,
                  }));
                  await this.assignPromoCodeToLocator();
                  resolve('saved');
                }
              }).catch((resp) => {
                log.debug(resp);
                if (resp.toString().indexOf('timeout') > -1) {
                  this.clearSession(false);
                }
                reject(resp);
              });
          } else {
            updateCarProject({
              stepPayload: payload.stepOne,
              step,
              isFullSale: false,
              newProjectId,
              locator,
              sessionId,
              process: businessProcess,
              carProjectVersionId: null,
            }).then(async (resp) => {
              await this.assignPromoCodeToLocator();
              if ((resp as any)?.cached) resolve('cached');
              resolve('saved');
            }).catch(reject);
          }
        }

        if (step !== 1) {
          if (newProjectId === null) {
            throw new Error('ProjectID cant be null after step 1');
          }
          if (step === 2) {
            updateCarProject({
              stepPayload: payload.stepTwo,
              step,
              newProjectId,
              isFullSale: false,
              carProjectVersionId: null,
              locator,
              sessionId,
            }).then((resp) => {
              if ((resp as any)?.cached) resolve('cached');
              resolve('saved');
            })
              .catch(reject);
          }

          if (step === 3) {
            updateCarProject({
              stepPayload: payload.stepThree,
              step,
              newProjectId,
              isFullSale: false,
              carProjectVersionId: null,
              locator,
              sessionId,
            }).then((resp) => {
              if ((resp as any)?.cached) resolve('cached');
              if (this.saveUsersIds(resp as any)) {
                resolve('saved');
              }
              reject(new Error('Missed users ids'));
            })
              .catch(reject);
          }
          if (step === 4) {
            const stepFourEmail = payload?.stepFour?.email || '';

            updateCarProject({
              stepPayload: payload.stepFour,
              step,
              newProjectId,
              isFullSale: false,
              carProjectVersionId,
              locator,
              sessionId,
              process: businessProcess,
              sendIdd: checkSendIdd(IDDLastEmail, stepFourEmail).sendIdd,
            }).then((resp) => {
              if ((resp as any)?.data?.latestCarProjectVersion?.id &&
                (resp as any).data.latestCarProjectVersion.id !== payload.carProjectVersionId) {
                dispatch(editPayloadParam({ carProjectVersionId: (resp as any).data.latestCarProjectVersion.id }));
                dispatch(ACTIONS.setIddLastEmail(stepFourEmail));
              }
              if ((resp as any)?.cached) {
                resolve('cached');
              }
              resolve('saved');
            }).catch(reject);
          }
        }
      }).catch((err) => {
        log.warn(err);
        reject(err);
      });
    } catch (err) {
      reject(err);
    }
  })

  pushNextStepToGtm = () => {
    const {
      step,
      payload: {
        stepOne: {
          protectionBegins,
        },
        stepTwo: {
          carUsage,
        },
      },
    } = this.props;

    const nextStep = (step + 1) as TSteps;

    sessionStorage.setItem('GTMStep', nextStep.toString());

    // Google Tag Manager
    try {
      if (nextStep === 2 && protectionBegins) {
        const given = moment(protectionBegins, DATE_FORMAT_YYYY_MM_DD).startOf('day');
        const current = moment().startOf('day');
        const renewalInDays = given.diff(current, 'days');

        pushFieldEventToDataLayer(GTMEvents.submitDanePojazdu.event, renewalInDays);

        sendEventToMautic(2, (this.props as any));
      }

      if (nextStep === 3) {
        sendEventToMautic(3);
        if (carUsage === STEP_TWO_FIELDS.carUse.defaultValue) {
          setStepTwoGtm('carUse', STEP_TWO_FIELDS.carUse.defaultValue);
        }
      }

      if (nextStep === 4) sendEventToMautic(4);

      if (nextStep === 5) sendEventToMautic(5, (this.props as any));

      if (nextStep < 5) {
        pushStepToDataLayer(nextStep);
      }
    } catch (e) {
      sendError(e, false, {
        method: 'nextStep',
        component: 'Form',
        customErrorMessage: 'GTM',
      });
    }
  }

  filterAgreementsBasedOnFieldValue = (agreementsToFilter, phoneNumber, email) => agreementsToFilter.filter((agreementStepFour) => {
    const isPhoneAgreement = !!phoneNumber;
    const isMailAgreement = !!email;
    switch (agreementStepFour) {
    case MARK_TEL: {
      return isPhoneAgreement;
    }
    case MARK_MAIL: {
      return isMailAgreement;
    }
    default:
      return true;
    }
  })

  nextStep = async () => {
    const {
      step,
      user,
      navigate,
      dispatch,
      agreements,
      email,
      openSockets,
      user: {
        userAgreements, phoneNumber, sessionId, locator, businessProcess,
      },
      usedEmail,
      payload: {
        projectId,
        stepFour: {
          email: emailFromStepFour,
        },
        mgm: {
          status: mgmStatus,
          code: mgmCode,
        },
      },
    } = this.props;

    const newStep = step + 1;

    if (agreementsStatus(requiredAgreements, userAgreements)) {
      if (step === 1 || step === 4) {
        // TODO maybe await saveAgreements( ??
        const { isPromoCode, promoType }: IPromoInfo = mgmCode && mgmStatus ? detectPromo(mgmStatus, mgmCode, themeName) : { isPromoCode: false, promoType: 'discount' };
        const { neededAgreements: agreementsStepFour } = createMgmAgreements(isPromoCode, promoType);

        // LEFT CAUSE OF REVERT
        const neededPromotionAgreements = [...neededAgreements];
        // END

        const filteredStepFourAgreements = this.filterAgreementsBasedOnFieldValue(agreementsStepFour, phoneNumber, email);
        const phonePayload = phoneNumber ? { phone: phoneNumber.replace(/\s+/g, '') } : {};
        const isStepFourAndNoAgreements = step === 4 && filteredStepFourAgreements.length === 0;

        if (!isStepFourAndNoAgreements) {
          await saveAgreements({
            agreements,
            userAgreements,
            sessionId,
            agrementsInfo: { ...phonePayload, mail: email },
            sendTheseAgreements: step === 1 ? neededPromotionAgreements : filteredStepFourAgreements,
            locatorFull: locator,
          });
        }
      }

      this.setState({ loader: true });
      try {
        const result = await this.saveStepData();
        // wait for response from api and with this data save mapped step in redis
        saveStepDataInRedis(this.props);

        this.pushNextStepToGtm();

        log.debug(step, result);

        dispatch(ACTIONS.pushStepToVisited(step));

        scrollToMyRef(scroll);

        dispatch(ACTIONS.setStep(newStep));

        if (step < 4) {
          dispatch(ACTIONS.setStep(newStep));
        } else if (step === 4) {
          openSockets();

          if (ENABLED_PROMOTIONS.length > 0 && businessProcess !== 'REN') {
            await handlePromotionMail({
              email: emailFromStepFour, projectId, usedEmail, dispatch, sessionId, locator,
            });
          }

          dispatch(ACTIONS.setFromStepFour('questionnaire'));
          dispatch(ACTIONS.setCalculated(true));

          navigate(createNavLink(ROUTING_RESULTS, user));
        } else {
          log.warn('strange step ?', step);
        }
      } catch (err) {
        log.error('Unhandled rejection in saveStepData', err);
        this.handleCriticalError('saveStepData', { step })(err);
      } finally {
        this.setState({ loader: false });
      }
    } else {
      log.warn('agreementsStatus ?!');
    }
  }

  override render() {
    const {
      props: {
        step,
        lockedWebsockets,
        user,
      },
      prevStep,
      nextStep,
      state: { loader, isLoadingSession },
    } = this;

    if (showLockModal(lockedWebsockets)) return <LockModal lockedData={lockedWebsockets?.data} />;

    return (
      <div id="form-moto">
        <Calculating msg="" show={loader} />

        <Advantages currentStep={step} />

        {ENABLED_PROMOTIONS.includes('freeMonth') && user.businessProcess !== 'REN' && <FreeMonthInfoBox step={step} />}

        {!isLoadingSession && (
          <StepsWrapper
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  step: state.step,
  user: state.user,
  email: state.email,
  startDate: state.startDate,
  payload: state.payload,
  agreements: state.agreements,
  coverType: state.coverType,
  offerDetails: state.offerDetails,
  lockedWebsockets: state.lockedWebsockets,
  IDDLastEmail: state.IDDLastEmail,
  usedEmail: state.usedEmail,
  keepFields: state.keepFields,
  sale: state.sale,
});

export default connect(mapStateToProps)(Form);
