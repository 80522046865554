import pickBy from 'lodash/pickBy';

import { isAion } from '../setupTheme';

import type { IBasketMarketed } from '../types/TagManagerUtils.type';

export const generateResultKey = (row: IBasketMarketed) => row?.name + Object.keys(pickBy(row?.covers, val => val)).join('-') + (`${row?.basket_id}` || '') + (row?.marketed ? '-marketed' : '');

export const calculateFreeMonthPrice = (price: number) => {
  const divider = isAion ? 6 : 12;
  return Math.ceil(price / divider);
};
