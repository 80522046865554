import { ERGO_POSTAL_CODE, EUROINS_PAYMENT_CODE } from './main';

import type { TPartnerName, TFullSalePartnerName } from '../types/Partners.type';
import type { IWarningMessages } from '../types/DaemonOutput.type';

// Partners
export const AVIVA = 'aviva';
export const BEESAFE = 'beesafe';
export const BENEFIA = 'benefia';
export const EUROINS = 'euroins';
export const GENERALI = 'generali';
export const LINK4 = 'link4';
export const MTU = 'mtu';
export const PROAMA = 'proama';
export const TRASTI = 'trasti';
export const TUZ = 'tuz';
export const UNIQA = 'uniqa';
export const YCD = 'ycd';
export const PZU = 'pzu';

// Partners Groups
export const GENERALI_GROUP = [GENERALI, PROAMA] as const;
export const ERGO_HESTIA_GROUP = [MTU, YCD] as const;

// Offers (results)
export const OFFER_LIST_INSURERS: TPartnerName[] = [
  ...GENERALI_GROUP, ...ERGO_HESTIA_GROUP,
  BENEFIA, LINK4, UNIQA, PZU, EUROINS, BEESAFE, TUZ, TRASTI,
];

// FullSale
export const FULL_SALE_INSURERS: TFullSalePartnerName[] = [...GENERALI_GROUP, ...ERGO_HESTIA_GROUP,
  BENEFIA, EUROINS, BEESAFE, TUZ, TRASTI, UNIQA, LINK4];

export const FULL_SALE_INSURERS_NO_UFG: TFullSalePartnerName[] = [BENEFIA, BEESAFE, TUZ, LINK4, TRASTI, UNIQA, ...ERGO_HESTIA_GROUP];

// DISABLE Buy Button
export const DISABLE_BUY_BTN_OFFER_LIST: TPartnerName[] = [];
export const DISABLE_BUY_BTN_ON_WARNINGS: Partial<Record<TPartnerName, IWarningMessages['warningCode'][]>> = {
  [MTU]: [ERGO_POSTAL_CODE],
  [YCD]: [ERGO_POSTAL_CODE],
  [EUROINS]: [EUROINS_PAYMENT_CODE],
};

// Move to config FS STEP2
export const INSURERS_BANK_TRANSFER: TFullSalePartnerName[] = [BENEFIA, EUROINS, TUZ, TRASTI]; // partners with only BankTransfer

export const INSURERS_ONLINE_PAYMENT: TFullSalePartnerName[] = [BEESAFE]; // partners with only onlinePayment

export const INSURERS_WITH_VINDICATED_PAYMENT: TFullSalePartnerName[] = [...ERGO_HESTIA_GROUP];

export const ERGO_HESTIA_VINDICATED_PAYMENT = 'online.ergohestia.pl'; // only for vindicated clients
