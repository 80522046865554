import log from 'loglevel';
import moment from 'moment';
import { isValidPesel, isValidPeselWithDate } from 'porowneo-ui';

import { setStepThreeGtm } from '../../../../../helpers/tagManager/TagManagerUtils';
import { DATE_FORMAT_YYYY_MM_DD } from '../../../../../config/formats';

import type { IInsurer, IInsurerFull, TInsurerType } from '../../../../../types/Insurer.type';

interface IHandleCallGTMInsurer {
  key: string;
  value: any;
  insurer: IInsurer<TInsurerType> | IInsurerFull<TInsurerType>;
  type: TInsurerType;
  pesel: string | null;
}

export const handleCallGtmInsurer = ({
  key,
  value,
  insurer,
  type,
  pesel,
}: IHandleCallGTMInsurer) => {
  log.trace('GTM Insurer', key, value);
  const keyCondition = { firstName: value?.length >= 3, lastName: value?.length >= 2 };
  switch (key) {
  case 'firstName':
  case 'lastName':
    if (value && value !== insurer[key] && keyCondition[key]) setStepThreeGtm(key, value, type);
    break;
  case 'birthdate': // check
    if (value && moment(value, DATE_FORMAT_YYYY_MM_DD).isValid() && value !== insurer[key]) {
      if (pesel && isValidPesel(pesel)) {
        const date = value.split('-') as [string, string, string];
        if (isValidPeselWithDate(pesel, date)) {
          setStepThreeGtm(key, value, type);
        }
      }
      setStepThreeGtm(key, value, type);
    }
    break;
  case 'pesel':
  case 'maritalStatus':
  case 'sex':
    if (value !== insurer[key]) setStepThreeGtm(key, value, type);
    break;
  case 'licenseYear':
    if (value !== insurer[key]) setStepThreeGtm(key, value.slice(0, 4), type);
    break;
  case 'licenseMonth':
    if (value !== insurer[key]) setStepThreeGtm(key, value.slice(5, 7), type);
    break;
  default:
    break;
  }
};
