import log from 'loglevel';
import moment from 'moment';

import { dateFromPesel, getGenderFromPesel } from '../../../../../helpers/FormUtils';
import { DATE_FORMAT, DATE_FORMAT_YYYY_MM_DD } from '../../../../../config/formats';

import type { TValidationFieldMethods } from '../../../../../types/Steps.type';
import type { TInsurerType } from '../../../../../types/Insurer.type';

const validateArrayOfFields = (arrOfFields, validationFieldMethods) => {
  if (Array.isArray(arrOfFields)) {
    arrOfFields
      .forEach(field => validationFieldMethods?.find(methodsObj => methodsObj.id === field)?.fn());
  }
};

interface IHandleRelativeAutofillIfPeselValid<T, K> {
  idFieldModifiedWithPrefix: string;
  valueFieldModified: string;
  FIELDS_CONF: K,
  type: T,
  handleChange,
  validationFieldMethods: TValidationFieldMethods,
}

export const handleRelativeAutofillIfFieldValid = <T extends TInsurerType, K>({
  idFieldModifiedWithPrefix,
  valueFieldModified,
  FIELDS_CONF,
  type,
  handleChange,
  validationFieldMethods,
}: IHandleRelativeAutofillIfPeselValid<T, K>) => {
  const idFieldModified = idFieldModifiedWithPrefix.replace(`${type}-`, '');
  log.debug(FIELDS_CONF, idFieldModified);
  const relativeFieldsAutoModified = FIELDS_CONF[idFieldModified].relativeAuto;
  const { required } = FIELDS_CONF[idFieldModified];

  if ((required === false && !valueFieldModified)) {
    validateArrayOfFields(relativeFieldsAutoModified, validationFieldMethods);
  } else if (valueFieldModified && relativeFieldsAutoModified.length) {
    relativeFieldsAutoModified.forEach((field) => {
      const methOB = validationFieldMethods.find(methodsObj => methodsObj.id === field);
      if (field.toLowerCase().includes('birth')) {
        handleChange(field, moment(dateFromPesel(valueFieldModified), DATE_FORMAT).format(DATE_FORMAT_YYYY_MM_DD));
      } else if (field.toLowerCase().includes('sex')) {
        const gender = getGenderFromPesel(valueFieldModified);
        if (gender === 'male') {
          handleChange('maidenName', '');
        }
        handleChange(field, gender);
      } else {
        handleChange(field, valueFieldModified);
      }
      return methOB && methOB.fn();
    });
  }
};
