import { sendPromotionMail, createOnPromotionMailFulfilled, createOnPromotionMailReject } from './promotion.utils';

import type { setUsedEmail } from '../../../actions';
import type { Dispatch } from 'redux';
import type { IContactData } from '../../../types/ContactData.type';
import type { IPayload } from '../../../reducers/Payload';
import type { IRootState, IUser } from '../../../reducers';

export const handlePromotionMail = ({
  email, projectId, usedEmail, dispatch, sessionId, locator,
}: {
  email: IContactData['email'], projectId: IPayload['projectId'], usedEmail: IRootState['usedEmail'], dispatch: Dispatch<ReturnType<typeof setUsedEmail>>, sessionId: IUser['sessionId'], locator: IUser['locator'],
}) => new Promise<void>((resolve, reject) => {
  if (email && !usedEmail?.promotions?.includes(email) && projectId) {
    sendPromotionMail({
      email,
      carProjectId: projectId,
      promoName: 'freeMonth',
    }, { sessionId, locator })
      .then(createOnPromotionMailFulfilled({ dispatch, resolve, data: { email, usedEmail } }))
      .catch(createOnPromotionMailReject(reject));
  } else {
    resolve();
  }
});
