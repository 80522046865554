import { dateToShow } from '../config/infobar.config';

export const isDayMonthYearMatched = (date: typeof dateToShow[number]) => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  return year === date.year && month === date.month && day === date.day;
};

export const checkIfIsBetweenWorkingHours = ({ from, to }: typeof dateToShow[number]) => {
  const today = new Date();
  const minutes = today.getMinutes();
  const hours = today.getHours();
  return ((hours === from.hour && (minutes >= from.minutes)) || (hours > from.hour)) &&
    ((hours === to.hour && (minutes <= to.minutes)) || (hours < to.hour));
};

export const isWorkingSunday = (): boolean => dateToShow.some(isDayMonthYearMatched);

export const checkIfDayIsBetweenWorkingHours = (): boolean => dateToShow.some(date => isDayMonthYearMatched(date) && checkIfIsBetweenWorkingHours(date));
