import { createAction } from './index';

import type { ICreatePayload } from '../helpers/payloadUtils';

// STEP 1
export const SELECT_START_DATE = 'SELECT_START_DATE';
export const SELECT_COVER_TYPE = 'SELECT_COVER_TYPE';
export const FILTER_BY = 'FILTER_BY';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export type TFilterByEuroTaxKey = 'hp' | 'doors' | 'capacity';

export const selectCoverType = (coverType: ICreatePayload['additional']['coverType'][number]) => ({
  type: SELECT_COVER_TYPE,
  coverType,
});
export const selectStartDate = (startDate: ICreatePayload['additional']['startDate']) => ({
  type: SELECT_START_DATE,
  startDate,
});

// STEP 4
export const TYPE_EMAIL = 'TYPE_EMAIL';
export const typeEmail = createAction(TYPE_EMAIL);

// LEAVE CONTACT
export const NAME = 'NAME';
export const SURNAME = 'SURNAME';
export const TYPE_LEAVE_PHONE_NUMBER = 'TYPE_LEAVE_PHONE_NUMBER';

export const typeName = createAction(NAME);
export const typeSurname = createAction(SURNAME);
