import { Fetch, AgreementsContainerV2 } from 'porowneo-ui';

import { AGREEMENTS_STEP_ONE } from '../../config/StepOne.config';
import { createGetAllAgreements, axiosConfigWithSession } from '../../../../config/url';
import { FetchError } from '../../../../components/fetchError/FetchError';
import { CheckboxField } from '../../../../components/fields/CheckboxField';

import type { TAgreementsStepOneKeys } from '../../config/StepOne.config';
import type { IAgreementsSectionProps } from './AgreementsSection.type';

const isAgreementUsedByHandleChange = (
  agreement: string,
): agreement is TAgreementsStepOneKeys => AGREEMENTS_STEP_ONE.neededAgreements.includes(agreement as TAgreementsStepOneKeys);

export const AgreementsSection = ({
  locator,
  sessionId,
  cacheAgreementsInStore,
  agreements,
  userAgreements,
  handleChange,
  handleSetValidation,
  uniformPrivacyPolicyLook = true,
}: IAgreementsSectionProps) => (
  <Fetch
    cacheIt
    url={createGetAllAgreements()}
    config={axiosConfigWithSession(sessionId, locator)}
    component={({ data }) => {
      cacheAgreementsInStore(data);
      return (
        <AgreementsContainerV2
          id="stepOneAgreements"
          agreements={agreements}
          neededAgreements={AGREEMENTS_STEP_ONE.neededAgreements}
          requiredAgreements={AGREEMENTS_STEP_ONE.required}
          handleChange={(key, value) => {
            if (isAgreementUsedByHandleChange(key)) {
              handleChange(key, typeof value === 'boolean' ? value : false);
            }
          }}
          userAgreements={userAgreements}
          handleSetValidation={handleSetValidation}
          startOrder={20}
          agreementsOrder={AGREEMENTS_STEP_ONE.order}
          stepOrigin={1}
          uniformPrivacyPolicyLook={uniformPrivacyPolicyLook}
          CheckboxComponent={CheckboxField as any} // TODO FORMMOTO-3709 Fix after moving agreementsContainer to porowneo-design
        />
      );
    }}
    errorComponent={() => (
      <FetchError
        id="stepOneAgreements"
        handleSetValidation={handleSetValidation}
      />
    )}
  />
);
