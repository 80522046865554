import { isMobile } from 'react-device-detect';

import { Fetch, FullLoader } from 'porowneo-ui';
import { InfoBox, FieldContainer } from 'porowneo-design';

// Type
// Config
import { CAR_FIELDS_BASE } from '../../config/BaseCarDataSection.config';
import { createUrlGetCarData, axiosConfigWithSession } from '../../../../config/url';
// Local components
import { EuroTaxSection } from '../eurotaxSection/EuroTaxSection';
import { DropDownField, DropDownFieldWithoutHOC } from '../../../../components/fields/DropDownField';
import FetchedField from '../../../../components/fields/ContainedFetch';
import { FetchError } from '../../../../components/fetchError/FetchError';
// Local utils
import {
  modifyCarDictionaryDataForDropdown,
  carDictionaryDataAvValues,
  groupByIsPopular,
  modifyFetchedDataForDropdown,
  modifyFetchedDataForRadioButtons,
  toLowerCaseRadioButtonsLabel,
  mapAvValuesToNewStandard,
  addPetrolPlusLpgToAvailableValues,
} from '../../utils/DataUtils';
import { isDisableField } from '../../../../helpers/payloadUtils';

import { InfoExpertInfoBoxWrapper } from './BaseCarDataSection.styled';

import type { TDropDownFieldInnerProps } from '../../../../components/fields/DropDownField';
import type { ICarDictionaryDataItem } from '../../../../types/Car.type';
import type {
  IRadioButtonAvValues, IDropdownAvValues,
} from '../../utils/DataUtils.type';
import type {
  IBaseCarDataSectionProps, ICarDictionaryData,
} from './BaseCarDataSection.types';

export const BaseCarDataSection = ({
  locator,
  sessionId,
  vehicle: {
    productionYear,
    brand,
    model,
    fuel,
    transmission,
    eurotaxVersion,
    infoexVersion,
  },
  handleChange,
  handleFilteredBy,
  handleSetValidation,
  disableFields,
  filters,
  ifMatchHideInfoex = true,
  noRenderFields = [],
  origin = 'form',
}: IBaseCarDataSectionProps) => (
  <>
    {!noRenderFields.includes('productionYear') && (
      <>
        <DropDownField
          {...CAR_FIELDS_BASE.productionYear}
          onChange={(key: 'productionYear', value: number) => handleChange({ key, value })}
          value={productionYear}
          handleSetValidation={handleSetValidation}
          order={1}
          searchable={!isMobile}
          disabled={isDisableField(disableFields, CAR_FIELDS_BASE.productionYear)}
        />
        <div className="hr" />
      </>
    )}

    {!!productionYear && !noRenderFields.includes('brand') && (
      <>
        <FetchedField
          {...CAR_FIELDS_BASE.brand}
          cacheIt
          url={createUrlGetCarData({ type: 'brand', productionYear })}
          config={axiosConfigWithSession(sessionId, locator)}
          modifyFetchedData={modifyFetchedDataForDropdown('name', 'id', groupByIsPopular)}
          value={brand}
          order={2}
          handleSetValidation={handleSetValidation}
          searchable={!isMobile}
          disabled={isDisableField(disableFields, CAR_FIELDS_BASE.brand)}
          onChange={(key: 'brand', value: number) => handleChange({ key, value })}
          component={({ data, ...additionalProps }: { data: IDropdownAvValues[][] } & Omit<TDropDownFieldInnerProps, 'availableValues'>) => (
            <DropDownFieldWithoutHOC
              {...additionalProps}
              availableValues={data}
            />
          )}
          errorComponent={() => (
            <FetchError
              id={CAR_FIELDS_BASE.brand.id}
              handleSetValidation={typeof handleSetValidation === 'function' ? handleSetValidation : handleSetValidation.handleSetValidation}
            />
          )}
        />
        <div className="hr" />
      </>
    )}

    {!!brand && !noRenderFields.includes('model') && (
      <>
        <FetchedField
          cacheIt
          url={createUrlGetCarData({ type: 'modelGroup', productionYear, brand })}
          config={axiosConfigWithSession(sessionId, locator)}
          modifyFetchedData={modifyFetchedDataForDropdown('group', 'group', groupByIsPopular)}
          {...CAR_FIELDS_BASE.model}
          order={3}
          onChange={(key: 'model', value: string) => value !== model && handleChange({ key, value })}
          value={model}
          handleSetValidation={handleSetValidation}
          searchable={!isMobile}
          disabled={isDisableField(disableFields, CAR_FIELDS_BASE.model)}
          component={({ data, ...additionalProps }: { data: IDropdownAvValues[][] } & Omit<TDropDownFieldInnerProps, 'availableValues'>) => (
            <DropDownFieldWithoutHOC
              {...additionalProps}
              searchable={!isMobile}
              availableValues={data}
            />
          )}
          errorComponent={() => (
            <FetchError
              id={CAR_FIELDS_BASE.model.id}
              handleSetValidation={typeof handleSetValidation === 'function' ? handleSetValidation : handleSetValidation.handleSetValidation}
            />
          )}
        />
        <div className="hr" />
      </>
    )}

    {!!model && !noRenderFields.includes('fuel') && (
      <>
        <FetchedField
          {...CAR_FIELDS_BASE.fuel}
          cacheIt
          url={createUrlGetCarData({
            type: 'fuel', productionYear, brand, model,
          })}
          config={axiosConfigWithSession(sessionId, locator)}
          modifyFetchedData={modifyFetchedDataForRadioButtons('fuel', 'fuel')}
          onChange={(key: 'fuel', value: string) => value !== fuel && handleChange({ key, value })}
          value={fuel}
          fullWidth
          handleSetValidation={handleSetValidation}
          stopPropagationChildren
          order={5}
          disabled={isDisableField(disableFields, CAR_FIELDS_BASE.fuel)}
          component={({ data, ...additionalProps }: { data: IRadioButtonAvValues } & Omit<TDropDownFieldInnerProps, 'availableValues'>) => {
            const dataForAvailableOptions = addPetrolPlusLpgToAvailableValues(data);
            return (
              <DropDownFieldWithoutHOC
                {...additionalProps}
                availableValues={mapAvValuesToNewStandard(dataForAvailableOptions)}
              />
            );
          }}
          errorComponent={() => (
            <FetchError
              id={CAR_FIELDS_BASE.fuel.id}
              handleSetValidation={typeof handleSetValidation === 'function' ? handleSetValidation : handleSetValidation.handleSetValidation}
            />
          )}
        />
        <div className="hr" />
      </>
    )}

    {!!fuel && !noRenderFields.includes('transmission') && (
      <>
        <FetchedField
          {...CAR_FIELDS_BASE.transmission}
          cacheIt
          url={createUrlGetCarData({
            type: 'transmission', productionYear, brand, model, fuel,
          })}
          config={axiosConfigWithSession(sessionId, locator)}
          modifyFetchedData={modifyFetchedDataForRadioButtons('gear', 'gear')}
          handleSetValidation={handleSetValidation}
          onChange={(key: 'transmission', value: string) => value !== transmission && handleChange({ key, value })}
          value={transmission}
          multiSelect={false}
          order={7}
          disabled={isDisableField(disableFields, CAR_FIELDS_BASE.transmission)}
          component={({ data, ...additionalProps }: { data: IRadioButtonAvValues } & Omit<TDropDownFieldInnerProps, 'availableValues'>) => (
            <DropDownFieldWithoutHOC
              {...additionalProps}
              availableValues={mapAvValuesToNewStandard(toLowerCaseRadioButtonsLabel(data))}
            />
          )}
          errorComponent={() => (
            <FetchError
              id={CAR_FIELDS_BASE.transmission.id}
              handleSetValidation={typeof handleSetValidation === 'function' ? handleSetValidation : handleSetValidation.handleSetValidation}
            />
          )}
        />
        <div className="hr" />
      </>
    )}

    {!!transmission && !noRenderFields.includes('eurotaxVersion') && (
      <>
        <Fetch
          cacheIt
          url={createUrlGetCarData({
            type: 'eurotaxVersion', productionYear, model, fuel, transmission,
          })}
          config={axiosConfigWithSession(sessionId, locator)}
          modifyFetchedData={data => modifyCarDictionaryDataForDropdown(data as ICarDictionaryDataItem[])}
          loader={FullLoader}
          component={({ data }: { data: ICarDictionaryData[] }) => (
            <EuroTaxSection
              data={data}
              eurotaxVersion={eurotaxVersion}
              transmission={transmission}
              handleChange={handleChange}
              handleSetValidation={handleSetValidation}
              disableFields={disableFields}
              handleFilteredBy={handleFilteredBy}
              filters={filters}
              isMobile={isMobile}
            />
          )}
          errorComponent={() => (
            <FetchError
              id={CAR_FIELDS_BASE.eurotaxVersion.id}
              handleSetValidation={typeof handleSetValidation === 'function' ? handleSetValidation : handleSetValidation.handleSetValidation}
            />
          )}
        />
        <div className="hr" />
      </>
    )}

    {!!eurotaxVersion && !noRenderFields.includes('infoexVersion') && (
      <Fetch
        cacheIt
        loader={FullLoader}
        url={createUrlGetCarData({
          type: 'infoexVersion', productionYear, eurotaxVersion,
        })}
        modifyFetchedData={data => modifyCarDictionaryDataForDropdown(data as ICarDictionaryDataItem[])}
        component={({ data }: { data: ICarDictionaryData[] }) => {
          const isMatched = ifMatchHideInfoex && data.length <= 1;

          return (
            <>
              <DropDownField
                {...CAR_FIELDS_BASE.infoexVersion}
                onChange={(_key: string, value: string | number | null) => {
                  if (value !== infoexVersion) {
                    if (value === 0 && data.length === 0) {
                      handleChange({ key: 'infoexVersion', value: null });
                    } else if (value !== null) {
                      const checkedValue = value as number;
                      handleChange({
                        key: 'infoexVersion',
                        value: checkedValue,
                        allData: data.find(({ value: dataValue }) => dataValue === value),
                      });
                    }
                  }
                }}
                value={infoexVersion}
                handleSetValidation={handleSetValidation}
                order={9}
                fullWidth
                ignoreFillStatus={isMatched}
                hidden={isMatched}
                availableValues={[
                  ...carDictionaryDataAvValues(data, infoexVersion, filters, CAR_FIELDS_BASE.infoexVersion.filterConfig),
                  ...CAR_FIELDS_BASE.infoexVersion.availableValues,
                ]}
                validations={{ requiredWithTextMsg: !isMatched && infoexVersion !== 0 }}
                searchable={!isMobile}
                disabled={isDisableField(disableFields, CAR_FIELDS_BASE.infoexVersion)}
                subTitle={origin === 'form' ? CAR_FIELDS_BASE.infoexVersion.subTitle : ''}
              />
              {!noRenderFields.includes('infoBoxInfoEkspert') && !isMatched && infoexVersion === 0 && (
                <InfoExpertInfoBoxWrapper>
                  <FieldContainer>
                    <InfoBox
                      {...CAR_FIELDS_BASE.infoBoxInfoEkspert}
                    />
                  </FieldContainer>
                </InfoExpertInfoBoxWrapper>
              )}
              {!noRenderFields.includes('infoBoxInfoEkspert') && !isMatched && <div className="hr" />}
            </>
          );
        }}
        errorComponent={() => (
          <FetchError
            id={CAR_FIELDS_BASE.infoexVersion.id}
            handleSetValidation={typeof handleSetValidation === 'function' ? handleSetValidation : handleSetValidation.handleSetValidation}
          />
        )}
      />
    )}
  </>
);
