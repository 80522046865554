import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import {
  FieldContainer,
  type IDropDown,
  ButtonSelect,
  checkIfDropDownShouldBeRendered,
  DropDown,
  ButtonCheckbox,
} from 'porowneo-design';
import { ValidationFieldHoc } from 'porowneo-ui';

import {
  FIELDS_ALWAYS_BEING_DROPDOWNS,
  FIELDS_ALWAYS_BEING_SELECT_BUTTONS,
  FIELDS_TO_COVERT_FROM_SELECT_BUTTON_TO_CHECKBOX,
} from '../../config/main';

import { withTooltipData } from './TooltipDataProvider';

import type { IValidationFieldHocInnerProps } from './ContainedFetch';

type TDropDownOuterValue = string | number | null;

export type TDropDownFieldInnerProps = Omit<IDropDown<TDropDownOuterValue, string>, 'clickOutsideCallBack' | 'handleChange'>
  & IValidationFieldHocInnerProps
  & { onChange: (key: IDropDown<TDropDownOuterValue, string>['id'], value: TDropDownOuterValue) => void }
  & { children?: ReactNode };

export const DropDownFieldWithoutHOC = (props: TDropDownFieldInnerProps) => {
  const {
    children,
    id,
    validationStatus,
    triggerValidationManually,
    onChange,
    autoSelectIfOne,
    value,
    searchable,
    validations,
  } = props;
  const shouldRunManualValidationAutoSelect: boolean = !!value && !!autoSelectIfOne && validationStatus === 'invalid';
  const shouldRunManualValidationRequiredChanged: boolean = !validations && !value && validationStatus === 'invalid';
  useEffect(() => {
    if (autoSelectIfOne && validationStatus === 'invalid') {
      triggerValidationManually();
    }
    if (!validations && validationStatus === 'invalid') {
      triggerValidationManually();
    }
  }, [shouldRunManualValidationAutoSelect, shouldRunManualValidationRequiredChanged]);

  const handleClickOutside = () => {
    if (validationStatus === 'init') {
      triggerValidationManually();
    }
  };

  let color: 'secondary' | 'error' | 'primary' = 'secondary';
  if (validationStatus === 'invalid') {
    color = 'error';
  }
  if (validationStatus === 'valid') {
    color = 'primary';
  }

  const componentProps = {
    ...props,
    color,
    clickOutsideCallBack: handleClickOutside,
    handleChange: onChange,
    fullWidth: true,
    searchable: searchable || !isMobile,
  };

  if (checkIfDropDownShouldBeRendered(componentProps, FIELDS_ALWAYS_BEING_DROPDOWNS, FIELDS_ALWAYS_BEING_SELECT_BUTTONS)) {
    return (
      <>
        { children }
        <DropDown dataTestId={`${id}-dropdown`} {...componentProps} />
      </>
    );
  }
  if (FIELDS_TO_COVERT_FROM_SELECT_BUTTON_TO_CHECKBOX.includes(id)) {
    return (
      <>
        { children }
        <ButtonCheckbox dataTestId={`${id}-buttoncheckbox`} {...componentProps} multiSelect={false} />
      </>
    );
  }

  return (
    <>
      { children }
      <ButtonSelect dataTestId={`${id}-buttonselect`} {...componentProps} />
    </>
  );
};

export const DropDownField = withTooltipData(ValidationFieldHoc(DropDownFieldWithoutHOC, FieldContainer as any));
