import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import { InfoBox } from 'porowneo-design';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

import { InputField } from '../../../../components/fields/inputField/InputField';
import { CustomDatePickerField } from '../../../../components/fields/DatePickerField';
import { setSensitiveInActive } from '../../../../actions/SensitiveData';
import { DATE_FORMAT_YYYY_MM_DD } from '../../../../config/formats';
import FIELDS from '../../../../config/fields';
import { generateInsurerFieldsConfig } from '../../../../config/InsurerCommonFields';
import { reverseYYYYMMDD } from '../../../../helpers/FormUtils';
import { setViewValue, isDisableField } from '../../../../helpers/payloadUtils';
import { YOUNG_PERSON } from '../../../../config/InsurerPersons.literal';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { CheckboxFieldWithContainer } from '../../../../components/fields/CheckboxField';
import ButtonCheckboxField from '../../../../components/fields/ButtonCheckboxField';
import { DropDownField } from '../../../../components/fields/DropDownField';

import LicenseDate from './LicenseDate';
import InsurerAddress from './InsurerAddress';
import { InfoBoxContainer, ReduceMargin } from './Insurer.styled';
import { selectInsurerPropsFromStore } from './utils/selectInsurerPropsFromStore';
import { handleRelativeAutofillIfFieldValid } from './utils/handleRelativeAutoFill';
import { formatBirthDate } from './utils/formatBirthDate';
import { handleCallGtmInsurer } from './utils/handleCallGtmInsurer';
import { handleChangeUtil } from './utils/handleChangeUtil';
import { showAddressParams } from './utils/showAddressParams';

import type {
  IInsurerProps, TAdditionArguments, TInsurersFields, TSelectStepThreeProps,
} from './Insurer.types';
import type { TInsurerType } from '../../../../types/Insurer.type';
import type { IRootState } from '../../../../reducers';

export type THandleChangeInsurer = <T extends TInsurersFields[keyof TInsurersFields]['id']>(
  key: T,
  value: string | boolean,
  _?: { [key: string]: unknown },
  autoSelect?: boolean,
  ) => void;

export const Insurer = <T extends TInsurerType>({
  showInfoBoxNameSurnamePesel = false,
  handleSetValidation,
  orderPrefix = 0,
  validationFieldMethods = [],
  setInitializedStep = undefined,
  disableFields = [],
  type,
  stepOrigin,
  showMaidenName = true,
// eslint-disable-next-line sonarjs/cognitive-complexity
}: IInsurerProps<T>) => {
  const dispatch = useDispatch();
  const {
    insurer,
    insurer: {
      firstName, lastName, maidenName, birthdate, licenseDate, licenseDateInitMonth,
      hasDrivingLicense, pesel, sex, maritalStatus,
      addressRegisteredIdentical, address, addressRegistered,
    },
    coowners,
    mainDriver,
    partnerName = '',
    sensitiveData,
  } = useAppSelector<TSelectStepThreeProps>((
    state: IRootState,
  ) => selectInsurerPropsFromStore<TAdditionArguments<typeof type>>(state, { type, stepOrigin }), isEqual);

  const showAddressSection = showAddressParams(stepOrigin, partnerName, type, addressRegisteredIdentical);

  const handleChange: THandleChangeInsurer = (
    keyWithPrefix,
    value,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _allData = {},
    autoSelect = false,
  ): void => {
    const key = keyWithPrefix.replace(`${type}-`, '');

    handleCallGtmInsurer({
      key, value, insurer, type, pesel,
    });

    if (setInitializedStep && stepOrigin === 3) setInitializedStep(keyWithPrefix, autoSelect);

    handleChangeUtil({
      key, value, stepOrigin, dispatch, address, licenseDate, type,
    });
  };

  const setSensitiveDataInactive = (id: string) => dispatch(setSensitiveInActive(id));

  const FIELDS_CONF = generateInsurerFieldsConfig(type, coowners, stepOrigin, sex, mainDriver, showMaidenName);

  const callbackIfPeselValid = (
    idFieldModifiedWithPrefix: string,
    valueFieldModified: string,
  ) => handleRelativeAutofillIfFieldValid<typeof type, typeof FIELDS_CONF>({
    idFieldModifiedWithPrefix, valueFieldModified, type, validationFieldMethods, handleChange, FIELDS_CONF,
  });

  const renderInsurer = insurer && (insurer.address || type === YOUNG_PERSON);

  return (
    renderInsurer ? (
      <>
        {FIELDS_CONF.firstName.isShow && (
          <>
            {showInfoBoxNameSurnamePesel && (
              <>
                <InfoBoxContainer>
                  <InfoBox
                    {...FIELDS.infoBoxNameSurnamePesel}
                    fullWidth
                  />
                </InfoBoxContainer>
                <div className="hr" />
              </>
            )}

            <InputField
              {...FIELDS_CONF.firstName}
              disabled={FIELDS_CONF.firstName?.disabled || isDisableField(disableFields, FIELDS_CONF.firstName)}
              order={orderPrefix + 1}
              handleChange={handleChange}
              value={firstName}
              owner
              ownerName={firstName}
              ownerSurname={lastName}
              ownerPesel={pesel}
              handleSetValidation={handleSetValidation}
              setSensitiveDataInactive={setSensitiveDataInactive}
              sensitiveData={sensitiveData}
              additionalValidationParams={sensitiveData}
            />

            <div className="hr" />
          </>
        )}

        {FIELDS_CONF.lastName.isShow && (
          <>
            <InputField
              {...FIELDS_CONF.lastName}
              disabled={FIELDS_CONF.lastName?.disabled || isDisableField(disableFields, FIELDS_CONF.lastName)}
              order={orderPrefix + 2}
              handleChange={handleChange}
              value={lastName}
              owner
              ownerName={firstName}
              ownerSurname={lastName}
              ownerPesel={pesel}
              handleSetValidation={handleSetValidation}
              setSensitiveDataInactive={setSensitiveDataInactive}
              sensitiveData={sensitiveData}
              additionalValidationParams={sensitiveData}
            />
            <div className="hr" />
          </>
        )}

        {FIELDS_CONF.maidenName.isShow && (
          <>
            <InputField
              {...FIELDS_CONF.maidenName}
              disabled={FIELDS_CONF.maidenName?.disabled || sex === 'male' || isDisableField(disableFields, FIELDS_CONF.maidenName)}
              order={orderPrefix + 3}
              handleChange={handleChange}
              value={maidenName}
              handleSetValidation={handleSetValidation}
              setSensitiveDataInactive={setSensitiveDataInactive}
              sensitiveData={sensitiveData}
              additionalValidationParams={sensitiveData}
            />
            <div className="hr" />
          </>
        )}

        {FIELDS_CONF.pesel.isShow && (
          <>
            <InputField
              {...FIELDS_CONF.pesel}
              disabled={FIELDS_CONF.pesel?.disabled || isDisableField(disableFields, FIELDS_CONF.pesel)}
              order={orderPrefix + 3}
              handleChange={handleChange}
              value={pesel}
              dateOfBirth={birthdate}
              birthdate={reverseYYYYMMDD(birthdate)}
              gender={sex}
              owner
              ownerName={firstName}
              ownerSurname={lastName}
              ownerPesel={pesel}
              listener="onAccept"
              handleSetValidation={handleSetValidation}
              sensitiveData={sensitiveData}
              callBackIfFieldValid={callbackIfPeselValid}
              setSensitiveDataInactive={setSensitiveDataInactive}
              additionalValidationParams={sensitiveData}
            />
            <div className="hr" />
          </>
        )}

        {FIELDS_CONF.birthdate.isShow && (
          <>
            <CustomDatePickerField
              {...FIELDS_CONF.birthdate}
              disabled={FIELDS_CONF.birthdate?.disabled || isDisableField(disableFields, FIELDS_CONF.birthdate)}
              order={orderPrefix + 4}
              pesel={pesel}
              onChange={(k, v: string) => handleChange(k, v ? moment(v).format(DATE_FORMAT_YYYY_MM_DD) : '')}
              handleSetValidation={handleSetValidation}
              value={formatBirthDate(birthdate)}
            />
            <div className="hr" />
          </>
        )}

        {FIELDS_CONF.sex.isShow && (
          <>
            <ButtonCheckboxField
              {...FIELDS_CONF.sex}
              disabled={FIELDS_CONF.sex?.disabled || isDisableField(disableFields, FIELDS_CONF.sex)}
              order={orderPrefix + 5}
              onChange={handleChange}
              multiSelect={false}
              value={sex}
              pesel={pesel}
              handleSetValidation={handleSetValidation}
            />
            <div className="hr" />
          </>
        )}

        <LicenseDate
          birthdate={birthdate}
          licenseDate={licenseDate}
          licenseDateInitMonth={licenseDateInitMonth}
          hasDrivingLicense={hasDrivingLicense}
          handleChange={handleChange}
          handleSetValidation={handleSetValidation}
          typeOfInsurer={type}
          stepOrigin={stepOrigin}
          mainDriver={mainDriver}
          insurerName={partnerName}
          coowners={coowners}
          orderPrefix={orderPrefix + 5}
          disableFields={disableFields}
          isMobile={isMobile}
        />

        {(FIELDS_CONF.maritalStatus.isShow) && (
          <>
            <DropDownField
              {...FIELDS_CONF.maritalStatus}
              disableAll={FIELDS_CONF.maritalStatus?.disabled || isDisableField(disableFields, FIELDS_CONF.maritalStatus)}
              order={orderPrefix + 9}
              onChange={handleChange}
              fullWidth
              value={setViewValue(maritalStatus)}
              handleSetValidation={handleSetValidation}
            />
            <div className="hr" />
          </>
        )}

        {showAddressSection?.isAddress && type !== 'youngPerson' && (
          <InsurerAddress
            typeOfAddress="address"
            typeOfInsurer={type}
            address={address}
            dispatch={dispatch}
            coowners={coowners}
            stepOrigin={stepOrigin}
            handleSetValidation={handleSetValidation}
            addressRegisteredIdentical={addressRegisteredIdentical}
            orderPrefix={orderPrefix + 9}
            setInitializedStep={setInitializedStep}
            partnerName={partnerName}
            disableFields={disableFields}
          />
        )}

        {showAddressSection?.isAddressCheckbox && (
          <>
            <ReduceMargin>
              <CheckboxFieldWithContainer
                {...FIELDS_CONF.addressRegisteredIdentical}
                disabled={FIELDS_CONF.addressRegisteredIdentical?.disabled || isDisableField(disableFields, FIELDS_CONF.addressRegisteredIdentical)}
                order={orderPrefix + 15}
                onChange={handleChange}
                value={addressRegisteredIdentical}
                textSize="small"
                handleSetValidation={handleSetValidation}
              />
            </ReduceMargin>
            <div className="hr" />
          </>
        )}

        {showAddressSection?.isAddressRegistered && type !== 'youngPerson' && (
          <>
            <InsurerAddress
              typeOfAddress="addressRegistered"
              typeOfInsurer={type}
              coowners={coowners}
              address={addressRegisteredIdentical ? address : addressRegistered}
              addressRegisteredIdentical={addressRegisteredIdentical}
              dispatch={dispatch}
              stepOrigin={stepOrigin}
              handleSetValidation={handleSetValidation}
              orderPrefix={orderPrefix + 15}
              setInitializedStep={setInitializedStep}
              partnerName={partnerName}
              disableFields={disableFields}
            />
            <div className="hr" />
          </>
        )}
      </>
    ) : null
  );
};

export default Insurer;
