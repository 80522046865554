/* eslint-disable no-param-reassign,camelcase */
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { isTimestampYoungerThanDaysInPast, sendError } from 'porowneo-ui';

import { DEV_STAY_ON_RESULTS } from '../config/dev.config';
import {
  ROUTING_BASE, ROUTING_FULL_SALE_PAYMENT, ROUTING_ROP, ROUTING_REN, ROUTING_FULL_SALE_DOCS,
} from '../config/Router.config';
import promoCodesApi from '../api/promoCode';
import { generateInitialAddressFull, generateInitialInsurerFull, initialMgm } from '../reducers/Payload';
import { initialUsedEmail, initialIDDLastEmail } from '../reducers';
import { editStepThreeInsurerParam, editStepOneFsInsurerParam } from '../actions/Payload';
import { ADDRESS, ADDRESS_AND_REGISTERED, REGISTERED } from '../config/Address.literal';
import { resetStore } from '../actions';

import { isRopRen } from './FormUtils';
import { createNavLink } from './routerUtils/NavUtils';
import { removeShortLocatorFromSessionStorage } from './payloadEquality/removeShortLocatorFromSessionStorage';
import { removeRecalculationPayloadFromSessionStorage } from './payloadEquality/removeRecalculationPayloadFromSessionStorage';

import type { Dispatch } from 'redux';
import type { TRegistered, TAddress, TAddressAndRegistered } from '../types/Address.type';
import type { TInsurerType, IInsurerFull, IInsurer } from '../types/Insurer.type';

export const settingAppVersion = (localStorage, appVersion) => {
  const versionKey = 'version_app';
  const varsionAppFromStorage = localStorage.getItem(versionKey);

  if (typeof varsionAppFromStorage === 'undefined' || varsionAppFromStorage === null || localStorage.version_app !== appVersion) {
    localStorage.clear();
    localStorage.setItem(versionKey, appVersion);
    return localStorage.getItem(versionKey);
  }
  return localStorage.getItem(versionKey);
};

export const loadState = (type) => {
  const isLocal = type === 'local';
  try {
    const serializedState = isLocal ? localStorage.getItem('state') : sessionStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    const persistedState = JSON.parse(serializedState);

    const {
      goingToStep,
      resultWebsocets,
      resultErrorsWebsocets,
      resultsErrorsPerInsurer,
      fromStepFour,
      activeFullSaleInsurer,
      fullSaleStatus,
      ...stateWithoutResults
    } = persistedState;

    const {
      fullSalePayload,
      offerDetails,
      partnerAgreements,
      reqPartnerAgreements,
      sensitiveData,
      ...localStorageState
    } = stateWithoutResults;

    if (DEV_STAY_ON_RESULTS) return persistedState;

    return isLocal ? {
      ...localStorageState,
      usedEmail: initialUsedEmail,
      IDDLastEmail: initialIDDLastEmail,
    } : stateWithoutResults;
  } catch (e) {
    sendError(e, false, {
      method: 'loadState',
    });
    return {};
  }
};

export const sortedCoverType = initialCoverType => uniq(initialCoverType.sort((a, b) => {
  switch (a) {
  case 'oc':
    return -1;
  case 'ac':
    if (b !== 'oc') return -1;
    return 1;
  case 'nnw':
    if (b !== 'oc' && b !== 'ac') return -1;
    return 1;
  default:
    return 0;
  }
}));

const setAnalyticsState = (stateAnalytics) => {
  let convertedStateAnalytics = { ...stateAnalytics };

  const insurerReset = {
    address: '',
    addressRegistered: '',
    addressRegisteredIdentical: '',
    birthdate: '',
    firstName: '',
    lastName: '',
    licenseDate: '',
    maritalStatus: '',
    pesel: '',
    sex: '',
    type: '',
  };

  const historyInsuranceReset = {
    carUser: '',
    insuranceHistorySame: '',
    ocInsuranceHistory: {
      type: 'oc',
      yearsBuying: '',
      damagesPaid: '',
      firstDamage: '',
      secondDamage: '',
      thirdDamage: '',
      fourthDamage: '',
      fifthDamage: '',
    },
    acInsuranceHistory: '',
  };

  if (stateAnalytics.coowners === 0) {
    convertedStateAnalytics = {
      ...stateAnalytics,
      payload: {
        ...stateAnalytics.payload,
        stepThree: {
          ...stateAnalytics.payload.stepThree,
          coOwner: {
            ...insurerReset,
          },
          coCoOwner: {
            ...insurerReset,
          },
        },
        stepFour: {
          ...stateAnalytics.payload.stepFour,
          coOwnerInsuranceHistory: {
            ...stateAnalytics.payload.stepFour.coOwnerInsuranceHistory,
            ...historyInsuranceReset,
          },
          coCoOwnerInsuranceHistory: {
            ...stateAnalytics.payload.stepFour.coOwnerInsuranceHistory,
            ...historyInsuranceReset,
          },
        },
      },
    };
  } else if (stateAnalytics.coowners === 1) {
    convertedStateAnalytics = {
      ...stateAnalytics,
      payload: {
        ...stateAnalytics.payload,
        stepThree: {
          ...stateAnalytics.payload.stepThree,
          driver: {
            ...insurerReset,
          },
        },
        stepFour: {
          ...stateAnalytics.payload.stepFour,

          coCoOwnerInsuranceHistory: {
            ...stateAnalytics.payload.stepFour.coOwnerInsuranceHistory,
            ...historyInsuranceReset,
          },
        },
      },
    };
  }

  if (stateAnalytics.mainDriver !== 'other') {
    convertedStateAnalytics = {
      ...stateAnalytics,
      payload: {
        ...stateAnalytics.payload,
        stepThree: {
          ...stateAnalytics.payload.stepThree,
          coCoOwner: {
            ...insurerReset,
          },
        },
        stepFour: {
          ...stateAnalytics.payload.stepFour,

          driverInsuranceHistory: {
            ...stateAnalytics.payload.stepFour.coOwnerInsuranceHistory,
            ...historyInsuranceReset,
          },
        },
      },
    };
  }

  if (stateAnalytics.youngPersonLicense === '') {
    convertedStateAnalytics = {
      ...stateAnalytics,
      youngPersonLicenseMonth: '',
    };
  }
  const functionGetConvertedKeys = (convertedObject) => {
    const stateAnalyticsKeys = Object.keys(convertedObject);
    stateAnalyticsKeys.forEach((key) => {
      const stateValue = convertedObject[key];
      const isEmptyString = stateValue === '';
      const isEmptyArray = isArray(stateValue) && stateValue.length === 0;
      const isNullOrUndefined = stateValue === null;
      const isEmptyObject = isObject(stateValue) && Object.keys(stateValue).length === 0;

      if (isObject(stateValue) && !isEmptyObject) {
        convertedObject[key] = functionGetConvertedKeys(stateValue);
      }

      if (isEmptyString || isEmptyArray || isEmptyObject || isNullOrUndefined) {
        convertedObject[key] = 'undefined';
      }
    });
    return convertedObject;
  };

  return JSON.stringify(functionGetConvertedKeys(convertedStateAnalytics));
};

export const saveState = (state, loadedLocalStorageState = false) => {
  try {
    const stateToStore = cloneDeep(state);
    const stateAnalytics = cloneDeep(state);

    const initialCoverType = stateToStore.coverType;
    const analyticsInitialCoverType = stateAnalytics.coverType;

    stateToStore.coverType = sortedCoverType(initialCoverType);
    stateAnalytics.coverType = sortedCoverType(analyticsInitialCoverType);

    const serializedState = JSON.stringify(stateToStore);
    sessionStorage.setItem('state', serializedState);

    try {
      const serializedStateAnalytics = setAnalyticsState(stateAnalytics);
      sessionStorage.setItem('stateAnalytics', serializedStateAnalytics);
    } catch (e) {
      sendError(e, false, {
        method: 'saveState',
        info: 'Error while trying to serialize data for analytics',
      });
    }

    if (loadedLocalStorageState) sessionStorage.setItem('loadedLocalStorageState', `${loadedLocalStorageState}`);

    if (stateToStore?.visitedSteps?.length > 0) {
      localStorage.setItem('state', serializedState);
      localStorage.setItem('stateTime', new Date().getTime().toString());
    }
  } catch (e) {
    sendError(e, false, {
      method: 'saveState',
    });
  }
};

export const clearSession = () => {
  sessionStorage.removeItem('state');
  window.location.reload();
};

export const shouldShowForUrlLoadState = () => {
  const { pathname } = window.location;
  const pathsToNotShowModal = [
    ROUTING_FULL_SALE_PAYMENT,
    ROUTING_FULL_SALE_DOCS,
    ROUTING_REN,
    ROUTING_ROP,
  ];
  return !pathsToNotShowModal.some(path => pathname.includes(path));
};

export const shouldShowLocalStateModal = (localStorageState, persistedState, localStorage) => {
  const DAYS_TO_SHOW_MODAL = 15;
  const localStorageVisitedSteps = localStorageState?.visitedSteps?.length;
  const sessionStorageVisitedSteps = persistedState?.visitedSteps?.length;

  return (
    localStorageVisitedSteps === 4 &&
    (sessionStorageVisitedSteps === 0 || typeof sessionStorageVisitedSteps === 'undefined') &&
    isTimestampYoungerThanDaysInPast(localStorage.getItem('stateTime'), DAYS_TO_SHOW_MODAL) &&
    shouldShowForUrlLoadState()
  );
};

export const loadStateFromLS = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (e) {
    sendError(e, false, {
      method: 'loadStateFromLS',
    });
    return {};
  }
};

export const getSensitiveFieldsFromState = (storageStepThree) => {
  const sensitiveFields: string[] = [];
  const insurers = ['owner', 'coOwner', 'coCoOwner', 'driver'];

  insurers.forEach((insurer) => {
    if (storageStepThree?.[insurer]) {
      if (storageStepThree[insurer].firstName) {
        sensitiveFields.push(`${insurer}-firstName`);
      }
      if (storageStepThree[insurer].lastName) {
        sensitiveFields.push(`${insurer}-lastName`);
      }
      if (storageStepThree[insurer].pesel) {
        sensitiveFields.push(`${insurer}-pesel`);
      }
      if (storageStepThree[insurer].maidenName) {
        sensitiveFields.push(`${insurer}-maidenName`);
      }
    }
  });
  return sensitiveFields;
};

export const handleLoadLocalState = async () => {
  const localStorageState = loadState('local');
  const { payload, user } = localStorageState || {};
  const { mgm, stepThree } = payload || {};
  const { businessProcess, locator, sessionId } = user || {};

  localStorageState.step = 1;
  localStorageState.sensitiveData = getSensitiveFieldsFromState(stepThree);

  removeShortLocatorFromSessionStorage(locator);
  removeRecalculationPayloadFromSessionStorage();

  if (isRopRen(businessProcess)) {
    sessionStorage.setItem('isRopLoadedFromStorage', 'true');
    window.location.href = createNavLink(ROUTING_BASE, user);
    return;
  }

  if (mgm?.code) {
    const { code } = mgm;
    try {
      const { data } = await promoCodesApi.verifyPromoCode(locator, code, sessionId);
      if (data) {
        localStorageState.payload.mgm = {
          code,
          status: data.success ? 'valid' : 'invalid',
          message: data.message,
        };
      } else {
        localStorageState.payload.mgm = initialMgm;
      }
    } catch (e) {
      localStorageState.payload.mgm = initialMgm;
      sendError(e, false, {
        method: 'validatePromoCode',
        component: 'Form',
      });
    }
  }
  saveState(localStorageState, true);

  window.location.reload();
};

export const getPropertyFromLocalStorageById = (localStorageKey: string, id: number, propName?: string): unknown => {
  const localStorageArrayOfObjects = localStorage.getItem(localStorageKey);
  const localStorageArrayOfObjectsParsed = JSON.parse(localStorageArrayOfObjects || '{}') as Record<string, unknown>[];
  if (localStorageArrayOfObjectsParsed?.length && Array.isArray(localStorageArrayOfObjectsParsed)) {
    const lookedForObject = localStorageArrayOfObjectsParsed.find(storageObject => storageObject?.id === id);
    if (propName) {
      return lookedForObject?.[propName] ?? '';
    }
    return lookedForObject;
  }
  return '';
};

export const updateInsurerStore = (stepOrigin, payload) => {
  if (stepOrigin === 'fs') {
    return (editStepOneFsInsurerParam(payload));
  }
  return (editStepThreeInsurerParam(payload));
};

export const createFullInsurer = <T extends TInsurerType>(type: T, insurer: IInsurer<T>): null | IInsurerFull<T> => {
  if (insurer !== null) {
    const { addressRegisteredIdentical, address, addressRegistered } = insurer;

    return ({
      ...generateInitialInsurerFull<T>(type),
      ...insurer,
      address: {
        ...generateInitialAddressFull<TAddressAndRegistered | TAddress>(addressRegisteredIdentical ? ADDRESS_AND_REGISTERED : ADDRESS),
        ...address,
      },
      addressRegistered: addressRegisteredIdentical ? null : {
        ...generateInitialAddressFull<TRegistered>(REGISTERED),
        ...addressRegistered,
      },
    });
  }
  return null;
};

export const deleteStore = (dispatch?: Dispatch) => {
  // if passed dispatch, will force deleteStore
  if (dispatch) {
    dispatch(resetStore());
  }
  localStorage.removeItem('state');
  sessionStorage.removeItem('state');
};

export const resetLocalStorageVisitedSteps = () => {
  const localStorageState = localStorage.getItem('state');
  const parsedLocalStorageState = localStorageState && JSON.parse(localStorageState);

  if (parsedLocalStorageState?.visitedSteps) {
    localStorage.setItem('state', JSON.stringify({ ...parsedLocalStorageState, visitedSteps: [] }));
  }
};
