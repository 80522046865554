import { Button } from 'porowneo-design';
import styled from 'styled-components';
import { below, VARS } from 'porowneo-ui';

interface IThankYouSection {
  closeModal: () => void;
  message?: string;
}

const SectionWrapper = styled('div')`
  min-height: 152px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${below(VARS.breakpoints.md)} {
    min-height: 132px;
  }
`;
SectionWrapper.displayName = 'SectionWrapper';

const ThankYouSection = ({
  closeModal,
  message = '',
}: IThankYouSection) => (
  <SectionWrapper>
    <h1 className="wrapper__header-title wrapper__header-title--text-centered wrapper__header-title--margin">
      Dziękujemy!
      <br />
      {message || 'Niebawem skontaktujemy się z Tobą.'}
    </h1>

    <Button
      id="closeButton"
      handleClick={() => closeModal()}
      fullWidth
      variant="contained"
    >
      Zamknij
    </Button>
  </SectionWrapper>
);

export default ThankYouSection;
