/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import { connect } from 'react-redux';

import { editStepThreeInsurerParam, editStepOneParam, editStepOneFsInsurerParam } from '../../actions/Payload';
import { Security } from '../../assets/icon/Security';
import { completeExtraDataPopup, handleCallGtmFieldErrorStep, pushSaveModalUfgDataFullSaleToDataLayer } from '../../helpers/tagManager/TagManagerUtils';
import { setSensitiveInActive } from '../../actions/SensitiveData';
import { isICarDictionaryData } from '../../views/stepOne/utils/HandleChange';
import { closeModal } from '../../actions';

import InfoExpert from './fieldsToComplete/InfoExpert';
import UFG from './fieldsToComplete/UFG';

import type { TReCalculate, TStateUpgradeParams } from '../../views/Results/Results.types';
import type { Dispatch } from 'redux';
import type { ICarDictionaryDataItem } from '../../types/Car.type';
import type { IInsurer, TInsurerBasePerson } from '../../types/Insurer.type';
import type { IRootState } from '../../reducers';

const initialUfgInsurer = <T extends TInsurerBasePerson>(insObj: null | IInsurer<T>) => ({
  firstName: insObj?.firstName || '',
  lastName: insObj?.lastName || '',
  maidenName: insObj?.maidenName || null,
  pesel: insObj?.pesel || '',
  birthdate: insObj?.birthdate || '',
  sex: insObj?.sex || '',
  type: insObj?.type || '',
});

const SENSITIVE_FIELDS_TO_CHECK_SUFFIX = ['-firstName', '-lastName', '-pesel'];

type TCompleteFieldsProps = ReturnType<typeof mapStateToProps> & {
  reCalculate: TReCalculate;
  needComplete: 'UFG' | 'InfoEkspert';
};

export class CompleteFields extends Component<TCompleteFieldsProps, any> {
  constructor(props: TCompleteFieldsProps) {
    super(props);
    this.state = {
      owner: props.owner ? initialUfgInsurer(props.owner) : null,
      coOwner: props.coOwner ? initialUfgInsurer(props.coOwner) : null,
      coCoOwner: props.coCoOwner ? initialUfgInsurer(props.coCoOwner) : null,
      driver: props.driver ? initialUfgInsurer(props.driver) : null,
      infoexVersion: props.vehicle.infoexVersion || null,
      infoexVersionCode: props.vehicle.infoexVersionCode || null,
    };
  }

  recalculateAndClose = async () => {
    const { props, state } = this;
    const {
      reCalculate,
      needComplete,
      dispatch,
      activeFullSaleInsurer,
      locator,
      stepOneFs,
      sensitiveData,
    } = props;

    const {
      infoexVersion,
      infoexVersionCode,
    } = state;

    if (needComplete === 'UFG') {
      const insurers = ['owner', 'coOwner', 'coCoOwner', 'driver'] as const;
      if (activeFullSaleInsurer) {
        pushSaveModalUfgDataFullSaleToDataLayer(activeFullSaleInsurer);
      }

      insurers.forEach((insurer) => {
        if (state[insurer]) {
          dispatch(editStepThreeInsurerParam({ ...state[insurer] }));
          if (stepOneFs[insurer] !== null) {
            dispatch(editStepOneFsInsurerParam({ ...stepOneFs[insurer], ...state[insurer] }));
          }

          SENSITIVE_FIELDS_TO_CHECK_SUFFIX.forEach((suffix) => {
            const sensitiveFieldToCheck = `${insurer}${suffix}`;
            if (!sensitiveData.includes(sensitiveFieldToCheck)) {
              dispatch(setSensitiveInActive(sensitiveFieldToCheck));
            }
          });
        }
      });
    }

    if (needComplete === 'InfoEkspert') {
      dispatch(editStepOneParam({
        infoexVersion,
        infoexVersionCode,
      }, 'vehicle'));
    }

    await reCalculate(state as TStateUpgradeParams);
    completeExtraDataPopup({ calculation_id: locator });
    return dispatch(closeModal());
  }

  handleChange = (key: string, value: any, type: any) => {
    if (type) {
      this.setState(prevState => ({ [type]: { ...prevState[type], [key]: value } }));
    } else {
      this.setState({ [key]: value });
    }
  }

  setSensitiveDataInactive = (id) => {
    const { dispatch } = this.props;
    return dispatch(setSensitiveInActive(id));
  };

  handleInfoExpertChange = ({ key, value, allData }: { key: string, value: any, allData: ICarDictionaryDataItem[] }) => {
    this.setState({
      [key]: value,
      infoexVersionCode: (isICarDictionaryData(allData) && allData.code) || (value === 0 ? value : null),
    });
  }

  override render() {
    const {
      props: {
        needComplete,
        coowners,
        mainDriver,
        activeFullSaleInsurer,
        vehicle,
        filters,
        step,
        sessionId,
        locator,
        sensitiveData,
      },
      state: {
        owner,
        coOwner,
        coCoOwner,
        driver,
        infoexVersion,
      },
      recalculateAndClose,
      handleChange,
      handleInfoExpertChange,
    } = this;

    return (
      <div className="col p-0">
        <h1 className="wrapper__header-title wrapper__header-title--contact">
          {
            activeFullSaleInsurer ?
              'Uzupełnij dane, aby kupić polisę' :
              'Uzupełnij dane, aby zobaczyć ofertę'
          }
        </h1>

        <div className="modal-default">
          <div>
            {needComplete === 'UFG' && (
              <UFG
                coowners={coowners}
                mainDriver={mainDriver}
                handleChange={handleChange}
                callBackIfValid={recalculateAndClose}
                stepOrigin={step}
                handleCallGtmFieldError={handleCallGtmFieldErrorStep('Extra data popup')}
                owner={owner}
                coOwner={coOwner}
                coCoOwner={coCoOwner}
                driver={driver}
                sensitiveData={sensitiveData}
                setSensitiveDataInactive={this.setSensitiveDataInactive}
              />
            )}
            {needComplete === 'InfoEkspert' && (
              <InfoExpert
                vehicle={{
                  ...vehicle,
                  infoexVersion,
                }}
                filters={filters}
                handleChange={handleInfoExpertChange}
                callBackIfValid={recalculateAndClose}
                sessionId={sessionId}
                locator={locator}
              />
            )}
          </div>

          {(needComplete === 'UFG') && (
            <div className="wrapper__seciurity">
              <Security />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  step,
  user: {
    sessionId,
    locator,
  },
  payload: {
    stepOne: {
      vehicle,
      filters,
    },
    stepThree: {
      coowners, mainDriver, owner, coOwner, coCoOwner, driver,
    },
    stepOneFs: {
      owner: ownerFromFs, coOwner: coOwnerFromFs, coCoOwner: coCoOwnerFromFs, driver: driverFromFs,
    },
  },
  activeFullSaleInsurer,
  sensitiveData,
  dispatch,
}: IRootState & { dispatch: Dispatch }) => ({
  filters,
  vehicle,
  owner,
  coOwner,
  coCoOwner,
  driver,
  step,
  coowners,
  mainDriver,
  activeFullSaleInsurer,
  sensitiveData,
  sessionId,
  locator,
  dispatch,
  stepOneFs: {
    owner: ownerFromFs, coOwner: coOwnerFromFs, coCoOwner: coCoOwnerFromFs, driver: driverFromFs,
  },
});

export default connect(mapStateToProps)(CompleteFields);
