// TO DO FIX ANY TYPES
// ! In types declarations always set initial or default value type first e.g.
// ! type a = null | 'a' | ... OR type b = '' | 'b' | ... OR type c = 0 | 10 | ...
import log from 'loglevel';
import omit from 'lodash/omit';

import {
  EDIT_PARAM,
  SET_PAYLOAD,
  RESET_PAYLOAD,
  EDIT_STEP_ONE_PARAM,
  EDIT_STEP_TWO_PARAM,
  EDIT_STEP_THREE_PARAM,
  EDIT_STEP_THREE_INSURER_PARAM,
  EDIT_STEP_FOUR_PARAM,
  EDIT_STEP_FOUR_HISTORY_PARAM,
  EDIT_STEP_FOUR_HISTORY_PARAM_BY_COVER,
  EDIT_STEP_ONE_FS_PARAM,
  EDIT_STEP_TWO_FS_PARAM,
  RESET_FULL_SALE_PAYLOAD,
  EDIT_STEP_ONE_FS_INSURER_PARAM,
  RESET_STEP_TWO_FS_PAYLOAD,
} from '../actions/Payload';
import { ADDRESS_AND_REGISTERED } from '../config/Address.literal';
import { OWNER } from '../config/InsurerPersons.literal';

import type { IFilter } from '../views/stepOne/components/baseCarDataSection/BaseCarDataSection.types';
import type {
  IInsurer,
  IInsurerFull,
  IInsurerHistoryPayload,
  IInsurerHistoryPerCover,
  TCoCoOwner,
  TCoOwner,
  TDriver,
  TInsurer,
  TInsurerAdditionalPerson,
  TInsurerBasePerson,
  TInsurerType,
  TOwner,
  TYoungPerson,
} from '../types/Insurer.type';
import type {
  TAddressType,
  IAddress,
  IAddressFull,
  TAddressAndRegistered,
} from '../types/Address.type';
import type { IVehicle } from '../types/Car.type';
import type { IContactData } from '../types/ContactData.type';
import type { TLocator } from '../types/Locator.type';
import type { promoCodes } from '../config/main';
import type { TRange } from '../helpers/TypeHelpers';
import type { TCorrectDataOrigin } from '../api/carProject/CarProject.types';
import type { IInstalment } from '../types/DaemonOutput.type';

export const CAR_SECURITIES = ['autoalarm', 'immobiliser', 'gear_lock'] as const;

export type TYesNo = 'yes' | 'no'; // TODO remove this type FORMMOTO-3484

export type TCoverType = 'oc' | 'ac';
export type TPaymentType = 'bankTransfer' | 'onlinePayment';
export type TDate = string;
export type TMgmCodeKeys = keyof typeof promoCodes;
export type TMgmCode = typeof promoCodes[TMgmCodeKeys][number];
export type TMgmStatus = 'valid' | 'invalid';
export type TLastInsurerName = string;
export type TLastInsurerId = null | number;
export type TCarSecurity = typeof CAR_SECURITIES[number];

export interface IPayloadStepOne {
  dataOrigin: TCorrectDataOrigin,
  phone: IContactData['phone'],
  protectionBegins: TDate,
  ac: boolean,
  nnw: boolean,
  // view Props
  vehicle: IVehicle,
  filters: IFilter,
}

export interface IPayloadStepTwo {
  carRegisteredOn: 'person' | 'selfEmployment' | 'leasingCompany' | 'company',
  carUsage: 'private' | 'taxi' | 'learn' | 'transport' | 'rally' | 'other',
  actualKm: null | number, // field name -> carMileage // TODO rename FORMMOTO-3485
  mileageEstimator: '' | '2500' | '7500' | '12500' | '17500' | '25000' | '35000' | '50000' | '70000' | '90000' | '120000', // field name -> planToDriveThisYear // TODO rename FORMMOTO-3485
  whichOwner: '' | 'first' | 'next',
  firstCountry: '' | 'pol' | 'eur' | 'oth' | 'gbr' | 'usa',
  firstImmat: TDate | null, // → date format YYYY-MM-DD
  ownerRegisteredDate: null | TDate,
  yearOwnerRegistered: number,
  plate: null | string,
  rightWheel: boolean,
  night: '' | 'private_garage' | 'driveway' | 'street' | 'shared_garage' | 'guarded_parking',
  abroad: 'none' | 'less_1_mo' | '1_to_3_mo' | 'more_3_mo',
  damage: boolean,
  damageDetail: null | 'scratch' | 'dent' | 'serious',
  regInPl: null | boolean,
  modification: boolean,
  modDetail: null | 'body' | 'wheels' | 'painting' | 'suspension' | 'adapting_for_disabled_person' | 'engine_and_transmission' | 'seats' | 'steering_and_braking' | 'exhaust',
  lastInsurerName: TLastInsurerName, // insurer name from api
  lastInsurerAc: TLastInsurerId, // insurer id from api relates to lastInsurerName
  lastInsurerOc: TLastInsurerId, // insurer id from api relates to lastInsurerName
}

export interface IPayloadStepThree {
  hasChildren: boolean,
  driverUnder26: null | boolean,
  coowners: TRange<0, 3>,
  mainDriver: '' | TInsurerBasePerson,
  numberOfChilds: null | TRange<0, 5>, // null is default value
  hasChildrenU26: null | boolean, // null is default value
  ageOldestChild: null | TRange<0, 26>, //  null is default value // TODO FORMMOTO-3481
  owner: IInsurer<TOwner>,
  coOwner: null | IInsurer<TCoOwner>,
  coCoOwner: null | IInsurer<TCoCoOwner>,
  driver: null | IInsurer<TDriver>,
  youngPerson: null | IInsurer<TYoungPerson>,
}

export interface IPayloadStepFour {
  ownerInsuranceHistory: IInsurerHistoryPayload<TOwner>,
  coOwnerInsuranceHistory: null | IInsurerHistoryPayload<TCoOwner>,
  coCoOwnerInsuranceHistory: null | IInsurerHistoryPayload<TCoCoOwner>,
  driverInsuranceHistory: null | IInsurerHistoryPayload<TDriver>,
  email: IContactData['email'],
}

export interface IPayloadStepOneFs {
  isFullSale: boolean,
  insurerPerson: '' | TInsurerBasePerson | Extract<TInsurerAdditionalPerson, 'insurer'>,
  owner: null | IInsurerFull<TOwner>,
  coOwner: null | IInsurerFull<TCoOwner>,
  coCoOwner: null | IInsurerFull<TCoCoOwner>,
  driver: null | IInsurerFull<TDriver>,
  insurer: null | IInsurerFull<TInsurer>,
  emailFromFs: IPayloadStepFour['email'],
  phoneFromFs: IPayloadStepOne['phone'],
  emailFromFsAdditional: IPayloadStepFour['email'],
  phoneFromFsAdditional: IPayloadStepOne['phone'],
  insurerUserId: IInsurerFull<TInsurerType>['userId'],
  carRegistrationNumber: IPayloadStepTwo['plate'],
  vinNumber: string,
  // Additional fields
  company: ICompany,
  fromAbroadLastYear: null | boolean, // Only tuz has boolean value
  carSecurity: null | TCarSecurity[],
  firstImmatInPoland: null | IPayloadStepTwo['firstImmat'],
  technicalInspectionDate: null |TDate, // date format DD-MM-YYYY
  carKeysNumber: null | TRange<1, 4>, // null is default value
  holdKeysNumberExtra: null | TRange<0, 3>, // null is default value
  hasServiceHistory: '' | TYesNo, // TODO change to null | boolean FORMMOTO-3484
  wasModified: '' | TYesNo, // TODO change to null | boolean FORMMOTO-3484
  damagesDetails: null | string, // text with maxLength 128 characters
}

export interface IInstalmentsVariant {
  [key: number]: IInstalment
}

interface IMgm {
  code: '' | TMgmCode | string;
  status: '' | TMgmStatus;
  message: string; // message from api response
}

interface ICompany {
  nip: string,
  vatType: 'netto' | 'brutto' | 'vat50',
}

export const initialCompany: ICompany = {
  nip: '',
  vatType: 'brutto',
};

export interface IPayloadStepTwoFs {
  partnerId: null | number,
  userAgreements: Record<string, boolean>,
  userPartnerAgreements: Record<number, boolean>
  installments: number,
  installmentsVariants: IInstalmentsVariant[],
  paymentType: '' | TPaymentType,
  originalOfferPrice: null | number,
}
export interface IPayload {
  mgm: IMgm,
  carProjectVersionId: null | number,
  projectId: null | number,
  ktApiProjectId: null | number,
  locator: TLocator,
  stepOne: IPayloadStepOne,
  stepTwo: IPayloadStepTwo,
  stepThree: IPayloadStepThree,
  stepFour: IPayloadStepFour,
  stepOneFs: IPayloadStepOneFs,
  stepTwoFs: IPayloadStepTwoFs,
}

const createReducer = (initialState: IPayload, handlers: any) => (
  state = initialState,
  action: any,
  ...params: any
): IPayload => {
  if (typeof handlers[action.type] !== 'undefined') {
    return handlers[action.type](state, action, ...params);
  }
  return state;
};

export const generateInitialAddress = <T extends TAddressType> (type: T): IAddress<T> => ({
  type,
  zipCode: '',
  city: '',
  district: '',
});

export const generateInitialAddressFull = <T extends TAddressType> (type: T): IAddressFull<T> => ({
  ...generateInitialAddress(type),
  street: null,
  flat: null,
  building: null,
});

export const generateInitialInsurer = <T extends TInsurerType> (type: T): IInsurer<T> => ({
  type,
  userId: null,
  firstName: null,
  lastName: null,
  maidenName: null,
  birthdate: '',
  pesel: null,
  licenseDate: null,
  licenseDateInitMonth: true,
  hasDrivingLicense: true,
  sex: '',
  maritalStatus: null,
  addressRegisteredIdentical: true,
  address: generateInitialAddress<TAddressAndRegistered>(ADDRESS_AND_REGISTERED),
  addressRegistered: null,
});

export const generateInitialInsurerFull = <T extends TInsurerType> (type: T): IInsurerFull<T> => ({
  ...generateInitialInsurer<T>(type),
  address: generateInitialAddressFull<TAddressAndRegistered>(ADDRESS_AND_REGISTERED),
  addressRegistered: null,
});

export const setHistoryPerCover = <T extends TCoverType> (type: T): IInsurerHistoryPerCover<T> => ({
  type,
  yearsBuying: null,
  damagesPaid: null,
  firstDamage: null,
  secondDamage: null,
  thirdDamage: null,
  fourthDamage: null,
  fifthDamage: null,
});

export const setInsuranceHistory = <T extends TInsurerBasePerson> (insurerType: T): IInsurerHistoryPayload<T> => ({
  type: insurerType,
  carUser: null,
  insuranceHistorySame: true,
  ocInsuranceHistory: setHistoryPerCover<'oc'>('oc'),
  acInsuranceHistory: null,
});

export const initialMgm: IMgm = {
  code: '',
  status: '',
  message: '',
};

export const initialPayload: IPayload = {
  carProjectVersionId: null,
  projectId: null,
  ktApiProjectId: null,
  locator: '',
  mgm: initialMgm,
  stepOne: {
    dataOrigin: 'www2',
    phone: '',
    protectionBegins: '',
    ac: false,
    nnw: false,
    filters: {},
    // view Props
    vehicle: {
      brand: null,
      model: '',
      fuel: '',
      transmission: '',
      productionYear: null,
      lpg: false,
      eurotaxVersion: null,
      eurotaxVersionCode: null,
      eurotaxVersionType: null,
      infoexVersion: null,
      infoexVersionCode: null,
    },
  },
  stepTwo: {
    carRegisteredOn: 'person',
    carUsage: 'private',
    actualKm: null,
    mileageEstimator: '',
    whichOwner: '',
    firstCountry: '',
    firstImmat: '', // → date format YYYY-MM-DD
    ownerRegisteredDate: '',
    yearOwnerRegistered: 0,
    plate: null,
    rightWheel: false,
    night: '',
    abroad: 'none',
    damage: false,
    damageDetail: null,
    regInPl: null,
    modification: false,
    modDetail: null,
    lastInsurerAc: null,
    lastInsurerOc: null,
    lastInsurerName: '',
  },
  stepThree: {
    hasChildren: false,
    driverUnder26: null,
    coowners: 0,
    mainDriver: '',
    numberOfChilds: null,
    hasChildrenU26: null,
    ageOldestChild: null,
    owner: generateInitialInsurer<TOwner>(OWNER),
    coOwner: null,
    coCoOwner: null,
    driver: null,
    youngPerson: null,
  },
  stepFour: {
    ownerInsuranceHistory: setInsuranceHistory<TOwner>(OWNER),
    coOwnerInsuranceHistory: null,
    coCoOwnerInsuranceHistory: null,
    driverInsuranceHistory: null,
    email: '',
  },
  stepOneFs: {
    isFullSale: false,
    insurerUserId: null,
    insurerPerson: '',
    emailFromFs: '',
    phoneFromFs: '',
    emailFromFsAdditional: '',
    phoneFromFsAdditional: '',
    owner: null,
    coOwner: null,
    coCoOwner: null,
    driver: null,
    insurer: null,
    company: initialCompany,
    firstImmatInPoland: null,
    technicalInspectionDate: null,
    fromAbroadLastYear: null,
    carRegistrationNumber: '',
    vinNumber: '',
    carSecurity: [],
    carKeysNumber: null,
    holdKeysNumberExtra: null,
    hasServiceHistory: '',
    wasModified: '',
    damagesDetails: null,
  },
  stepTwoFs: {
    partnerId: null,
    userAgreements: {},
    userPartnerAgreements: {},
    installments: 1,
    installmentsVariants: [],
    paymentType: '',
    originalOfferPrice: null,
  },
};

function setPayload(state: IPayload, action: any) {
  return {
    ...initialPayload,
    ...state,
    ...action.payload,
  };
}

function resetPayload() {
  return initialPayload;
}

function editPayloadParam(state: IPayload, action: any) {
  return {
    ...state,
    ...action.payload,
  };
}

type TKeyToSet = keyof IPayloadStepOne | keyof IVehicle | keyof IFilter;

function editStepOneParam(
  state: IPayload,
  {
    payload,
    nestedKey,
    keysToReset,
  } : {
    payload: IPayloadStepOne | IVehicle,
    nestedKey?: '' | 'vehicle' | 'filters',
    keysToReset?: Array<keyof IPayloadStepOne> | Array<keyof IVehicle>
  },
) {
  let resetValue = {};

  const isVehicleKey = (keyToSet: TKeyToSet): keyToSet is keyof IVehicle => nestedKey === 'vehicle';
  const isFilterKey = (keyToSet: TKeyToSet): keyToSet is keyof IFilter => nestedKey === 'filters';
  if (nestedKey) {
    if (keysToReset && keysToReset?.length > 0) {
      keysToReset.forEach((key:TKeyToSet) => {
        if (isVehicleKey(key) || isFilterKey(key)) {
          resetValue = {
            ...resetValue,
            [key]: initialPayload.stepOne[nestedKey][key],
          };
        }
      });
    }
    if (nestedKey === 'filters') {
      const omittedFilters = omit({ ...state.stepOne[nestedKey], ...payload }, [...Object.keys(resetValue)]);

      return ({
        ...state,
        stepOne: {
          ...state.stepOne,
          [nestedKey]: omittedFilters,
        },
      });
    }

    return {
      ...state,
      stepOne: {
        ...state.stepOne,
        [nestedKey]: {
          ...state.stepOne[nestedKey],
          ...payload,
          ...resetValue,
        },
      },
    };
  }

  if (keysToReset && keysToReset?.length > 0) {
    keysToReset.forEach((key: keyof IPayloadStepOne | keyof IVehicle) => {
      if (!isVehicleKey(key)) {
        resetValue = {
          ...resetValue,
          [key]: initialPayload.stepOne[key],
        };
      }
    });
  }

  return {
    ...state,
    stepOne: {
      ...state.stepOne,
      ...payload,
      ...resetValue,
    },
  };
}

function editStepTwoParam(
  state: IPayload,
  {
    payload,
    keysToReset,
  }: {
  payload: IPayloadStepTwo,
  keysToReset?: Array<keyof IPayloadStepTwo>
},
) {
  let resetValue = {};

  if (keysToReset && keysToReset?.length > 0) {
    keysToReset.forEach((key) => {
      resetValue = {
        ...resetValue,
        [key]: initialPayload.stepTwo[key],
      };
    });
  }
  return {
    ...state,
    stepTwo: {
      ...state.stepTwo,
      ...payload,
      ...resetValue,
    },
  };
}

function editStepThreeParam(state: IPayload, action: any) {
  return {
    ...state,
    stepThree: {
      ...state.stepThree,
      ...action.payload,
    },
  };
}

const editInsurerParam = (target: 'stepThree' | 'stepOneFs', state: IPayload, action: any) => ({
  ...state,
  [target]: {
    ...state[target],
    [action.payload.type]: {
      ...state[target][action.payload.type],
      ...action.payload,
    },
  },
});

function editStepThreeInsurerParam(state: IPayload, action: any) {
  return editInsurerParam('stepThree', state, action);
}

function editStepFourParam(state: IPayload, action: any) {
  return {
    ...state,
    stepFour: {
      ...state.stepFour,
      ...action.payload,
    },
  };
}

function editStepOneFsParam(state: IPayload, action: any) {
  return {
    ...state,
    stepOneFs: {
      ...state.stepOneFs,
      ...action.payload,
    },
  };
}

function editStepOneFsInsurerParam(state: IPayload, action: any) {
  return editInsurerParam('stepOneFs', state, action);
}

function editStepTwoFsParam(state: IPayload, action: any) {
  return {
    ...state,
    stepTwoFs: {
      ...state.stepTwoFs,
      ...action.payload,
    },
  };
}

function resetFullSale(state: IPayload) {
  return {
    ...state,
    stepOneFs: initialPayload.stepOneFs,
    stepTwoFs: initialPayload.stepTwoFs,
  };
}

function resetStepTwoFSPayload(state: IPayload) {
  return {
    ...state,
    stepTwoFs: initialPayload.stepTwoFs,
  };
}

function editStepFourInsuranceHistoryParamByCover(state: IPayload, { payload, who, cover }: any) {
  log.debug('editStepFourInsuranceHistoryParamByCover', payload, who, cover);
  if (!who || !cover) return state;
  if (cover !== 'ac' && cover !== 'oc') return state;
  return {
    ...state,
    stepFour: {
      ...state.stepFour,
      [`${who}InsuranceHistory`]: {
        ...state.stepFour[`${who}InsuranceHistory`],
        [`${cover}InsuranceHistory`]: {
          ...state.stepFour[`${who}InsuranceHistory`][`${cover}InsuranceHistory`],
          ...payload,
        },
      },
    },
  };
}

function editStepFourInsuranceHistoryParam(state: IPayload, { payload, who }: any) {
  if (!who) return state;
  return {
    ...state,
    stepFour: {
      ...state.stepFour,
      [`${who}InsuranceHistory`]: {
        ...state.stepFour[`${who}InsuranceHistory`],
        ...payload,
      },
    },
  };
}

const payloadReducer = createReducer(initialPayload, {
  [EDIT_PARAM]: editPayloadParam,
  [SET_PAYLOAD]: setPayload,
  [RESET_PAYLOAD]: resetPayload,
  [EDIT_STEP_ONE_PARAM]: editStepOneParam,
  [EDIT_STEP_TWO_PARAM]: editStepTwoParam,
  [EDIT_STEP_THREE_PARAM]: editStepThreeParam,
  [EDIT_STEP_THREE_INSURER_PARAM]: editStepThreeInsurerParam,
  [EDIT_STEP_FOUR_PARAM]: editStepFourParam,
  [EDIT_STEP_FOUR_HISTORY_PARAM]: editStepFourInsuranceHistoryParam,
  [EDIT_STEP_FOUR_HISTORY_PARAM_BY_COVER]: editStepFourInsuranceHistoryParamByCover,
  [EDIT_STEP_ONE_FS_PARAM]: editStepOneFsParam,
  [EDIT_STEP_ONE_FS_INSURER_PARAM]: editStepOneFsInsurerParam,
  [EDIT_STEP_TWO_FS_PARAM]: editStepTwoFsParam,
  [RESET_FULL_SALE_PAYLOAD]: resetFullSale,
  [RESET_STEP_TWO_FS_PAYLOAD]: resetStepTwoFSPayload,
});

export default payloadReducer;
