import axios from 'axios';

import { axiosConfigWithSession, createUrlInsurersToShow } from '../config/url';

import type { AxiosResponse } from 'axios';

const dictionary = {
  async getInsurersToShow(locator: string, sessionId: string): Promise<AxiosResponse> {
    const axiosConfig = axiosConfigWithSession(sessionId, locator);
    const insurer = await axios.get(createUrlInsurersToShow(), axiosConfig);
    if (insurer.data) {
      localStorage.setItem(createUrlInsurersToShow(), JSON.stringify(insurer.data));
    }
    return insurer;
  },
};

export default dictionary;
