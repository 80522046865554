/* eslint-disable camelcase */
import {
  ValidationHoc as validationHoc, AgreementsContainerV2 as AgreementsContainer,
} from 'porowneo-ui';
import { Button } from 'porowneo-design';

import { agreementsOrder } from '../config/main';
import FIELDS from '../config/fields';

import { CheckboxField } from './fields/CheckboxField';
import { InputField } from './fields/inputField/InputField';

import type { IValidationProps, TAgreement } from 'porowneo-ui';
import type { IUser } from '../reducers';

interface IOfferRemindForm extends Pick<IValidationProps, 'handleSetValidation'> {
  user: Pick<IUser, 'email' | 'phoneLeaveContact'> & { firstName: IUser['name'] };
  userAgreements: IUser['userAgreements'];
  stepOrigin: string | number;
  agreements: TAgreement<'api'>[];
  handleChange: () => void;
  triggerValidation: () => void;
  neededAgreements: string[];
  handleSetAgreements: () => void;
  requiredAgreements: string[];
  status: string;
}

export const OfferRemindForm = ({
  user = {
    firstName: '',
    email: '',
    phoneLeaveContact: '',
  },
  userAgreements,
  stepOrigin,
  agreements,
  handleChange,
  triggerValidation,
  neededAgreements = [],
  handleSetAgreements,
  requiredAgreements = [],
  status,
  handleSetValidation,
}: IOfferRemindForm) => (
  <div>
    <InputField
      {...FIELDS.name}
      id="firstName"
      handleChange={handleChange}
      value={user.firstName}
      stepOrigin={stepOrigin}
      order={1}
      handleSetValidation={handleSetValidation}
    />

    <InputField
      {...FIELDS.email}
      required
      validations={{
        ...FIELDS?.email?.validations,
        required: true,
      }}
      handleChange={handleChange}
      value={user.email}
      stepOrigin={stepOrigin}
      order={2}
      handleSetValidation={handleSetValidation}
    />

    <InputField
      {...FIELDS.phoneLeaveContact}
      handleChange={handleChange}
      value={user.phoneLeaveContact}
      stepOrigin={stepOrigin}
      order={3}
      handleSetValidation={handleSetValidation}
    />

    <AgreementsContainer
      id="needContactAgreements"
      agreements={agreements}
      neededAgreements={neededAgreements}
      handleChange={handleSetAgreements}
      requiredAgreements={requiredAgreements}
      userAgreements={userAgreements}
      stepOrigin={stepOrigin ? parseInt(stepOrigin?.toString(), 10) : 0}
      startOrder={4}
      handleSetValidation={handleSetValidation}
      agreementsOrder={agreementsOrder}
      CheckboxComponent={CheckboxField as any} // TODO FORMMOTO-3709 Fix after moving agreementsContainer to porowneo-design
    />

    <Button
      id="remindButton"
      handleClick={() => (status !== 'validating' ? triggerValidation() : '')}
      fullWidth
      variant="contained"
    >
      {status === 'validating' ? 'Przypominanie...' : 'Przypomnij mi'}
    </Button>
  </div>
);

// TODO TS
export default validationHoc<any>(OfferRemindForm);
