import styled from 'styled-components';
import { PartnerLogo, PartnerGroupTooltip } from 'porowneo-ui';

import consultant from '../../assets/img/consultant.png';
import { phoneNumber } from '../../helpers/phone';

const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F8F6F8;
  padding: 24px 24px 24px 32px;
  height: 100px;
  width: 100%;

  @media (max-width: 719px) {
    height: 134px;
    padding: 24px 24px 15px 24px;
  }

  .contact-box__phone-info {
    display: flex;
    min-width: 80%;
  }

  @media (max-width: 719px) {
    .contact-box__phone-info {
      min-width: 60%;
    }
  }

  .contact-box__phone-info-img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }

  @media (max-width: 719px) {
    .contact-box__phone-info-img {
      display: none;
    }
  }

  .contact-box__phone-info-title {
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 10px 0;
  }

  @media (max-width: 719px) {
    .contact-box__phone-info-title {
      line-height: 20px;
      width: 150px;
    }
  }

  .contact-box__phone-info-link {
    text-decoration: none;
    color: #334457;
  }

  .contact-box__phone-info-content {
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: #334457;
  }

  .contact-box__insurer-logo {
    height: 42px;
  }

  @media (max-width: 719px) {
    .contact-box__insurer-logo {
      height: 34px;
    }
  }
`;

interface IContactInfoBox {
  name: string;
}

const ContactInfoBox = ({ name }: IContactInfoBox) => (
  <ContactBox>
    <div className="contact-box__phone-info">
      <img
        src={consultant}
        alt="consultant"
        className="contact-box__phone-info-img"
        width="50px"
        height="50px"
      />
      <div>
        <p className="contact-box__phone-info-title">Skontaktuj się z naszym konsultantem</p>
        <a className="contact-box__phone-info-link" href={`tel:${phoneNumber}`}>
          <span
            className="contact-box__phone-info-content"
          >
            {phoneNumber}
          </span>
        </a>
      </div>
    </div>
    <LogoBox>
      <PartnerLogo
        partnerName={name}
        maxWidth="90px"
        maxHeight="42px"
        maxMobileWidth="90px"
        maxMobileHeight="32px"
      />
      <PartnerGroupTooltip
        partner={name}
      />
    </LogoBox>
  </ContactBox>
);

export default ContactInfoBox;
