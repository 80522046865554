export const licenseMonth = 'Miesiąc uzyskania uprawnienia kat. B';

export const currentAdr = 'Miejscowość zamieszkania';
export const registrationAdr = 'Miejscowość zameldowania';

export const damagesPaidFromPolicies = 'Czy z polis OC były wypłacone odszkodowania?';
export const whenWosFirstDamage = 'Kiedy była pierwsza szkoda?';
export const whenWosSecondDamage = 'Kiedy była druga szkoda?';
export const whenWosThirdDamage = 'Kiedy była trzecia szkoda?';
export const whenWosFifthDamage = 'Kiedy była piąta szkoda?';
export const damagesPaidFromPoliciesAc = 'Czy z polis AC były wypłacone odszkodowania?';
